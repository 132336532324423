<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a href="#" class="sidebar-brand">
        <img src="../../assets/logo.png" style="width: 120px" />
      </a>
    </div>
    <div class="sidebar-body">
      <ul class="nav" style="padding-left:0;">
        <!-- ## AI 튜터 숨김 처리 ## -->
        <li class="nav-item">
          <a href="/aibanner" target="_self" class="nav-link">
            <i class="link-icon" data-feather="phone"></i>
            <span :class="{ 'pick-inc-menu' : $router.currentRoute.name === 'AiBanner' || $router.currentRoute.name === 'AiBannerAdd' || $router.currentRoute.name === 'AiBannerModify' }" class="link-title font-weight-light">AI튜터 배너 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/ailecture" target="_self" class="nav-link">
            <i class="link-icon" data-feather="phone"></i>
            <span :class="{ 'pick-inc-menu' : $router.currentRoute.name === 'AiLecture' || $router.currentRoute.name === 'AiLectureAdd' || $router.currentRoute.name === 'AiLectureModify' }" class="link-title font-weight-light">AI튜터 이러닝 강의 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/aicourse" target="_self" class="nav-link">
            <i class="link-icon" data-feather="phone"></i>
            <span :class="{ 'pick-inc-menu' : $router.currentRoute.name === 'AiCourse' || $router.currentRoute.name === 'AiCourseAdd' || $router.currentRoute.name === 'AiCourseModify' }" class="link-title font-weight-light">AI튜터 이러닝 강좌 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/ailecturefree" target="_self" class="nav-link">
            <i class="link-icon" data-feather="phone"></i>
            <span :class="{ 'pick-inc-menu' : $router.currentRoute.name === 'AiLectureFree' || $router.currentRoute.name === 'AiLectureFreeAdd' || $router.currentRoute.name === 'AiLectureFreeModify' }" class="link-title font-weight-light">AI튜터 무료강의 관리</span>
          </a>
        </li>

        <li class="nav-item">
          <a href="/banner" target="_self" class="nav-link">
            <i class="link-icon" data-feather="phone"></i>
            <span
              :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Banner' || $router.currentRoute.name === 'BannerAdd' || $router.currentRoute.name === 'BannerModify' }"
              class="link-title font-weight-light"
              >배너 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/program" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span
              :class="{
                'pick-inc-menu':
                  $router.currentRoute.name === 'Program' || $router.currentRoute.name === 'ProgramAdd' || $router.currentRoute.name === 'ProgramModfiy' || $router.currentRoute.name === 'ProgramUser',
              }"
              class="link-title font-weight-light"
              >교육 프로그램 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/bizcard" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span
              :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Bizcard' || $router.currentRoute.name === 'BizcardModfiy' || $router.currentRoute.name === 'BizcardAdd' }"
              class="link-title font-weight-light"
              >Biz 카드뉴스 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/ensentence" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span
              :class="{ 'pick-inc-menu': $router.currentRoute.name === 'EnsentenceModify' || $router.currentRoute.name === 'Ensentence' || $router.currentRoute.name === 'EnsentenceAdd' }"
              class="link-title font-weight-light"
              >99초 동영상 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/enlecture" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span
              :class="{ 'pick-inc-menu': $router.currentRoute.name === 'EnLectureModify' || $router.currentRoute.name === 'EnLecture' || $router.currentRoute.name === 'EnLectureAdd' }"
              class="link-title font-weight-light"
              >알짜강의 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/curation" target="_self" class="nav-link" style="cursor: pointer">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Curation' || $router.currentRoute.name === 'CurationsDetail' }" class="link-title font-weight-light"
              >찾아가는 큐레이션 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/reuter" target="_self" class="nav-link" style="cursor: pointer">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Reuters' || $router.currentRoute.name === 'ReuterDetail' }" class="link-title font-weight-light">로이터 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/curation-user" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'CurationUsers' || $router.currentRoute.name === 'CurationUsersDetail' }" class="link-title font-weight-light"
              >구독자 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/member" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Member' || $router.currentRoute.name === 'MemberView' }" class="link-title font-weight-light">학습자 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/payment" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Payment' }" class="link-title font-weight-light">결제 관리</span>
          </a>
        </li>
        <!--
        <li class="nav-item">
          <a href="/product" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu' : $router.currentRoute.name === 'Product' || $router.currentRoute.name === 'ProductModify' }" class="link-title font-weight-light">상품 관리</span>
          </a>
        </li>-->
        <li class="nav-item">
          <a href="/push" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span
              :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Push' || $router.currentRoute.name === 'PushView' || $router.currentRoute.name === 'PushAdd' }"
              class="link-title font-weight-light"
              >푸시 관리</span
            >
          </a>
        </li>
        <li class="nav-item">
          <a href="/notice" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'Notice' || $router.currentRoute.name === 'NoticeModfiy' }" class="link-title font-weight-light">공지사항/이벤트 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/dw" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'dailyWriting' }" class="link-title font-weight-light">데일리 라이팅 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/overview" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'dailyWritingOerview' }" class="link-title font-weight-light">데일리 라이팅 학습현황</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/ds" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'dailySpeaking' }" class="link-title font-weight-light">데일리 스피킹 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/ds/overview" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'dailySpeakingOverView' }" class="link-title font-weight-light">데일리 스피킹 학습현황</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/reward" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'reward' }" class="link-title font-weight-light">리워드 관리</span>
          </a>
        </li>
        <li class="nav-item">
          <a href="/version" target="_self" class="nav-link">
            <i class="link-icon" data-feather="edit"></i>
            <span :class="{ 'pick-inc-menu': $router.currentRoute.name === 'version' }" class="link-title font-weight-light">버전 관리</span>
          </a>
        </li>
        <li class="nav-item" style="bottom: 0">
          <a href="#" onclick="return false;" target="_self" class="nav-link" @click="logout()">
            <i class="link-icon" data-feather="edit"></i>
            <span class="link-title font-weight-light">로그아웃</span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import router from "@/router";

export default {
  name: "incHead",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },

  data: () => ({}),

  mounted() {},

  methods: {
    logout: function() {
      if (confirm("정말로 로그아웃 하시겠습니까?") == true) {
        // alert("a");
        var params = {};
        this.axios
          .get(this.$store.state.host + "/v1/admins/out", params, {
            headers: {},
          })
          .then(function(res) {
            if (res.data.status == 0) {
              localStorage.removeItem("authorization");
              localStorage.removeItem("autoLogin");
              alert("로그아웃 되었습니다.");
              router.push("/");
            } else {
              alert("로그아웃 실패");
              return false;
            }
          });
      }
    },
    goImooc: function() {
      const url = "http://admin.imooc.co.kr/rest/auth/login?kname=" + localStorage.getItem("kname") + "&email=" + localStorage.getItem("uid");
      let wnd = window.open(url);
      setTimeout(function() {
        wnd.location.href = "https://admin.imooc.co.kr/admin/curation/list";
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
