<script>
import axiosCommons from "@/components/commons/axiosCommons";

export default {
  name: "curationComponent",

  mixins: [axiosCommons],

  data: () => ({
    page: 1,
    lastpage: 0,

    reuters: [],
    records: [],

    filter: {
      queryString: '',
    },

    recordsFilter: {
      type: 'name',
      query: '',
      isOpen: '',
    },

    reuter: {},
    result: {},
    categories: {}
  }),

  methods: {
    clickCallback: function (pageNum) {
      this.page = pageNum;
      this.getReuters();
    },

    recordsClickCallback: function (pageNum) {
      this.page = pageNum;
      this.getReuterRecord();
    },

    getReuters: function () {
      const url = this.$store.state.host + "/v1/admin/reuters"
      const params = {
        page: this.page,
      }
      this.axios.get(url, {params})
      .then((res) => {
        this.result = res.data.result
        this.reuters = res.data.result.value
        this.lastpage = res.data.result.lastPage
      })
      .catch(error =>{ alert(`network error = ${error}`)});

    },

    getReuterOne: function (idxReuters) {
      const url = this.$store.state.host + "/v1/admin/reuters/" + idxReuters
      const params = {
        page: this.page
      }

      this.axios.get(url, {params})
      .then((res) => {
        this.reuter = res.data.result.reuter
        this.categories = res.data.result.categories
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getReuterRecord: function (idxReuters) {
      const url = this.$store.state.host + "/v1/admin/reuters/" + idxReuters + "/records"
      const params = {
        page: this.recordsFilter.page,
        isOpen: this.recordsFilter.isOpen,
        type: this.recordsFilter.type,
        query: this.recordsFilter.query,
      }

      this.axios.get(url, {params})
      .then((res) => {
        if (res.data.status === 0) {
          this.records = res.data.result.value
          this.lastpage = res.data.result.lastPage
          this.result = res.data.result
        } else {
          this.records = []
          this.lastpage = 0
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    /// MOVE
    moveReuter: function (idx) {
      this.$router.push('/reuter/' + idx);
    },

    showPreview: function (idx) {
      window.open('https://reuters.carrotenglish.com/cafe/pop_news_learn_recommend?news_seq=' + idx)
    },

    moveReuterCheck: function (idxUser) {
      this.$router.push('/curation-user/' + idxUser);
    },

    getReuterUserDetail: function (idxUser) {
      const params = {
        'idxUser': idxUser
      }

      const url = this.$store.state.host + "/v1/admin/reuters/records"
      this.axios.get(url, {params})
      .then((res) => {
        console.log('/v1/admin/reuters/records', res)
        this.reuterRecord = res.data.result
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    }

  },
}
</script>

