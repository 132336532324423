<template>
<div></div>
</template>

<script>
import router from "@/router";

export default {
  name: "enSentenceComponent",

  data: () => ({
    enlecture: {
      isUse: "Y",
      thum: "",
      title: "",
      url: ""
    },
    isEnlecturePreview: false,
    isEnlectureLoading: false,
    enLectureFilter: {
      page: 1
    },
    enlectures: [],
  }),

  methods: {

    getEnLecture: function (idx) {
      this.axios.get(this.$store.state.host + "/v1/admin/enlecture/" + idx)
      .then((res) => {
        if (res.data.status === 105) {
          alert("존재하지 않는 동영상 입니다.")
          router.push("/enlecture");
        } else {
          this.enlecture = res.data.result;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getEnLectures: function (enLectureFilter) {
      const url = this.$store.state.host + "/v1/admin/enlecture";

      const params = {
        page: (enLectureFilter.page === '' ? null : enLectureFilter.page)
      };

      this.axios.get(url, {
        params
      })
      .then((res) => {

        if(res.data.status === 0){
          this.result = res.data.result;
          this.enlectures = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }else{
          this.enlectures = [];
        }

      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    postSentence: function () {

      if (this.enlecture.title === "") {
        alert("동영상 제목을 입력해주세요.")
      } else if (this.enlecture.url === "") {
        alert("동영상 URL 을 입력해주세요.")
      } else {
        const url = this.$store.state.host + "/v1/admin/enlecture";
        this.axios.post(url, this.enlecture)
        .then((res) => {
          var data = res.data;
          if (data.status === 150) {
            alert("99초 동영상 등록에 실패했습니다. " + data.result)
          } else {
            alert("99초 동영상 작성이 완료되었습니다.")
            this.$router.push("/enlecture");
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    putSentence: function () {
      if (this.enlecture.title === "") {
        alert("동영상 제목을 입력해주세요.")
      } else if (this.enlecture.thum === "") {
        alert("썸네일을 업로드 해주세요.")
      } else if (this.enlecture.url === "") {
        alert("동영상 URL 을 입력해주세요.")
      } else {
        const url = this.$store.state.host + "/v1/admin/enlecture/" + this.$route.params.idx;
        this.axios.put(url, this.enlecture)
        .then(function (res) {
          var data = res.data;
          if (data.status === 0) {
            alert("수정이 완료되었습니다.")
            router.push("/enlecture")
          } else {
            alert("수정에 실패했습니다." + data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    deleteSentence: function () {
      if(confirm('삭제 하시겠습니까?')){
        const url = this.$store.state.host + "/v1/admin/enlecture/" + this.$route.params.idx;
        this.axios.delete(url)
        .then(function (res) {
          var data = res.data;
          if (data.status === 0) {
            alert("삭제가 완료되었습니다.")
            router.push("/enlecture")
          } else {
            alert("삭제에 실패했습니다." + data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    selectImage: function () {
      const formData = new FormData();
      const url = this.$store.state.host + "/v1/admin/enlecture/image";
      let fileRefs = this.$refs.file.files[0];
      formData.append('img', fileRefs);

      this.isLoading = true;
      this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        if(res.data.status === 0){
          this.isLoading = false
          this.enlecture.thum = res.data.result;
        }else{
          this.isLoading = false
          alert(res.data.result);
        }
      })
      .catch((err) => {
        alert(`network error = ${err}`);
      })
    },

  }
}
</script>

<style scoped>

</style>
