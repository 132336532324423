<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">주소 검색</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body" style="height: 500px; overflow-y:  scroll">
              <vue-daum-postcode @complete="onSearch($event)"/>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "popAddressSearch",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },

    address: {
      type: String,
      require: false,
      default: ''
    },

  },
  data: () => ({
    test: '',
  }),

  methods: {

    close: function () {
      this.$emit("close", false);
    },

    onSearch: function (search) {
      this.$emit('onSearch', search)
      this.$emit("close", false);
    },

  },
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}
</style>
