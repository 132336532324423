<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">{{ this.reuter.title }}</h3>
              <p class="lead">

              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th rowspan="2">노출일</th>
                        <th rowspan="2">제목</th>
                        <th rowspan="2">산업군</th>
                        <th colspan="3">메일</th>
                        <th rowspan="2">구독자 조회수</th>
                        <th rowspan="2">구독자 누적 조회수</th>
                        <th rowspan="2">누적 조회수</th>
                        <th rowspan="2">미리 보기</th>
                      </tr>
                      <tr>
                        <th>발송 수</th>
                        <th>오픈 수</th>
                        <th>오픈율</th>
                      </tr>
                      <tr>
                        <td>{{ reuter.IS_FARMDATE }}</td>
                        <td>{{ reuter.eng_title }}</td>
                        <td>
                          <span v-for="(category, index) in this.categories" :key="index" class="ml-1 label-industry">
                            {{category.name}}
                          </span></td>
                        <td>{{ reuter.reuter_mail_send_count }}</td>
                        <td>{{ reuter.reuter_mail_read_count }}</td>
                        <td>{{ reuter.reuter_mail_read_rate }}</td>
                        <td>{{ reuter.reuter_sub_send_count }}</td>
                        <td>{{ reuter.reuter_sub_total_count }}</td>
                        <td>{{ reuter.HIT_FARM }}</td>
                        <td>
                          <button @click="showPreview(reuter.news_seq)">View</button>
                        </td>
                      </tr>
                    </table>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <div class="h4 fl ml-2 mb-2">메일 수신자 리스트</div>
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th style="width: 10%; min-width: 120px">검색</th>
                        <td style="width: 10%; min-width: 120px">
                          <select v-model="recordsFilter.type" name="selectOption">
                            <option value="name">이름</option>
                            <option value="email">ID(E-mail)</option>
                          </select>
                        </td>
                        <td>
                          <input @keyup.enter="getReuterRecord($route.params.idx)" v-model="recordsFilter.query" type="text" class="form-control" id="colFormLabel" placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary" @click="getReuterRecord($route.params.idx)">
                      검색
                    </button>
                  </div>
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <select v-model="recordsFilter.isOpen" name="isSend" class="form-control fl w-20per" @change="getReuterRecord($route.params.idx)">
                        <option value="">발송 여부</option>
                        <option value="Y">오픈</option>
                        <option value="N">미오픈</option>
                      </select>
                    </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th class="wd-10p-f">No</th>
                        <th class="wd-10p-f">구분</th>
                        <th class="wd-10p-f">이름</th>
                        <th>ID(E-mail)</th>
                        <th class="wd-10p-f">메일 오픈 여부</th>
                        <th class="wd-10p-f">앱 조회 여부</th>
                      </tr>
                      <tr v-for="(record, index) in this.records" :key="record.index">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ record.division }}</td>
                        <td>{{ record.name }}</td>
                        <td><p style="color:#0e4cfd" class="cursor-pointer" @click="moveReuterCheck(record.idx_user)">
                          {{ record.uid }}
                        </p></td>
                        <td>{{ record.is_read }}</td>
                        <td>{{ record.is_appread }}</td>
                      </tr>
                      <tr v-if="this.lastpage <= 0">
                        <td colspan="7">메일발송 내역이 존재하지 않습니다.</td>
                      </tr>
                    </table>
                  </div>

                  <div v-show="this.lastpage > 0" style="padding-left:35%; padding-top:20px;">
                    <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                              :click-handler="recordsClickCallback" :prev-text="'Prev'"
                              :next-text="'Next'" :container-class="'pagination'"
                              :page-class="'page-link'">
                    </paginate>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import ReuterComponent from "@/components/model/reuterComponent";
import Paginate from 'vuejs-paginate';

export default {
  mixins: [ReuterComponent],
  name: 'reuterDetail',
  components: {
    Menu,
    Paginate
  },

  data: () => ({
  }),

  computed: {
  },

  methods: {

  },

  mounted() {
    this.getReuterOne(this.$route.params.idx)
    this.getReuterRecord(this.$route.params.idx)
  },
}
</script>

<style scoped lang="scss">

</style>
