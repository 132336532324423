<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">구독자 관리</h3>
              <p class="lead">
                - 구독자를 관리할 수 있습니다.<br>
                - 누적 구독자: 해당 기간 동안 1일이라도 구독을 한 자<br>
                - 구독자: 누적 구독자 – 구독 종료자<br>
                - 구독 종료자: 해당 기간 동안 구독이 종료된 자<br>
                - 결제 구독자: 해당 기간 동안 결제를 한 구독자 수<br>
                - 결제 금액: 해당 기간 동안 결제된 금액
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th width="60">기간</th>
                        <td class="text-left">
                          <input type="date" v-model="subscriberFilter.sdate" class="" placeholder="">
                          ~ <input type="date" v-model="subscriberFilter.edate" class="" placeholder="">
                        </td>
                        <th>이름</th>
                        <td>
                          <input v-model="subscriberFilter.name" type="text" class="form-control">
                        </td>
                      </tr>
                      <tr>
                        <th>ID(E-mail)</th>
                        <td colspan="3">
                          <input v-model="subscriberFilter.email" type="text" class="form-control">
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button @click="getCurationSubscribers()" type="submit" class="btn btn-primary">
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>누적 구독자</th>
                        <th>구독자</th>
                        <th>구독 종료자</th>
                        <th>결제한 구독자</th>
                        <th>결제 금액</th>
                      </tr>
                      <tr>
                        <td>{{ paymentResultSet.totalSubScribeCount }}</td>
                        <td>{{ paymentResultSet.subScriberCount }}</td>
                        <td>{{ paymentResultSet.ntSubScriberCount }}</td>
                        <td>{{ paymentResultSet.payScriberCount }}</td>
                        <td>{{ paymentResultSet.totalPayment }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <select v-model="subscriberFilter.kubun" @change="getCurationSubscribers()" class="form-control fl w-20per">
                      <option value="">상품구분</option>
                      <option value="curation">찾아가는 큐레이션</option>
                      <option value="bizcard">Biz 카드뉴스</option>
                    </select>

                    <select v-model="subscriberFilter.division" @change="getCurationSubscribers()" class="form-control fl ml-10 w-20per">
                      <option value="">회원구분</option>
                      <option value="CTM">CTM</option>
                      <option value="ITM">ITM</option>
                      <option value="통합">통합</option>
                      <option value="자체유입">자체유입</option>
                    </select>

                    <select v-model="subscriberFilter.isSubscribe" @change="getCurationSubscribers()" class="form-control fl ml-10 w-20per">
                      <option value="">구독 여부</option>
                      <option value="true">구독중</option>
                      <option value="false">구독 종료</option>
                    </select>

                    <select v-model="subscriberFilter.type" @change="getCurationSubscribers()" class="form-control fl ml-10 w-20per">
                      <option value="">구독권</option>
                      <option value="1">1개월</option>
                      <option value="3">3개월</option>
                      <option value="12">12개월</option>
                    </select>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%">No</th>
                        <th style="width: 10%">구분</th>
                        <th style="width: 10%">이름</th>
                        <th style="width: 10%">ID(E-mail)</th>
                        <th style="width: 10%">구매 구독권</th>
                        <th>구독 시작일</th>
                        <th style="width: 10%">구독 종료일</th>
                        <th style="width: 10%">구독여부</th>
                      </tr>
                      <tr v-for="(item, index) in this.subscribers" :key="item.idx">
                        <td>{{ subscribers.length - index }}</td>
                        <td>{{ item.kubun }}</td>
                        <td>{{ item.user.userName }}</td>
                        <td><p style="color:#0e4cfd" class="cursor-pointer" @click="moveCurationCheck(item.user.idx)">
                          {{ item.user.uid }}
                        </p><a></a></td>
                        <td>{{ (item.merchant != null ) ? item.merchant.name : '' }}</td>
                        <td>{{ item.sdate }}</td>
                        <td>{{ item.edate }}</td>
                        <td>{{ item.isSubscribed }}</td>
                      </tr>
                      <tr v-if="this.lastpage <= 0">
                        <td colspan="10">검색하신 구독자가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div v-if="this.lastpage > 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="curationPageCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from "@/components/commons/IncMenu";
import CurationComponent from "@/components/model/curationComponent";
import Paginate from 'vuejs-paginate';

export default {
  mixins: [CurationComponent],
  name: 'CurationUser',
  components: {
    Menu,
    Paginate
  },

  props: {
    msg: String
  },

  mounted() {
    this.getCurationSubscribers();
    this.subscriberFilter.sdate = this.$moment().startOf('month').format('YYYY-MM-DD');
    this.subscriberFilter.edate = this.$moment().endOf('month').format('YYYY-MM-DD');
  },
}
</script>

<style scoped lang="scss">

</style>
