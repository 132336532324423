<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">미리보기</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div class="preview">
                <div class="previewHeader">
                  <i class="fas fa-bars"></i>
                  <img src="../../assets/platform_logo.png" class="logo"/>
                  <img src='../../assets/my_icon.png' class="icon"/>
                </div>
                <div class="previewBody">
                  <div class="main-bg">
                    <img v-if="bannerType !== '메인 슬라이드 배너'" src="../../assets/platform_main_img1.jpg"/>
                    <img v-if="bannerType === '메인 슬라이드 배너'" :src="banner.img"/>
                  </div>
                  <div class="applyList">
                    <div class="applyListSubtitle">
                      <h1 class="main-title">지금 신청 가능한 프로그램</h1>
                      <span style="padding-right:16px">더보기</span>
                    </div>
                    <div>
                      <div>
                        <img src="../../assets/program_img1.png"/>
                        <div>
                          <h5>SMART WORKGIN</h5>
                          <p><span class="text-orange bold">0</span>일전 | <span class="bold">3</span>/30명
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main-banner">
                    <img v-if="bannerType !== '중간 배너'" src="../../assets/banner_img.png"/>
                    <img v-if="bannerType === '중간 배너'" :src="banner.img"/>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary">확인</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">취소</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'previewModal',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    bannerType: String,
    banner_idx: Number,
    banner_img: String
  },

  data: () => ({
    banner: {}
  }),
  watch: {
    visible: function (newVal) {
      if (newVal == true) {
        this.visible = true;
        // console.log(this.banner_idx);
        // this.doSearch();
        // console.log(this.banner_img);
        if (this.banner_img == "") {
          this.initPage();
        } else {
          this.banner.img = this.banner_img;
        }
      } else {
        this.visible = false;
      }
    }
  },
  mounted() {

  },

  methods: {
    initPage() {
      this.axios.get(this.$store.state.host + "/v1/admin/banners/" + this.banner_idx).then(
          (res) => {
            // console.log(res);
            if (res.data.status == 0) {
              this.banner = res.data.result;
              console.log(this.banner);
            } else {
              alert("배너 미리보기 오류");
              return false;
            }
          })
          .catch(error =>{ alert(`network error = ${error}`)});
    },
    close() {
      this.$emit("close", false);
    },

  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}

.preview {
  width: 80%;
  padding-bottom: 20px;
  margin: 0 auto;
  border: 1px solid #dddddd;

  .previewHeader {
    text-align: center;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;

    i {
      position: absolute;
      left: 10px;
      top: 15px;
      font-size: 20px;
    }

    .logo {
      width: 120px;
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 40px;
    }
  }

  .previewBody {
    .main-bg {
      position: relative;

      img {
        width: 100%;
      }
    }

    .main-title {
      font-size: 22px;
      // font-weight: bold;
      font-family: "AppleSDGothicNeoB";

    }

    .applyList {
      padding-top: 36px;
      padding-left: 16px;
      padding-bottom: 36px;

      ion-slide {
        margin-right: 20px;
      }

      ion-slide div {
        text-align: left;
        padding-top: 15px;
      }

      ion-slide h5,
      ion-slide p {
        margin-top: 0px;
        margin-bottom: 0;
      }

      ion-slide h5 {
        font-family: "AppleSDGothicNeoB";
      }

      ion-slide p {
        padding-top: 5px;
      }

      ion-slide p .text-orange {
        color: #ff802b;
      }

      ion-slide > img {
        max-height: 50%;
        max-width: 100%;
      }

      .applyListSubtitle {
        position: relative;

        h1 {
          // margin-left: 16px;
        }

        span {
          position: absolute;
          top: 2px;
          right: 0px;
          font-size: 16px;
          // line-height: 25px;
          cursor: pointer;

          i {
            font-size: 14px;
            margin-right: 5px;
          }
        }

        p {
          font-size: 14px;
        }
      }

      div {
        img {
          width: 200px;
        }

        div {
          text-align: left;
          padding-top: 15px;
        }

        h5,
        p {
          margin-top: 0px;
          margin-bottom: 0;
        }

        h5 {
          font-family: "AppleSDGothicNeoB";
        }

        p {
          padding-top: 5px;
        }

        p .text-orange {
          color: #ff802b;
        }
      }
    }

    .main-banner {
      width: 95%;
      margin: 0 auto;

      img {
        width: 100%;
      }
    }
  }
}
</style>
