<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <location-add :visible="isLocation" @mount="mountLocation" @close="locationClose"/>
      <LectureList :visible="is_preview" @close="vue_close"  @selected="setLectures"></LectureList>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">강좌 수정 상세페이지 </h3>
              <p class="lead">
                - 등록된 강좌를 수정합니다.
              </p>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;강좌 타이틀</th>
                        <td class="text-left">
                          <div class="mt-2">
                            <input v-model="course.subject" type="text" class="form-control fl w-85per" placeholder="강좌 타이틀을 입력하세요."/>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>&nbsp;카테고리</th>
                        <td class="text-left">
                          <select  v-model="course.categoryIdx" class="form-control fl w-85per">
                            <option value="-999">카테고리 선택</option>
                            <option v-for="(item) in categoryList" :value="item.idx" :key="item.idx">
                              {{item.name}}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;사용여부</th>
                        <td class="text-left">
                          <label><input v-model="course.isUse" type="radio" value="Y"> 사용</label>
                          <label class="ml-1"><input v-model="course.isUse" type="radio" value="N"> 사용안함</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;레벨</th>
                        <td class="txt-left">
                          <div class="mt-2">
                            <label><input type="radio" v-model="course.level" value="초급">초급</label>
                            <label class="ml-2"><input type="radio" v-model="course.level" value="중급">중급</label>
                            <label class="ml-2"><input type="radio" v-model="course.level" value="중고급">중고급</label>
                            <label class="ml-2"><input type="radio" v-model="course.level" value="고급">고급</label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 강의리스트</th>
                        <td class="txt-left">
                          <div class="w-100 mb-4 mt-2">
                            <span class="fl"> *강의 리스트를 드래그하여 순서를 변경할 수 있습니다.
                            </span>
                            <a class="fr cursor-pointer" style="text-decoration: underline" @click="showPreview()">
                              강의등록하기
                            </a>
                          </div>
                          <table class="table table-hover table-bordered ">
                            <draggable >
                              <tr>
                                <th class="w-130px"></th>
                                <th class="w-130px">순서</th>
                                <th class="w-130px">강의번호</th>
                                <th class="w-40per">강의타이틀</th>
                                <th class="w-130px">사용여부</th>
                                <th class="w-130px">퀴즈</th>
                                <th class="w-130px">AI Speaking</th>
                              </tr>
                            </draggable>
                          </table>

                          <table class="table table-hover table-bordered ">
                            <draggable v-model="lectures"   >
                              <div v-for="(item,i) in lectures" :key="item.idx">
                                <tr class="cursor-pointer">
                                  <td class="w-10per">   <button type="submit" class="btn btn-primary fr ml-10" @click="removeItem(i)">
                                    삭제
                                  </button></td>
                                  <td class="w-130px"> {{i+1}}</td>
                                  <td class="w-130px"> L{{ item.idx }}</td>
                                  <td class="w-40per"> {{ item.title }}</td>
                                  <td class="w-130px"> {{ item.isUse }}</td>
                                  <td class="w-130px"> {{ item.isQuiz}}</td>
                                  <td class="w-130px"> {{ item.isSpeaking}}</td>
                                 </tr>
                              </div>
                            </draggable>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>썸네일<p class=" red">
                          jpg, png 등록가능 <br>
                          사이즈 (158*104)
                        </p>

                        </th>
                        <td>
                          <biz-image :url="imageUrl" :img="course.thumbnail" :img-style="imageSize" @mountImage="course.thumbnail = $event"/>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goBanners()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="putBanners()">
                      수정
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import LectureList from "../../components/pop/pop_AiLectureList.vue";
import LocationAdd from '../../components/pop/popLocationAdd';
import BizImage from "../../components/upload/uploadImage";
import draggable from "vuedraggable";
export default {
  name: 'aiCourseAdd',
  components: {
    Menu,
    LectureList,
    LocationAdd,
    BizImage,
    draggable
  },
  props: {
    msg: String
  },
  data: () => ({
    fileList: [],
    lectures:[],

    validation: {
      ordernum: false,
    },

    /// loader
    loading: false,
    loader: {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '5px',
      margin: '2px',
      radius: '2px'
    },

    /// course
    course: {
      subject:''
      , courseSubList:[]
      , isUse: 'Y'
      , categoryIdx:'-999'
      , level:'초급'
      , thumbnail:''
    },
    input_data: {
      subject:''
      , courseSubList:[]
      , isUse: 'Y'
      , categoryIdx:'-999'
      , level:'초급'
      , thumbnail:''
    },
    is_preview: false,
    isLocation: false,
    course_img: "",
    categoryList:[]
  }),

  computed: {
    imageUrl: function () {
      let url = this.$store.state.host + "/v1/aitutor/course/image";
      return url;
    },

    imageSize: function () {
      return 'width:158px; height:104px;'
    }
  },

  mounted() {
    this.getCategoryList();
    this.getCourse();
  },
  methods: {
    getCategoryList: function () {
      const url = this.$store.state.host + "/v1/aitutor/category";
      var params = {};

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.categoryList = res.data.result.value;
        } else {
          this.categoryList = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    chooseImage: function () {
      document.getElementById("upload").click()
    },
    checkValid: function () {
      alert(this.course.ordernum + "," + isNaN(this.course.ordernum));
      this.validation.ordernum = (isNaN(this.course.ordernum));
    },
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },

    putBanners: function () {
      for(var i=0; i<this.lectures.length; i++){
        var temp={};
        temp["lectureIdx"]=this.lectures[i].idx;
        temp["sort"]=i+1;
        this.input_data.courseSubList.push(temp);
      }

      this.input_data.categoryIdx=this.course.categoryIdx;
      this.input_data.subject=this.course.subject;
      this.input_data.isUse=this.course.isUse;
      this.input_data.level=this.course.level;
      this.input_data.thumbnail=this.course.thumbnail;


      ///validation
      const url = this.$store.state.host + "/v1/aitutor/course/"+this.$route.params.idx;
      if (this.input_data.subject === "") {
        alert('타이틀을 입력해주세요.')
      } else if (this.input_data.isUse=== "") {
        alert('사용여부를 선택해주세요.')
      } else if (this.input_data.level=== "") {
        alert('레벨을 선택해주세요.')
      } else if (this.input_data.courseSubList.length === 0) {
        alert('강의를 입력해주세요.')
      } else if (this.input_data.thumbnail === '') {
        alert('썸네일을 입력해주세요.')
      } else {
        this.axios.put(url, this.input_data)
            .then((res) => {
              const data = res.data;
              if (data.status === 0) {
                alert("강좌가 수정되었습니다.")
                this.goBanners();
              } else {
                alert(data.result)
              }
            })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    goBanners: function () {
      this.$router.push("/AiCourse")
    },

    postBannerImage: function () {

    },

    locationClose: function (state) {
      this.isLocation = state;
    },

    mountLocation: function (idx, subject) {
      if(subject == "reuter"){
        this.course.link = '/' + subject + '?newsSeq=' + idx
      }else if(subject == "dw"){
        this.course.link = '/' + subject;
      }else{
        this.course.link = '/' + subject + '?idx=' + idx
      }
    },
    setLectures: function(info) {
      this.lectures=info.lectures;
      for(var i=0; i<this.lectures.length; i++){
        this.lectures[i]["sort"]=i+1;
      }
      console.log(info);
    },
    removeItem: function(idx){
      console.log(idx);
      this.lectures.splice(idx, 1);
    },
    getCourse: function(){
      this.axios.get(this.$store.state.host + "/v1/aitutor/course/" + this.$route.params.idx)
          .then((res) => {
            this.course = res.data.result;
            this.lectures =res.data.subList;

            console.log(this.lectures);
          })
        .catch(error =>{ alert(`network error = ${error}`)});          
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
