<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">카테고리 등록</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>


            <div class="modal-body">
              <div>
                <button type="submit" class="btn btn-primary fr mb-2" @click="postCategory()">등록
                </button>
              </div>
              <table class="table table-hover table-bordered">
                <tr>
                  <th class="text-center">카테고리명</th>
                  <td><input v-model="add_category.name" type="text" class="form-control" /></td>
                </tr>
              </table>

              <table class="table table-hover table-bordered mt-30 text-center">
                <tr>
                  <th class="w-10px">No</th>
                  <th class="w-45per">카테고리</th>
                  <th class="w-20per">등록일</th>
                  <th class="w-20per">HQ매니저</th>
                  <th class="w-15per">삭제</th>
                </tr>
                <tr v-for="(category, index) in popCategories" :key="category.idx">
                  <td>{{ (popCategories.length - index) }}</td>
                  <td>{{ category.name }}</td>
                  <td>{{ category.createDate }}</td>
                  <td v-if="category.emp !== null">{{ category.emp.englishname }}({{ category.emp.koreanname }})</td>
                  <td><button class="btn btn-outline-primary" @click="deleteCategory(category.idx)">선택</button></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'tutorModal',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
  },

  data: () => ({
    popCategories: [],
    category: {},
    add_category:{
      banner_file:'',
      name:'',
    },
    filter: {
      searchTitle: null,
    },
  }),

  watch: {
    visible: function (newVal) {
      if (newVal == true) {
        this.visible = true;
        // this.doSearch();
      } else {
        this.visible = false;
      }
    }
  },
  computed: {

  },
  mounted() {
    this.getCategories();
  },

  methods: {
    pickCategory(categoryIdx) {
      this.axios.delete(this.$store.state.host + "/v1/aitutor/category/"+categoryIdx)
          .then((res) => {
            console.log(res);
            alert("처리되었습니다.");
          })
          .catch(error =>{ alert(`network error = ${error}`)});
      this.$emit('pick', categoryIdx);
    },
    close() {
      this.$emit("close", false);
    },
    getCategories: function () {
      const params = {
        searchTitle: this.filter.searchTitle
      };

      this.axios.get(this.$store.state.host + "/v1/aitutor/category", {params})
      .then((res) => {
        this.popCategories = res.data.result.value;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    postCategory: function () {
      const url = this.$store.state.host + "/v1/aitutor/category";

      this.axios.post(url, this.add_category)
      .then((res) => {
        if (res.data.status === 100) {
          alert(res.data.result)
        } else {
          this.getCategories();
        }
        console.log(res);
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    deleteCategory: function (idx) {

      if(confirm('삭제 하시겠습니까?')){
        const url = this.$store.state.host + "/v1/aitutor/category/" + idx;
        this.axios.delete(url)
            .then((res) => {
              if (res.data.status === 0) {
                alert("삭제 완료되었습니다");
                this.getCategories();
              }else{
                alert(res.data.result);
              }
            })
            .catch(error =>{ alert(`network error = ${error}`)});
      }


    },
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}
</style>
