<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">99초 동영상 관리</h3>
              <p class="lead">
                - 99초 동영상 컨텐츠 관리 페이지 입니다<br>
              </p>
            </div>
          </div>
          <div class="row">

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <button type="submit" class="btn btn-primary fr ml-10"
                              @click="$router.push('/ensentenceadd')">등록
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%">No</th>
                        <th>제목</th>
                        <th style="width: 5%">사용여부</th>
                        <th style="width: 10%">HQ매니저</th>
                        <th style="width: 5%">등록일</th>
                      </tr>
                      <tr v-for="(item) in ensentences" :key="item.idx">
                        <td>{{ item.idx }}</td>
                        <td><a :href="'/ensentencemodify/' + item.idx">{{ item.title }}</a></td>
                        <td>{{ item.isUse }}</td>
                        <td>{{ item.member.koreanname }}({{ item.member.englishname }})</td>
                        <td>{{ item.createDate }}</td>
                      </tr>
                      <tr v-if="ensentences.length <= 0">
                        <td colspan="5">99초 동영상 정보가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <div v-if="ensentences.length > 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'Ensentence',
  components: {
    Menu,
    Paginate,
  },
  props: {
    msg: String
  },
  data: () => ({
    filter: {page: 1,},
    ensentences: [],
    lastpage: 1,
    result: {},
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    initPage: function () {
      this.getSentence(this.filter.page);
    },

    getSentence: function (page, title, idxEmp, subject) {
      const url = this.$store.state.host + "/v1/admin/scvideos";
      var params = {
        title: (title === '' ? null : title),
        emp: (idxEmp === '' ? null : idxEmp),
        subject: (subject === '' ? null : subject),
        page: (page === '' ? null : page)
      };

      this.axios.get(url, {
        params
      })
      .then((res) => {

        if(res.data.status === 0){
          this.result = res.data.result;
          this.ensentences = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }else{
          this.ensentences = [];
        }

      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
