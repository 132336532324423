<template>
  <div class="dim" v-if="visible">
    <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 700px">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">회원 검색</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="close()"><span aria-hidden="true">×</span></button>
          </div>
          <div class="modal-body">
            <table class="table table-hover table-bordered">
              <tr>
                <th style="width: 30%" class="txt-center">
                  <select v-model="filter.type">
                    <option value="name">이름</option>
                    <option value="uid">이메일</option>
                    <option value="companyname">회사명</option>
                    <option value="mobile">전화번호</option>
                  </select>
                </th>
                <th style="width: 60%">
                  <input @keyup.enter="searchUser()" v-model="filter.queryString" type="text"
                         class="form-control">
                </th>
                <th style="width: 10%">
                  <button @click="searchUser()" class="btn btn-dark">검색</button>
                </th>
              </tr>
            </table>
            <div style="height: 500px; overflow-y: auto">
              <table class="table table-hover table-bordered mt-30 text-center">
                <tr>
                  <th style="width: 10%">No</th>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>전화번호</th>
                  <th>회사</th>
                  <th style="width: 10%"><input id="allCheck" type="checkbox" @change="allChecked(items)">
                  </th>
                </tr>
                <tr v-for="(item, index) in items" :key="item.idx">
                  <td>{{
                      (result.userList.total - (result.userList.currentPage - 1)
                          * result.userList.perPage) - index
                    }}
                  </td>
                  <td>{{ item.userName }}</td>
                  <td>{{ item.uid }}</td>
                  <td>{{ item.mobile }}</td>
                  <td>{{ item.companyname }}</td>
                  <td>
                    <input :checked="pushUsers.has(item.idx)" type="checkbox" :id="item.idx" class="checkbox" @change="addUser(item)">
                  </td>
                </tr>
                <tr v-if="items.length === 0">
                  <td colspan="6">검색된 회원이 존재하지 않습니다.</td>
                </tr>
              </table>
            </div>
            <div v-if="lastpage !== 0" style="padding-left:40%; padding-top:20px;">
              <paginate
                  :page-count="lastpage"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="searchUser"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'page-link'">
              </paginate>
            </div>
          </div>

          <div class="modal-footer">
            <button @click="inputUser" type="button" class="btn btn-primary">입력</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  name: "popUserSearch",
  components: {
    Paginate,
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
  },
  data: () => ({
    filter: {
      queryString: '',
      type: 'name',
    },
    lastpage: 0,
    items: [],
    pushUsers: new Map(),
  }),
  methods: {
    allChecked: function (items) {

      let elements = document.querySelectorAll('.checkbox');
      elements.forEach(value => {
        value.checked = document.querySelector('#allCheck').checked;
      });

      items.forEach(user => {
        this.addUser(user);
      });
    },

    close() {
      this.$emit("close", false);
      //this.pushUsers = new Map();
    },

    inputUser: function () {
      if (confirm('입력을 완료 하시겠습니까?')) {
        this.$emit('input', this.pushUsers);
        //this.pushUsers = new Map();
      }
    },

    searchUser: function (page) {
      document.querySelector('#allCheck').checked = false;

      const params = {};
      params['page'] = page;
      params[this.filter.type] = this.filter.queryString;
      params['isDevice'] = 'Y';

      this.axios
      .get(this.$store.state.host + "/v1/admin/users", {params})
      .then((res) => {
        const data = res.data;
        if (data.status === 0) {
          this.result = data.result;
          this.items = data.result.userList.value;
          this.lastpage = data.result.userList.lastPage;
        } else {
          this.items = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    addUser: function (user) {
      let isChecked = document.getElementById(user.idx).checked;
      if (isChecked === true) {
        this.pushUsers.set(user.idx, user);
      } else {
        this.pushUsers.delete(user.idx);
      }
    },
  },
}
</script>

<style scoped>
</style>
