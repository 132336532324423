<template>
  <div style="background-color: white; position: absolute; height: 100%; width: 100%">
    <div style="text-align: center;">
      <img style="margin-top: 15%" src="../../../public/icee_admin/images/404.jpg">
    </div>
  </div>
</template>

<script>
export default {
  name: "404page"
}
</script>

<style scoped>
</style>
