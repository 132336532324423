<template>
  <div>
    <div class="wd-500" style="margin: 15% auto;">
      <div>
        <h1 class="page-title">Admin</h1>
      </div>
      <div>
        <div>
          <div class="grid-margin stretch-card" style="">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                    <tr class="w-50per">
                      <th class="text-center">User ID(email)</th>
                      <td>
                        <div class="form-group">
                          <input type="text" class="form-control" placeholder="아이디를 입력하세요."
                                 v-model="id" id="uid">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-center">Password</th>
                      <td>
                        <div class="form-group">
                          <input type="password" class="form-control" placeholder="비밀번호를 입력하세요."
                                 v-model="password" id="upw" @keyup.enter="postLogin()">
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div>
                    <input v-model="autoLogin" class="fr" type="checkbox">
                    <span class="fr"> 자동로그인 </span>
                    <input v-model="uidSave" class="fr" type="checkbox">
                    <span class="fr"> ID 저장 </span>
                  </div>
                </div>
                <div class="text-right mt-2">
                  <button type="submit" class="btn btn-primary"
                          @click="postLogin()">Login
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'Login',
  props: {
    msg: String
  },
  data: () => ({
    id: "",
    password: "",
    autoLogin: false,
    uidSave: false,
  }),

  mounted: function () {
    let uid = this.$route.query.uid;
    if(uid != null){
      const params = {
        uid: uid,
      };
      this.axios.get(this.$store.state.host + "/system/token", {params}).then((res) => {
        console.log(res);
        localStorage.setItem("authorization", res.data.result.token);
        localStorage.setItem("uid", res.data.result.uid);
        localStorage.setItem("kname", res.data.result.kname);

        if (res.data.status === 0) {
          if (this.uidSave === true) {
            localStorage.setItem('uidSave', this.id);
          } else {
            localStorage.removeItem('uidSave');
          }

          /// 자동 로그인 토큰 발급
          if (this.autoLogin === true) {
            this.axios.post(this.$store.state.host + "/v1/admins/autoLogin", params, {
              headers: {}
            }).then((res) => {
              console.log(res);
              localStorage.setItem('autologin', res.data.result);
            })
          }
        } else {
          alert('로그인에 실패했습니다. ' + res.data.result);
        }

        router.push("/banner");
      });
    }

    /// 저장된 아이디 붙이기
    if (localStorage.getItem('uidSave') !== null) {
      this.uidSave = true;
      this.id = localStorage.getItem('uidSave');
    }

    /// 자동로그인 체크
    if (localStorage.getItem('autologin') !== null) {
      let params = {
        autoLoginToken: localStorage.getItem('autologin')
      };
      this.axios.get(this.$store.state.host + "/v1/admins/autoLogin", {params}).then(
          function (res) {
            /// 토큰 확인후 유효할시 바로 통과
            if (res.data.token !== null) {
              localStorage.setItem("authorization", res.data.token);
            }
          })
    }

    this.axios.get(this.$store.state.host + "/v1/admins/token", {
      headers: {}
    }).then(function (res) {
      /// 토큰 확인후 유효할시 바로 통과
      if (res.data.status === 0) {
        router.push('/banner');
      }
    })
  },

  computed: {
    isAutoLogin: function () {
      return localStorage.getItem('autologin') !== null
    },
  },

  methods: {
    postLogin: function () {
      if (this.id === "") {
        alert("아이디를 입력해주세요.");
        return false;
      }
      if (this.password === "") {
        alert("패스워드를 입력해주세요.");
        return false;
      }

      const params = {
        id: this.id,
        password: this.password
      };

      this.axios.post(this.$store.state.host + "/v1/admins/login", params, {
        headers: {}
      })
      .then((res) => {
        localStorage.setItem("authorization", res.data.result.token);
        localStorage.setItem("uid", res.data.result.uid);
        localStorage.setItem("kname", res.data.result.kname);

        if (res.data.status === 0) {
          if (this.uidSave === true) {
            localStorage.setItem('uidSave', this.id);
          } else {
            localStorage.removeItem('uidSave');
          }

          /// 자동 로그인 토큰 발급
          if (this.autoLogin === true) {
            this.axios.post(this.$store.state.host + "/v1/admins/autoLogin", params, {
              headers: {}
            }).then((res) => {
              console.log(res);
              localStorage.setItem('autologin', res.data.result);
            })
          }
        } else {
          alert('로그인에 실패했습니다. ' + res.data.result);
        }

        router.push("/banner");
      }).catch((err) => {
        alert('로그인에 실패했습니다. ' + err);
      });
    },
  }

}
</script>

<style lang="scss" scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.page-content {
  padding: 10%;
}
</style>
