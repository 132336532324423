<template>
  <div v-if= "visible" v-show="onLoactionPush" >
    <LocationPush v-if="isLocation" :visibleLP="isLocation" @mount="mountLocation" @close="closeLP"></LocationPush>
    <div class="dim">
      <div
        class="modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="row">
          <div class="col-xl-12">
            <h3 class="page-title">푸시 내용 설정</h3>
            <p class="lead">
              - 선택한 수신자에게 발송할 푸시 내용을 입력합니다.
            </p>
          </div>
        </div>             
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
              >
                <span aria-hidden="true" @click="close()">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th><span class="red">*</span>&nbsp;구분</th>
                      <td class="txt-left">
                        <label><input type="radio" value="now" v-model="pushData.pushType" class="pointer"> 즉시전송</label>&nbsp;&nbsp;&nbsp;
                        <label><input type="radio" value="nt-now" v-model="pushData.pushType" class="pointer"> 예약</label>
                        <label class="ml-1"><input type="datetime-local" v-if="pushData.pushType === 'nt-now'" v-model="pushData.pushDate" class="pointer" /></label>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="red">*</span>&nbsp;푸시제목<br>
                        <span> (공백포함 40자 이내)</span>
                      </th>
                      <td class="txt-left">
                        <input type="text"
                               class="form-control w-100per fl"
                               v-model="pushData.title"
                               />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="red">*</span>&nbsp;푸시내용<br>
                        <span class="red">140자 이내(공백 포함)</span>
                      </th>
                      <td class="txt-left">
                        <ckeditor name="textarea" 
                                 :config="editorConfig"
                                 v-model="pushData.contents"
                          ></ckeditor>
                      </td>
                    </tr>
                    <tr>
                      <th>&nbsp;이동경로</th>
                      <td>
                        <div class="text-left">
                          <label><input type="radio" value="N"  v-model="pushData.linkType" class="pointer">미사용</label>
                          <label><input type="radio" value="I"  v-model="pushData.linkType" class="pointer">내부링크</label>&nbsp;
                          <!-- <label><input type="radio" :disabled="true" v-model="pushData.linkType" class="pointer">내부링크(수정중)</label> -->
                          <button @click="locationClose('B')" v-if="pushData.linkType === 'I'"
                                    class="btn btn-dark w-80per">
                              {{ pushData.link === '' ? '이동경로를 선택해주세요' : pushData.link }}
                            </button>
                        </div>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="clear mt-20">
                    <button @click="close()" type="submit" class="btn btn-primary fl">
                      목록
                    </button>
                    <button @click="postPush()" type="submit" class="btn btn-primary fr">발송</button>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="guide_pop" v-if="isPopVisible">
                    <p v-if="!checkedFilter && allPush"><b>학습자 전체에게 푸시를 발송합니다</b></p>
                    <p v-else-if="checkedFilter && allPush"><b>선택된 조건에 맞는 학습자 전체<br> {{ checkPeopleCount }} 명에게 푸시를 발송합니다 </b></p>
                    <p v-else><b>선택한 총 {{ checkPeopleCount }}명에게 푸시를 발송합니다</b></p>
                    <br/>
      <div class="clear btn_box">
          <button @click="agreePush('Y')" class="btn btn-primary fl">예</button>
          <button @click="agreePush('N')" class="btn btn-primary btn_red">아니오</button>                          
      </div>
    </div> 
    <div class="guide_pop" v-if="isCheckVisible">
                    <p v-if="allPush"><b>학습자 전체에게 푸시발송이 완료되었습니다.</b></p>
                    <p v-else><b>{{ checkPeopleCount }}명에게 푸시 발송이 완료되었습니다.</b></p>
      <div class="clear btn_box btn_center">
          <button @click="checkClose" class="btn btn-primary">확인</button>                          
      </div>
    </div>           
  </div>
</template>

<script>
import LocationPush from '../../components/pop/popLocationPush';
export default {
  name: 'CarrotPlatformAdminPopPushInput',
  components: {
    LocationPush,
  },
  data() {
      return {
        isVisible: false,
        isLocation: false,
        pushData: {
          pushType: 'now'
          , pushDate: null
          , pushTime: null
          , title: ''
          , contents: ''
          , linkType: 'N'
          , link: ''
        },
        editorConfig: {
          filebrowserUploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor',
          ckfinder: {
            uploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor'
          },
          allowedContent: true,
          height   : 200,
        },
        onLoactionPush: true,
        isPopVisible: false,
        isCheckVisible: false,
        peopleCount: 0,
      };
    },

  props: {
      visible:{
        type: Boolean,
        require: true,
        default: false,
      },
      pushList: {}, 
      allPush: {
        type: Boolean,
        require: true,
        default: false,
      },
      checkedFilter: {
        type: Boolean,
        require: true,
        default: false,
      },      
      checkPeopleCount: {
        type: Number,
        require: true,
        default: 0,
      },
      memberDivision: {
        type: String,
        require: true,
        default: '',
      },
      memberMembership: {
        type: String,
        require: true,
        default: '',
      },      
  },

  methods: {
      close() {
      this.initPushData();
      this.$emit("close");
    },
    postPush(){     
      if(this.chkValidation()){ 
        if(!this.allPush) {
          console.log(this.allPush)   
        }
        this.isPopVisible = true;
      }
    },

    locationClose(type) {
      if(type == 'B') {
        this.isLocation = true;
      }
      
    },
    mountLocation: function (idx, subject) {
      this.pushData.link = '/' + subject + '?idx=' + idx;
      this.isLocation = false;
    },    


    isNull(value){
      if(value === '' || value === null || value === undefined ) {
        return true;
      }
      else {
        return false;
      }
    }, 
    chkValidation(){
      if(this.isNull(this.pushData.title)) {
        alert(`푸시제목을 입력해주세요.`);
        return false;
      }
      if(this.isNull(this.pushData.contents)) {
        alert(`푸시내용을 입력해주세요.`);
        return false;
      }
      return true;
    },
    initPushData(){
        this.pushData.pushType= 'now'
        this.pushData.pushDate = null
        this.pushData.pushTime = null
        this.pushData.title = ''
        this.pushData.contents = ''
        this.pushData.linkType = 'N'
        this.pushData.link = ''
    },
    closeLP(){
      this.isLocation = false;
    },
    agreePush(agree){
      if(agree === 'N'){
        this.isPopVisible = false 
      }
      else if(agree === 'Y'){
        
        let resultPushList = '';
        for (let idx of this.pushList){
          resultPushList += idx + ','
        }
        if(this.allPush) {
          resultPushList = -1
          if(this.memberDivision !=''){resultPushList += `&division=${this.memberDivision}`}
          if(this.memberMembership !=''){resultPushList += `&membership=${this.memberMembership}`}
        }
        ///v1/admin/pushes/checkedPeople?division=ctm&idxUser=-1&membership=b2b
        const url = this.$store.state.host + `/v1/admin/pushes/checkedPeople?idxUser=${resultPushList}`;        
        this.axios.post(url,this.pushData)
        .then((res) => {
          if(res.status == 200) {
            this.isCheckVisible = true;
          }
        })
        .catch((err)=> {
          alert(`발송에 실패하였습니다 err : ${err}`);
        })

        this.isCheckVisible = true; // 테스트를 위한 true 처리 
      }
    },
    checkClose(){
      this.initPushData();
      this.isPopVisible =false;
      this.isCheckVisible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
 .modal-dialog {
    max-width:880px;
  }
  .pointer{
  cursor: pointer
}
.guide_pop{
  z-index: 1003;
}
.guide_pop .btn_red {
  margin-left: 1.5rem;
}
.btn_red {
  background-color: #FF3030; 
  border-color: #FF3030;
  color: #fff;
}
.guide_pop > p {
  margin-top: 11px;
  text-align: center;
}
.guide_pop .btn_box {
  width: 45%;
  margin: 0 auto 0.95rem;
}
.guide_pop .btn_center {
  width: 20%;
  margin-top: 11px;
}

</style>