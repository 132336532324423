<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">푸시 상세</h3>
              <p class="lead">
                - 앱 사용자에게 푸시알림을 보낼 수 있습니다.<br>
                - 아이폰에서는 푸시제목 푸시내용만 표시 됩니다.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4>발송설정</h4>
                  <div class="table-responsive text-center mt-20">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;발송일시</th>
                        <td class="txt-left">
                          <span>{{ push.pushDate }}</span>
                          <span class="ml-1" style="color: #0e4cfd">{{ push.statusString }}</span>
                          <span class="ml-1" v-if="push.status === 'R'" ><button @click="deletePush()" type="submit" class="btn btn-danger">예약취소</button></span>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;회원구분</th>
                        <td class="txt-left">
                          {{ push.targetUserString }}
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;사용이력</th>
                        <td class="txt-left">
                          {{ push.targetDateString }}
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4>발송내용</h4>
                  <div class="table-responsive text-center mt-20">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;푸시제목</th>
                        <td class="txt-left">
                          {{ push.title }}
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;푸시내용</th>
                        <td class="txt-left">
                          {{push.contents}}
                        </td>
                      </tr>
                      <!--<tr>
                        <th><span class="red">*</span>&nbsp;이미지</th>
                        <td class="txt-left">
                          <img style="width: 100px; height: 100px; border-radius: 0" v-if="push.image !== '' && push.image !== null" :src="push.image">
                          <p v-if="push.image === '' || push.image === null">이미지 없음</p>
                        </td>
                      </tr>-->
                      <tr>
                        <th><span class="red">*</span>이동경로</th>
                        <td class="txt-left">
                          <a :href="push.link">{{push.link}}</a>
                          <p v-if="push.linkType === 'N'">사용안함</p>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button @click="$router.push('/push')" type="submit" class="btn btn-primary fl">목록</button>
                    <!--<button @click="postPush()" type="submit" class="btn btn-primary fr">지금발송</button>-->
                  </div>
                </div>
              </div>
            </div>

           <!-- <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4>발송내역</h4>
                  <div class="table-responsive text-center mt-20">
                    <table class="table table-bordered">
                      <colgroup>
                        <col style="width: 10%">
                        <col >
                        <col >
                      </colgroup>
                      <tr>
                        <th>발송유저</th>
                        <th>발송상태</th>
                        <th>기타</th>
                      </tr>
                      <tr v-for="item in pushLogs" :key="item.deviceId">
                        <td class="txt-left">{{ item.user.userName }}</td>
                        <td class="txt-left">{{ item.pushStatus }}</td>
                        <td class="txt-left">{{ item.errorInfo }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>-->

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import router from "@/router";

export default {
  name: 'pushview',
  components: {
    Menu,
  },
  props: {
    msg: String
  },
  data: () => ({
    push: {},
    pushLogs: [],
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    initPage: function () {
      this.getPush();
    },

    getPush: function () {
      const url = this.$store.state.host + "/v1/admin/pushes/" + this.$route.params.idx;
      const params = {
        page: 1
      };
      this.axios.get(url, {params})
      .then((res) => {
        var data = res.data;
        if (data.status === 0) {
          this.push = res.data.result.push;
          this.push.pushDate = this.$moment(this.push.pushDate).format('YYYY-MM-DD HH:mm:ss'); /// 날짜처리
          this.pushLogs = res.data.result.pushLogs;
        } else {
          alert(data.result);
          router.push('/push');
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    postPush: function () {
      const url = this.$store.state.host + "/v1/admin/pushes/" + this.$route.params.idx;
      this.axios.post(url)
      .then((res) => {
        var data = res.data;
        if (data.status === 0) {
          router.push('/push')
        } else {
          alert(data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    deletePush: function () {
      const url = this.$store.state.host + "/v1/admin/pushes/" + this.$route.params.idx;
      this.axios.delete(url)
      .then((res) => {
        var data = res.data;
        if (data.status === 0) {
          alert(data.result);
          router.push('/push' +
              '' +
              '' +
              '')
        } else {
          alert(data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
