<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">찾아가는 큐레이션 관리</h3>
              <p class="lead">
                - 찾아가는 큐레이션 컨텐츠 관리 페이지 입니다.<br>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th width="60">노출일</th>
                        <td class="text-left">
                          <input v-model="filter.sdate" type="date" class="" placeholder=""> ~ <input v-model="filter.edate" type="date" class="" placeholder="">
                        </td>
                        <th>제목</th>
                        <td>
                          <input @keyup.enter="getCurations(1)" v-model="filter.title" type="text" class="form-control"
                                 id="colFormLabel" placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      <tr>
                        <th>산업 군</th>
                        <td colspan="3">
                          <label class="fl mr-1"><input name="industries" value="6601" type="checkbox">전자/통신/반도체</label>
                          <label class="fl mr-1"><input name="industries" value="6602" type="checkbox">건설/화학</label>
                          <label class="fl mr-1"><input name="industries" value="6603" type="checkbox">기계/철강/자동차</label>
                          <label class="fl mr-1"><input name="industries" value="6604" type="checkbox">에너지/환경</label>
                          <label class="fl mr-1"><input name="industries" value="6605" type="checkbox">패션/무역/유통</label>
                          <label class="fl mr-1"><input name="industries" value="6606" type="checkbox">의료/제약</label>
                          <label class="fl mr-1"><input name="industries" value="6607" type="checkbox">서비스/교육/금융</label>
                          <label class="fl mr-1"><input name="industries" value="6608" type="checkbox">IT/인터넷</label>
                          <label class="fl mr-1"><input name="industries" value="6609" type="checkbox">공공 부문</label>
                          <label class="fl mr-1"><input name="industries" value="6610" type="checkbox">생산/제조</label>
                          <label class="fl mr-1"><input name="industries" value="6611" type="checkbox">언론</label>
                          <label class="fl mr-1"><input name="industries" value="6612" type="checkbox">기타</label>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary" @click="getCurations(filter.page)">
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th rowspan="2">No</th>
                        <th rowspan="2">노출일</th>
                        <th rowspan="2">제목</th>
                        <th rowspan="2">산업군</th>
                        <th colspan="3">메일</th>
                        <th rowspan="2">구독자<br>조회수</th>
                        <th rowspan="2">구독자<br>누적 조회수</th>
                        <th rowspan="2">누적<br>조회수</th>
                        <th rowspan="2">미리 보기</th>
                      </tr>
                      <tr>
                        <th>발송 수</th>
                        <th>오픈 수</th>
                        <th>오픈율</th>
                      </tr>
                      <tr v-for="item in this.curations" :key="item.idx">
                        <td>{{ item.idx }}</td>
                        <td>{{ item.createDate }}</td>
                        <td><a style="color:dodgerblue; cursor: pointer"
                               @click="moveCuration(item.idx)">{{ item.title }}</a></td>
                        <td><span class="ml-1 label-industry" v-for="industry in item['industries']"
                                  :key="industry.value">{{ industry }}</span></td>
                        <td>{{ item.mailSendCount }}</td>
                        <td>{{ item.mailReadCount }}</td>
                        <td>{{ item.mailReadRate }}</td>
                        <td>{{ item.subSendCount }}</td>
                        <td style="width: 5%">{{ item.subTotalCount }}</td>
                        <td>{{ item.readCount }}</td>
                        <td>
                          <button @click="showPreview(item.idx)">View</button>
                        </td>
                      </tr>
                    </table>

                    <div style="padding-left:35%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="curationPageCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';
import CurationComponent from "@/components/model/curationComponent";

export default {
  mixins: [CurationComponent],
  name: 'program',
  components: {
    Menu,
    Paginate
  },

  props: {
    msg: String
  },

  mounted() {
    this.getCurations(this.filter.page)
  },
}
</script>

<style scoped lang="scss">

</style>
