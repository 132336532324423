<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">푸시관리</h3>
              <p class="lead">
                -발송된 푸시 목록을 확인할 수 있는 페이지입니다.<br />
                -푸시 제목을 선택하여 발송된 내용을 확인할 수 있습니다.<br />
                -수신확인을 선택하여 푸시를 확인한 회원 목록을 불러올수 있습니다.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th width="60">발송일시</th>
                          <td>
                            <input v-model="filter.date" type="date" class="form-control" id="colFormLabel" placeholder="검색어를 입력하세요." />
                          </td>
                          <th>구분</th>
                          <td>
                            <select class="form-control fl" v-model="filter.division" @change="getPushes(1)">
                            <option value="">구분선택</option>
                            <option value="CTM">CTM</option>
                            <option value="ITM">ITM</option>
                            <option value="통합">통합</option>
                            <option value="자체유입">자체유입</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <th width="60">상태</th>
                          <td>
                            <select class="form-control fl" v-model="filter.status" @change="getPushes(1)">
                            <option value="">구분선택</option>
                            <option value="R">준비</option>
                            <option value="C">취소</option>
                            <option value="S">완료</option>
                            <option value="P">진행중</option>
                            </select>
                          </td>
                          <th>푸시제목</th>
                          <td>
                            <input @keyup.enter="getPushes(1)" v-model="filter.title" type="text" class="form-control" id="colFormLabel" placeholder="검색어를 입력하세요." />
                          </td>
                        </tr>                        
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary" @click="getPushes(1)">검색</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <!-- <div class="clear mb-2">
                    <button type="submit" class="btn btn-primary fr ml-10" @click="$router.push('/pushadd')">등록</button>
                  </div> -->
                  <div class="table-responsive text-center" style="overflow-y: hidden">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%">No</th>
                        <th style="width: 5%">상태</th>
                        <th style="width: 10%">발송일시</th>
                        <th style="width: 5%">총발송</th>
                        <th style="width: 5%">수신확인</th>
                        <th style="width: 5%">구분</th>
                        <th>푸시제목</th>
                        <th style="width: 5%">발송자</th>
                      </tr>
                      <tr v-for="(item, index) in pushes" :key="item.idx">
                        <td>{{ result.total - (result.currentPage - 1) * result.perPage - index }}</td>
                        <td>{{ item.statusString }}</td>
                        <td>{{ $moment(item.pushDate).format("YYYY-MM-DD HH:mm:ss") }}</td>
                        <td>
                          {{ item.sendCnt }}
                        </td>
                        <td>
                          <a class="link" @click="checkedPeopleModal(item)">{{ item.checkCnt }}</a>
                        </td>
                        <td>{{ item.division }}</td>
                        <td>
                          <a :href="'/pushview/' + item.idx">{{ item.title }}</a>
                        </td>
                        <td v-if="item.emp !== null">{{ item.emp.englishname }}({{ item.emp.koreanname }})</td>
                        <td v-else>시스템</td>
                      </tr>
                      <tr v-if="pushes.length === 0">
                        <td colspan="11">검색하신 푸시 내역이 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div v-if="lastpage !== 0" style="padding-left: 40%; padding-top: 20px">
                      <paginate
                        :page-count="lastpage"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="clickCallback"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination'"
                        :page-class="'page-link'"
                      >
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PopCheckedPeople :visible="modalVisible" :idxPush="selectIdx" @close="onClose"></PopCheckedPeople>
  </div>
</template>

<script>
import Menu from "../../components/commons/IncMenu.vue";
import PopCheckedPeople from "../../components/pop/pop_checkedPeople.vue";
import Paginate from "vuejs-paginate";

export default {
  name: "push",
  components: {
    Menu,
    Paginate,
    PopCheckedPeople,
  },
  props: {
    msg: String,
  },
  data: () => ({
    filter: {
      page: 1,
      data: "",
      title: "",
      status:"",
      division: "",
    },
    
    total: 0,
    pushes: [],
    lastpage: 1,
    modalVisible: false,
    selectIdx: -1,
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    initPage: function () {
      this.getPushes(1);
    },
    clickCallback: function (pageNum) {
      this.getPushes(pageNum);
    },
    getPushes: function (page) {
      if (page !== undefined) this.filter.page = page;
      var params = {
        page: this.filter.page,
        title: this.filter.title === "" ? null : this.filter.title,
        date: this.filter.date === "" ? null : this.filter.date,
        status:this.filter.status === "" ? null : this.filter.status,
        division:this.filter.division === "" ? null : this.filter.division,
      };

      this.axios.get(this.$store.state.host + "/v1/admin/pushes", { params }).then((res) => {
        if (res.data.status === 105) {
          this.pushes = [];
          this.lastpage = 0;
        } else {
          this.result = res.data.result;
          this.pushes = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    checkedPeopleModal(item) {
      if (item.emp == null) {
        alert(`시스템으로 발송된 스케줄러(푸시)의\n수신 목록은 별도 제공하지 않습니다`);
      } else {
        this.selectIdx = item.idx;
        this.modalVisible = true;
      }
    },
    onClose: function (val) {
      this.modalVisible = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.link {
  color: #007bff !important;
  text-decoration: none !important;
  background-color: transparent;
  cursor: pointer;
}
</style>
