<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">입영작 컨텐츠 상세</h3>
            </div>
          </div>

          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th><span class="red">*</span>&nbsp;카테고리</th>
                          <td class="text-left">
                            <select class="form-control fl w-10per" ref="cSelectRef" v-model="curComposition">
                              <option value="">선택</option>
                              <option v-for="item in compositionCategories" :key="item.idx" :value="item.idx">{{item.categoryName}}</option>
                            </select>
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;사용여부</th>
                          <td class="text-left useStatus">
                            <label class="mr-10"><input type="radio" name="useStatus" value="Y" v-model="curUseStatus" />Y</label>
                            <label><input type="radio" name="useStatus" value="N" v-model="curUseStatus"/>N</label>
                          </td>
                        </tr>
                
                        <tr>
                          <th><span class="red">*</span>&nbsp;국문 1</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="korSentence1" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;모범답안 1</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="engSentence1" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;국문 Keyword 1 & 2</th>
                          <td>
                            <input type="text" class="form-control w-100per mb-10" placeholder="Keyword 1" v-model="korKeyword1_1" />
                            <input type="text" class="form-control w-100per" placeholder="Keyword 2" v-model="korKeyword1_2" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;영문 Keyword 1 & 2</th>
                          <td>
                            <input type="text" class="form-control w-100per mb-10" placeholder="Keyword 1" v-model="engKeyword1_1" />
                            <input type="text" class="form-control w-100per" placeholder="Keyword 2" v-model="engKeyword1_2" />
                          </td>
                        </tr>
                        

                        <tr>
                          <th><span class="red">*</span>&nbsp;국문 2</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="korSentence2" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;모범답안 2</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="engSentence2" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;국문 Keyword 1 & 2</th>
                          <td>
                            <input type="text" class="form-control w-100per mb-10" placeholder="Keyword 1" v-model="korKeyword2_1" />
                            <input type="text" class="form-control w-100per" placeholder="Keyword 2" v-model="korKeyword2_2" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;영문 Keyword 1 & 2</th>
                          <td>
                            <input type="text" class="form-control w-100per mb-10" placeholder="Keyword 1" v-model="engKeyword2_1" />
                            <input type="text" class="form-control w-100per" placeholder="Keyword 2" v-model="engKeyword2_2" />
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;국문 3</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="korSentence3" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;모범답안 3</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="engSentence3" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;국문 Keyword 1 & 2</th>
                          <td>
                            <input type="text" class="form-control w-100per mb-10" placeholder="Keyword 1" v-model="korKeyword3_1" />
                            <input type="text" class="form-control w-100per" placeholder="Keyword 2" v-model="korKeyword3_2" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;영문 Keyword 1 & 2</th>
                          <td>
                            <input type="text" class="form-control w-100per mb-10" placeholder="Keyword 1" v-model="engKeyword3_1" />
                            <input type="text" class="form-control w-100per" placeholder="Keyword 2" v-model="engKeyword3_2" />
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;이미지</th>
                          <td class="text-left">
                            <input 
                              id="categoryImage"
                              ref="file"
                              type="file"
                              accept=".jpg, .png"
                              hidden
                              @change="onFileSelected($event)"
                            />
                            <span class="upload-image">
                              <img @click="moveImage" :src="uploadImage">
                            </span>
                            <span class="red">* jpg, png 등록 가능</span>
                          </td>
                        </tr>
                        
                         <tr>
                          <th><span class="red">*</span>&nbsp;발송일</th>
                          <td>
                            <input 
                              type="date" 
                              class="form-control dateInput" 
                              id="session-date" name="session-date" 
                              v-model="curSendDate" />
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="clear">
            <button
              @click="$router.push('/ds');"
              type="submit"
              class="btn btn-primary fl"
            >
              목록
            </button>
            <button
              @click="saveCompositionDetail();"
              type="submit"
              class="btn btn-primary fr ml-1"
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../../components/commons/IncMenu.vue";
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";

export default {
  mixins:[DailySpeakingComponent],
  components: {
    Menu,
  },
  data(){
    return{
      isModify:false,
      curIdx:'-1',
      curComposition:'',
      curUseStatus:'Y',
      korSentence1:'',
      korSentence2:'',
      korSentence3:'',
      engSentence1:'',
      engSentence2:'',
      engSentence3:'',
      korKeyword1_1:'',
      korKeyword1_2:'',
      engKeyword1_1:'',
      engKeyword1_2:'',
      korKeyword2_1:'',
      korKeyword2_2:'',
      engKeyword2_1:'',
      engKeyword2_2:'',
      korKeyword3_1:'',
      korKeyword3_2:'',
      engKeyword3_1:'',
      engKeyword3_2:'',
      curSendDate:'',
      uploadImage:'https://kr.object.ncloudstorage.com/platform-storage/commons/img-placeholder.jpg',
      imageAdded:false,
      curImageFile:new FormData(),
    }
  },

  methods: {
    moveImage: function () {
      document.getElementById('categoryImage').click();
    },

    //미리보기 이미지 생성 
    setPreviewImage(e){
      const input=e.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadImage = e.target.result;
        }
        reader.readAsDataURL(input.files[0])
      }
    },


    //카테고리 이미지 파일 등록
    onFileSelected(event){
      if (!event.target.value){
        this.uploadImage='https://kr.object.ncloudstorage.com/platform-storage/commons/img-placeholder.jpg'
        this.imageAdded=false;
        return
      }
      console.log(event.target.value,'val')
      this.setPreviewImage(event);

      let file = this.$refs.file.files[0];
      this.imageAdded=true;
      this.curImageFile.set('categoryImg', file);
    },


    //입영작 컨텐츠 저장
    saveCompositionDetail(){
    // Model 로 벨리데이션 작성해두어있던것 줄바꿈만 진행 -Eruda FIXME: 반복이나, 라이브러리로 정리필요  
      if (
           !this.curComposition ||
           !this.curUseStatus   || 
           !this.korSentence1   ||
            !this.korSentence2  || 
            !this.korSentence3  || 
            !this.engSentence1  || 
            !this.engSentence2  || 
            !this.engSentence3  || 
            !this.korKeyword1_1 || 
            // !this.korKeyword1_2 || 
            !this.engKeyword1_1 || 
            // !this.engKeyword1_2 || 
            !this.korKeyword2_1 || 
            // !this.korKeyword2_2 || 
            !this.engKeyword2_1 || 
            // !this.engKeyword2_2 || 
            !this.korKeyword3_1 || 
            // !this.korKeyword3_2 || 
            !this.engKeyword3_1 || 
            // !this.engKeyword3_2 || 
            !this.curSendDate || 
            !this.imageAdded)
      {
        alert('입력되지 않은 정보가 존재합니다')
        return;
      }
      
      const params={
        idx:this.curIdx,
        idxCategoryTranslation:this.curComposition,
        useStatus:this.curUseStatus,
        korQuestion1:encodeURI(this.korSentence1),
        engAnswer1:encodeURI(this.engSentence1),
        question1EngKeyword1:encodeURI(this.engKeyword1_1),
        question1KorKeyword1:encodeURI(this.korKeyword1_1),
        question1EngKeyword2: !this.engKeyword1_2 ? '-' : encodeURI(this.engKeyword1_2),
        question1KorKeyword2: !this.korKeyword1_2 ? '-' : encodeURI(this.korKeyword1_2),
        korQuestion2:encodeURI(this.korSentence2),
        engAnswer2:encodeURI(this.engSentence2),
        question2EngKeyword1:encodeURI(this.engKeyword2_1),
        question2KorKeyword1:encodeURI(this.korKeyword2_1),
        question2EngKeyword2: !this.engKeyword2_2 ? '-' : encodeURI(this.engKeyword2_2),
        question2KorKeyword2: !this.korKeyword2_2 ? '-' : encodeURI(this.korKeyword2_2),
        korQuestion3:encodeURI(this.korSentence3),
        engAnswer3:encodeURI(this.engSentence3),
        question3EngKeyword1:encodeURI(this.engKeyword3_1),
        question3KorKeyword1:encodeURI(this.korKeyword3_1),        
        question3EngKeyword2: !this.engKeyword3_2 ? '-' : encodeURI(this.engKeyword3_2),
        question3KorKeyword2: !this.korKeyword3_2 ? '-' : encodeURI(this.korKeyword3_2),


        imgUrl:this.curImageFile.get('categoryImg')!==null?"":this.uploadImage,
        categoryImg:this.curImageFile,
        sendDate:this.curSendDate,
      };
      
      this.insertCompositionDetail(params)
    },
  },

  mounted(){
    if (Object.keys(this.$store.state.dsState.itemInfo).length>1){
      const item=this.$store.state.dsState.itemInfo;
      this.isModify=true;
      this.imageAdded=true;
      this.curIdx=item.idx;
      this.curComposition=item.idxCategoryTranslation;
      this.curUseStatus=item.useStatus;
      this.korSentence1=item.korQuestion1;
      this.engSentence1=item.engAnswer1;
      this.engKeyword1_1=item.question1EngKeyword1;
      this.korKeyword1_1=item.question1KorKeyword1;
      this.engKeyword1_2=item.question1EngKeyword2 == '-' ? '' : item.question1EngKeyword2;
      this.korKeyword1_2=item.question1KorKeyword2 == '-' ? '' : item.question1KorKeyword2;

      this.korSentence2=item.korQuestion2;
      this.engSentence2=item.engAnswer2;
      this.engKeyword2_1=item.question2EngKeyword1;
      this.korKeyword2_1=item.question2KorKeyword1;
      this.engKeyword2_2=item.question2EngKeyword2 == '-' ? '' : item.question2EngKeyword2;
      this.korKeyword2_2=item.question2KorKeyword2 == '-' ? '' : item.question2KorKeyword2;

      this.korSentence3=item.korQuestion3;
      this.engSentence3=item.engAnswer3;
      this.engKeyword3_1=item.question3EngKeyword1;
      this.korKeyword3_1=item.question3KorKeyword1;
      this.engKeyword3_2=item.question3EngKeyword2 == '-' ? '' : item.question3EngKeyword2;
      this.korKeyword3_2=item.question3KorKeyword2 == '-' ? '' : item.question3KorKeyword2;

      this.uploadImage=item.imgUrl 
      this.curSendDate=item.sendDate;

      //select 태그 selected된 option 지정
      this.$refs.cSelectRef.innerHTML=`
        <option value="${item.idxCategoryTranslation}" selected>${item.categoryName}</option>
      `
      
    } else {
      this.getCompositionCategoryList();
    }
    
  },
  computed:{

  }
};
</script>

<style lang="scss" scoped>
.useStatus{
  display:flex;
  align-items: center;

  label{
    display:flex;
    align-items: center;
    
    &, *{
      cursor: pointer;
    }
  }
}
.p-input {
  border: 0.5px solid rgba(0, 0, 0, 0.3);
}

.upload-image {
  position: relative;
  float: left;
  margin-bottom: 5px;
  margin-right: 5px;
  width:130px;
  height:100px;

  img{
    cursor:pointer;
    border-radius: 0;
    width:130px;
    height:100px;
  }
}

.upload-image::after {
  content: "";
  display: block;
  clear: both;
}

</style>