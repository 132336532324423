<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <location-add :visible="isLocation" @mount="mountLocation" @close="locationClose"/>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">AI튜터 배너 등록 </h3>
              <p class="lead">
                - 서비스단에 노출할 AI튜터 배너를 등록하는 페이지입니다.
              </p>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너종류</th>
                        <td class="text-left">
                          <label><input type="radio" v-model="banner.bannerType" value="slide-banner">&nbsp; 메인 슬라이딩 배너</label>
                          <label class="ml-1"><input type="radio" v-model="banner.bannerType" value="belt-banner">&nbsp; 띠 배너</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너순서</th>
                        <td class="text-left">
                          <input v-model.number="banner.sort" type="number" class="w-10"/>&nbsp;노출순서 숫자로 입력 (작은 수부터 순서대로 노출)
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;타이틀</th>
                        <td>
                          <input v-model="banner.title" type="text" class="form-control" placeholder="배너 타이틀을 입력하세요."/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;노출기간</th>
                        <td class="txt-left">
                          <input v-model="banner.startDate" type="date" placeholder="yyyy-mm-dd"/> ~
                          <input v-model="banner.endDate" type="date" placeholder="yyyy-mm-dd"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 사용여부</th>
                        <td class="txt-left">
                          <label><input v-model="banner.isUse" type="radio" value="Y">사용</label>
                          <label class="ml-1"><input v-model="banner.isUse" type="radio" value="N">미사용</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너 이미지<p class=" red">
                          jpg, png 등록가능 <br>
                          메인 슬라이드 배너(375*221) <br>
                          띠 배너(375*120)
                        </p>

                        </th>
                        <td>
                          <biz-image :url="imageUrl" :img="banner.bannerFile" :img-style="imageSize" @mountImage="banner.bannerFile = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 이동경로</th>
                        <td>
                          <div class="text-left">
                            <label><input @click="banner.link = ''" v-model="banner.isLink"  type="radio" value="N">미사용</label>
                            <label class="ml-1"><input @click="banner.link = ''" v-model="banner.isLink" type="radio" value="Y"></label>
                            사용
                          </div>
                          <div>
                            <input v-if="banner.isLink !== 'N'" :disabled="banner.isLink === 'N'"  v-model="banner.link" type="text" class="form-control"  placeholder="http://"/>
                          </div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goBanners()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="postBanners()">
                      등록
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import LocationAdd from '../../components/pop/popLocationAdd';
import BizImage from "../../components/upload/uploadImage";

export default {
  name: 'aiBannerAdd',
  components: {
    Menu,
    LocationAdd,
    BizImage,
  },
  props: {
    msg: String
  },
  data: () => ({
    fileList: [],

    bannerImage: '',

    validation: {
      sort: false,
    },

    /// loader
    loading: false,
    loader: {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '5px',
      margin: '2px',
      radius: '2px'
    },

    /// banner
    banner: {
      title: ''
      , bannerType: 'slide-banner'
      , bannerFile: ''
      , link: ''
      , isLink: 'N'
      , sort: ''
      , sdate: ''
      , edate: ''
      , isUse: 'Y'
    },
    is_preview: false,
    isLocation: false,
    banner_img: "",
  }),

  computed: {
    imageUrl: function () {
      let url = this.$store.state.host + "/v1/aitutor/banner/image/";

      return url + this.banner.bannerType;
    },

    imageSize: function () {
      if (this.banner.bannerType === 'slide-banner') {
        return 'width:375px; height:221px'
      }else  {
        return 'width:375px; height:120px;'
      }
    }
  },

  mounted() {
  },
  methods: {
    chooseImage: function () {
      document.getElementById("upload").click()
    },
    checkValid: function () {
      alert(this.banner.sort + "," + isNaN(this.banner.sort));
      this.validation.sort = (isNaN(this.banner.sort));
    },
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },

    postBanners: function () {
      ///validation
      const url = this.$store.state.host + "/v1/aitutor/banner";
      if (this.banner.bannerType === "") {
        alert('배너 종류를 선택해주세요.')
      } else if (this.banner.sort === "") {
        alert('배너 순서를 입력해주세요.')
      } else if (this.banner.title === "") {
        alert('배너 타이틀을 입력해주세요.')
      } else if (this.banner.startDate === "") {
        alert('배너 시작 시간을 입력해주세요.')
      } else if (this.banner.endDate === "") {
        alert('배너 종료 시간을 입력해주세요.')
      } else if (this.banner.bannerFile === "") {
        alert('이미지를 먼저 업로드 해주세요.');
      } else if (this.banner.isLink == "") {
        alert('이동경로를 입력해주세요.');
      } else if( this.banner.link === '' && this.banner.isLink === "Y"){
        alert('이동경로를 입력해주세요.');
      } else {
        this.axios.post(url, this.banner)
        .then((res) => {
          const data = res.data;
          if (data.status === 0) {
            alert("정상적으로 배너등록이 완료되었습니다.")
            this.goBanners();
          } else {
            alert(data.result)
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    goBanners: function () {
      this.$router.push("/AiBanner")
    },

    locationClose: function (state) {
      this.isLocation = state;
    },

    mountLocation: function (idx, subject) {
      if(subject == "reuter"){
        this.banner.link = '/' + subject + '?newsSeq=' + idx
      }else if(subject == "dw"){
        this.banner.link = '/' + subject;
      }else{
        this.banner.link = '/' + subject + '?idx=' + idx
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
