<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <location-add :visible="isLocation" @mount="mountLocation" @close="locationClose"/>
      <Preview :visible="is_preview" @close="vue_close" :banner_img="banner.img"
               :banner-type="banner.type"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">배너 등록 </h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너종류</th>
                        <td class="text-left">
                          <label><input type="radio" v-model="banner.type" value="메인슬라이드배너">메인 슬라이드
                            배너</label>
                          <label class="ml-1"><input type="radio" v-model="banner.type"
                                                     value="중간배너">중간 배너</label>
                          <label class="ml-1"><input type="radio" v-model="banner.type" value="찾아가는큐레이션">찾아가는 큐레이션</label>
                          <label class="ml-1"><input type="radio" v-model="banner.type" value="로이터뉴스">로이터뉴스</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너순서</th>
                        <td class="text-left">
                          <input v-model.number="banner.ordernum" type="number" class=""/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;타이틀</th>
                        <td>
                          <input v-model="banner.title" type="text" class="form-control"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;노출기간</th>
                        <td class="txt-left">
                          <input v-model="banner.sdate" type="date" placeholder="yyyy-mm-dd"/> ~
                          <input v-model="banner.edate" type="date" placeholder="yyyy-mm-dd"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 사용여부</th>
                        <td class="txt-left">
                          <label><input v-model="banner.isUse" type="radio" value="Y">사용</label>
                          <label class="ml-1"><input v-model="banner.isUse" type="radio" value="N">사용안함</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;안드로이드 노출여부</th>
                        <td class="txt-left">
                          <label><input v-model="banner.isAndroid" type="radio" value="Y">사용</label>
                          <label class="ml-1"><input v-model="banner.isAndroid" type="radio" value="N">사용안함</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;IOS 노출여부</th>
                        <td class="txt-left">
                          <label><input v-model="banner.isIos" type="radio" value="Y">사용</label>
                          <label class="ml-1"><input v-model="banner.isIos" type="radio" value="N">사용안함</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너 이미지<p class=" red">
                          jpg, png 등록가능 <br>
                          메인 슬라이드 배너(824*468) <br>
                          중간 배너(782*262)
                        </p>

                        </th>
                        <td>
                          <biz-image :url="imageUrl" :img="banner.img" :img-style="imageSize" @mountImage="banner.img = $event"/>
                        </td>
                      </tr>
                      <tr v-if="banner.type === '메인슬라이드배너' || banner.type === '중간배너'">
                        <th>&nbsp;이동경로</th>
                        <td>
                          <div class="text-left">
                            <label><input @click="banner.link = ''" v-model="banner.linkType"
                                          type="radio" value="">미사용</label>
                            <label class="ml-1"><input @click="banner.link = ''"
                                                       v-model="banner.linkType" type="radio"
                                                       value="O">외부링크</label>
                            <label class="ml-1"><input @click="banner.link = ''"
                                                       v-model="banner.linkType" type="radio"
                                                       value="I">내부링크</label>
                          </div>
                          <div>
                            <button @click="locationClose(true)" v-if="banner.linkType === 'I'"
                                    class="btn btn-dark w-100per">
                              {{ banner.link === '' ? '이동경로를 선택해주세요' : banner.link }}
                            </button>
                            <input v-if="banner.linkType !== 'I'" :disabled="banner.linkType === ''"
                                   v-model="banner.link" type="text" class="form-control"
                                   placeholder="http://"/>
                          </div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goBanners()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="postBanners()">
                      등록
                    </button>
                    <button type="submit" class="btn btn-primary fr" @click="showPreview()">
                      미리보기
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Preview from "../../components/preview/pop_preview.vue";
import LocationAdd from '../../components/pop/popLocationAdd';
import BizImage from "../../components/upload/uploadImage";

export default {
  name: 'bannerAdd',
  components: {
    Menu,
    Preview,
    LocationAdd,
    BizImage,
  },
  props: {
    msg: String
  },
  data: () => ({
    fileList: [],

    bannerImage: '',

    validation: {
      ordernum: false,
    },

    /// loader
    loading: false,
    loader: {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '5px',
      margin: '2px',
      radius: '2px'
    },

    /// banner
    banner: {
      title: ''
      , type: '메인 슬라이드 배너'
      , img: ''
      , link: ''
      , linkType: ''
      , ordernum: ''
      , sdate: ''
      , edate: ''
      , isUse: 'Y'
      , isAndroid: 'Y'
      , isIos: 'Y'
    },
    is_preview: false,
    isLocation: false,
    banner_img: "",
  }),

  computed: {
    imageUrl: function () {
      let url = this.$store.state.host + "/v1/admin/banners/image/";
      let extraUrl;

      if (this.banner.type === '메인슬라이드배너'
      ||this.banner.type === '찾아가는큐레이션'
      ||this.banner.type === '로이터뉴스') {
        extraUrl = 'upper';
      }else if (this.banner.type === '중간배너') {
        extraUrl = 'bottom'
      }else {
        extraUrl = 'ai'
      }

      return url + extraUrl;
    },

    imageSize: function () {
      if (this.banner.type === '메인슬라이드배너') {
        return 'width:412px; height:234px'
      }else if (this.banner.type === '중간배너') {
        return 'width:391px; height:131px;'
      }else if (this.banner.type === '찾아가는큐레이션') {
        return 'width:412px; height:234px'
      }else if (this.banner.type === '로이터뉴스') {
        return 'width:412px; height:234px'
      }else {
        return 'width:391px; height:201px;'
      }
    }
  },

  mounted() {
  },
  methods: {
    chooseImage: function () {
      document.getElementById("upload").click()
    },
    checkValid: function () {
      alert(this.banner.ordernum + "," + isNaN(this.banner.ordernum));
      this.validation.ordernum = (isNaN(this.banner.ordernum));
    },
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },

    postBanners: function () {
      ///validation
      const url = this.$store.state.host + "/v1/admin/banners";
      if (this.banner.type === "") {
        alert('배너 종류를 선택해주세요.')
      } else if (this.banner.ordernum === "") {
        alert('배너 순서를 입력해주세요.')
      } else if (this.banner.title === "") {
        alert('배너 타이틀을 입력해주세요.')
      } else if (this.banner.sdate === "") {
        alert('배너 시작 시간을 입력해주세요.')
      } else if (this.banner.edate === "") {
        alert('배너 종료 시간을 입력해주세요.')
      } else if (this.banner.img === "") {
        alert('이미지를 먼저 업로드 해주세요.');
      } else if (this.banner.linkType !== "" && this.banner.link === '') {
        if (this.banner.type!=="찾아가는큐레이션" || this.banner.type!=="로이터뉴스"){
        alert('이동경로를 입력해주세요.');
        }
      } else {
        this.axios.post(url, this.banner)
        .then((res) => {
          const data = res.data;
          if (data.status === 0) {
            alert("정상적으로 배너등록이 완료되었습니다.")
            this.goBanners();
          } else {
            alert(data.result)
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    goBanners: function () {
      this.$router.push("/Banner")
    },

    selectBannerImage: function () {
      this.loading = true;
      const file = this.$refs.bannerImage.files[0];

      console.log(file.size);

      this.banner_img = URL.createObjectURL(file);
      let formData = new FormData();
      let url = this.$store.state.host + "/v1/admin/banners/image/";
      let extraUrl = (this.banner.type === '메인 슬라이드 배너'
                      ||this.banner.type === '찾아가는큐레이션'
                      ||this.banner.type === '로이터뉴스') ? 'upper' : 'bottom';
      formData.append('img', this.$refs.bannerImage.files[0]);

      this.axios.post(url + extraUrl, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        if (res.data.status === 0) {
          this.banner.img = res.data.result;
          this.loading = false;
        } else {
          alert(res.data.result);
          this.loading = false;
        }
      })
      .catch((err) => {
        alert(`network error = ${err}`);
        this.loading = false;
      })
    },

    postBannerImage: function () {

    },

    locationClose: function (state) {
      this.isLocation = state;
    },

    mountLocation: function (idx, subject) {
      if(subject == "reuter"){
        this.banner.link = '/' + subject + '?newsSeq=' + idx
      }else if(subject == "dw"){
        this.banner.link = '/' + subject;
      }else{
        this.banner.link = '/' + subject + '?idx=' + idx
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
