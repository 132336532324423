<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">AI튜터 강의 관리</h3>
              <p class="lead">
                - AI튜터 앱에서 제공되는 이러닝 강의 관리 페이지 입니다.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <h3>전체강의수 : {{ this.lec_total}} 개</h3>
                    </div>
                    <div class="form-group float-left clear w-100per">
                      <input @keyup.enter="getLectures()" v-model="filter.searchTitle" type="text"
                             class="form-control fl w-30per"
                             placeholder="검색할 강의 타이틀을 입력하세요.">
                      <select @change="getLectures()" v-model="filter.isUse"
                              class="form-control fl ml-1 w-15per">
                        <option value="">사용여부 선택</option>
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                      <button type="submit" class="btn btn-primary ml-2 my-1" @click="getLectures()">검색</button>
                      <button type="submit" class="btn btn-primary ml-2 my-1" @click="getLecturesExcel()">엑셀다운로드</button>
                      <button type="submit" class="btn btn-primary fr" @click="goLectureAdd()">등록
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th class="w-10px"><input type="checkbox" v-model="checkall" @change="setCheckAll"></th>
                        <th class="w-10per">No</th>
                        <th class="w-10per">강의번호</th>
                        <th class="w-30per">강의타이틀</th>
                        <th class="w-10per">사용여부</th>
                        <th class="w-10per">퀴즈</th>
                        <th class="w-15per">AI Speaking</th>
                        <th class="w-15per">등록일</th>
                        <th class="w-10per">등록자</th>
                      </tr>
                      <tr v-for="(item,index) in lectures" :key="item.idx">
                        <td><input type="checkbox" v-model="item.check"></td>
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>L{{ item.idx }}</td>
                        <td><a :href="'/ailecturemodify/' + item.idx">{{ item.title }}</a></td>
                        <td>{{ item.isUse }}</td>
                        <td>{{ item.isQuiz }}</td>
                        <td>{{ item.isSpeaking }}</td>
                        <td>{{ item.createDate }}</td>
                        <td>
                          <span v-if="item.emp != null">
                          {{  (item.emp.englishname != null ) ? item.emp.englishname :'' }}({{  (item.emp.koreanname != null ) ? item.emp.koreanname :'' }})
                          </span>
                        </td>
                      </tr>
                      <tr v-if="lectures.length === 0">
                        <td colspan="8">검색 결과가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <button class="btn btn-danger float-left mh-100 my-2" @click="deleteCheckList()">삭제</button>
                    <div v-if="lectures.length !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'aiBanner',
  components: {
    Menu,
    Paginate,
  },
  props: {
    msg: String
  },
  data: () => ({
    lectures: [],
    filter: {
      page: 1,
      searchTitle: '',
      isUse: ''
    },
    is_preview: false,
    lastpage: 1,
    banner_idx: 0,
    banner_img: "",
    bannerType: '',
    checkall:false,
    lec_total:0,
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    vue_close: function (state) {
      this.is_preview = state;
    },
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    goLectureAdd: function () {
      this.$router.push("AiLectureAdd")
    },

    getLectures: function (page) {
      if (page !== undefined) {
        this.filter.page = page;
      }
      const url = this.$store.state.host + "/v1/aitutor/lecture";
      var params = {
        isUse: (this.filter.isUse === '' ? null : this.filter.isUse),
        page: (this.filter.page === '' ? null : this.filter.page),
        searchTitle: (this.filter.searchTitle === '' ? null : this.filter.searchTitle)
      };

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.result = res.data.result;
          this.lectures = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
          this.lec_total=res.data.lec_total;
          for(let i=0;i<this.lectures.length;i++) {
            this.lectures[i].check = this.checkall;
          }
        } else {
          console.log('검색실패', res)
          this.lec_total=res.data.lec_total;
          this.lectures = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    getLecturesExcel: function () {
      var isUseTxt="";
      if(this.filter.isUse !==""){
        isUseTxt="&isUse="+this.filter.isUse;
      }
      location.href=this.$store.state.host + "/v1/aitutor/lecture/excel-down?searchTitle="+ this.filter.searchTitle+isUseTxt;
    },


    putBanner: function (idx) {
      const url = this.$store.state.host + "/v1/admin/lectures/" + idx;

      this.axios.put(url, this.banner)
          .then(function (res) {
            var data = res.data.result;
            console.log(data);
          })
          .catch(error =>{ alert(`network error = ${error}`)});
    },

    selectBannerImage: function () {
      this.bannerImage = this.$refs.bannerImage.files[0];
    },


    setCheckAll : function() {
      console.log(this.lectures);
      for(let i=0;i<this.lectures.length;i++) {
        this.lectures[i].check = this.checkall;
      }
    },
    deleteCheckList :function () {

      let list = [];
      for(let i=0;i<this.lectures.length;i++) {
        if(this.lectures[i].check == true) {
          list.push(this.lectures[i].idx);
        }
      }

      if(list.length==0){
        alert("선택된리스트가 없습니다")
        return;
      }
      console.log(list);

      const url = this.$store.state.host + "/v1/aitutor/lecture/delete";



      if(confirm('선택한 '+list.length+'개 리스트를 삭제 하시겠습니까?')){
        this.axios.post(url,list
        ).then((res) => {
          if (res.data.status === 0) {
            alert('선택한 '+list.length+'개 리스트가 삭제되었습니다.');
            this.getLectures();
          } else {
            alert(res.data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }

    },
    initPage: function () {
      this.getLectures();
    }
  }

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
