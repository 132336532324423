<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <location-add :visible="isLocation" @close="locationClose"/>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">강의 수정 상세 페이지 </h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;강의 타이틀</th>
                        <td>
                          <input v-model="lecture.title" type="text" class="form-control" placeholder="강의 타이틀을 입력하세요."/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;동영상URL</th>
                        <td class="text-left">
                          <input v-model="lecture.videoUrl" type="text" class="form-control" placeholder="동영상 URL을 입력하세요."/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 사용여부</th>
                        <td class="txt-left">
                          <label><input v-model="lecture.isUse" type="radio" value="Y"> 사용</label>
                          <label class="ml-1"><input v-model="lecture.isUse" type="radio" value="N"> 사용안함</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>썸네일<p class=" red">
                          jpg, png 등록가능 <br>
                          사이즈 (158*104)
                        </p>

                        </th>
                        <td>
                          <biz-image :url="imageUrl" :img="lecture.thumbnail" :img-style="imageSize" @mountImage="lecture.thumbnail = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red"></span> 퀴즈</th>
                        <td>
                          <span  v-for="(item, idx) in lecture.quizList" :key="item.sort">
                            <div class="float-left w-95">
                            <span><p class="float-left w-5 mt-2">Q{{idx+1}}</p>
                              <textarea v-model="item.question" class="form-control  w-95" style="height: 50px;" placeholder="질문을 입력하세요."></textarea>
                            </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="1" :name="idx" /> </p>
                              <textarea v-model="item.example1" class="form-control w-95" placeholder="보기 1을 입력하세요."></textarea>
                            </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="2" :name="idx" /> </p>
                              <textarea v-model="item.example2" class="form-control w-95" placeholder="보기 2을 입력하세요."></textarea>
                            </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="3" :name="idx" /> </p>
                              <textarea v-model="item.example3"  class="form-control w-95" placeholder="보기 3을 입력하세요."></textarea>
                              </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="4" :name="idx" /> </p>
                              <textarea v-model="item.example4"  class="form-control w-95" placeholder="보기 4을 입력하세요."></textarea>
                            </span><br>
                            <textarea v-model="item.commentary" class="form-control" style="height: 100px;" placeholder="퀴즈 해설을 입력하세요."></textarea><br>
                            </div>

                            <span class="float-right">
                              <button class="swal2-close" @click="delQuiz(idx)">X</button>
                            </span>
                          </span>


                          <button  class="btn btn-primary fr" @click="addQuiz()">
                            퀴즈 추가
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th> Ai Speaking</th>
                        <td>
                          <input v-model="lecture.speakingText1" type="text" class="form-control" placeholder="음성과 매칭할 문구를 입력하세요."/><br>
                          <input v-model="lecture.speakingText2" type="text" class="form-control" placeholder="음성과 매칭할 문구를 입력하세요."/><br>
                          <input v-model="lecture.speakingText3" type="text" class="form-control" placeholder="음성과 매칭할 문구를 입력하세요."/>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goAiLectures()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="putAiLectures($route.params.idx)">
                      수정
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import router from "@/router";
import LocationAdd from '../../components/pop/popLocationAdd';
import BizImage from "../../components/upload/uploadImage";
export default {
  name: 'aiLectureModify',
  components: {
    Menu,
    LocationAdd,
    BizImage
  },
  props: {
    msg: String
  },
  data: () => ({
    /// loader
    loading: false,
    loader: {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '5px',
      margin: '2px',
      radius: '2px'
    },

    /// lecture
    lecture: {
      title: ''
      , videoUrl: ''
      , isUse: 'Y'
      , speakingText1: 'Y'
      , speakingText2: 'Y'
      , speakingText3: 'Y'
      , thumbnail: ''
      , quizList:[{
        "commentary": "",
        "example1": "",
        "example2": "",
        "example3": "",
        "example4": "",
        "question": "",
        "rightAnswer": 1,
        "sort": 1
      }]
    },
    is_preview: false,
    isLocation: false
  }),
  mounted() {
    this.initPage();

  },
  computed: {
    imageUrl: function () {
      let url = this.$store.state.host + "/v1/aitutor/lecture/image";
      return url;
    },
    imageSize: function () {
      return 'width:158px; height:104px;'
    }
  },
  methods: {
    initPage: function () {
      this.getAiLecture();
    },
    getAiLecture(){
      this.axios.get(this.$store.state.host + "/v1/aitutor/lecture/" + this.$route.params.idx)
          .then((res) => {
            this.lecture = res.data.result;
            this.lecture.quizList = res.data.quizList;
          })
          .catch(error =>{ alert(`network error = ${error}`)});
    },

    chooseImage: function () {
      document.getElementById("upload").click()
    },

    showPreview: function () {
      this.is_preview = true;
    },

    goBanners: function () {
      this.$router.push("/Banner")
    },

    vue_close: function (state) {
      this.is_preview = state;
    },

    putAiLectures: function (idx) {
      ///validation
      const url = this.$store.state.host + "/v1/aitutor/lecture/"+idx;
      if (this.lecture.title === "") {
        alert('강의 타이틀을 입력해주세요.')
      } else if (this.lecture.videoUrl === "") {
        alert('강의 동영상 URL을 입력해주세요.')
      } else if (this.lecture.isUse === "") {
        alert('사용여부를 선택해주세요.')
      } else {
        this.axios.put(url, this.lecture)
            .then((res) => {
              const data = res.data;
              if (data.status === 0) {
                alert("강의 수정이 완료되었습니다.")
                router.push("/ailecture")
              } else {
                alert(data.result)
              }
            })
            .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    goAiLectures: function () {
      this.$router.push("/AiLecture")
    },
    locationClose: function (state) {
      this.isLocation = state;
    },
    addQuiz: function () {
      //this.lecture.quizList[this.lecture.quizList.length]=this.lecture.quizList[this.lecture.quizList.length-1];
      this.lecture.quizList.push(
          {
            "commentary": "",
            "example1": "",
            "example2": "",
            "example3": "",
            "example4": "",
            "question": "",
            "rightAnswer": 1,
            "sort": this.lecture.quizList.length+1
          }
      );
      this.$forceUpdate();// 재렌더링 오류방지
      console.log(this.lecture.quizList);
    },
    delQuiz: function(idx){
      this.lecture.quizList.splice(idx, 1);
      var temp=[];
      temp= this.lecture.quizList;
      for(let i=0; i< temp.length; i++){
        temp.sort=i+1;
      }
      this.lecture.quizList= temp;

      console.log(this.lecture.quizList);
      this.$forceUpdate();// 재렌더링 오류방지
    },


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
