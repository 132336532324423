<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <Preview :visible="is_preview" @close="vue_close" :enlecture="enlecture"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">99초 동영상 수정</h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;제목</th>
                        <td>
                          <input v-model="enlecture.title" type="text"
                                 class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;사용여부</th>
                        <td class="txt-left">
                          <label><input type="radio" v-model="enlecture.isUse" value="Y">사용</label>
                          <label class="ml-1"><input type="radio" v-model="enlecture.isUse" value="N">사용안함</label>
                          <!--<button @click="enlecture.isUse = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : enlecture.isUse === 'N'}"> 사용</button>
                          <button @click="enlecture.isUse = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : enlecture.isUse === 'Y'}"> 사용안함</button>-->
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;동영상 URL</th>
                        <td class="text-left clear">
                          <input @change="checkUrl(enlecture.url)"  v-model="enlecture.url" type="text" class="form-control w-100per" />
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>동영상 내용</th>
                        <td class="text-left clear">
                          <ckeditor name="textarea" :editor="editor" v-model="enlecture.content" :config="editorConfig"></ckeditor>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button @click="$router.push('/enlecture')" type="submit"
                            class="btn btn-primary fl">목록
                    </button>
                    <button @click="deleteSentence()" type="submit" class="btn btn-danger fl ml-10">
                      삭제
                    </button>
                    <button @click="putSentence()" type="submit" class="btn btn-primary fr ml-10">
                      저장
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Menu from '../../components/commons/IncMenu.vue'
import Preview from "../../components/preview/pop_preview_enSentence.vue";
import host from "@/store/host";
import EnLectureComponent from "@/pages/enLecture/enLectureComponent";

export default {
  mixins: [EnLectureComponent],
  name: 'EnsentenceAdd',
  components: {
    Menu,
    Preview,
  },
  props: {
    msg: String
  },
  data: () => ({
    date: new Date(2016, 9, 16),
    enlecture: {

    },
    is_preview: false,
    isLoading: false,

    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      // The configuration of the editor.
      filebrowserUploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor',
      ckfinder: {
        uploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor'
      },
      allowedContent: true,
      height : 600
    },
    imageUrl: '',
  }),
  computed: {
    enlectureUrl: () => {
      return this.enlecture.url;
    },
  },
  mounted() {
    this.initPage();
    this.imageUrl = host + "/v1/admin/scvideos/image";
  },
  methods: {
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },
    initPage: function () {
      const idx = this.$route.params.idx;
      this.getEnLecture(idx);
    },
    checkUrl: function (url) {
      if(url.startsWith('https://')) this.enlecture.url = url;
      else {
        if (url.startsWith('http://')) {
          url = url.slice(6)
          console.log(url);
        }
        this.enlecture.url = 'https://' + url;
      }
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
