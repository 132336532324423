<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <location-add :visible="isLocation" @mount="mountLocation" @close="locationClose" />
      <Preview :visible="is_preview" @close="vue_close" :banner_img="banner.img" :banner-type="banner.type"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">배너 수정</h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th><span class="red">*</span>&nbsp;배너종류</th>
                          <td class="text-left">
                            <label><input type="radio" v-model="banner.type" value="메인슬라이드배너" />메인 슬라이드 배너</label>
                            <label class="ml-1"><input type="radio" v-model="banner.type" value="중간배너" />중간 배너</label>
                            <label class="ml-1"><input type="radio" v-model="banner.type" value="찾아가는큐레이션" />찾아가는 큐레이션</label>
                            <label class="ml-1"><input type="radio" v-model="banner.type" value="로이터뉴스" />로이터뉴스</label>
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;배너순서</th>
                          <td class="text-left">
                            <input v-model.number="banner.ordernum" type="number" class="" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;타이틀</th>
                          <td>
                            <input v-model="banner.title" type="text" class="form-control" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;노출기간</th>
                          <td class="txt-left">
                            <input v-model="banner.sdate" type="date" placeholder="yyyy-mm-dd" /> ~
                            <input v-model="banner.edate" type="date" placeholder="yyyy-mm-dd" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span> 사용여부</th>
                          <td class="txt-left">
                            <label><input v-model="banner.isUse" type="radio" value="Y" />사용</label>
                            <label class="ml-1"><input v-model="banner.isUse" type="radio" value="N" />사용안함</label>
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;안드로이드 노출여부</th>
                          <td class="txt-left">
                            <label><input v-model="banner.isAndroid" type="radio" value="Y" />사용</label>
                            <label class="ml-1"><input v-model="banner.isAndroid" type="radio" value="N" />사용안함</label>
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;IOS 노출여부</th>
                          <td class="txt-left">
                            <label><input v-model="banner.isIos" type="radio" value="Y" />사용</label>
                            <label class="ml-1"><input v-model="banner.isIos" type="radio" value="N" />사용안함</label>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <span class="red">*</span>&nbsp;배너 이미지
                            <p class=" red">
                              jpg, png 등록가능 <br />
                              메인 슬라이드 배너(824*468) <br />
                              중간 배너(782*262)
                            </p>
                          </th>
                          <td>
                            <biz-image :url="imageUrl" :img="banner.img" :img-style="imageSize" @mountImage="banner.img = $event" />
                          </td>
                        </tr>
                        <tr v-if="banner.type === '메인슬라이드배너' || banner.type === '중간배너'">
                          <th>&nbsp;이동경로</th>
                          <td>
                            <div class="text-left">
                              <label><input @click="banner.link = ''" v-model="banner.linkType" type="radio" value="" />미사용</label>
                              <label class="ml-1"><input @click="banner.link = ''" v-model="banner.linkType" type="radio" value="O" />외부링크</label>
                              <label class="ml-1"><input @click="banner.link = ''" v-model="banner.linkType" type="radio" value="I" />내부링크</label>
                            </div>
                            <div>
                              <button @click="locationClose(true)" v-if="banner.linkType === 'I'" class="btn btn-dark w-100per">
                                {{ banner.link === "" ? "이동경로를 선택해주세요" : banner.link }}
                              </button>
                              <input v-if="banner.linkType !== 'I'" :disabled="banner.linkType === ''" v-model="banner.link" type="text" class="form-control" placeholder="http://" />
                            </div>
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goBanners()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-danger fl ml-1" @click="deleteBanner($route.params.idx)">
                      삭제
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-1" @click="putBanner($route.params.idx)">
                      수정
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-1" @click="showPreview()">
                      미리보기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../../components/commons/IncMenu.vue";
import Preview from "../../components/preview/pop_preview.vue";
import router from "@/router";
import LocationAdd from "../../components/pop/popLocationAdd";
import BizImage from "../../components/upload/uploadImage";

export default {
  name: "bannerModfiy",
  components: {
    Menu,
    Preview,
    LocationAdd,
    BizImage,
  },
  props: {
    msg: String,
  },
  data: () => ({
    /// loader
    loading: false,
    loader: {
      color: "#cc181e",
      color1: "#5bc0de",
      size: "5px",
      margin: "2px",
      radius: "2px",
    },

    banner: {
      idx: 0,
      title: "",
      type: "",
      img: "",
      link: "",
      linkType: "",
      ordernum: "",
      sdate: "",
      edate: "",
      isUse: "Y",
    },
    is_preview: false,
    isLocation: false,
    banner_img: "",
  }),
  mounted() {
    this.initPage();
  },
  computed: {
    imageUrl: function() {
      let url = this.$store.state.host + "/v1/admin/banners/image/";
      let extraUrl = this.banner.type === "메인슬라이드배너" || this.banner.type === "찾아가는큐레이션" || this.banner.type === "로이터뉴스" ? "upper" : "bottom";
      return url + extraUrl;
    },

    imageSize: function() {
      if (this.banner.type === "메인슬라이드배너" || this.banner.type === "찾아가는큐레이션" || this.banner.type === "로이터뉴스") {
        return "width:412px; height:234px";
      } else if (this.banner.type === "중간배너") {
        return "width:391px; height:131px;";
      } else {
        return "width:380px; height:195px;";
      }
    },
  },
  methods: {
    initPage: function() {
      this.axios.get(this.$store.state.host + "/v1/admin/banners/" + this.$route.params.idx).then((res) => {
        this.banner = res.data.result;
        this.banner_img = this.banner.img;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    chooseImage: function() {
      document.getElementById("upload").click();
    },

    showPreview: function() {
      this.is_preview = true;
    },

    goBanners: function() {
      this.$router.push("/Banner");
    },

    vue_close: function(state) {
      this.is_preview = state;
    },

    putBanner: function(idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;

      ///validation
      if (this.banner.type === "") {
        alert("배너 종류를 선택해주세요.");
      } else if (this.banner.ordernum === "") {
        alert("배너 순서를 입력해주세요.");
      } else if (this.banner.title === "") {
        alert("배너 타이틀을 입력해주세요.");
      } else if (this.banner.sdate === "") {
        alert("배너 시작 시간을 입력해주세요.");
      } else if (this.banner.edate === "") {
        alert("배너 종료 시간을 입력해주세요.");
      } else if (this.banner.img === "") {
        alert("이미지를 먼저 업로드 해주세요.");
      } else if (this.banner.type !== "찾아가는큐레이션" && this.banner.type !== "로이터뉴스" && this.banner.linkType !== "" && this.banner.link === "") {
        alert("이동경로를 입력해주세요.");
      } else {
        this.axios.put(url, this.banner).then(function(res) {
          const data = res.data;
          if (data.status === 0) {
            alert("배너 수정이 완료되었습니다.");
            router.push("/banner");
          } else {
            alert("배너 수정이 실패했습니다." + data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    deleteBanner: function(idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;
      if (confirm("배너를 삭제하시겠습니까?")) {
        this.axios.delete(url).then(function(res) {
          var data = res.data;
          if (data.status === 0) {
            alert("삭제가 완료되었습니다.");
            router.push("/banner");
          } else {
            alert("삭제에 실패했습니다." + data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    selectBannerImage: function() {
      this.loading = true;
      const file = this.$refs.bannerImage.files[0];
      this.banner_img = URL.createObjectURL(file);

      let formData = new FormData();
      let url = this.$store.state.host + "/v1/admin/banners/image/";
      let extraUrl = this.banner.type === "메인 슬라이드 배너" || this.banner.type === "찾아가는큐레이션" || this.banner.type === "로이터뉴스" ? "upper" : "bottom";
      formData.append("img", this.$refs.bannerImage.files[0]);

      this.axios
        .post(url + extraUrl, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then((res) => {
          if (res.data.status === 0) {
            this.banner.img = res.data.result;
            this.loading = false;
          } else {
            alert(res.data.result);
            this.loading = false;
          }
        })
        .catch((err) => {
          alert(`2MB 이상 이미지는 등록할 수 없습니다. err${err}`);
          this.loading = false;
        });
    },

    locationClose: function(state) {
      this.isLocation = state;
    },

    mountLocation: function(idx, subject) {
      if (subject == "reuter") {
        this.banner.link = "/" + subject + "?newsSeq=" + idx;
      } else if (subject == "dw") {
        this.banner.link = "/" + subject;
      } else {
        this.banner.link = "/" + subject + "?idx=" + idx;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
