<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">강의등록 상세페이지 </h3>
            </div>
          </div>
          <!-- 탭 전환 버튼 그룹 -->
          <div class="toggle-group mt-20">
            <button v-bind:class="tab_type==='multi-lecture'?'activeToggleBtn':'inactiveToggleBtn'" @click="changeTab('multi-lecture')">강의 일괄 등록</button>
            <button v-bind:class="tab_type==='lecture'?'activeToggleBtn':'inactiveToggleBtn'" @click="changeTab('lecture')">강의 개별 등록</button>
            <button v-bind:class="tab_type==='multi-quiz'?'activeToggleBtn':'inactiveToggleBtn'" @click="changeTab('multi-quiz')">퀴즈 일괄 등록</button>
            <button v-bind:class="tab_type==='multi-thumbnail'?'activeToggleBtn':'inactiveToggleBtn'" @click="changeTab('multi-thumbnail')">썸네일 일괄 등록</button>
          </div>
          <!-- 강의 개별등록 부분 시작 -->
          <div class="row" v-if="tab_type==='lecture' ">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;강의 타이틀</th>
                        <td>
                          <input v-model="lecture.title" type="text" class="form-control" placeholder="강의 타이틀을 입력하세요."/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;동영상URL</th>
                        <td class="text-left">
                          <input v-model="lecture.videoUrl" type="text" class="form-control" placeholder="동영상 URL을 입력하세요."/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 사용여부</th>
                        <td class="txt-left">
                          <label><input v-model="lecture.isUse" type="radio" value="Y"> 사용</label>
                          <label class="ml-1"><input v-model="lecture.isUse" type="radio" value="N"> 사용안함</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>썸네일<p class=" red">
                          jpg, png 등록가능 <br>
                          사이즈 (158*104)
                        </p>

                        </th>
                        <td>
                          <biz-image :url="imageUrl" :img="lecture.thumbnail" :img-style="imageSize" @mountImage="lecture.thumbnail = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red"></span> 퀴즈</th>
                        <td>
                          <span v-for="(item,idx) in lecture.quizList" :key="item.sort">
                            <div class="float-left w-95">
                            <span><p class="float-left w-5 mt-2">Q{{idx+1}}</p>
                              <textarea v-model="item.question" class="form-control w-95" placeholder="질문을 입력하세요."></textarea>
                            </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="1" /> </p>
                              <textarea v-model="item.example1" class="form-control w-95" placeholder="보기 1을 입력하세요."></textarea>
                            </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="2" /> </p>
                              <textarea v-model="item.example2" class="form-control w-95" placeholder="보기 2을 입력하세요."></textarea>
                            </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="3" /> </p>
                              <textarea v-model="item.example3"  class="form-control w-95" placeholder="보기 3을 입력하세요."></textarea>
                            </span><br>
                            <span><p class="float-left w-5 mt-2"> <input type="radio" v-model="item.rightAnswer" value="4" /> </p>
                              <textarea v-model="item.example4" class="form-control w-95" placeholder="보기 4을 입력하세요."></textarea>
                            </span><br>
                            <textarea v-model="item.commentary" class="form-control" style="height: 100px;" placeholder="퀴즈 해설을 입력하세요."></textarea>
                            </div>
                            <span class="float-right">
                              <button class="swal2-close" @click="delQuiz(idx)">X</button>
                            </span>
                              <br>
                          </span>
                          <button type="submit" class="btn btn-primary fr" @click="addQuiz()">
                            퀴즈 추가
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <th> Ai Speaking</th>
                        <td>
                          <input v-model="lecture.speakingText1" type="text" class="form-control" placeholder="음성과 매칭할 문구를 입력하세요."/><br>
                          <input v-model="lecture.speakingText2" type="text" class="form-control" placeholder="음성과 매칭할 문구를 입력하세요."/><br>
                          <input v-model="lecture.speakingText3" type="text" class="form-control" placeholder="음성과 매칭할 문구를 입력하세요."/>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goAiLectures()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="postAiLectures()">
                      등록
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 강의 개별등록 부분 끝 -->

          <!-- 강의 일괄등록 부분 시작 -->
          <div class="row" v-if="tab_type==='multi-lecture'">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <td><span class="red">*</span>&nbsp;강의 일괄등록</td>
                        <td>
                          <input id="lectureUpload" type="file" ref="files_lecture" class="form-control-file w-60 fl" >
                          <a class="cursor-pointer fr ml-10 "  @click="sampleDownload('lecture')" style="text-decoration: underline">강의 일괄등록 엑셀 양식</a>
                        </td>
                      </tr>
                      <tr v-if="lecture_excel.result!=''">
                        <td colspan="2">
                          <div v-html="lecture_excel.result" ></div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goAiLectures()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="lectureUpload()">
                      등록
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 강의 일괄등록 부분 끝 -->

          <!-- 강의퀴즈 일괄등록 부분 시작 -->
          <div class="row" v-if="tab_type==='multi-quiz'">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <td><span class="red">*</span>&nbsp;퀴즈 일괄등록</td>

                        <td>
                          <input id="quizUpload" type="file" ref="files_quiz" class="form-control-file w-60 fl" >
                          <a class="cursor-pointer fr ml-10 "  @click="sampleDownload('quiz')" style="text-decoration: underline">퀴즈 일괄 등록 엑셀 양식</a>
                        </td>
                      </tr>
                      <tr v-if="lecture_excel.result!=''">
                        <td colspan="2">
                          <div v-html="lecture_excel.result" ></div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goAiLectures()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="quizUpload()">
                      등록
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 강의퀴즈 일괄등록 부분 끝 -->

          <!-- 강의퀴즈 일괄등록 부분 시작 -->
          <div class="row" v-if="tab_type==='multi-thumbnail'">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <td><span class="red">*</span>&nbsp;썸네일 일괄등록</td>

                        <td>
                          <input id="thumbnailUpload" type="file" ref="files_quiz" class="form-control-file w-60 fl" >
                          <a class="cursor-pointer fr ml-10 "  @click="sampleDownload('thumbnail')" style="text-decoration: underline">썸네일 일괄 등록 엑셀 양식</a>
                        </td>
                      </tr>
                      <tr v-if="lecture_excel.result!=''">
                        <td colspan="2">
                          <div v-html="lecture_excel.result" ></div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goAiLectures()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="thumbnailUpload()">
                      등록
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 강의퀴즈 일괄등록 부분 끝 -->
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.toggle-group {
  display: flex;

  .activeToggleBtn, .inactiveToggleBtn{
    padding: 5px 30px;
    width:150px;
    border:none;
    border-radius:10px 10px 0 0;
    transition:background,color 0.3s linear;
  }

  .activeToggleBtn{
    background:#e9e7e7cc;
  }
  .inactiveToggleBtn{
    background:white;
    color:#C9C8C8;
  }
}
.excel-button{
  text-decoration: underline;
}
</style>
<script>
import Menu from '../../components/commons/IncMenu.vue'
import BizImage from "../../components/upload/uploadImage";

export default {
  name: 'aiLectureAdd',
  components: {
    Menu,
    BizImage
  },
  props: {
    msg: String
  },
  data: () => ({
    fileList: [],


    validation: {
      ordernum: false,
    },

    // loader
    loading: false,
    loader: {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '5px',
      margin: '2px',
      radius: '2px'
    },
    tab_type:"multi-lecture",
    // lecture 개별
    lecture: {
        title: ''
      , videoUrl: ''
      , isUse: 'Y'
      , speakingText1: ''
      , speakingText2: ''
      , speakingText3: ''
      , thumbnail: ''
      , quizList:[{
          "commentary": "",
          "example1": "",
          "example2": "",
          "example3": "",
          "example4": "",
          "question": "",
          "rightAnswer": 1,
          "sort": 1
        }]
    },
    // lecture 일괄
    lecture_excel:{
      result:'',
    }
  }),
  computed: {
    imageUrl: function () {
      let url = this.$store.state.host + "/v1/aitutor/lecture/image";
      return url;
    },
    imageSize: function () {
      return 'width:158px; height:104px;'
    }
  },

  mounted() {

  },
  methods: {
    changeTab: function (tab_type){
      this.tab_type=tab_type;
    },
    postAiLectures: function () {
      ///validation
      const url = this.$store.state.host + "/v1/aitutor/lecture";
      if (this.lecture.title === "") {
        alert('강의 타이틀을 입력해주세요.')
      } else if (this.lecture.videoUrl === "") {
        alert('강의 동영상 URL을 입력해주세요.')
      } else if (this.lecture.isUse === "") {
        alert('사용여부를 선택해주세요.')
      } else {
        this.axios.post(url, this.lecture)
        .then((res) => {
          const data = res.data;
          if (data.status === 0) {
            alert("강의 등록이 완료되었습니다.")
            this.goAiLectures();
          } else {
            alert("강의 등록에 실패하였습니다.<br>다시 확인 후, 시도해주세요.")
            //alert(data.result)
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    goAiLectures: function () {
      this.$router.push("/AiLecture")
    },
    locationClose: function (state) {
      this.isLocation = state;
    },
    addQuiz: function () {
      //this.lecture.quizList[this.lecture.quizList.length]=this.lecture.quizList[this.lecture.quizList.length-1];
      this.lecture.quizList.push(
          {
            "commentary": "",
            "example1": "",
            "example2": "",
            "example3": "",
            "example4": "",
            "question": "",
            "rightAnswer": 1,
            "sort": this.lecture.quizList.length+1
          }
      );


      console.log(this.lecture.quizList);
    },

    delQuiz: function(idx){
      this.lecture.quizList.splice(idx, 1);
      var temp=[];
      temp= this.lecture.quizList;
      for(let i=0; i< temp.length; i++){
        temp.sort=i+1;
      }
      this.lecture.quizList= temp;

      console.log(this.lecture.quizList);
      this.$forceUpdate();// 재렌더링 오류방지
    },

    lectureUpload: function () {
      const files = this.$refs.files_lecture.files;
      const url = this.$store.state.host + "/v1/aitutor/lecture/excel-lecture";

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let formData = new FormData();
        formData.append('file', file);

        this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
              if (res.data.status === 0) {
                ///빈곳에 넣기
                alert('등록 되었습니다. ');
                this.lecture_excel.result=res.data.result;
              } else {
                this.lecture_excel.result=res.data.result;
                alert('등록에 실패하였습니다. ');
              }
            })
            .catch((err) => {
              alert(`network error = ${err}`);
              //alert('2MB 이상는 등록할 수 없습니다. ' + file.name);
            })
      }
      document.getElementById("lectureUpload").value = null;
    },
    quizUpload: function () {
      const files = this.$refs.files_quiz.files;
      const url = this.$store.state.host + "/v1/aitutor/lecture/excel-quiz";

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let formData = new FormData();
        formData.append('file', file);

        this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
              if (res.data.status === 0) {
                ///빈곳에 넣기
                alert('등록 되었습니다. ' + file.name);
                this.lecture_excel.result=res.data.result;
              } else {
                this.lecture_excel.result=res.data.result;
                alert('등록에 실패하였습니다. ');
              }
            })
            .catch((err) => {
              alert(`network error = ${err}`);
              //alert('2MB 이상는 등록할 수 없습니다. ' + file.name);
            })
      }
      document.getElementById("quizUpload").value = null;
    },

    thumbnailUpload: function () {
      const files = this.$refs.files_quiz.files;
      const url = this.$store.state.host + "/v1/aitutor/lecture/excel-thumbnail";

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let formData = new FormData();
        formData.append('file', file);

        this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            .then((res) => {
              if (res.data.status === 0) {
                ///빈곳에 넣기
                alert('등록 되었습니다. ' + file.name);
                this.lecture_excel.result=res.data.result;
              } else {
                this.lecture_excel.result=res.data.result;
                alert('등록에 실패하였습니다. ');
              }
            })
            .catch((err) => {
              alert(`network error = ${err}`);
              //alert('2MB 이상는 등록할 수 없습니다. ' + file.name);
            })
      }
      document.getElementById("thumbnailUpload").value = null;
    },
    //양식 다운로드
    sampleDownload(type) {
      if(type=='lecture'){
        location.href=this.$store.state.host +"/v1/aitutor/lecture/excel-lecture-sample";
      }
      else if (type=='quiz'){
        location.href=this.$store.state.host +"/v1/aitutor/lecture/excel-quiz-sample";
      }
      else if (type=='thumbnail'){
        location.href=this.$store.state.host +"/v1/aitutor/lecture/excel-thumbnail-sample";
      }
    },


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
