<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">상품 수정</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>상품 명</th>
                        <td class="text-left">{{ product.name }}</td>
                      </tr>
                      <tr>
                        <th>사용여부</th>
                        <td class="text-left">
                          <label><input type="radio" v-model="product.isUse" value="Y">사용</label>
                          <label class="ml-1"><input type="radio" v-model="product.isUse" value="N">사용안함</label>
                          <!--<button @click="product.isUse = 'Y'" class="btn w-30 btn-dark "
                                  :class="{ 'disabled' : product.isUse === 'N'}"> 사용
                          </button>
                          <button @click="product.isUse = 'N'" class="btn w-30 btn-dark ml-1"
                                  :class="{ 'disabled' : product.isUse === 'Y'}"> 사용안함
                          </button>-->
                        </td>
                      </tr>
                      <tr>
                        <th>유/무료</th>
                        <td class="text-left">
                          <label><input type="radio" v-model="product.isFree" value="N">유료</label>
                          <label class="ml-1"><input type="radio" v-model="product.isFree" value="Y">무료</label>
                          <!--<button @click="product.isFree = 'N'" class="btn w-30 btn-dark "
                                  :class="{ 'disabled' : product.isFree === 'Y'}"> 유료
                          </button>
                          <button @click="product.isFree = 'Y'" class="btn w-30 btn-dark ml-1"
                                  :class="{ 'disabled' : product.isFree === 'N'}"> 무료
                          </button>-->
                        </td>
                      </tr>
                      <tr v-if="product.isFree !== 'Y'">
                        <th>구독료(1년)</th>
                        <td class="text-left">
                          <div>
                            <input v-model="product.price" type="number" class="text-right"/> 원
                          </div>
                        </td>
                      </tr>
                      <!--<tr>
                        <th>할인율(%)</th>
                        <td class="text-left">
                          <input v-model="product.discount" type="number" class="form-control w-100per" />&nbsp;
                        </td>
                      </tr>-->
                      <tr>
                        <th>상품설명</th>
                        <td class="text-left">
                          <textarea v-model="product.note" class="form-control"></textarea>
                        </td>
                      </tr>
                    </table>

                    <div class="clear mt-20">
                      <button @click="$router.push('/product')" type="submit" class="btn btn-primary fl">목록</button>
                      <button @click="putProduct()" type="submit" class="btn btn-primary fr ml-10">저장</button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import router from "@/router";

export default {
  name: 'ProductModify',
  components: {
    Menu,
  },
  props: {
    msg: String
  },
  data: () => ({
    product: {

    },
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    initPage: function () {
      this.axios.get(this.$store.state.host + "/v1/admin/merchants/" + this.$route.params.idx)
      .then((res) => {
        console.log(res)
        this.product = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    putProduct: function () {
      const url = this.$store.state.host + "/v1/admin/merchants/" + this.$route.params.idx;
      if(!(this.product.discount >= 0 && this.product.discount <= 100)){
        alert("할인율은 0에서 100사이로 입력바랍니다.")
      }else{
        this.axios.put(url, this.product)
        .then((res) => {
          let data = res.data;
          if(data.status === 0){
            alert('상품정보 수정이 완료되었습니다.')
            router.push('/product')
          }else{
            alert(data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
