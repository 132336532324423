<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <PopAddressSearch :visible="isAddress" :address="program.location" @onSearch="addressSearch"
                        @close="addressClose"/>
      <CategorySearch :visible="isCategory" @close="categoryClose"
                      @pick="selectCategory"></CategorySearch>
      <Preview :visible="is_preview" @close="vue_close" :program="program"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">교육 프로그램 수정</h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;카테고리</th>
                        <td>
                          <select v-model="program.idxCategory" class="form-control w-20per">
                            <option value="">카테고리 선택</option>
                            <option v-for="item in categories" :key="item.idx" :value="item.idx">
                              {{ item.name }}
                            </option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>노출순서</th>
                        <td>
                          <input v-model.number="program.ordernum" type="number"
                                 class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;프로그램 명</th>
                        <td>
                          <input v-model="program.programName" type="text"
                                 class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 공유기능사용</th>
                        <td class="txt-left">
                          <span>
                            <label class="mt-1"><input type="radio" value="Y"  v-model="program.shareYN"/>사용</label>
                            <label><input type="radio" v-model="program.shareYN" value="N"/>사용 안함</label>
                          </span>
                        </td>
                      </tr>
                      <tr v-if="program.shareYN==='Y'">
                        <th><span class="red">*</span> 공유 제목</th>
                        <td class="txt-left">
                          <input type="text" v-model="program.shareTitle" class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr v-if="program.shareYN==='Y'">
                        <th><span class="red">*</span> 공유 내용</th>
                        <td class="txt-left">
                          <input type="text" v-model="program.shareContents"
                                 class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr v-if="program.shareYN==='Y'">
                        <th><span class="red">*</span> 공유 링크</th>
                        <td class="txt-left">
                          <div class="ml-3">
                            <span>ios</span>
                            <input type="text" class="ios" v-model="program.shareLinkIOS"/>
                          </div>
                          <div class="ml-3 mt-3">
                            <span>안드로이드</span>
                            <input type="text" class="android" v-model="program.shareLinkAndroid"/>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;사용여부</th>
                        <td class="txt-left">
                          <label><input v-model="program.isUse" type="radio" value="Y">사용</label>
                          <label class="mt-1"><input v-model="program.isUse" type="radio" value="N">사용안함</label>
                          <!--<button @click="program.isUse = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : program.isUse === 'N'}"> 사용</button>
                          <button @click="program.isUse = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : program.isUse === 'Y'}"> 사용안함</button>-->
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;안드로이드 노출 여부</th>
                        <td class="txt-left">
                          <label><input v-model="program.isAndroid" type="radio" value="Y">노출</label>
                          <label class="mt-1"><input v-model="program.isAndroid" type="radio" value="N">노출안함</label>
                          <!--<button @click="program.isUse = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : program.isUse === 'N'}"> 사용</button>
                          <button @click="program.isUse = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : program.isUse === 'Y'}"> 사용안함</button>-->
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;IOS 노출 여부</th>
                        <td class="txt-left">
                          <label><input v-model="program.isIos" type="radio" value="Y">노출</label>
                          <label class="mt-1"><input v-model="program.isIos" type="radio" value="N">노출안함</label>
                          <!--<button @click="program.isUse = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : program.isUse === 'N'}"> 사용</button>
                          <button @click="program.isUse = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : program.isUse === 'Y'}"> 사용안함</button>-->
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="red">*</span> 썸네일
                          <p class="red">* jpg, png 등록 가능 <br>권장사이즈198*220 </p>
                        </th>
                        <td>
                          <upload-image :img-style="'width:198px; height:220px;'"
                                        :url="imageUrl + 'thum'" :img="program.thum"
                                        @mountImage="program.thum = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="red">*</span>&nbsp;상단이미지
                          <p class="red">* jpg, png 등록 가능 <br>권장사이즈412*245 </p>
                        </th>
                        <td>
                          <upload-image :img-style="'width:412px; height:245px;'"
                                        :url="imageUrl + 'upperImage'" :img="program.upperImage"
                                        @mountImage="program.upperImage = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;신청기간</th>
                        <td class="text-left">
                          <span>
                          <label><input type="radio" value="N" v-model="program.isTime">상시모집</label>
                          <label  class="ml-1"><input type="radio" value="Y" v-model="program.isTime">사용</label>
                          </span>
                          <span class="pl-1" v-if="program.isTime == 'Y'">
                          <input type="date" v-model="program.applySdate"/>
                          ~ <input type="date" v-model="program.applyEdate"/>
                          </span>
                          <!--<input type="radio" value="N" v-model="program.isTime">-->
                          <!--<button @click="program.isTime = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : program.isTime === 'N'}"> 기간제한</button>
                          <button @click="program.isTime = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : program.isTime === 'Y'}"> 제한없음</button>-->
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;교육기간</th>
                        <td class="text-left">
                          <span>
                            <label><input type="radio" value="N" name="isEdudate" v-model="program.isEdudate"/>사용 안함</label>
                            <label class="ml-1"><input type="radio" value="Y" name="isEdudate" v-model="program.isEdudate"/>사용</label>
                          </span>
                          <span class="pl-1" v-if="program.isEdudate == 'Y'">
                            <input type="date" v-model="program.sdate"/>
                            ~ <input type="date" v-model="program.edate"/>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;수강기간</th>
                        <td class="text-left">
                          <span>
                            <label><input type="radio" value="N" name="isStudydate" v-model="program.isStudydate"/>사용 안함</label>
                            <label class="ml-1"><input type="radio" value="Y" name="isStudydate" v-model="program.isStudydate"/>사용</label>
                          </span>
                          <span class="pl-1" v-if="program.isStudydate == 'Y'">
                            <input type="number" class="w-10" v-model="program.studydays"/> 일
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;교육장소</th>
                        <td class="text-left clear">
                          <div>
                            <label><input type="radio" name="isLocation" v-model="program.isLocation" value="N">사용 안함</label>
                            <label class="ml-10"><input type="radio" name="isLocation" v-model="program.isLocation" value="Y">사용</label>
                          </div>
                          <label class="w-100per">
                            <button class="btn btn-dark fl ml-1" @click="isAddress = true">주소검색
                            </button>
                            <input type="text" v-model="program.location"
                                   class="form-control w-90per fl">
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;모집인원</th>
                        <td class="text-left clear">
                          <label><input type="radio" v-model='program.isLimit' @change="changeLimit()"
                                        value="N">제한없음</label>
                          <span class="pl-1">
                            <label><input type="radio" v-model='program.isLimit' @change="changeLimit()"
                                          value="Y">선착순</label> 
                            <input :disabled="program.isLimit === 'N'" v-model="program.maxCount"
                                   type="number" class="w-10"/> 명
                            <label v-if="program.isLimit == 'Y'"><input type="checkbox"  v-model="program.isApplyCountView" true-value="Y" false-value="N"/> 신청 인원 노출</label>
                          </span>
                          <!--<button @click="program.isLimit = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : program.isLimit === 'N'}"> 인원제한</button>
                          <button @click="program.isLimit = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : program.isLimit === 'Y'}"> 제한없음</button>-->
                        </td>
                      </tr>
                      <tr>
                        <th>내용</th>
                        <td class="text-left clear">
                          <ckeditor :editor="editor" v-model="program.content"
                                    :config="editorConfig"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;유료여부</th>
                        <td class="txt-left">
                          <label><input type="radio" v-model="program.isFree" value="X">사용안함</label>
                          <label><input type="radio" v-model="program.isFree" value="Y">무료</label>
                          <label class="ml-1"><input type="radio" v-model="program.isFree" value="N">유료</label>
                          <span v-if="program.isFree === 'N'">
                            (
                            <label class="ml"><input type="radio" v-model="program.isOnline" value="N">오프라인 과정 결제(PG 결제)</label>
                            <label class="ml-1"><input type="radio" v-model="program.isOnline" value="Y">온라인 컨텐츠/패키지 결제(인앱 결제)</label>
                            )
                          </span>
                          <div v-if="program.isFree === 'N' && program.isOnline === 'N'">
                            <div v-if="policyLimit >= 1">
                              <label class="mr-1"><input type="radio" name="mainprice1" v-model="program.detail.mainprice1" value="Y" @change="changeMainPrice('mainprice1')"/></label>
                              <input v-model="program.detail.policy1" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price1" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 2">
                              <label class="mr-1"><input type="radio" name="mainprice2" v-model="program.detail.mainprice2" value="Y" @change="changeMainPrice('mainprice2')"/></label>
                              <input v-model="program.detail.policy2" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price2" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 3">
                              <label class="mr-1"><input type="radio" name="mainprice3" v-model="program.detail.mainprice3" value="Y" @change="changeMainPrice('mainprice3')"/></label>
                              <input v-model="program.detail.policy3" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price3" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 4">
                              <label class="mr-1"><input type="radio" name="mainprice4" v-model="program.detail.mainprice4" value="Y" @change="changeMainPrice('mainprice4')"/></label>
                              <input v-model="program.detail.policy4" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price4" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 5">
                              <label class="mr-1"><input type="radio" name="mainprice5" v-model="program.detail.mainprice5" value="Y" @change="changeMainPrice('mainprice5')"/></label>
                              <input v-model="program.detail.policy5" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price5" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 6">
                              <label class="mr-1"><input type="radio" name="mainprice6" v-model="program.detail.mainprice6" value="Y" @change="changeMainPrice('mainprice6')"/></label>
                              <input v-model="program.detail.policy6" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price6" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 7">
                              <label class="mr-1"><input type="radio" name="mainprice7" v-model="program.detail.mainprice7" value="Y" @change="changeMainPrice('mainprice7')"/></label>
                              <input v-model="program.detail.policy7" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price7" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 8">
                              <label class="mr-1"><input type="radio" name="mainprice8" v-model="program.detail.mainprice8" value="Y" @change="changeMainPrice('mainprice8')"/></label>
                              <input v-model="program.detail.policy8" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price8" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 9">
                              <label class="mr-1"><input type="radio" name="mainprice9" v-model="program.detail.mainprice9" value="Y" @change="changeMainPrice('mainprice9')"/></label>
                              <input v-model="program.detail.policy9" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price9" type="number" placeholder="금액 입력"
                                     class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>&nbsp;
                            </div>
                            <div class="mt-10" v-if="policyLimit >= 10">
                              <label class="mr-1"><input type="radio" name="mainprice10" v-model="program.detail.mainprice10" value="Y" @change="changeMainPrice('mainprice10')"/></label>
                              <input v-model="program.detail.policy10" type="text"
                                     class="" placeholder="상품명 입력"/>
                              <input v-model="program.detail.price10" type="number"
                                     placeholder="금액 입력" class="ml-1 text-right"/> 원
                              <label class="ds-inline"></label>
                            </div>
                            <button @click="addPolicy()" class="btn btn-dark mt-10">+ 상품 추가</button>
                            <button @click="removePolicy()" class="btn btn-dark mt-10 ml-1">- 상품 삭제</button>
                          </div>
                          <div v-if="program.isFree === 'N' && program.isOnline === 'Y'">
                            <select v-model="program.onlinePrice">
                              <option value="0">금액 선택</option>
                              <option value="4900">4900원</option>
                              <option value="9900">9900원</option>
                              <option value="15000">15000원</option>
                              <option value="20000">20000원</option>
                              <option value="25000">25000원</option>
                              <option value="30000">30000원</option>
                              <option value="40000">40000원</option>
                              <option value="45000">45000원</option>
                              <option value="50000">50000원</option>
                              <option value="55000">55000원</option>
                              <option value="60000">60000원</option>
                              <option value="65000">65000원</option>
                              <option value="69000">69000원</option>
                              <option value="75000">75000원</option>
                              <option value="79000">79000원</option>
                              <option value="89000">89000원</option>
                              <option value="95000">95000원</option>
                              <option value="99000">99000원</option>
                              <option value="105000">105000원</option>
                              <option value="109000">109000원</option>
                              <option value="129000">129000원</option>
                              <option value="139000">139000원</option>
                              <option value="149000">149000원</option>
                              <option value="169000">169000원</option>
                              <option value="179000">179000원</option>
                              <option value="199000">199000원</option>
                              <option value="209000">209000원</option>
                              <option value="229000">229000원</option>
                              <option value="239000">239000원</option>
                              <option value="249000">249000원</option>
                              <option value="259000">259000원</option>
                              <option value="269000">269000원</option>
                              <option value="279000">279000원</option>
                              <option value="299900">299900원</option>
                            </select>
                            <p class="red">
                              *ios는 ios 정책에 따라 119,000원을 넘는 인앱결제 상품은 등록할 수 없으니 유의바랍니다.
                            </p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>신청안내</th>
                        <td class="txt-left">
                          <!-- <ckeditor :editor="editor" v-model="program.content"
                                    :config="editorConfig"/> -->
                            <textarea class="w-100" style="height:150px;" v-model="program.applyinfo"></textarea>
                        </td>
                      </tr>
                      <tr>
                        <th>입력정보</th>
                        <td class="txt-left">
                          <div v-if="questionLimit >= 1">
                            <input v-model="program.detail.question1" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder1" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer1" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 2">
                            <input v-model="program.detail.question2" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder2" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer2" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 3">
                            <input v-model="program.detail.question3" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder3" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer3" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 4">
                            <input v-model="program.detail.question4" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder4" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer4" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 5">
                            <input v-model="program.detail.question5" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder5" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer5" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 6">
                            <input v-model="program.detail.question6" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder6" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer6" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 7">
                            <input v-model="program.detail.question7" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder7" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer7" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 8">
                            <input v-model="program.detail.question8" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder8" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer8" type="checkbox" true-value="Y" false-value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 9">
                            <input v-model="program.detail.question9" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder9" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer9" type="checkbox" true-value="Y" false-value="N" value="N">
                          </div>
                          <div class="mt-10" v-if="questionLimit >= 10">
                            <input v-model="program.detail.question10" type="text"
                                   placeholder="옵션 입력 "
                                   class=""/>
                            <input v-model="program.detail.placeholder10" type="text"
                                   placeholder="안내문구 입력"
                                   class="ml-1 w-30per"/>
                            필수 <input v-model="program.detail.needanswer10" type="checkbox" true-value="Y" false-value="N" value="N">
                          </div>

                          <button @click="addQuestion()" class="btn btn-dark mt-10">+ 옵션 추가</button>
                          <button @click="removeQuestion()" class="btn btn-dark mt-10 ml-1">- 옵션 삭제</button>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button @click="$router.push('/program')" type="submit"
                            class="btn btn-primary fl">목록
                    </button>
                    <button @click="deleteProgram()" type="submit" class="btn btn-danger fl ml-10">
                      삭제
                    </button>
                    <button @click="putProgram()" type="submit" class="btn btn-primary fr ml-10">
                      저장
                    </button>
                    <button type="submit" class="btn btn-primary fr" @click="showPreview()">미리보기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Preview from "../../components/preview/pop_preview_program.vue";
import CategorySearch from '@/components/pop/popCategorySearch';
import PopAddressSearch from '../../components/pop/popAddressSearch';
import UploadImage from '../../components/upload/uploadImage';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import router from "@/router";

export default {
  name: 'ProgramModify',
  components: {
    Menu,
    Preview,
    CategorySearch,
    PopAddressSearch,
    UploadImage
  },
  props: {
    msg: String
  },

  data: () => ({
    categories: [],

    ///component
    is_preview: false,
    isCategory: false,
    isAddress: false,
    isThum: false,
    isUpper: false,

    ///limit
    questionLimit: 1,
    policyLimit: 1,

    ///editor
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      // The configuration of the editor.
      filebrowserUploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor',
      ckfinder: {
        uploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor'
      },
      allowedContent: true,
      height : 600
    },

    ///program
    category: {},
    program: {
      idx: 0,
      idxCategory: '',
      categoryName: "",
      shareYN:'',
      shareTitle: '',
      shareContents: '',
      shareLinkAndroid: '',
      shareLinkIOS:'',
      ordernum: 1,
      currentCount: 0,
      maxCount: 0,
      programName: "",
      content: "",
      thum: "",
      upperImage: "",
      location: "",
      week: "",
      isUse: "Y",
      isTime: "Y",
      isFree: "N",
      isLimit: "Y",

      isEdudate:"N",
      isStudydate:"N",
      studydays:0,
      applyinfo:"",

      applySdate: "",
      applyEdate: "",
      sdate: "",
      edate: "",
      createDate: "",
      updateDate: "",
      isApplyCountView:"",
      isLocation:"",
      detail: {
        policy1: null,
        policy2: null,
        policy3: null,
        policy4: null,
        policy5: null,
        policy6: null,
        policy7: null,
        policy8: null,
        policy9: null,
        policy10: null,
        price1: null,
        price2: null,
        price3: null,
        price4: null,
        price5: null,
        price6: null,
        price7: null,
        price8: null,
        price9: null,
        price10: null,
        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: null,
        question8: null,
        question9: null,
        question10: null,
        placeholder1: null,
        placeholder2: null,
        placeholder3: null,
        placeholder4: null,
        placeholder5: null,
        placeholder6: null,
        placeholder7: null,
        placeholder8: null,
        placeholder9: null,
        placeholder10: null,
        mainprice1:"Y",
        mainprice2:"N",
        mainprice3:"N",
        mainprice4:"N",
        mainprice5:"N",
        mainprice6:"N",
        mainprice7:"N",
        mainprice8:"N",
        mainprice9:"N",
        mainprice10:"N",
      },
      category: {},
      isOnline: 'N',
      onlinePrice: 0,
    },

    ///search
    filter: {
      page: 1,
      status: '',
      isUse: 'Y',
    },

  }),
  computed: {
    needAnswer: function (value){
      return (value === 'Y') ? 'Y' : 'N';
    },

    imageUrl: function () {
      return this.$store.state.host + "/v1/admin/programs/image/";
    },
  },
  mounted() {
    this.initPage();
  },
  methods: {
    changeLimit:function(){
      if(this.program.isLimit == 'N'){
        this.program.maxCount = 0;
      }else{
        this.program.isApplyCountView = 'N';
      }
    },
    changeMainPrice:function(name){
      // console.log(idx)
      // console.log(detail)
      this.program.detail.mainprice1 = 'N';
      this.program.detail.mainprice2 = 'N';
      this.program.detail.mainprice3 = 'N';
      this.program.detail.mainprice4 = 'N';
      this.program.detail.mainprice5 = 'N';
      this.program.detail.mainprice6 = 'N';
      this.program.detail.mainprice7 = 'N';
      this.program.detail.mainprice8 = 'N';
      this.program.detail.mainprice9 = 'N';
      this.program.detail.mainprice10 = 'N';

      this.program.detail[name] = 'Y';
    },
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },
    initPage: function () {
      this.getProgram();
      this.getCategories();
    },

    ///policy&question
    addPolicy: function () {
      if (this.policyLimit <= 10) {
        this.policyLimit++;
      } else {
        alert("추가 가능한 가격정보를 초과했습니다.")
      }
    },
    removePolicy: function () {
      if (this.policyLimit > 1) {
        this.program.detail['price' + this.policyLimit] = null;
        this.program.detail['policy' + this.policyLimit] = null;
        this.policyLimit--;
      } else {
        alert("더이상 삭제할 가격정보가 없습니다.")
      }
    },
    addQuestion: function () {
      if (this.questionLimit <= 10) {
        this.questionLimit++;
      } else {
        alert("추가 가능한 입력정보를 초과했습니다.")
      }
    },

    ///address
    addressSearch: function (result) {
      this.program.location = result.address;
    },
    addressClose: function () {
      this.isAddress = false;
    },

    removeQuestion: function () {
      if (this.questionLimit > 1) {
        this.program.detail['placeholder' + this.questionLimit] = null;
        this.program.detail['question' + this.questionLimit] = null;
        this.program.detail['needanswer' + this.questionLimit] = 'N';
        this.questionLimit--;
      } else {
        alert("더이상 삭제할 입력정보가 없습니다.")
      }
    },

    getProgram: function () {
      const url = this.$store.state.host + "/v1/admin/programs/" + this.$route.params.idx;
      this.axios.get(url)
      .then((res) => {
        var data = res.data;
        if (data.status === 0) {
          this.program = res.data.result;
          if (this.program.detail == null) {
            this.program.detail = {
              policy1: null,
              policy2: null,
              policy3: null,
              policy4: null,
              policy5: null,
              policy6: null,
              policy7: null,
              policy8: null,
              policy9: null,
              policy10: null,
              price1: null,
              price2: null,
              price3: null,
              price4: null,
              price5: null,
              price6: null,
              price7: null,
              price8: null,
              price9: null,
              price10: null,
              question1: null,
              question2: null,
              question3: null,
              question4: null,
              question5: null,
              question6: null,
              question7: null,
              question8: null,
              question9: null,
              question10: null,
              placeholder1: null,
              placeholder2: null,
              placeholder3: null,
              placeholder4: null,
              placeholder5: null,
              placeholder6: null,
              placeholder7: null,
              placeholder8: null,
              placeholder9: null,
              placeholder10: null,
              mainprice1:"Y",
              mainprice2:"N",
              mainprice3:"N",
              mainprice4:"N",
              mainprice5:"N",
              mainprice6:"N",
              mainprice7:"N",
              mainprice8:"N",
              mainprice9:"N",
              mainprice10:"N",
            };
          }
          /// 프로그램 세부사항 처리
          if (this.program.detail.question1 != null) {
            this.questionLimit = 1;
          }
          if (this.program.detail.question2 != null) {
            this.questionLimit = 2;
          }
          if (this.program.detail.question3 != null) {
            this.questionLimit = 3;
          }
          if (this.program.detail.question4 != null) {
            this.questionLimit = 4;
          }
          if (this.program.detail.question5 != null) {
            this.questionLimit = 5;
          }
          if (this.program.detail.question6 != null) {
            this.questionLimit = 6;
          }
          if (this.program.detail.question7 != null) {
            this.questionLimit = 7;
          }
          if (this.program.detail.question8 != null) {
            this.questionLimit = 8;
          }
          if (this.program.detail.question9 != null) {
            this.questionLimit = 9;
          }
          if (this.program.detail.question10 != null) {
            this.questionLimit = 10;
          }

          if (this.program.detail.policy1 != null) {
            this.policyLimit = 1;
          }
          if (this.program.detail.policy2 != null) {
            this.policyLimit = 2;
          }
          if (this.program.detail.policy3 != null) {
            this.policyLimit = 3;
          }
          if (this.program.detail.policy4 != null) {
            this.policyLimit = 4;
          }
          if (this.program.detail.policy5 != null) {
            this.policyLimit = 5;
          }
          if (this.program.detail.policy6 != null) {
            this.policyLimit = 6;
          }
          if (this.program.detail.policy7 != null) {
            this.policyLimit = 7;
          }
          if (this.program.detail.policy8 != null) {
            this.policyLimit = 8;
          }
          if (this.program.detail.policy9 != null) {
            this.policyLimit = 9;
          }
          if (this.program.detail.policy10 != null) {
            this.policyLimit = 10;
          }

        } else {
          alert(data.result);
          router.push('/program');
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    putProgram: function () {
      const url = this.$store.state.host + "/v1/admin/programs/" + this.$route.params.idx;
      ///validation
      if (this.program.idxCategory === "") {
        alert("프로그램 카테고리를 선택 해주세요.")
      } else if (this.program.ordernum === "") {
        alert("프로그램 노출순서를 입력 해주세요.")
      } else if (this.program.programName === "") {
        alert("프로그램 제목을 입력 해주세요.")
      }else if(this.program.shareYN === 'Y' && this.program.shareTitle.trim() === ""){
         alert("공유 제목을 입력해주세요.");
      }else if(this.program.shareYN === 'Y' && this.program.shareContents.trim() === ""){
         alert("공유 내용을 입력해주세요.");
      }else if(this.program.shareYN === 'Y' && this.program.shareLinkIOS.trim() === ""){
         alert("공유 링크 ios를 입력해주세요.");
      }else if(this.program.shareYN === 'Y' && this.program.shareLinkAndroid.trim() === ""){
        alert("공유 링크 안드로이드를 입력해주세요.");
      }else if (this.program.thum === "") {
        alert("프로그램 썸네일을 업로드 해주세요.")
      } else if (this.program.upperImage === "") {
        alert("프로그램 상단이미지를 업로드 해주세요.")
      } else if (this.program.isTime === "Y" && (this.program.applySdate === null
          || this.program.applyEdate === null)) {
        alert("프로그램 신청기간를 입력해주세요.")
      } else if (this.program.isEdudate === 'Y' && (this.program.sdate === null || this.program.edate === null)) {
        alert("프로그램 교육기간를 입력해주세요.")
      } else if(this.program.isStudydate === 'Y' && (this.program.studydays <= 0)){
        alert("프로그램 수강 기간을 입력해주세요.");
      }else if (this.program.isLimit === "Y" && this.program.maxCount <= 0) {
        alert("프로그램 모집인원을 입력해주세요.")
      } else {
        console.log(this.program)
        this.axios.put(url, this.program)
        .then((res) => {
          if (res.data.status === 0) {
            alert("프로그램 수정이 완료되었습니다.")
            this.$router.push('/program')
          } else {
            alert(res.data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    deleteProgram: function () {
      if (confirm('삭제 하시겠습니까?')) {
        const url = this.$store.state.host + "/v1/admin/programs/" + this.$route.params.idx;
        this.axios.delete(url)
        .then(function (res) {
          if (res.data.status === 0) {
            alert(res.data.result);
            router.push('/program')
          } else {
            alert(res.data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    postProgramImage: function () {
      let formData = new FormData();
      formData.append('file', this.$refs.thum.files[0]);

      const url = this.$store.state.host + "/v1/admin/programs/image/" + "thum";
      this.isThum = true;
      this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        this.isThum = false;
        this.program.thum = res.data.result;
      })
      .catch((err) => {
        this.isThum = false;
        alert(`network error = ${err}`);
      })
    },

    postProgramUpperImage: function () {
      let formData = new FormData();
      formData.append('file', this.$refs.upper.files[0]);

      const url = this.$store.state.host + "/v1/admin/programs/image/" + "upperImage";
      this.isUpper = true;
      this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        this.isUpper = false;
        this.program.upperImage = res.data.result;
      })
      .catch((err) => {
        this.isUpper = false;
        alert(`network error = ${err}`);
      })
    },

    getCategories: function () {
      this.axios.get(this.$store.state.host + "/v1/admin/programs/category")
      .then((res) => {
        this.categories = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    selectCategory: function (category) {
      this.program.category = category
      this.program.idxCategory = category.idx
    },
    categoryClose: function () {
      this.isCategory = false;
    },

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
input.ios{
  display: inline-block;
  margin-left: 60px;
  border: 1px solid #e8ebf1;
  font-weight: 400;
  width: 600px;
  font-size: 0.875rem;
}

input.android{
  display: inline-block;
  margin-left: 18px;
  border: 1px solid #e8ebf1;
  font-weight: 400;
  width: 600px;
  font-size: 0.875rem;
}
</style>
