<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">{{ member.uid }} ({{ member.userName }})</h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;구분</th>
                        <td class="txt-left">
                          <input type="text" :value="member.division" class="form-control" readonly>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;회원구분</th>
                        <td class="txt-left">
                          <input type="text" :value="member.membership" class="form-control"
                                 readonly>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;회사명</th>
                        <td class="txt-left">
                          <input type="text" :value="member.companyname" class="form-control"
                                 readonly>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;이름</th>
                        <td class="txt-left">
                          <input type="text" :value="member.userName" class="form-control"
                                 readonly>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;ID(Email)</th>
                        <td class="txt-left">
                          <input type="text" v-model="member.uid" class="form-control" readonly>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;Mobile</th>
                        <td class="txt-left">
                          <input type="text" v-model="member.mobile" class="form-control"
                                 readonly>
                        </td>
                      </tr>
                      <tr>
                        <th>관심설정</th>
                        <td class="txt-left">
                          <span v-if="(member.attntion1String !== '' && member.attntion1String !== null)
                          && (member.attntion2String !== '' && member.attntion2String !== null)
                          && (member.attntion3String !== '' && member.attntion3String !== null) ">
                            {{ member.attntion1String }} {{ member.attntion2String }} {{ member.attntion3String }}
                          </span>
                          <span v-else>
                            관심설정이 없습니다.
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <th>수신여부</th>
                        <td class="txt-left">
                          <label><input type="checkbox" v-model="member.isAcceptDailyWrite"
                                        true-value="Y" false-value="N">데일리 라이팅 수신여부</label>
                          <label class="ml-1"><input type="checkbox" v-model="member.isAcceptAd" true-value="Y" false-value="N">광고성 정보</label>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button @click="$router.push('/curation-user')" type="submit"
                            class="btn btn-primary fl">목록
                    </button>
                    <button @click="putMemberImpl()" type="submit" class="btn btn-primary fr ml-10">저장
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center clear">
                    <h4 class="txt-left">구독 내역</h4>
                    <table class="table table-bordered">
                      <tr>
                        <th>구독상품</th>
                        <th>구매 구독권</th>
                        <th>시작일</th>
                        <th>종료일</th>
                        <th>구독여부</th>
                      </tr>
                      <tr v-for="(item) in subscribes" :key="item.idx">
                        <td class="txt-left">{{ subject(item.kubun) }}</td>
                        <td class="txt-left">{{ item.merchant.name }}</td>
                        <td class="txt-left">{{ item.sdate }}</td>
                        <td class="txt-left">{{ item.edate }}</td>
                        <td class="txt-left">{{ item.isSubscribed }}</td>
                      </tr>
                      <tr>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center clear">
                    <h4 class="txt-left">찾아가는 큐레이션 구독 내역</h4>
                    <table class="table table-bordered">
                      <tr>
                        <th>No</th>
                        <th>노출일</th>
                        <th>제목</th>
                        <th>산업군</th>
                        <th>메일 오픈 여부</th>
                        <th>앱 조회 여부</th>
                      </tr>
                      <tr v-for="(record, index) in this.records" :key="record.index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ record.createDate }}</td>
                        <td><p style="color:#0e4cfd" class="cursor-pointer" @click="moveCuration(record.curation.idx)">
                          {{ record.curation.title }}
                        </p></td>
                        <td>
                          <span v-for="industry in record.curation.industries" :key="industry.index" class="ml-1 label-industry">
                            {{industry}}
                          </span></td>
                        <td>{{ record.isAppRead }}</td>
                        <td>{{ record.isRead }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center clear">
                    <h4 class="txt-left">로이터 뉴스 구독 내역</h4>
                    <table class="table table-bordered">
                      <tr>
                        <th>No</th>
                        <th>노출일</th>
                        <th>제목</th>
                        <th>산업군</th>
                        <th>메일 오픈 여부</th>
                        <th>앱 조회 여부</th>
                      </tr>
                      <tr v-for="(record, index) in this.reuterRecord" :key="record.index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ record.is_farmdate }}</td>
                        <td><p style="color:#0e4cfd" class="cursor-pointer" @click="moveCuration(record.curation.idx)">
                          {{ record.KOR_TITLE }}
                        </p></td>
                        <td>
                          {{ record.CATEGORY }}
                        </td>
                        <td>{{ record.is_read }}</td>
                        <td>{{ record.is_appread }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from "@/components/commons/IncMenu";
import CurationComponent from "@/components/model/curationComponent";
import MemberComponent from "@/components/model/memberComponent";
import ReuterComponent from "@/components/model/reuterComponent";

export default {
  mixins: [CurationComponent, MemberComponent, ReuterComponent],
  name: 'curationUsersDetail',
  components: {
    Menu
  },

  props: {
    msg: String
  },

  methods: {
    putMemberImpl : function (){
      this.putMember();
    }
  },

  mounted() {
    this.getMember(this.$route.params.idx);
    this.getCurationUserDetail(0, this.$route.params.idx);
    this.getReuterUserDetail(this.$route.params.idx);
  },
}
</script>

<style scoped lang="scss">

</style>
