<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title txt-left" id="myModalLabel">푸시 수신확인</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <p>-발송된 푸시를 수신한 회원 목록을 확인합니다.</p>
              <div class="ov-hidden mt-10">
                <div class="f-left">
                  <input type="text" v-model="searchData.searchName" class="form-control dp-inblock input-name mr-3" placeholder="검색할 이름을 입력하세요" />
                  <select v-model="searchData.searchDivision" class="form-control dp-inblock select-gb1 w-20per mr-3">
                    <option value="">구분선택</option>
                    <option value="ITM">ITM</option>
                    <option value="CTM">CTM</option>
                    <!-- <option value="T">통합</option> -->
                    <option value="자체유입">자체유입</option>
                  </select>
                  <select v-model="searchData.searchMembership" class="form-control dp-inblock select-gb1 w-20per">
                    <option value="">회원 구분 선택</option>
                    <option value="B2B">B2B</option>
                    <option value="B2C">B2C</option>
                  </select>
                </div>
                <div class="f-right">
                  <button type="submit" @click="clickSearch" class="btn btn-primary my-1">검색</button>
                  <button type="submit" class="btn btn-primary ml-2 my-1" @click="excelDownLoad">엑셀 다운로드</button>
                </div>
              </div>

              <table class="table table-hover table-bordered mt-5 text-center">
                <tr>
                  <th class="w-10per">No</th>
                  <th class="w-10per">이름</th>
                  <th class="w-10per">수신일시</th>
                  <th class="w-20per">구분</th>
                  <th class="w-20per">회원구분</th>
                  <th class="w-10per">모바일OS</th>
                </tr>
                <tr v-for="(item,idx) in chkList" :key="item.idx">
                  <td class="w-10per">{{ idx + 1 }}</td>
                  <td class="w-10per">{{item.name}}</td>
                  <td class="w-10per">{{item.checkDate}}</td>
                  <td class="w-20per">{{item.membership}}</td>
                  <td class="w-20per">{{item.division}}</td>
                  <td class="w-10per">{{item.deviceType}}</td>
                </tr>
              </table>
              <div v-if="lastpage > 0" style="padding-left:40%; padding-top:20px;">
                <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                :click-handler="clickCallback" :prev-text="'Prev'"
                :next-text="'Next'" :container-class="'pagination'"
                :page-class="'page-link'">
                </paginate>
              </div>              
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';
export default {
  name: "checkedModal",
  components: {
    Paginate,
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    idxPush: {
      type: Number,
      require: false,
      default: -1,
    },
  },
  data: () => ({
    chkList:[],
    searchData: {
      searchName:'',
      searchDivision:'',
      searchMembership:'',
    },
    page: 1,
    lastpage: 1
  }),
  computed: {},
  mounted() {},
  watch: {
    visible: function (newVal) {
      if (newVal == true) {
        console.log("!");
        this.apiGetCheckedList();
      }
    },
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },    
    close() {
      this.$emit("close", false);
    },
    apiGetCheckedList: function () {
      const params = {
        division: this.searchData.searchDivision == '' ? null : this.searchData.searchDivision,
        idxPush: this.idxPush,
        membership: this.searchData.searchMembership == '' ? null : this.searchData.searchMembership,
        name: this.searchData.searchName == '' ? null : this.searchData.searchName,
        page: 1,
      };
      console.log(`idxPush = ${this.idxPush}`);
      this.axios.get(this.$store.state.host + "/v1/admin/pushes/getPushCheckList", { params })
      .then((res) => {
        this.chkList = res.data.result.value
        console.log(`res.data.lastPage = ${res.data.result.lastPage}`);
        this.lastpage =res.data.result.lastPage;
      });  
    },
    excelDownLoad(){     
      const params = {
        idxPush: this.idxPush
      };
      let filter = params.idxPush; 
      const url = this.$store.state.host + `/v1/admin/excel/admin/PushCheckListExcel?idxPush=${filter}`;
      
      this.axios.get(url)
      .then((res) => {
        if(res.status == 200) {
          console.log(`filter = ${filter}`);
          if(filter != undefined) {
            location.href=this.$store.state.host + `/v1/admin/excel/admin/PushCheckListExcel?idxPush=${filter}`
          }
        }
        else {
          alert('다운로드에 실패하였습니다.')
        }
      });
    },
    clickSearch(){
      this.apiGetCheckedList();
    }
  },
};
</script>
<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}

.dp-inblock {
  display: inline-block;
}

.ov-hidden {
  overflow: hidden;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.input-name {
  width: 200px !important;
}

.select-gb1 {
  width: 140px !important;
}

.select-gb2 {
  width: 140px !important;
}
</style>
