<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <location-add :visible="isLocation" @mount="mountLocation" @close="locationClose"/>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">배너 수정 </h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너종류</th>
                        <td class="text-left">
                          <label><input type="radio" v-model="banner.bannerType" value="slide-banner">&nbsp; 메인 슬라이딩 배너</label>
                          <label class="ml-1"><input type="radio" v-model="banner.bannerType" value="belt-banner">&nbsp; 띠 배너</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너순서</th>
                        <td class="text-left">
                          <input v-model.number="banner.sort" type="number" class="w-10"/>&nbsp;노출순서 숫자로 입력 (작은 수부터 순서대로 노출)
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;타이틀</th>
                        <td>
                          <input v-model="banner.title" type="text" class="form-control" placeholder="배너 타이틀을 입력하세요."/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;노출기간</th>
                        <td class="txt-left">
                          <input v-model="banner.startDate" type="date" placeholder="yyyy-mm-dd"/> ~
                          <input v-model="banner.endDate" type="date" placeholder="yyyy-mm-dd"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 사용여부</th>
                        <td class="txt-left">
                          <label><input v-model="banner.isUse" type="radio" value="Y">사용</label>
                          <label class="ml-1"><input v-model="banner.isUse" type="radio" value="N">미사용</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;배너 이미지<p class=" red">
                          jpg, png 등록가능 <br>
                          메인 슬라이드 배너(375*221) <br>
                          띠 배너(375*120)
                        </p>

                        </th>
                        <td>
                          <biz-image :url="imageUrl" :img="banner.bannerFile" :img-style="imageSize" @mountImage="banner.bannerFile = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 이동경로</th>
                        <td>
                          <div class="text-left">
                            <label><input @click="banner.link = ''" v-model="banner.isLink"  type="radio" value="N">미사용</label>
                            <label class="ml-1"><input @click="banner.link = ''" v-model="banner.isLink" type="radio" value="Y"></label>
                            사용
                          </div>
                          <div>
                            <input v-if="banner.isLink !== 'N'" :disabled="banner.isLink === 'N'"  v-model="banner.link" type="text" class="form-control"  placeholder="http://"/>
                          </div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl" @click="goBanners()">
                      목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-1" @click="putBanner($route.params.idx)">
                      수정
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import router from "@/router";
import LocationAdd from '../../components/pop/popLocationAdd';
import BizImage from "../../components/upload/uploadImage";

export default {
  name: 'aiBannerModify',
  components: {
    Menu,
    LocationAdd,
    BizImage
  },
  props: {
    msg: String
  },
  data: () => ({
    /// loader
    loading: false,
    loader: {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '5px',
      margin: '2px',
      radius: '2px'
    },

    banner: {
      idx: 0,
      title: ''
      , bannerType: 'slide-banner'
      , bannerFile: ''
      , link: ''
      , isLink: 'N'
      , sort: ''
      , sdate: ''
      , edate: ''
      , isUse: 'Y'
    },
    is_preview: false,
    isLocation: false,
    input_data:{
       title: ''
      , bannerType: 'slide-banner'
      , bannerFile: ''
      , link: ''
      , isLink: 'N'
      , sort: ''
      , sdate: ''
      , edate: ''
      , isUse: 'Y'
    },
  }),
  mounted() {
    this.initPage();
  },
  computed: {
    imageUrl: function () {
      let url = this.$store.state.host + "/v1/aitutor/banner/image/";

      return url + this.banner.bannerType;
    },

    imageSize: function () {
      if (this.banner.bannerType === 'slide-banner') {
        return 'width:375px; height:221px'
      }else  {
        return 'width:375px; height:120px;'
      }
    }
  },
  methods: {
    initPage: function () {
      this.axios.get(this.$store.state.host + "/v1/aitutor/banner/" + this.$route.params.idx)
      .then((res) => {
        this.banner = res.data.result;
        this.banner_img = this.banner.img;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    chooseImage: function () {
      document.getElementById("upload").click()
    },

    showPreview: function () {
      this.is_preview = true;
    },

    goBanners: function () {
      this.$router.push("/Banner")
    },

    vue_close: function (state) {
      this.is_preview = state;
    },

    putBanner: function (idx) {
      const url = this.$store.state.host + "/v1/aitutor/banner/" + idx;

      ///validation
      if (this.banner.bannerType === "") {
        alert('배너 종류를 선택해주세요.')
      } else if (this.banner.sort === "") {
        alert('배너 순서를 입력해주세요.')
      } else if (this.banner.title === "") {
        alert('배너 타이틀을 입력해주세요.')
      } else if (this.banner.startDate === "") {
        alert('배너 시작 시간을 입력해주세요.')
      } else if (this.banner.endDate === "") {
        alert('배너 종료 시간을 입력해주세요.')
      } else if (this.banner.bannerFile === "") {
        alert('이미지를 먼저 업로드 해주세요.');
      } else if (this.banner.isLink == "") {
        alert('이동경로를 입력해주세요.');
      } else if( this.banner.link === '' && this.banner.isLink === "Y"){
        alert('이동경로를 입력해주세요.');
      }else {
        this.input_data.bannerType=this.banner.bannerType;
        this.input_data.sort=this.banner.sort;
        this.input_data.title=this.banner.title;
        this.input_data.startDate=this.banner.startDate;
        this.input_data.endDate=this.banner.endDate;
        this.input_data.bannerFile=this.banner.bannerFile;
        this.input_data.isLink=this.banner.isLink;
        this.input_data.link=this.banner.link;

        this.axios.put(url, this.input_data)
        .then(function (res) {
          const data = res.data;
          if (data.status === 0) {
            alert("배너 수정이 완료되었습니다.")
            router.push("/aibanner")
          } else {
            alert("배너 수정이 실패했습니다." + data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }

    },

    locationClose: function (state) {
      this.isLocation = state;
    },

    mountLocation: function (idx, subject) {
      if(subject == "reuter"){
        this.banner.link = '/' + subject + '?newsSeq=' + idx
      }else if(subject == "dw"){
        this.banner.link = '/' + subject;
      }else{
        this.banner.link = '/' + subject + '?idx=' + idx
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
