<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">학습자 상세</h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <button v-show="member.userName !== '탈퇴회원'" class="btn btn-warning fl mb-3" @click="tempPWSMS()">비밀번호 초기화 문자 발송</button>
                    <button v-show="member.userName !== '탈퇴회원'" class="btn btn-danger fr mb-3" @click="modalOpen()">회원 탈퇴</button>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th><span class="red">*</span>&nbsp;구분</th>
                          <td class="txt-left">
                            <input type="text" :value="member.division" class="form-control" readonly />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;회원구분</th>
                          <td class="txt-left">
                            <input type="text" :value="member.membership" class="form-control" readonly />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;회사명</th>
                          <td class="txt-left">
                            <input type="text" :value="member.companyname" class="form-control" readonly />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;이름</th>
                          <td class="txt-left">
                            <input type="text" :value="member.userName" class="form-control" :readonly="member.division !== '자체유입'" />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;ID(Email)</th>
                          <td class="txt-left">
                            <input type="text" v-model="member.uid" class="form-control" readonly />
                          </td>
                        </tr>
                        <tr>
                          <th><span class="red">*</span>&nbsp;Mobile</th>
                          <td class="txt-left">
                            <input type="text" v-model="member.mobile" class="form-control" />
                          </td>
                        </tr>
                        <tr>
                          <th>관심설정</th>
                          <td class="txt-left">
                            <span
                              v-if="
                                member.attntion1String !== '' &&
                                  member.attntion1String !== null &&
                                  member.attntion2String !== '' &&
                                  member.attntion2String !== null &&
                                  member.attntion3String !== '' &&
                                  member.attntion3String !== null
                              "
                            >
                              {{ member.attntion1String }} {{ member.attntion2String }} {{ member.attntion3String }}
                            </span>
                            <span v-else>
                              관심설정이 없습니다.
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>수신여부</th>
                          <td class="txt-left">
                            <label><input type="checkbox" v-model="member.isAcceptDailyWrite" true-value="Y" false-value="N" />데일리 라이팅 수신여부</label>
                            <label class="ml-1"><input type="checkbox" v-model="member.isAcceptAd" true-value="Y" false-value="N" />광고성 정보</label>
                            <!--<label><input type="radio" v-model="member.isAcceptAd" value="N">사용안함</label>-->
                            <!--<button @click="member.isAcceptDailyWrite = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : member.isAcceptDailyWrite === 'N'}"> 사용</button>
                          <button @click="member.isAcceptDailyWrite = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : member.isAcceptDailyWrite === 'Y'}"> 사용안함</button>-->
                          </td>
                        </tr>
                        <tr>
                          <th>탈퇴 처리일</th>
                          <td class="txt-left">
                            <input type="text" v-model="member.deleteDate" class="form-control" readonly />
                          </td>
                        </tr>
                        <tr></tr>
                        <tr>
                          <th>탈퇴 사유</th>
                          <td class="txt-left">
                            <input type="text" v-model="member.leaveReason" class="form-control" readonly />
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button @click="$router.push('/member')" type="submit" class="btn btn-primary fl">목록</button>
                    <button @click="putMember()" type="submit" class="btn btn-primary fr ml-10">저장</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center clear">
                    <h4 class="txt-left">구독 내역</h4>
                    <table class="table table-bordered">
                      <tr>
                        <th>구독상품</th>
                        <th>구독갱신일</th>
                        <th>시작일</th>
                        <th>종료일</th>
                      </tr>
                      <tr v-for="item in subscribes" :key="item.idx">
                        <td class="txt-left">{{ subject(item.kubun) }}</td>
                        <td class="txt-left">{{ item.createDate }}</td>
                        <td class="txt-left">{{ item.sdate }}</td>
                        <td class="txt-left">{{ item.edate }}</td>
                      </tr>
                      <tr v-if="pushes.length <= 0">
                        <td colspan="4">구독 내역이 존재하지 않습니다.</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center clear">
                    <h4 class="txt-left">푸시 전송 내역</h4>
                    <table class="table table-bordered">
                      <tr>
                        <th>번호</th>
                        <th>제목</th>
                        <th>발송일</th>
                      </tr>
                      <tr v-for="(item, index) in pushes" :key="item.idxPush">
                        <td class="txt-left">{{ pushes.length - index }}</td>
                        <td class="txt-left">
                          <a :href="'/pushview/' + item.push.idx">{{ item.push.title }}</a>
                        </td>
                        <td class="txt-left">{{ item.push.createDate }}</td>
                      </tr>
                      <tr v-if="pushes.length <= 0">
                        <td colspan="3">푸시 전송 내역이 존재하지 않습니다.</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Reason :visible="reasonModalVisible" :userIdx="userindex" @close="modalClose"></Reason>
  </div>
</template>

<script>
import Menu from "../../components/commons/IncMenu.vue";
import router from "@/router";
import Reason from "../../components/resign/reason.vue";

export default {
  name: "memberView",
  components: {
    Menu,
    Reason,
  },
  props: {
    msg: String,
  },
  data: () => ({
    member: {
      mobile: "",
    },
    pushes: {},
    subscribes: [],
    reasonModalVisible: false,
    userindex: "-1",
  }),
  mounted() {
    this.initPage();
    //사용자 idx 세팅
    this.userindex = this.$route.params.idx;
  },
  computed: {},
  methods: {
    subject: function(item) {
      switch (item) {
        case "curation":
          return "큐레이션";
        default:
          return "알수없음";
      }
    },

    initPage: function() {
      this.axios.get(this.$store.state.host + "/v1/admin/users/" + this.$route.params.idx).then((res) => {
        this.member = res.data.result.user;
        this.pushes = res.data.result.pushes;
      })
      .catch(error =>{ alert(`network error = ${error}`)});

      this.axios.get(this.$store.state.host + "/v1/admin/subscribes/" + this.$route.params.idx).then((res) => {
        this.subscribes = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    putMember: function() {
      const url = this.$store.state.host + "/v1/admin/users/" + this.$route.params.idx + "/subscribe";

      var params = {
        dailyWright: this.member.isAcceptDailyWrite,
        ad: this.member.isAcceptAd,
        name: this.member.userName,
        mobile: this.member.mobile,
      };

      this.axios.put(url, params).then(function(res) {
        var data = res.data;
        if (data.status === 0) {
          alert("수정이 완료되었습니다.");
          router.push("/member");
        } else {
          alert("수정에 실패했습니다. " + data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    outMember: function() {
      const url = this.$store.state.host + "/v1/admin/users/" + this.$route.params.idx;

      if (confirm("해당 회원을 탈퇴처리 하시겠습니까?")) {
        this.axios.delete(url).then(function(res) {
          var data = res.data;
          if (data.status === 0) {
            alert("탈퇴처리가 완료되었습니다.");
            router.push("/member");
          } else {
            alert("수정에 실패했습니다. " + data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },
    tempPWSMS: function() {
      const url = this.$store.state.host + "/v1/admin/users/" + this.$route.params.idx + "/temp_pw_sms";

      if (confirm("해당 회원의 비밀번호 초기화 문자를 발송 하시겠습니까?")) {
        this.axios.put(url).then(function(res) {
          var data = res.data;
          if (data.status === 0) {
            alert("초기화 문자가 발송되었습니다.");
          } else {
            alert("메세지 발송에 실패했습니다. " + data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    /**
     * @description : 모달 open
     */
    modalOpen() {
      this.reasonModalVisible = true;
    },

    /**
     * @description : 모달 close
     */
    modalClose(result) {
      const { deleteYn } = result;
      this.reasonModalVisible = false;

      if (deleteYn === "Y") {
        router.push("/member");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
