<template>
  <div v-if="visibleLP">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">이동경로 선택</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <table class="table table-hover table-bordered">
                <tr>
                  <th class="txt-center" style="max-width:470px; overflow-x: scroll;">
                    <button @click="getPrograms(1)" class="btn btn-dark w-30" :class="{disabled: subject != 'apply'}">프로그램</button>
                    <button @click="getBizcards(1)" class="btn btn-dark w-30 ml-1" :class="{disabled: subject != 'biz-cardnews'}">비즈영어</button>
                    <button @click="getNotices(1)" class="btn btn-dark w-30 ml-1" :class="{disabled: subject != 'notice-view'}">공지사항</button>
                    <button @click="getEnsentence(1)" class="btn btn-dark w-30 ml-1" :class="{disabled : subject != 'en-sentence'}">99초 동영상</button>
                    <button @click="getCuration()" class="btn btn-dark w-30 ml-1" :class="{disabled : subject != 'trend-view'}">찾아가는큐레이션</button>
                    <button @click="getReuter()" class="btn btn-dark w-30 ml-1" :class="{disabled : subject != 'reuter'}">로이터</button>
                    <button @click="getDailyWriting()" class="btn btn-dark w-30 ml-1" :class="{disabled : subject != 'dw'}">데일리 라이팅</button>
                    <button @click="getDailySpeaking()" class="btn btn-dark w-30 ml-1" :class="{disabled : subject != 'daily-speaking'}">데일리 스피킹</button>
                    <button @click="getEmailTemplate(1)" class="btn btn-dark w-30 ml-1" :class="{disabled : subject != 'email-template'}">이메일 템플릿</button>
                    <!-- <button class="btn btn-dark w-30 ml-1">데일리라이팅</button> -->
                  </th>
                </tr>
              </table>
              <div style="height: 500px; overflow-y: auto">
                <table class="table table-hover table-bordered mt-30 text-center">
                  <tr>
                    <th style="width: 10%">No</th>
                    <th>이름</th>
                    <th style="width: 10%">선택</th>
                  </tr>
                  <tr v-for="(item, index) in items" :key="item.idx">
                    <td>{{ items.length - index }}</td>
                    <td>
                      <!-- <p v-if="isProgram">{{ item.programName }}</p> -->
                      <!-- <p v-else>{{ item.title }}&nbsp;</p> -->
                      <!-- {{item.title}} -->
                      <p v-if="subject == 'apply'">{{ item.programName }}</p>
                      <p v-if="subject == 'reuter'">{{ item.eng_title }}</p>
                      <p v-else>{{ item.title }}</p>
                    </td>
                    <td>
                      <button @click="postLocationIdx(item.news_seq)" class="btn btn-outline-dark" v-if="subject == 'reuter'">선택</button>
                      <button @click="postLocationIdx(item.idx)" class="btn btn-outline-dark" v-else>선택</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div v-if="lastpage !== 0" class="box">
              <paginate
                  :page-count="lastpage"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="clickCallback"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'page-link'">
              </paginate>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';

export default {
  name: 'tutorModal',
  components: {
    Paginate,
  },
  props: {
    visibleLP: {
      type: Boolean,
      require: true,
      default: false
    },
  },

  data: () => ({
    category: {},
    filter: {
      page: 1,
      isUse: 'Y',
    },
    programs: {},
    bizcards: {},
    notices: {},
    items: {},
    lastpage: 1,

    isProgram: false,
    isBizcard: false,
    isNotice: false,
    isEnsentence : false,
    subject : "apply"
  }),

  watch: {
    visibleLP: function (newVal) {
      this.visibleLP = newVal === true;
    }
  },

  mounted() {
    this.getPrograms(1);
  },

  methods: {

    getDailyWriting(){
      this.subject = "dw";
      let dwObject = {
        idx: 1,
        title: `데일리 라이팅`
      }
      this.items = [dwObject];
    },
    getDailySpeaking(){
      this.subject = "daily-speaking";
      let dsObject = {
        idx: 1,
        title: `데일리 스피킹`
      }
      this.items = [dsObject];
    },

    //이메일템플릿
    getEmailTemplate(){
       this.subject = "email-template";
       const url = this.$store.state.host + "/v1/admin/banners/emailpad-category";

       this.axios.get(url,{})
        .then((res)=>{
          const { result, status } = res.data;
          if(status === 0){
            this.items = result;
            //title 컬럼에 q1Kor에 넣기
            for(let item of this.items){
                item['title'] = item.kor_name;
            }
          }
          else{
            this.items = [];
          }
        })
        .catch((err)=>{
          console.log(err);
        });
    },
   
    //로이터
    getReuter(){
      this.subject = "reuter";
      let reuterObject = {
        news_seq: 1,
        eng_title: `생생 로이터 뉴스`
      }
      this.items = [reuterObject];
      // if(page !== undefined) this.filter.page = page;
      // const url = this.$store.state.host + "/v1/admin/reuters";
      // var params = {
      //   page: this.filter.page,
      // };
      // this.axios.get(url,{params}).then((res)=>{
      //   if(res.data.status == 0){
      //     let temp = res.data.result.value;
      //     // this.items = [temp[0]];
      //     console.log(temp)
      //     this.items = temp;
      //     this.lastpage = 1;
      //   }
      // }).catch((err)=>{
      //     console.log(err);
      // });
    },

   //찾아가는 큐레이션
   getCuration(){
    this.subject = "trend-view";
      let trendObject = {
        idx: 1,
        title: `찾아가는 큐레이션`
      }
      this.items = [trendObject];
    // if(page !== undefined) this.filter.page = page;
    // const url = this.$store.state.host + "/v1/admin/curations";
    // var params = {
    //   page: this.filter.page,
    //   industries : ''
    // };
    // this.axios.get(url,{params}).then((res)=>{
    //   if(res.data.status == 0){
    //     let temp = res.data.result.value;
    //     console.log(temp);
    //     this.items = temp;
    //     this.lastpage = 1;
    //   }
    // }).catch((err)=>{
    //   console.log(err);
    // });
   },


    //99초 동영상
    getEnsentence(page){
      this.subject = "en-sentence";
      if(page !== undefined) this.filter.page = page;
      const url = this.$store.state.host + "/v1/admin/scvideos";
      var params = {
        page: this.filter.page
      };
      this.axios.get(url,{params}).then((res)=>{
        // console.log(res);
        if(res.data.status == 0){
          this.items = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      }).catch((err)=>{
        console.log(err);
      });
    },


    close() {
      this.$emit("close", false);
      // this.visibleLP = false;
    },

    clickCallback: function (pageNum) {
      if(this.isProgram !== false) this.getPrograms(pageNum);
      else if(this.isNotice !== false) this.getNotices(pageNum);
      else if(this.isBizcard !== false) this.getBizcards(pageNum);
    },

    getPrograms: function (page) {
      this.subject = "apply";
      this.isProgram = true;
      this.isBizcard = false;
      this.isNotice = false;

      if (page !== undefined) {
        this.filter.page = page;
      }
      var params = {
        isUse: (this.filter.isUse === '') ? null : this.filter.isUse,
        status: (this.filter.status === '') ? null : this.filter.status,
        page: this.filter.page,
      };

      this.axios.get(this.$store.state.host + "/v1/admin/programs", {params})
      .then((res) => {
        if (res.data.status === 105) {
          this.programs = [];
          this.lastpage = 0;
        } else {
          this.items = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      });
    },

    getBizcards: function (page) {
      this.subject = "biz-cardnews";
      this.isProgram = false;
      this.isBizcard = true;
      this.isNotice = false;

      if(page !== undefined) this.filter.page = page;
      const url = this.$store.state.host + "/v1/admin/bizcards";
      var params = {
        page: this.filter.page
      };

      this.axios.get(url, {params})
      .then((res) => {
        this.items = res.data.result.value;
        this.lastpage = res.data.result.lastPage;
      });
    },

    getNotices: function (page) {
      this.subject = "notice-view";
      this.isProgram = false;
      this.isBizcard = false;
      this.isNotice = true;

      const url = this.$store.state.host + "/v1/admin/notices";
      var params = {
        page: (page === '' ? null : page)
      };

      this.axios.get(url, {
        params
      })
      .then((res) => {
        if (res.data.status === 105) {
          this.notices = [];
          this.lastpage = 0;
        } else {
          this.items = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      });
    },

    postLocationIdx: function (idx){
      this.$emit('mount', idx, this.subject);
      this.close();
    },
    
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1001;
}
.modal {
  display: block;
}
.box {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
