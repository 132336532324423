<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <Preview :visible="is_preview" @close="vue_close" :ensentence="ensentence"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">99초 동영상 등록</h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;제목</th>
                        <td>
                          <input v-model="ensentence.title" type="text"
                                 class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;부제목</th>
                        <td>
                          <input v-model="ensentence.title" type="text"
                                 class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;썸네일<p class="red">* jpg, png 400*225 권장</p>
                        </th>
                        <td>
                          <upload-image :img-style="'width:91px; height:91px;'" :url="imageUrl" :img="ensentence.thum" @mountImage="ensentence.thum = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;사용여부</th>
                        <td class="txt-left">
                          <label><input type="radio" v-model="ensentence.isUse" value="Y">사용</label>
                          <label class="ml-1"><input type="radio" v-model="ensentence.isUse" value="N">사용안함</label>
                          <!--<button @click="ensentence.isUse = 'Y'" class="btn w-30 btn-dark " :class="{ 'disabled' : ensentence.isUse === 'N'}"> 사용</button>
                          <button @click="ensentence.isUse = 'N'" class="btn w-30 btn-dark ml-1" :class="{ 'disabled' : ensentence.isUse === 'Y'}"> 사용안함</button>-->
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;동영상 URL</th>
                        <td class="text-left clear">
                          <input @change="checkUrl(ensentence.url)"  v-model="ensentence.url" type="text" class="form-control w-100per" />
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>동영상 내용</th>
                        <td class="text-left clear">
                          <ckeditor name="textarea" :editor="editor" v-model="ensentence.content" :config="editorConfig"></ckeditor>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button @click="$router.push('/ensentence')" type="submit"
                            class="btn btn-primary fl">목록
                    </button>
                    <button @click="postSentence()" type="submit" class="btn btn-primary fr ml-10">
                      등록
                    </button>
                    <button type="submit" class="btn btn-primary fr" @click="showPreview()">미리보기</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Menu from '../../components/commons/IncMenu.vue'
import Preview from "../../components/preview/pop_preview_enSentence.vue";
import UploadImage from '../../components/upload/uploadImage';
import host from "@/store/host";

export default {
  name: 'EnsentenceAdd',
  components: {
    Menu,
    Preview,
    UploadImage
  },
  props: {
    msg: String
  },
  data: () => ({
    ensentence: {
      isUse: "Y",
      thum: "",
      title: "",
      url: ""
    },
    is_preview: false,
    isLoading: false,

    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      // The configuration of the editor.
      filebrowserUploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor',
      ckfinder: {
        uploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor'
      },
      allowedContent: true,
      height : 600
    },
    imageUrl: '',
  }),
  mounted() {
    this.initPage();
    this.imageUrl = host + "/v1/admin/scvideos/image";
  },
  methods: {
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },
    initPage: function () {

    },

    checkUrl: function (url) {
      if(url.startsWith('https://')) this.ensentence.url = url;
      else {
        if (url.startsWith('http://')) {
          url = url.slice(6)
          console.log(url);
        }
        this.ensentence.url = 'https://' + url;
      }
    },

    postSentence: function () {

      if (this.ensentence.title === "") {
        alert("동영상 제목을 입력해주세요.")
      } else if (this.ensentence.thum === "") {
        alert("썸네일을 업로드 해주세요.")
      } else if (this.ensentence.url === "") {
        alert("동영상 URL 을 입력해주세요.")
      } else {
        const url = this.$store.state.host + "/v1/admin/scvideos";
        this.axios.post(url, this.ensentence)
        .then((res) => {
          var data = res.data;
          if (data.status === 150) {
            alert("99초 동영상 등록에 실패했습니다. " + data.result)
          } else {
            alert("99초 동영상 작성이 완료되었습니다.")
            this.$router.push("/ensentence");
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    selectImage: function () {
      const formData = new FormData();
      const url = this.$store.state.host + "/v1/admin/scvideos/image";
      let fileRefs = this.$refs.file.files[0];
      formData.append('img', fileRefs);

      this.isLoading = true;
      this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        if(res.data.status === 0){
          this.isLoading = false
          this.ensentence.thum = res.data.result;
        }else{
          this.isLoading = false
          alert(res.data.result);
        }
      })
      .catch((err) => {
        alert(`network error = ${err}`);
      })
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
