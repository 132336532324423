<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <Preview :visible="is_preview" @close="vue_close" :banner_idx="banner_idx" :banner_img="banner_img" :banner-type="bannerType"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">배너 관리</h3>
              <p class="lead">
                - CARROT 통합플랫폼 메인 페이지에 노출 될 이미지와 링크를 등록할 수 있습니다.<br />
                - 배너의 종류는 메인 슬라이드 배너, 중간 배너가 있습니다. 각 최대 5개까지 등록 가능합니다.<br />
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <select @change="getBanners(1)" v-model="filter.type" class="form-control fl w-10per">
                        <option value="">종류선택</option>
                        <option value="메인슬라이드배너">메인 슬라이드 배너</option>
                        <option value="중간배너">중간 배너</option>
                      </select>

                      <select @change="getBanners()" v-model="filter.isUse" class="form-control fl ml-10 w-10per">
                        <option value="">사용여부</option>
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>

                      <button type="submit" class="btn btn-primary fr" @click="goBannerAdd()">등록</button>
                    </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th class="w-10per">No</th>
                        <th class="w-10per">배너종류</th>
                        <th class="w-10per">배너순서</th>
                        <th>타이틀</th>
                        <th>노출기간</th>
                        <th class="w-10per">사용여부</th>
                        <th class="w-10per">안드로이드</th>
                        <th class="w-10per">IOS</th>
                        <th class="w-10per">HQ 매니저</th>
                        <th style="max-width:200px;">최근 업데이트일</th>
                        <th style="max-width:200px;">미리보기</th>
                      </tr>
                      <tr v-for="(item, index) in banners" :key="item.idx">
                        <td>{{ result.total - (result.currentPage - 1) * result.perPage - index }}</td>
                        <td>{{ item.type }}</td>
                        <td>{{ item.ordernum }}</td>
                        <td>
                          <a :href="'/bannermodify/' + item.idx">{{ item.title }}</a>
                        </td>
                        <td>{{ item.sdate }} ~ {{ item.edate }}</td>
                        <td>{{ item.isUse === "Y" ? "사용" : "사용안함" }}</td>
                        <td>{{ item.isAndroid }}</td>
                        <td>{{ item.isIos }}</td>
                        <td>{{ item.emp.englishname }}({{ item.emp.koreanname }})</td>
                        <td>{{ $moment(item.updateDate).format("YYYY-MM-DD HH:mm") }}</td>
                        <td>
                          <button @click="showPreview(item)">View</button>
                        </td>
                      </tr>
                      <tr v-if="banners.length === 0">
                        <td colspan="9">검색하신 배너가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <div v-if="banners.length !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate
                        :page-count="lastpage"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="clickCallback"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination'"
                        :page-class="'page-link'"
                      >
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../../components/commons/IncMenu.vue";
import Preview from "../../components/preview/pop_preview.vue";
import Paginate from "vuejs-paginate";

export default {
  name: "banner",
  components: {
    Menu,
    Preview,
    Paginate,
  },
  props: {
    msg: String,
  },
  data: () => ({
    banners: [],
    filter: {
      page: 1,
      type: "",
      isUse: "",
    },
    is_preview: false,
    lastpage: 1,
    banner_idx: 0,
    banner_img: "",
    bannerType: "",
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    showPreview: function(banner) {
      this.banner_idx = banner.idx;
      this.banner_img = banner.img;
      this.bannerType = banner.type;
      this.is_preview = true;
    },
    vue_close: function(state) {
      this.is_preview = state;
    },
    clickCallback: function(pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    goBannerAdd: function() {
      this.$router.push("BannerAdd");
    },

    getBanners: function(page) {
      if (page !== undefined) {
        this.filter.page = page;
      }
      const url = this.$store.state.host + "/v1/admin/banners";
      var params = {
        isUse: this.filter.isUse === "" ? null : this.filter.isUse,
        page: this.filter.page === "" ? null : this.filter.page,
        type: this.filter.type === "" ? null : this.filter.type,
      };

      this.axios
        .get(url, {
          params,
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.result = res.data.result;
            this.banners = res.data.result.value;
            this.lastpage = res.data.result.lastPage;
          } else {
            console.log("검색실패", res);
            this.banners = [];
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
    },

    putBanner: function(idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;

      this.axios.put(url, this.banner).then(function(res) {
        var data = res.data.result;
        console.log(data);
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    deleteBanner: function(idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;

      this.axios.delete(url).then(function(res) {
        var data = res.data.result;
        console.log(data);
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    selectBannerImage: function() {
      this.bannerImage = this.$refs.bannerImage.files[0];
    },

    postBannerImage: function() {
      let formData = new FormData();
      formData.append("img", this.bannerImage);

      const url = this.$store.state.host + "/v1/admin/banners/image";

      this.axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status === 0) {
            this.banner.img = res.data.result;
          } else {
            alert(res.data.result);
          }
        })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getBannerPreview: function() {
      const url = this.$store.state.host + "/v1/admin/banners/preview";
      this.axios.get(url, this.banner).then(function() {}).catch(error =>{ alert(`network error = ${error}`)});
    },

    initPage: function() {
      this.getBanners();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss"></style>
