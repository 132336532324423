const state={
    dsQuery:{},
    itemInfo:{}
};

const mutations={
    saveQuery (state, data) {
        state.dsQuery=data;
    },
    resetQuery (state) {
        state.dsQuery={};
    },

    saveItemInfo (state, data) {
        state.itemInfo=data;
    },

    resetItemInfo (state) {
        state.itemInfo={};
    }
}

export default {
    state,
    mutations
}