<template>
  <div v-if="visible">
    <div class="dim">
      <div
        class="modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">발음등록</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
              >
                <span aria-hidden="true" @click="close()">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th><span class="red">*</span>&nbsp;발음</th>
                      <td class="text-left">
                        <input
                          type="text"
                          class="form-control w-100per pointer"
                          v-model="categoryP"
                        />
                      </td>
                    </tr>

                    <tr>
                      <th><span class="red">*</span>&nbsp;이미지</th>
                      <td class="w-100per text-left imageUpload">
                        <input type="file" ref="categoryImage" accept=".png, .jpg" @change="handleFileChange()" multiple/>
                        <span class="red">* jpg, png 등록 가능</span>&nbsp;&nbsp;
                        <span 
                        v-if="btnText == '수정'"
                        class="pointer"
                        @click="imgDwonLoad"
                        >[업로드된 파일다운로드]
                        <img 
                        class="imgset"
                        src="../../assets/dwonload_img.png"></span>
                      </td>
                    </tr>

                    <tr>
                      <th><span class="red">*</span>&nbsp;가이드</th>
                      <td>
                        <div v-for="(data,index) in guideText" v-bind:key="data.index" >
                          <div class="divSet" v-if= "data.isVisible">
                          <span class="indexSet">{{(index +1)}}.</span>
                          <input type="text"
                            class="form-control wd-80p inlineText pointer"
                            v-model = "guideText[index].guideStr"
                          />
                          <!-- hasOwnProperty() 객체의 특정 속성이 존재하는지 확인하고 true / false 반환 -->
                            <button v-if="!data.hasOwnProperty('endPoint')" class="btn btn-primary btnSet" @click="inputShow(index)">+</button>
                            <button v-if="!data.hasOwnProperty('startPoint')" class="btn btn-danger btnSet" @click="inputDelete(index)">-</button>
                          </div>
                        </div>
                      </td> 
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="w-100per mt-10 btnLayer">
                <button class="btn btn-primary" @click="routeCategoryApi();">{{btnText}}</button>
              </div>
              <div class="mt-20 pList">
                <table class="table table-hover table-bordered text-center">
                  <thead>
                    <th class="w-10per">No</th>
                    <th>발음</th>
                    <th>등록일</th>
                    <th>HQ매니저</th>
                    <th>삭제</th>
                  </thead>
                  <tbody v-for="item in categoryPronunciations" :key="item.idx">
                    <td>{{ item.idx }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.createDate }}</td>
                    <td>{{ item.engName }} ({{ item.korName }})</td>
                    <td>
                      <button class="btn btn-primary"  @click="updateInit(item)">수정</button><span>&nbsp;</span>
                      <button class="btn btn-danger" @click="deleteCategoryPronunciation(item.idx)">삭제</button>
                    </td>
                  </tbody>
                </table>
              </div>
              <div
                class="pt-20 pgWrapper"
                v-show="sLastpage > 0"
              >
              </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary">확인</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">취소</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";
// import Paginate from "vuejs-paginate";

export default {
  mixins: [DailySpeakingComponent],
  name: "pronunciationAddModal",
  components: {
    // Paginate,
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      categoryP: "",
      formData: new FormData(),
      guideText: [
       {
        guideStr: '',
        startPoint: true, // 처음 input - 버튼 가리기위한 속성
        isVisible: true
       },
       {
       guideStr: '',
       isVisible: false
       },
       {
       guideStr: '',
       isVisible: false
       },
       {
        guideStr: '',
       isVisible: false
       },
       {
        guideStr: '',
        endPoint: true, // 마지막 input + 버튼 가리기위한 속성
        isVisible: false
       }
      ],
      sLastpage: 1,
      btnText: '등록',
      routeValue: 'insert',
      modifyidx:'',
      fileName: [],
      NumCount: 1
    };
  },
  created(){
    this.getCategoryPronunciationList();
  },
  methods: {
    close() {
      this.resetData();
      this.$emit("close");
    },
    clickCallback: function (pageNum) {
      this.categoryPronunciationPage = pageNum;
      this.getCategoryPronunciationList();
      this.resetData();
    },

    handleFileChange(){
      console.log(this.$refs.categoryImage.files);
      let file = this.$refs.categoryImage.files[0];
      this.formData.append('categoryImg', file);
    },

    clearCategoryPronunciationInputs(){
      this.categoryP='';
      this.$refs.categoryImage.value=null;
      this.formData.delete('categoryImg');
    },
    updateInit(item){
      this.getCategoryPronunciationList();
      //리스트수정 버튼 눌렀을시 수정할수 있도록 데이터 Setting 
      this.btnText = `수정`;
      this.routeValue = 'update'
      this.modifyidx = item.idx,
      this.categoryP = item.category.trim();
      
      this.fileName = item.imageUrl.split('/');
      // this.fileName[this.fileName.length - 1] // 이게 파일명 
      /*

      API에서 contents 반환을 각각 5개를 해주기때문에 반복처리 안함 추후 갯수 증가 될시 요청해서 
      contents 를 Object로 반환 받아오면 아래처럼 반복으로 처리  - eruda
        this.guideText.forEach((el,i) => {
          this.guideText[i] = contents[i]
        });
      */
      console.log(item.contents1);
      console.log(item.contents2);
      console.log(item.contents3);
      console.log(item.contents4);
      console.log(item.contents5);


      //저장시에 빈값이 아니면 넘버링 부여되게 처리되어 있음 . 
      let setContents1 = this.isNull(item.contents1) ? '' : item.contents1.split('.');
      let setContents2 = this.isNull(item.contents2) ? '' : item.contents2.split('.');
      let setContents3 = this.isNull(item.contents3) ? '' : item.contents3.split('.');
      let setContents4 = this.isNull(item.contents4) ? '' : item.contents4.split('.');
      let setContents5 = this.isNull(item.contents5) ? '' : item.contents5.split('.');
        
      // console.log(setContents1[1]);
      // console.log(setContents2[1]);      
      // console.log(setContents3[1]);
      // console.log(setContents4[1]);
      // console.log(setContents5[1]);

      //. 을 기준으로 짤라서 가져옴  [1] 이 입력넣은 데이터  
      this.guideText[0].guideStr= setContents1[1] || '';
      this.guideText[1].guideStr= setContents2[1] || '';
      this.guideText[2].guideStr= setContents3[1] || '';
      this.guideText[3].guideStr= setContents4[1] || '';
      this.guideText[4].guideStr= setContents5[1] || '';
      
      this.guideText.forEach((el) => { 
          console.log(JSON.stringify(el));
            if(!this.isNull(el.guideStr.trim())) {
              el.isVisible =true;
            }
            else {
              el.isVisible = false;
            }
      });

    },
    // 사업부 요청사항뒤늦게 Update 개발요청 아래와 같이 분기 처리로 API 각각 다르게 호출하게 처리 
    routeCategoryApi(){
      if(this.routeValue === 'insert'){ 
        if(this.chkValidation()){
          return;
        }
        else {
          this.insertCategoryPronunciation();  // insert API call 
        }
        
      }

      else if(this.routeValue === 'update'){
        if(this.chkValidation()){
          return;
        }
        else {
          this.updateCategoryPronunciation(this.modifyidx); // update API Call
          this.getCategoryPronunciationList(); //업데이트 이후 리스트 갱신
        }
      }
    },
    resetData(){
      this.btnText = `등록`;
      this.routeValue = 'insert';
      this.modifyidx = '';
      this.categoryP = '';
      this.fileName= [];
      this.guideText.forEach((element,index) => {
        this.guideText[index].guideStr = '';
        if(index === 0) {
          element.isVisible = true;  
        }
        else {
          element.isVisible = false;
        }
      });
    },
    isNull(value){
      if(value === '' || value === null || value === undefined ) {
        return true;
      }
      else {
        return false;
      }
    },
    inputShow(index){
      if (this.guideText[index].guideStr =='') {
        alert(`가이드를 입력하고 추가해주세요`);
        return;
      }
      this.guideText[index + 1].isVisible = true;
    },
    inputDelete(index){
      //FIXME: 강제로 guideText[5]에는 접근 못하게 처리 , 더 좋은 방법 있을텐데 추후 수정 할 것 - Eruda 
      if (index != 4 && this.guideText[index + 1].isVisible == true) {
        alert(`아래의 가이드 먼저 삭제해주세요.`);
        return;
      }
      this.guideText[index].isVisible = false;
      this.guideText[index].guideStr = '';
    },

    chkValidation(){ 
      if(this.categoryP == '') {
        alert(`발음를 입력해주세요.`);
        return true
      }
      //this.$refs.categoryImage.files.length  0 : file 없음 , 1 file 등록 
      if(this.$refs.categoryImage.files.length == 0 && this.routeValue == 'insert') {
        alert(`이미지 파일을 업로드해주세요.`);
        return true;
      }
      for(let i =0; i < this.guideText.length; i++){
          if(this.guideText[i].isVisible && this.guideText[i].guideStr =='') {
            alert(`${i +1}번째 가이드 텍스트를 입력하세요`);
            return true
            }
        }
          return false;
    },
    imgDwonLoad(){
    this.downloadImage(`https://platform-api.carrotenglish.net/download-object?fileName=${this.fileName[this.fileName.length - 1]}&folderName=dailySpeaking%2FpronounceImg`,`${this.fileName[this.fileName.length - 1]}`);
    },
    downloadImage(url, filename) {
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = filename;
          link.click();
      })
      .catch(console.error);
    },     
  },

  watch: {
    visible() {
      if (this.visible === true) {
        this.getCategoryPronunciationList();
      }
    },
  },
};
</script>

<style lang="scss">

.modal-dialog {
  min-width:900px;
  max-height:100%;

  .modal-content{
    max-height: 100%;
  }
}

.btnLayer{
  display:flex;
  justify-content: flex-end;
}

.pointer{
  cursor:pointer;
}

.imageUpload input{
  cursor:pointer;
}

.pList{
  max-height:314px;
  overflow-y:scroll;
  border:1px solid #e8ebf1;

  thead{
    position:sticky;
    top:0;

    th{
      font-size:14px;
    }
  }
}

.divblock{
  display:block;
}

.divSet{
  display: flex;
  align-items: center;
  margin-bottom: 10px; 
}
.btnSet{
  display: inline;
  float: left;
  margin-left: 7px;
}
.spanSet{
  display: inline;
  float: left;  
  padding: 10px;
}

.pgWrapper{
  display:flex;
  justify-content: center;
  align-items: center;

  .pagination{
    li{
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #007bff;
      background-color: #fff;
      border: 1px solid #dee2e6;
    }
  }
  .btninterval{
    margin: 1px;
  }
}
</style>