<template>
  <div class="carrot">
    <popPushInput :visible="isPushPop" 
    :pushList="userIdxList" 
    :allPush="mainChecked" 
    :checkedFilter="filterExistence" 
    :checkPeopleCount="checkedCount"
    :memberDivision="filter.division"
    :memberMembership="filter.membership"
    @close="pushPopClose"></popPushInput>
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">학습자 관리</h3>
              <p class="lead">
                - CARROT 앱에 로그인 성공한 회원만 표시됩니다.<br>
                - 회원구분: CTM(전화영어 학습자), ITM(글로벌 오프라인 학습자), 통합 (전화영어 + 글로벌 오프라인 학습자)
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th width="60">이름</th>
                        <td>
                          <input @keyup.enter="getMembers(1)" v-model="filter.name" type="text"
                                 class="form-control" id=""
                                 placeholder="검색어를 입력하세요.">
                        </td>
                        <th>ID(Email)</th>
                        <td>
                          <input @keyup.enter="getMembers(1)" v-model="filter.uid" type="text"
                                 class="form-control" id=""
                                 placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      <tr>
                        <th>회사명</th>
                        <td>
                          <input @keyup.enter="getMembers(1)" v-model="filter.companyname"
                                 type="text" class="form-control" id=""
                                 placeholder="검색어를 입력하세요.">
                        </td>
                        <th>Mobile</th>
                        <td>
                          <input @keyup.enter="getMembers(1)" v-model="filter.mobile" type="text"
                                 class="form-control" id=""
                                 placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary"
                            @click="getMembers(filter.page, filter.email, filter.name, filter.companyname, filter.mobile, filter.name)">
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>ITM</th>
                        <th>CTM</th>
                        <th>통합</th>
                        <th>자체유입</th>
                      </tr>
                      <tr>
                        <td>{{ count.itmCount }}</td>
                        <td>{{ count.ctmCount }}</td>
                        <td>{{ count.totalCount }}</td>
                        <td>{{ count.selfCount }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <select @change="getMembers(1)" v-model="filter.division"
                            class="form-control fl w-20per">
                      <option value="">구분선택</option>
                      <option value="CTM">CTM</option>
                      <option value="ITM">ITM</option>
                      <!-- <option value="통합">통합</option> -->
                      <option value="자체유입">자체유입</option>
                    </select>

                    <select @change="getMembers(1)" v-model="filter.membership"
                            class="form-control fl ml-10 w-20per">
                      <option value="">회원구분 선택</option>
                      <option value="B2C">B2C</option>
                      <option value="B2B">B2B</option>
                    </select>
                    <button type="button" class="btn btn-primary ml-10 " @click="excelDownLoad">
                        엑셀 다운로드
                    </button>

                    <div class="float-right ">
                      
                    <button type="button" class="btn btn-primary" @click="clickPushPop">
                        푸시알림 발송
                    </button>
                </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%"><input type="checkbox" @change="allChangeChk" :checked="mainChecked" class="pointer"></th>
                        <th style="width: 5%">No</th>
                        <th style="width: 10%">구분</th>
                        <th style="width: 10%">회원구분</th>
                        <th style="width: 10%">회사명</th>
                        <th style="width: 10%">이름</th>
                        <th>ID(이메일)</th>
                        <th style="width: 10%">Mobile</th>
                        <th>추천인 ID</th>
                        <th style="width: 10%">최근접속일</th>
                        <th style="width: 10%">최초등록일</th>
                        <th style="width: 10%">탈퇴처리일</th>
                        <th style="width: 10%">탈퇴사유</th>
                      </tr>

                      <tr v-for="(item,index) in members" :key="item.idx">
                        <td><input type="checkbox" @change="changeChkbox(item.idx)" :checked=item.isChecked class="pointer"></td>
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ item.division }}</td>
                        <td>{{ item.membership }}</td>
                        <td>{{ item.companyname }}</td>
                        <td><a :href="('/memberview/' + item.idx )">{{ item.userName }}</a></td>
                        <td>{{ item.uid }}</td>
                        <td>{{ item.mobile }}</td>
                        <td>{{ item.refUid }}</td>
                        <td>{{ item.currentLogin }}</td>
                        <td>{{ item.updateDate }}</td>
                        <td>{{ item.deleteDate }}</td>
                        <td>{{ item.leaveReason }}</td>
                      </tr>
                      <tr v-if="lastpage === 0">
                        <td colspan="10">검색하신 학습자가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    
                    <div v-if="lastpage !== 0" style="padding-left:35%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import popPushInput from '../../components/pop/popPushInput.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'member',
  components: {
    Menu,
    Paginate,
    popPushInput
  },
  props: {
    msg: String
  },
  data: () => ({
    members: [],
    count: {},
    filter: {
      name: '',
      email: '',
      companyname: '',
      mobile: '',
      division: '',
      membership: '',
      isStatus:'',
      page: 1,
    },
    userIdxList: new Set(),
    lastpage: 1,
    isPushPop: false,
    mainChecked: false,
    filterExistence: false,
    checkedCount: 0
  }),
  mounted() {
    this.getMembers(1);
  },
  methods: {
    clickCallback: function (pageNum) {
      this.filter.page = pageNum;
      this.getMembers();
    },
    initPage: function () {
    },
    getMembers: function (page) {
      this.mainChecked = false; // 리스트를 갱신할때마다 전체발송 체크박스 초기화
      if (page !== undefined) {
        this.filter.page = page;
      }
      const url = this.$store.state.host + "/v1/admin/users";
      // const url = this.$store.state.host + "/v1/admin/findUsersLargePage";
      var params = {
        page: (this.filter.page === '' ? null : this.filter.page),
        uid: (this.filter.uid === '' ? null : this.filter.uid),
        companyname: (this.filter.companyname === '' ? null : this.filter.companyname),
        mobile: (this.filter.mobile === '' ? null : this.filter.mobile),
        division: (this.filter.division === '' ? null : this.filter.division),
        membership: (this.filter.membership === '' ? null : this.filter.membership),
        name: (this.filter.name === '' ? null : this.filter.name),
        isStatus: ('회원')  // 탈퇴 회원 안보이기 위한 처리 
      };

      this.axios.get(url, {params})
      .then((res) => {
        if (res.data.status === 105) {
          this.members = [];
          this.count = {
            itmCount: 0,
            ctmCount: 0,
            totalCount: 0,
            selfCount: 0,
          };
          this.lastpage = 0
        } else {
          this.result = res.data.result.userList;
          this.count = res.data.result.statics;
          this.lastpage = res.data.result.userList.lastPage;
          this.members = res.data.result.userList.value;
          
          this.userIdxList.clear(); 
          // 아래 내용은 페이징 처리를 하여도 체크박스 유지하려고했다가 
          //일정상의 이슈로 해당기능 추후 구현할수도있어서 남겨둠 -Eruda
          // this.members.forEach((el)=>{
          //   if(this.userIdxList.has(el.idx)){
          //     el.isChecked  = true;
          //   }
          // });
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    changeChkbox(userIdx){
      this.userIdxList.add(userIdx);
      this.members.forEach((el) => {
        if(el.idx == userIdx) { 
          if( Object.hasOwnProperty.call(el, "isChecked") ){
              delete el.isChecked;
              this.userIdxList.delete(userIdx);
          }
          else {
            el.isChecked  = true; 
          }
        } 
      });
      let chkAllChecked = [];
      this.members.forEach((el) => {
        chkAllChecked.push(Object.hasOwnProperty.call(el, "isChecked"));
      });
      if(chkAllChecked.includes(false)){
        this.mainChecked = false;
      }
      else {
        if(this.members.length == 1) {
          this.mainChecked = false;
        }else {
          this.mainChecked = true;
        }
      }
    },
    allChangeChk() {
      if(this.mainChecked == false){
        this.mainChecked = true;
        this.members.forEach((el) => {
          this.userIdxList.add(el.idx);
          el.isChecked  = true;
        });
      }
      else {
        this.mainChecked = false;
        this.members.forEach((el) => {
          delete el.isChecked;
          this.userIdxList.clear();
        });
      }
    },
    excelDownLoad(){     
      const url = this.$store.state.host + "/v1/admin/excel/admin/userListExcel";
      const params = {
        isStatus: 'isStatus=회원',
        companyname: (this.filter.companyname === '' ? '' : `&companyname=${this.filter.companyname}`),
        division: (this.filter.division === '' ? '' : `&division=${this.filter.division}`),
        isDevice: (this.filter.isDevice === '' || this.filter.isDevice === undefined ? '' : `&isDevice=${this.filter.isDevice}`),
        membership: (this.filter.membership === '' ? '' : `&membership=${this.filter.membership}`),
        mobile: (this.filter.mobile === '' ? '' : `&mobile=${this.filter.mobile}`),
        name: (this.filter.name === '' ? '' : `&name=${this.filter.name}`),
      };
      let filter = params.isStatus + params.companyname + params.division + params.isDevice + params.membership + params.mobile +  params.name
      this.axios.get(url)
      .then((res) => {
        if(res.status == 200) {
          if(filter != undefined) {
            location.href=this.$store.state.host + `/v1/admin/excel/admin/userListExcel?${filter}`
          }
          else {
            location.href=this.$store.state.host + `/v1/admin/excel/admin/userListExcel`
          }
        }
        else {
          alert('다운로드에 실패하였습니다.')
        }
      });
    },
    clickPushPop() {
      let targetCount = 0;
      this.members.forEach((el)=>{
        if(Object.hasOwnProperty.call(el, "isChecked")) {
          targetCount++;
        }
      });
      if(!this.mainChecked){
        this.checkedCount = targetCount;
      }
      else {
        this.getPeopleCount();
      }
      if(targetCount == 0) {
        alert('발송대상을 선택해주세요');
        return;
      }
      this.filterExistence = this.getFilter();
      this.isPushPop = true;
    },
    pushPopClose(){
      this.isPushPop = false;
    },
    getFilter(){ 
      //검색 조건이 하나라도 존재하면 true / 없으면 false
      if(
      this.filter.name == '' && 
      this.filter.email == '' &&
      this.filter.companyname == '' &&
      this.filter.mobile == '' &&
      this.filter.division == '' &&
      this.filter.membership == '' 
      )
      {
        return false; 
      }
      else {
        return true;
      }
    },
    async getPeopleCount(){

      let params = {
        division: (this.filter.division === '' ? null : this.filter.division),
        membership: (this.filter.membership === '' ? null : this.filter.membership),
      };

      let filter = '';
      if(params.division != null) { filter += `&division=${params.division}`}
      if(params.membership != null) { filter += `&membership=${params.membership}`}
      const url = `${this.$store.state.host}/v1/admin/pushes/getCountCheckedPeople?${filter}`
      await this.axios.get(url)
      .then((res) => {
        this.checkedCount = res.data.checkedPeopleCnt;
      })
      .catch((err) => 
        alert(`network error = ${err}`)
      )
    },
    initChecked(){
      alert(`initChecked`);
    }
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.pointer{
  cursor: pointer
}
</style>
