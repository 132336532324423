<template>
  <div id="main">
    <div id="index">
      <h2>통합플랫폼 베타버전</h2><br>
      <label for="android">안드로이드 업데이트 URL : <input id="android" type="text" v-model="android_url" name="android"></label><br>
      <label for="ios">IOS 업데이트 URL : <input id="ios" type="text" v-model="ios_url" name="ios"></label><br>
      <button @click="upload_mobile">업로드</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "download",

  mounted() {

    this.axios.get(this.$store.state.host + "/url")
    .then((res) => {
      let anroid = res.data.result[0];
      let ios = res.data.result[1];
      this.android_url = anroid.url;
      this.android_update_date = anroid.update_date;
      this.ios_url = ios.url;
      this.ios_update_date = ios.update_date;
    })
    .catch(error =>{ alert(`network error = ${error}`)});

  },

  data: () => ({

    android_url: '',
    android_update_date: '',
    ios_url: '',
    ios_update_date: ''

  }),

  methods: {

    upload_mobile() {

      const params = {
        "ios": this.ios_url,
        "android": this.android_url
      }

      this.axios.post(this.$store.state.host + "/url", params)
      .then((res) => {
        console.log(res);
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

  },

}
</script>

<style scoped>

#index {
  text-align: center;
  margin-top: 50px;
}

</style>
