<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <LectureList :visible="is_preview" @close="vue_close"  @selected="setLectures"></LectureList>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">AI튜터 강좌 관리</h3>
              <p class="lead">
                - AI튜터에 제공되는 이러닝 강좌 관리 페이지 입니다.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div  class="form-group float-left clear w-100per">
                      <table class="table table-hover table-bordered">
                        <tr>
                          <th class="text-center" v-for="(item) in statistics" :key="item.idx">
                            {{item.category_name}}<br>
                            (강좌/강의)
                          </th>
                        </tr>
                        <tr>
                          <td class="text-center" v-for="(item) in statistics" :key="item.idx">
                            {{item.course_cnt}}/{{item.lec_cnt}}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="form-group float-left clear w-100per">
                      <input @keyup.enter="getBanners()" v-model="filter.searchTitle" type="text"
                             class="form-control w-40per fl"
                             placeholder="검색할 강좌 타이틀을 입력하세요.">
                      <button type="submit" class="btn btn-primary ml-2 my-1" @click="getBanners()">검색</button>
                    </div>
                    <div class="form-group float-left clear w-100per">
                      <select @change="getBanners()" v-model="filter.categoryIdx"
                              class="form-control fl w-15per">
                        <option value="-999">카테고리 선택</option>
                        <option v-for="(item) in categoryList" :value="item.idx" :key="item.idx">
                          {{item.name}}
                        </option>
                      </select>
                      <select @change="getBanners()" v-model="filter.isUse"
                              class="form-control fl w-20per">
                        <option value="">사용여부 선택</option>
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>
                      <button type="submit" class="btn btn-primary ml-2 my-1" @click="getCoursesExcel()">엑셀다운로드</button>

                      <button type="submit" class="btn btn-primary ml-2 my-1" @click="showPreview()">카테고리등록</button>

                      <button type="submit" class="btn btn-primary fr" @click="goCourseAdd()">등록
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th class="w-10px"><input type="checkbox" v-model="checkall" @change="setCheckAll"></th>
                        <th class="w-10per">No</th>
                        <th class="w-10per">강좌번호</th>
                        <th class="w-10per">카테고리</th>
                        <th class="w-40per">강좌타이틀</th>
                        <th class="w-10per">강의수</th>
                        <th class="w-10per">사용여부</th>
                        <th class="w-10per">등록일</th>
                        <th class="w-10per">등록자</th>
                      </tr>
                      <tr v-for="(item,index) in courses" :key="item.idx">
                        <td><input type="checkbox" v-model="item.check"></td>
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>C{{ item.idx }}</td>
                        <td>{{ item.categoryName }}</td>
                        <td><a :href="'/aicoursemodify/' + item.idx">{{ item.subject }}</a></td>
                        <td>{{ item.lecCnt }}</td>
                        <td>{{ item.isUse }}</td>
                        <td>{{ item.createDate }}</td>
                        <td>
                          <span v-if="item.emp != null">
                          {{  (item.emp.englishname != null ) ? item.emp.englishname :'' }}({{  (item.emp.koreanname != null ) ? item.emp.koreanname :'' }})
                          </span>
                        </td>
                      </tr>
                      <tr v-if="courses.length === 0">
                        <td colspan="8">검색 결과가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <button class="btn btn-danger float-left mh-100 my-2" @click="deleteCheckList()">삭제</button>
                    <div v-if="courses.length !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import LectureList from "../../components/pop/pop_AiCategorySearch.vue";
import Paginate from 'vuejs-paginate';

export default {
  name: 'aiBanner',
  components: {
    Menu,
    LectureList,
    Paginate,
  },
  props: {
    msg: String
  },
  data: () => ({
    courses: [],
    statistics: [],
    filter: {
      page: 1,
      type: '',
      isUse: '',
      categoryIdx:-999,
      searchTitle:""
    },
    is_preview: false,
    lastpage: 1,
    banner_idx: 0,
    banner_img: "",
    bannerType: '',
    checkall:false,
    registers:[],
    categoryList:[],
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    goCourseAdd: function () {
      this.$router.push("AiCourseAdd")
    },
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
      this.getCategoryList();
    },
    getBanners: function (page) {
      if (page !== undefined) {
        this.filter.page = page;
      }
      const url = this.$store.state.host + "/v1/aitutor/course";
      var params = {
        isUse: (this.filter.isUse === '' ? null : this.filter.isUse),
        page: (this.filter.page === '' ? null : this.filter.page),
        categoryIdx: (this.filter.categoryIdx === '-999' ? null : this.filter.categoryIdx),
        searchTitle: (this.filter.searchTitle === '' ? null : this.filter.searchTitle)
      };

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.result = res.data.result;
          this.courses = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
          for(let i=0;i<this.courses.length;i++) {
            this.courses[i].check = this.checkall;
          }
        } else {
          console.log('검색실패', res)
          this.courses = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    getBannerRegisters:function(){
      const url = this.$store.state.host + "/v1/aitutor/banner/registers";
      this.axios.get(url, {
      }).then((res) => {
        if (res.data.status === 0) {
          this.registers = res.data.result;
        } else {
          console.log('검색실패', res)
          this.banners = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    getCategoryList: function () {
      const url = this.$store.state.host + "/v1/aitutor/category";
      var params = {};

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.categoryList = res.data.result.value;
        } else {
          this.categoryList = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    getStatistics: function () {
      const url = this.$store.state.host + "/v1/aitutor/course/statistics-category";
      var params = {};

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.statistics = res.data.result;
          console.log(this.statistics);
        } else {
          console.log('통계 검색실패', res)
          this.statistics = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    putBanner: function (idx) {
      const url = this.$store.state.host + "/v1/admin/course/" + idx;

      this.axios.put(url, this.banner)
          .then(function (res) {
            var data = res.data.result;
            console.log(data);
          })
          .catch(error =>{ alert(`network error = ${error}`)});
    },

    deleteBanner: function (idx) {
      const url = this.$store.state.host + "/v1/admin/course/" + idx;

      this.axios.delete(url)
          .then(function (res) {
            var data = res.data.result;
            console.log(data);
          })
          .catch(error =>{ alert(`network error = ${error}`)});
    },

    setCheckAll : function() {
      console.log(this.courses);
      for(let i=0;i<this.courses.length;i++) {
        this.courses[i].check = this.checkall;
      }
    },
    deleteCheckList :function () {

      let list = [];
      for(let i=0;i<this.courses.length;i++) {
        if(this.courses[i].check == true) {
          list.push(this.courses[i].idx);
        }
      }

      if(list.length==0){
        alert("선택된리스트가 없습니다")
        return;
      }
      console.log(list);

      const url = this.$store.state.host + "/v1/aitutor/course/delete";
      if(confirm('선택한 '+list.length+'개 리스트를 삭제 하시겠습니까?')){
        this.axios.post(url,list
        ).then((res) => {
          if (res.data.status === 0) {
            alert('선택한 '+list.length+'개 리스트가 삭제되었습니다.');
            this.getBanners();
          } else {
            alert(res.data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }


    },
    initPage: function () {
      this.getBanners();
      this.getStatistics();
      this.getBannerRegisters();
      this.getCategoryList();
    },
    getCoursesExcel: function () {
      var isUseTxt="";
      if(this.filter.isUse !==""){
        isUseTxt="&isUse="+this.filter.isUse;
      }

      location.href=this.$store.state.host + "/v1/aitutor/course/excel-down?searchTitle="+ this.filter.searchTitle+"&categoryIdx="+ this.filter.categoryIdx+isUseTxt;
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
