<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">버전 관리</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: auto;">
                    <div class="clear mb-2">
                      <div class="form-group float-left clear w-100per">
                        <button type="submit" class="btn btn-primary fr ml-10"
                                @click="insertColumn()">추가
                        </button>
                      </div>
                    </div>
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>플랫폼</th>
                        <th>버전</th>
                        <th>지원시작</th>
                        <th>지원종료</th>
                        <th>수정</th>
                      </tr>
                      <tr v-for="item in this.versions" :key="item.idx">
                        <td v-show="!item.editShow">{{ item.platform }}</td>
                        <td v-show="!item.editShow">{{ item.version }}</td>
                        <td v-show="!item.editShow">{{ item.sdate }}</td>
                        <td v-show="!item.editShow">{{ item.edate }}</td>
                        <td v-show="!item.editShow"><button @click="changeEditMode(item)">수정</button></td>
                        <td v-show="item.editShow">
                          <select v-model="item.platform">
                            <option value="ANDROID">ANDROID</option>
                            <option value="IOS">IOS</option>
                          </select>
                        </td>
                        <td v-show="item.editShow"><input type="text" v-model="item.version"/></td>
                        <td v-show="item.editShow"><input type="text" v-model="item.sdate"/></td>
                        <td v-show="item.editShow"><input type="text" v-model="item.edate"/></td>
                        <td v-show="item.editShow"><button @click="updateEditMode(item)">완료</button></td>
                      </tr>
                      <tr v-if="lastpage === 0">
                        <td colspan="4">버전 정보가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div v-if="this.lastpage !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'version',
  components: {
    Menu,
    Paginate
  },
  props: {
    msg: String
  },
  data: () => ({
    lastpage: 1,
    versions: [],
    filter: {
      page: 1
    }
  }),
  mounted() {
    this.getVersions(1)
  },
  methods: {
    insertColumn: function (item) {
      const url = this.$store.state.host + "/v1/admin/versions/bulk";
      this.axios.post(url, item)
      .then((res) => {
        if(res.data.status === 0){
          this.getVersions(this.filter.page)
        }
      });
    },
    changeEditMode: function (item) {
      item.editShow = !item.editShow
      this.$forceUpdate();
    },
    updateEditMode: function (item) {
      const url = this.$store.state.host + "/v1/admin/versions/" + item.idx;
      this.axios.put(url, item)
      .then((res) => {
        if (res.data.status !== 0) {
          alert("에러가 발생했습니다 " + res.data.result)
        } else {
          this.changeEditMode(item)
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    clickCallback: function (pageNum) {
      this.filter.page = pageNum;
      this.getVersions(pageNum);
    },
    getVersions: function (page) {
      const url = this.$store.state.host + "/v1/admin/versions";
      const params = {
        page: (page === '' ? null : page)
      };

      this.axios.get(url, {params})
      .then((res) => {
        if (res.data.status === 105) {
          this.versions = [];
          this.lastpage = 0;
        } else {
          console.log(res)
          this.result = res.data.result;
          this.versions = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
