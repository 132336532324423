<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">발음 컨텐츠 상세</h3>
              <p class="lead">
                - 특수문자 중
                <span class="code">
                  <b class="apostrophe">
                    ’
                  </b>
                </span>
                은 허용하지 않습니다.<br />
              </p>
            </div>
          </div>

          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th><span class="red">*</span>&nbsp;발음</th>
                          <td class="text-left">
                            <select class="form-control fl w-10per" ref="pSelectRef" v-model="curPronunciation">
                              <option value="">선택</option>
                              <option v-for="item in categoryPronunciations" :key="item.idx" :value="item.idx">{{ item.category }}</option>
                            </select>
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;사용여부</th>
                          <td class="text-left useStatus">
                            <label class="mr-10"><input type="radio" name="useStatus" value="Y" v-model="curUseStatus" />Y</label>
                            <label><input type="radio" name="useStatus" value="N" v-model="curUseStatus" />N</label>
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;문장 1</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="curSentence1" />
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;문장 2</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="curSentence2" />
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;문장 3</th>
                          <td>
                            <input type="text" class="form-control w-100per" v-model="curSentence3" />
                          </td>
                        </tr>

                        <tr>
                          <th><span class="red">*</span>&nbsp;발송일</th>
                          <td>
                            <input type="date" class="form-control dateInput" id="session-date" name="session-date" v-model="curSendDate" />
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="clear">
            <button @click="$router.push('/ds')" type="submit" class="btn btn-primary fl">
              목록
            </button>
            <button @click="saveDetailContent()" type="submit" class="btn btn-primary fr ml-1">
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "../../components/commons/IncMenu.vue";
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";

export default {
  mixins: [DailySpeakingComponent],
  components: {
    Menu,
  },
  data() {
    return {
      curPronunciation: "",
      curUseStatus: "Y",
      curSentence1: "",
      curSentence2: "",
      curSentence3: "",
      curSendDate: "",
      curIdx: -1,
    };
  },

  methods: {
    saveDetailContent() {
      const specialRule = /[’]/gi;

      // 문장1, 문장2, 문장3
      if (specialRule.test(this.curSentence1) || specialRule.test(this.curSentence2) || specialRule.test(this.curSentence3)) {
        alert("허용되지 않는 특수문자가 존재힙나디.");
        return false;
      }

      if (!this.curPronunciation || !this.curUseStatus || !this.curSentence1 || !this.curSentence2 || !this.curSentence3 || !this.curSendDate) {
        alert("입력되지 않은 정보가 존재합니다");
        return;
      }

      const params = {
        idxCategoryPronunciation: this.curPronunciation,
        sendDate: this.curSendDate,
        sentence1: encodeURI(this.curSentence1),
        sentence2: encodeURI(this.curSentence2),
        sentence3: encodeURI(this.curSentence3),
        useStatus: this.curUseStatus,
        idx: this.curIdx,
      };
      console.log(params);
      this.insertPronunciationDetail(params);
    },
  },

  mounted() {
    //스토어에 저장된 아이템 정보가 있는 경우
    if (Object.keys(this.$store.state.dsState.itemInfo).length > 0) {
      const item = this.$store.state.dsState.itemInfo;
      this.curPronunciation = item.idxCategoryPronunciation;
      this.curUseStatus = item.useStatus;
      this.curSendDate = item.sendDate;
      this.curSentence1 = item.sentence1;
      this.curSentence2 = item.sentence2;
      this.curSentence3 = item.sentence3;
      this.curIdx = item.idx;

      //select 태그 selected된 option 지정
      this.$refs.pSelectRef.innerHTML = `
        <option value="${item.idxCategoryPronunciation}" selected>${item.category}</option>
      `;
    } else {
      this.getCategoryPronunciationList();
    }
  },
};
</script>

<style lang="scss" scoped>
.useStatus {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;

    &,
    * {
      cursor: pointer;
    }
  }
}

.code {
  font-size: 20px;
  line-height: 20px;
  display: inline-block;
  margin: auto 0;
  color: red;
  height: 20px;
}

.apostrophe {
  width: 5px;
  height: 5px;
}
</style>
