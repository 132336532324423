<script>
export default {
  name: "dailySpeakingComponent",
  data() {
    return {
      member: {
        mobile: "",
      },
      curTopic:'composition',
      pushes: {},
      subscribes: [],
      members: [],
      count: {},
      categoryPronunciations:null,
      compositionCategories:null,
      filter: {
        page: 1,
        selectedComposition:null,
        selectedPronunciation:null,
        useStatus:null,
        sendDate:null,
      },
      sRewardsFilter: {
        page: 1,
        kname: null,
        company: null,
        deliveryStatus:'N',
        sdate:null,
        edate:null,
        mobile: null,
      },
      categoryPronunciationPage:1,
      compositionCategoryPage:1,
      memberFilter: {
        name: "",
        email: "",
        companyname: "",
        mobile: "",
        division: "",
        membership: "",
        page: 1,
      },
      overviewFilter: {
        sdate: "",
        edate: "",
        kname: "",
        company: "",
        mobile: "",
        page: 1,
      },
      sLastpage: 1,
      dsContent: {
        idx: "",
        ndate: "",
        ctype: "1",
        companyCode: "1",
        company: "",
        q1Kor: "",
        q1Eng: "",
        q1Keyword: "",
        q1Itm1Kor: "",
        q1Itm1Eng: "",
        q1Itm1Mp3: "",
        q1Itm2Kor: "",
        q1Itm2Eng: "",
        q1Itm2Mp3: "",
        q2Txt: "",
        q2Itm1: "",
        q2Itm2: "",
        q2Itm3: "",
        q2Itm4: "",
        q3Kor: "",
        q3Eng: "",
        q3Itm1Kor: "",
        q3Itm1Eng: "",
        q3Itm1Mp3: "",
        q3Itm2Kor: "",
        q3Itm2Eng: "",
        q3Itm2Mp3: "",
        q3Itm3Kor: "",
        q3Itm3Eng: "",
        q3Itm3Mp3: "",
        q4Eng: "",
        q4Ans: "",
        isUse: "",
        difficulty: "A",
      },
      dsRewards: [],
      dsOverView: [],
      
    };
  },
  methods: {
    //입영작 탭 전환 처리
    changeToComposition(){
          this.curTopic='composition';
          this.clearFilter();
          this.$store.commit('resetQuery')
          this.getDailySpeakingComposition(this.filter);
          this.getCompositionCategoryList();
      },

    //발음 탭 전환 처리
    changeToPronunciation(){
          this.curTopic='pronunciation'
          this.clearFilter();
          this.$store.commit('resetQuery')
          this.getDailySpeakingPronunciation(this.filter);
          this.getCategoryPronunciationList();
      },

    //상세 콘텐츠 등록 페이지 이동
    moveToDetailPage(){
      if (this.curTopic==='pronunciation'){
        this.$router.push('/dspadd')
      }else {
        this.$router.push('/dscadd')
      }
    },

    clearFilter(){
      this.filter= {
        page: 1,
        selectedComposition:null,
        selectedPronunciation:null,
        useStatus:null,
        sendDate:null,
      }
      this.$store.state.dsState.dsQuery={};
    },

    //검색 쿼리 스토어 저장
    saveFormQuery(){
      const query={
        ...this.filter,
        curTopic:this.curTopic,
        selectedPronunciation:this.filter.selectedPronunciation,
        selectedComposition:this.filter.selectedComposition,
        useStatus:this.filter.useStatus,
        sendDate:this.filter.sendDate,
      }
      this.$store.commit('saveQuery',query)
    },

    //아이템 정보 스토어 저장
    saveItemInfo(item){
      this.$store.commit('saveItemInfo',item)
    },

    //전체 체크박스 체크
    checkCheckbox() {
      let checkReward = document.querySelectorAll(".check-rewards");
      let checkNum = 0;
      for (const element of checkReward) {
        if (element.checked) {
          checkNum++;
        }
      }
      document.querySelector("#all-select").checked =
        checkNum === checkReward.length;
    },
    allSelectDailySpeakingRewards() {
      let checked = document.querySelector("#all-select").checked;
      for (const element of document.querySelectorAll(".check-rewards")) {
        element.checked = checked;
      }
    },

    //데일리 스피킹 리워드 발송상태 갱신
    updateDailySpeakingRewardsStatus() {
      let idxRewards = [];

      let checkReward = document.querySelectorAll(".check-rewards");
      for (const element of checkReward) {
        if (element.checked) {
          idxRewards.push(element.value);
        }
      }

      if (idxRewards.length > 0) {
        const params = this.getParams({
          idxRewards: idxRewards,
        });
        const url =
          this.$store.state.host + "/v1/admin/ds/updateDeliveryDsRewards";
        this.axios.put(url, params).then((res) => {
          if (res.data.status === 0) {
            this.getDailySpeakingRewards();
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      } else {
        alert("리워드 대상자를 선택해주세요.");
      }
    },

    //데일리 스피킹 리워드 엑셀 다운로드
    downloadDailySpeakingRewardsStatus() {
      const tmp={
        deliveryStatus:this.sRewardsFilter.deliveryStatus,
        companyName:this.sRewardsFilter.company,
        kname:this.sRewardsFilter.kname,
        sdate:this.sRewardsFilter.sdate,
        edate:this.sRewardsFilter.edate,
        mobile:this.sRewardsFilter.mobile,
        page:this.sRewardsFilter.page,
      }
      const params = this.getParams(tmp);
      console.log(params)
      const searchParams = new URLSearchParams(params);
      let rewardsUrl = "/v1/admin/excel/rewards/ds/excel?" + searchParams.toString();

      location.href = this.$store.state.host + rewardsUrl;
    },

    //데일리 스피킹 리워드 조회
    getDailySpeakingRewards() {
      const params = this.getParams(this.sRewardsFilter);
      const url = this.$store.state.host + "/v1/admin/ds/speakingRewards";
      this.axios.get(url, { params }).then((res) => {
        if (res.data.status === 105) {
          this.dsRewards = [];
          this.sLastpage = 0;
        } else {
          this.result = res.data.result;
          this.dsRewards = res.data.result;
          this.sLastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getDailySpeakingOverView(pageNum) {
      const params = this.getParams(this.overviewFilter);
      params["page"] = pageNum;
      console.log(params);
      const url = this.$store.state.host + "/v1/admin/ds/attends";
      this.axios.get(url, { params }).then((res) => {
        if (res.data.status === 105) {
          this.dsOverView = [];
          this.lastpage = 0;
        } else {
          this.dsOverView = res.data.result.value;
          this.result = res.data.result;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getOverViewExcel() {
      // const params = this.getParams(this.overviewFilter);
      // console.log(params);
      // const url = this.$store.state.host + "/v1/admin/excel/attends";
      // this.axios.get(url,{params}).then((res)=>{
      //   console.log(res);

      // });
      // console.log("aa");
      console.log(
        this.$store.state.host +
          "/v1/admin/excel/ds/attends?kname=" +
          this.overviewFilter.kname +
          "&sdate=" +
          this.overviewFilter.sdate +
          "&edate=" +
          this.overviewFilter.edate +
          "&mobile=" +
          this.overviewFilter.mobile +
          "&company=" +
          this.overviewFilter.company
      );

      location.href =
        this.$store.state.host +
        "/v1/admin/excel/ds/attends?kname=" +
        this.overviewFilter.kname +
        "&sdate=" +
        this.overviewFilter.sdate +
        "&edate=" +
        this.overviewFilter.edate +
        "&mobile=" +
        this.overviewFilter.mobile +
        "&company=" +
        this.overviewFilter.company;
    },

    getOverViewDateExcel() {
      location.href =
        this.$store.state.host +
        "/v1/admin/excel/ds/attends-date?kname=" +
        this.overviewFilter.kname +
        "&sdate=" +
        this.overviewFilter.sdate +
        "&edate=" +
        this.overviewFilter.edate +
        "&mobile=" +
        this.overviewFilter.mobile +
        "&company=" +
        this.overviewFilter.company;
    },

    //발음 목록 조회
    getCategoryPronunciationList() {
      
      const url = this.$store.state.host + "/v1/admin/ds/categoryPronunciation/list";
      this.axios.get(url, {page:this.categoryPronunciationPage}).then((res) => {
        console.log(res.data);
        // fileName = .split('/')
        
        if (res.data.status === 0) {
          this.categoryPronunciations = res.data.result.value;
          //this.categoryPronunciationSet=new Set(Array.from(res.data.result.value,item=>item.category))
        } 
      })
      .catch(err=>{
        console.log("err : "+err);
        alert(`network error = ${err}`);
        this.categoryPronunciations=[];
      });
    },

    //발음 컨텐츠 조회
    getDailySpeakingPronunciation(filter) {
      const params = {
        idxCategoryPronunciation:filter.selectedPronunciation,
        sendDate:filter.sendDate,
        useStatus:filter.useStatus,
        page:filter.page,
      };

      const url = this.$store.state.host + `/v1/admin/ds/pronunciation/list/-1`;
      this.axios.get(url, { params }).then((res) => {
        if (res.data.status === 105) {
          this.dss = [];
          this.sLastpage = 0;
        } else {
          this.result = res.data.result;
          this.dss = res.data.result.value;
        
          this.sLastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    //입영작 리스트 조회
    getDailySpeakingComposition(filter) {
      console.log(`filter = ${JSON.stringify(filter)} `);
      const params = {
        idxCategoryTranslation:filter.selectedComposition,
        sendDate:filter.sendDate,
        useStatus:filter.useStatus,
        page:filter.page
      };
      console.log(filter.page);
      const url = this.$store.state.host + `/v1/admin/ds/translation/list/-1`;
      this.axios.get(url, { params }).then((res) => {
        if (res.data.status === 105) {
          this.dss = [];
          this.sLastpage = 0;
        } else {
          this.result = res.data.result;
          this.dss = res.data.result.value;
          this.sLastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getCompositionCategoryList(){
      const url = this.$store.state.host + "/v1/admin/ds/categoryTranslation/list";

      this.axios.get(url,{page:this.compositionCategoryPage})
      .then((res)=>{
        if (res.data.status===0){
          this.compositionCategories=res.data.result.value
        }
      })
      .catch(err=>{
        alert(`network error = ${err}`);
        console.log("err : "+err);
        this.compositionCategories=[];
      })
    },

    //새 발음 등록  
    insertCategoryPronunciation(){
      //자동 넘버링때문에  (insert,update)시 넘버링 부여 
      let Contents1 = this.isNull(this.guideText[0].guideStr) ? '' : `1.${this.guideText[0].guideStr}`;
      let Contents2 = this.isNull(this.guideText[1].guideStr) ? '' : `2.${this.guideText[1].guideStr}`;
      let Contents3 = this.isNull(this.guideText[2].guideStr) ? '' : `3.${this.guideText[2].guideStr}`;
      let Contents4 = this.isNull(this.guideText[3].guideStr) ? '' : `4.${this.guideText[3].guideStr}`;
      let Contents5 = this.isNull(this.guideText[4].guideStr) ? '' : `5.${this.guideText[4].guideStr}`;

      const url = this.$store.state.host + "/v1/admin/ds/categoryPronunciation/add?" + 'category=' + this.categoryP.trim() 
      + "&contents1=" + Contents1.trim() 
      + "&contents2=" + Contents2.trim() 
      + "&contents3=" + Contents3.trim() 
      + "&contents4=" + Contents4.trim() 
      + "&contents5=" + Contents5.trim() 

      this.axios.post(url, this.formData ,{headers: {'Content-Type': 'multipart/form-data'}})
      .then((res)=>{
        if (res.data.status===0){
          alert("발음 등록이 완료되었습니다.")
          this.resetData();
          this.clearCategoryPronunciationInputs();
          this.getCategoryPronunciationList();
        }
      })
      .catch((err)=>{
        alert(`network error = ${err}`);
        console.log("err : " + err);
      })
    },

    //발음 수정
    updateCategoryPronunciation(idx){
      //updateInit() 에서 부여된 넘버링 제거해서 v-model 에 바인딩 
      let Contents1 = this.isNull(this.guideText[0].guideStr) ? '' : `1.${this.guideText[0].guideStr}`;
      let Contents2 = this.isNull(this.guideText[1].guideStr) ? '' : `2.${this.guideText[1].guideStr}`;
      let Contents3 = this.isNull(this.guideText[2].guideStr) ? '' : `3.${this.guideText[2].guideStr}`;
      let Contents4 = this.isNull(this.guideText[3].guideStr) ? '' : `4.${this.guideText[3].guideStr}`;
      let Contents5 = this.isNull(this.guideText[4].guideStr) ? '' : `5.${this.guideText[4].guideStr}`;

      let params = new FormData();
      const url = this.$store.state.host + `/v1/admin/ds/categoryPronunciatio/modify/${idx}?category= ${this.categoryP.trim()}`
      + "&contents1=" + Contents1
      + "&contents2=" + Contents2
      + "&contents3=" + Contents3
      + "&contents4=" + Contents4
      + "&contents5=" + Contents5
      
        let file = this.$refs.categoryImage.files[0];
        console.log(` ${idx}}`)
        if(this.$refs.categoryImage.files.length == 1) {// 추가 파일업로드를 한경우 
            params.append("categoryImg", file);
        }
      
      this.axios.put(url, params ,{headers: {'Content-Type': 'multipart/form-data'}})
      .then((res)=>{
        console.log(res);
        alert('수정이 완료되었습니다.')
        this.resetData();
        this.getCategoryPronunciationList();
      })
      .catch((err)=>{
        alert(`network error = ${err}`);
        console.log('err : '+err);
      });
    },    

    //새 발음 상세 컨텐츠 등록 or 수정
    insertPronunciationDetail(params){
      const url = this.$store.state.host + "/v1/admin/ds/pronunciation?" + `idx=${params.idx}` + "&idxCategoryPronunciation=" + params.idxCategoryPronunciation
      + "&sendDate=" + params.sendDate + "&sentence1=" + params.sentence1 + "&sentence2=" + params.sentence2 + "&sentence3=" + params.sentence3 + "&useStatus=" + params.useStatus;
      
      this.axios.post(url)
      .then(res=>{
        if (res.data.status===0){
          alert(res.data.result)
          this.$router.push('/ds');
        }else if(res.data.status===150){
          alert(res.data.result)
        }else {
          alert('관리자에게 문의해주세요.')
        }
      })
      .catch((err)=>{
        alert(`network error = ${err}`);
        console.log('err : '+err);
      })
    },

    //발음 삭제 
    deleteCategoryPronunciation(idx){
      if(!confirm('발음을 삭제 하시겠습니까?')){
        return;
      }

      const url=this.$store.state.host + `/v1/admin/ds/categoryPronunciation/delete/${idx}`;
      const params={
        idx:idx
      }

      this.axios.delete(url,params)
      .then((res)=>{
        if (res.data.status===0) {
          alert(res.data.result);
          this.getCategoryPronunciationList();
        }
      }).catch(err=>{
        alert(`network error = ${err}`);
        console.log("err : " + err);
      })

    },

    //입영작 카테고리 추가
    insertCompositionCategory(){
      const url=this.$store.state.host + `/v1/admin/ds/categoryTranslation/add?categoryName=${this.newCompositionCategory}`;

      this.axios.post(url)
      .then(res=>{
        if (res.data.status===0){
          alert("입영작 카테고리 등록이 완료되었습니다");
          this.clearCompositionCategoryInput();
          this.getCompositionCategoryList();
        }
      })
      .catch(err=>{
        alert(`network error = ${err}`);
        console.log("err : "+err)
      })
    },

    //새 입영작 카테고리 컨텐츠 등록 or 수정
    insertCompositionDetail(params){
      const url = 
      this.$store.state.host + "/v1/admin/ds/translation?"+ 
      "engAnswer1=" + params.engAnswer1 + 
      "&engAnswer2=" + params.engAnswer2 + 
      "&engAnswer3=" + params.engAnswer3 + 
      "&idx=" + params.idx + 
      "&idxCategoryTranslation=" + params.idxCategoryTranslation + 
      "&imgUrl=" + params.imgUrl +
      "&korQuestion1=" + params.korQuestion1 + 
      "&korQuestion2=" + params.korQuestion2 + 
      "&korQuestion3=" + params.korQuestion3 + 
      "&question1EngKeyword1=" + params.question1EngKeyword1 + 
      "&question1EngKeyword2=" + params.question1EngKeyword2 + 
      "&question1KorKeyword1=" + params.question1KorKeyword1 + 
      "&question1KorKeyword2=" + params.question1KorKeyword2 + 
      "&question2EngKeyword1=" + params.question2EngKeyword1 + 
      "&question2EngKeyword2=" + params.question2EngKeyword2 + 
      "&question2KorKeyword1=" + params.question2KorKeyword1 + 
      "&question2KorKeyword2=" + params.question2KorKeyword2  + 
      "&question3EngKeyword1=" + params.question3EngKeyword1 + 
      "&question3EngKeyword2=" + params.question3EngKeyword2 + 
      "&question3KorKeyword1=" + params.question3KorKeyword1 + 
      "&question3KorKeyword2=" + params.question3KorKeyword2 + 
      "&sendDate=" + params.sendDate + 
      "&useStatus=" + params.useStatus;
      
      this.axios.post(url, params.categoryImg, {headers: {'Content-Type': 'multipart/form-data'}})
      .then(res=>{
        if (res.data.status === 0){
            alert('입영작 카테고리 등록이 완료되었습니다');
            this.$router.push('/ds');
        }
        else if (res.data.status === 104){
          alert(res.data.result);
        }
      })
      .catch(err=>{
        alert(`network error = ${err}`);
        console.log("err : "+err);
      })
    },
    //새 입영작 카테고리 컨텐츠 등록 or 수정
    insertCompositionDetailExcel(formData){
      const url = 
      this.$store.state.host + "/v1/admin/ds/contentsTranslation-insert/excel" ;
      this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then(res=>{
        if(res.data.success == "false"){
          alert('일괄업로드가 실패하였습니다.\n 발송날짜 중복이 있는지 확인해주세요.');
        }
        if(res.data.success == "success"){
          alert('일괄업로드가 완료되었습니다.');
        }        
              this.FileUpload= '';
      })
      .catch(err=>{
        alert(`network error = ${err}`);
        console.log("err : "+err);
      })
    },

    
    deleteCompositionCategory(idx){
      if(!confirm('카테고리를 삭제 하시겠습니까?')){
        return;
      }

      const url=this.$store.state.host + `/v1/admin/ds/categoryTranslation/delete/${idx}`;
      const params={
        idx:idx
      }

      this.axios.delete(url,params)
      .then((res)=>{
        if (res.data.status===0) {
          alert(res.data.result);
          this.getCompositionCategoryList();
        }
      }).catch(err=>{
        alert(`network error = ${err}`);
        console.log("err : " + err);
      })

    },

    updateContent() {
      const url = this.$store.state.host + "/v1/admin/dw";
      this.axios
        .put(url, this.dsContent)
        .then((res) => {
          if (res.data.status === 0) {
            alert("데일리 스피킹 컨텐츠 업데이트가 완료되었습니다.");
            this.$router.push("/ds");
          }
        })
        .catch((err) => {
          alert(`network error = ${err}`);
          console.log("err : " + err);
        });
    },
    selectContentOne(idx) {
      const url = this.$store.state.host + "/v1/admin/ds/" + idx;
      this.axios
        .get(url, {})
        .then((res) => {
          this.dsContent = res.data.result;
        })
        .catch((err) => {
          alert(`network error = ${err}`);
          console.log("err : " + err);
        });
    },


    
    addCompany(companyInfo) {
      this.dsContent.company = companyInfo.companyname;
      this.dsContent.companyCode = companyInfo.regid;
    },
  },
};
</script>
