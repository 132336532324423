<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <SeriesAdd :visible="seriesVisible" @close="vue_close"></SeriesAdd>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">Biz 카드뉴스 관리</h3>
              <p class="lead">
                - Biz 카드뉴스 컨텐츠 관리 페이지 입니다.<br>
              </p>
            </div>
          </div>
          <div class="row">

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">

                      <button type="submit" class="btn btn-primary fr ml-10"
                              @click="$router.push('/bizcardadd')">등록
                      </button>
                      <button type="submit" class="btn btn-primary fr" @click="seriesAdd()">카테고리 등록
                      </button>
                    </div>
                    <!-- <div class="float-right">
                    <button type="button" class="btn btn-primary btn-icon-text" @click="excelDown()">
                        <i class="btn-icon-prepend" data-feather="file-text"></i>
                        Excel Download
                    </button>
                </div> -->
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%">No</th>
                        <th >제목</th>
                        <th style="width: 5%">사용여부</th>
                        <th style="width: 5%">HQ매니저</th>
                        <th style="width: 5%">등록일</th>
                      </tr>
                      <tr v-for="(item, index) in bizcards" :key="item.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td><a :href="'/bizcardmodify/' + item.idx">{{ item.title }}</a></td>
                        <td>{{ (item.isUse === 'Y') ? '사용' : '사용안함' }}</td>
                        <td>{{ item.emp.englishname }}({{ item.emp.koreanname }})</td>
                        <td>{{ item.createDate }}</td>
                      </tr>
                      <tr v-if="bizcards.length === 0">
                        <td colspan="6">검색하신 카드뉴스 정보가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <div v-if="bizcards.length !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import SeriesAdd from '../../components/pop/pop_seriesAdd.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'bizcard',
  components: {
    Menu,
    SeriesAdd,
    Paginate
  },
  props: {
    msg: String
  },
  data: () => ({
    seriesVisible: false,
    bizcards: [],
    bizcardImageLimit: 0,
    lastpage: 1,
    filter: {
      page : 1
    }
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    getBizcards: function (page) {
      if(page !== undefined) this.filter.page = page;
      const url = this.$store.state.host + "/v1/admin/bizcards";
      var params = {
        page: this.filter.page
      };

      this.axios.get(url, {params})
      .then((res) => {

        if (res.data.status === 0){
          this.result = res.data.result;
          this.bizcards = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }else {
          this.bizcards = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    seriesAdd: function () {
      this.seriesVisible = true;
    },

    vue_close: function (state) {
      this.seriesVisible = state;
    },

    initPage: function () {
      this.getBizcards();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
