<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>

      <FreeLectureList :visible="is_preview" @close="vue_close"  ></FreeLectureList>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">AI튜터 무료강의 관리</h3>
              <p class="lead">
                - 프론트 페이지에 제공할 무료강의를 설정하는 곳입니다.
              </p>
            </div>
          </div>
          <div class="row">

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <select @change="getBanners()" v-model="filter.useStatus"
                              class="form-control fl w-15per">
                        <option value="">무료강의 사용현황</option>
                        <option value="사용예정">사용예정</option>
                        <option value="사용완료">사용완료</option>
                      </select>
                      <input  class="form-control fl ml-2 w-15per" v-model="filter.startDate" type="date" placeholder="yyyy-mm-dd"/>
                      <span  class="fl ml-1 mr-1">~</span>
                      <input  class="form-control fl w-15per" v-model="filter.endDate" type="date" placeholder="yyyy-mm-dd"/>
                      <button type="submit" class="btn btn-primary ml-2 fl" @click="getBanners()">검색</button>
                      <button type="submit" class="btn btn-primary fr"  @click="showPreview()">무료강의 등록 하기</button>
                    </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th class="w-10px"><input type="checkbox" v-model="checkall" @change="setCheckAll"></th>
                        <th class="w-20px">번호</th>
                        <th class="w-30per">강좌타이틀</th>
                        <th class="w-30per">강의타이틀</th>
                        <th class="w-10per">무료강의 사용일</th>
                        <th class="w-10per">무료강의 사용현황</th>
                        <th class="w-10per">등록일</th>
                        <th class="w-10per">등록자</th>
                      </tr>
                      <tr v-for="(item, index) in banners" :key="item.idx">
                        <td><input type="checkbox" v-model="item.check"></td>
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>
                            <span v-if="item.lecture != null && item.lecture.course != null">
                           {{ item.lecture.course.subject }}
                          </span>
                        </td>
                        <td>
                          <span v-if="item.lecture != null">
                            <a :href="'/ailecturemodify/' + item.lectureIdx" target="_blank">{{ item.lecture.title }}</a>
                          </span></td>
                        <td>{{ item.useDate }}</td>
                        <td>{{ item.useStatus }}</td>
                        <td>{{ item.createDate }}</td>
                        <td>
                          <span v-if="item.emp != null">
                          {{  (item.emp.englishname != null ) ? item.emp.englishname :'' }}({{  (item.emp.koreanname != null ) ? item.emp.koreanname :'' }})
                          </span>
                        </td>
                      </tr>
                      <tr v-if="banners.length === 0">
                        <td colspan="9">검색한 결과가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <button class="btn btn-danger float-left mh-100 my-2" @click="deleteCheckList()">삭제</button>
                    <div v-if="banners.length !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

import FreeLectureList from "../../components/pop/pop_AiFreeLectureList.vue";

export default {
  name: 'aiBanner',
  components: {
    Menu,
    FreeLectureList,
    Paginate,
  },
  props: {
    msg: String
  },
  data: () => ({
    banners: [],
    filter: {
      page: 1,
      useStatus: '',
      startDate: '',
      endDate: ''
    },
    is_preview: false,
    lastpage: 1,
    banner_idx: 0,
    banner_img: "",
    bannerType: '',
    checkall:false,
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
      this.initPage();
    },
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    goBannerAdd: function () {
      this.$router.push("AiBannerAdd")
    },

    getBanners: function (page) {
      if (page !== undefined) {
        this.filter.page = page;
      }
      const url = this.$store.state.host + "/v1/aitutor/lecture-free";
      var params = {
        useStatus: (this.filter.useStatus === '' ? null : this.filter.useStatus),
        startDate: (this.filter.startDate === '' ? null : this.filter.startDate),
        endDate: (this.filter.endDate === '' ? null : this.filter.endDate)
      };

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.result = res.data.result;
          this.banners = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
          for(let i=0;i<this.banners.length;i++) {
            this.banners[i].check = this.checkall;
          }
        } else {
          console.log('검색실패', res)
          this.banners = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    putBanner: function (idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;

      this.axios.put(url, this.banner)
          .then(function (res) {
            var data = res.data.result;
            console.log(data);
          })
        .catch(error =>{ alert(`network error = ${error}`)});
    },

    deleteBanner: function (idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;

      this.axios.delete(url)
          .then(function (res) {
            var data = res.data.result;
            console.log(data);
          })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    selectBannerImage: function () {
      this.bannerImage = this.$refs.bannerImage.files[0];
    },

    postBannerImage: function () {
      let formData = new FormData();
      formData.append('img', this.bannerImage);

      const url = this.$store.state.host + "/v1/admin/banners/image";

      this.axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then((res) => {
            if(res.data.status === 0){
              this.banner.img = res.data.result;
            }else{
              alert(res.data.result);
            }
          })
          .catch(error =>{ alert(`network error = ${error}`)});          
    },

    setCheckAll : function() {
      console.log(this.banners);
      for(let i=0;i<this.banners.length;i++) {
        this.banners[i].check = this.checkall;
      }
    },
    deleteCheckList :function () {

      let list = [];
      for(let i=0;i<this.banners.length;i++) {
        if(this.banners[i].check == true) {
          list.push(this.banners[i].idx);
        }
      }

      if(list.length==0){
        alert("선택된리스트가 없습니다")
        return;
      }
      console.log(list);

      const url = this.$store.state.host + "/v1/aitutor/lecture-free/delete";
      if(confirm('선택한 '+list.length+'개 리스트를 삭제 하시겠습니까?')){
        this.axios.post(url,list
        ).then((res) => {
          if (res.data.status === 0) {
            alert('선택한 '+list.length+'개 리스트가 삭제되었습니다.');
            this.getBanners();
          } else {
            alert(res.data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }


    },
    initPage: function () {
      this.getBanners();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
