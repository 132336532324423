import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import host from './host'
import dsState from './dsState';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    dsState
  },
  plugins:[createPersistedState({
    paths:['dsState.dsQuery', 'dsState.itemInfo'],
    storage:window.localStorage,
  })],
  state: {
    host: host,
  },
  mutations: {
  },
  actions: {
  },
})
