<template>
  <div id="main">
    <div id="body">
      <div class="detailMain">
        <h1 class="detailTitle">개인정보 처리방침</h1>
      </div>
      <div class="detailContent faqContent">
        <div class="wrap faqWrap">
          <ul class="terms"><li><h4>1. 수집하는 개인정보의 항목 및 수집방법</h4> <p>
            당근영어는 온라인 및 모바일 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.<br> <br>
            가. 수집하는 개인정보 항목<br>
            1) 최초 회원가입 시 회원식별 및 최적화된 서비스 제공을 위해 아래와 같은 정보를 수집합니다.<br>
            - 필수항목: 이름, 아이디, 비밀번호, 이메일, 휴대전화<br>
            - 선택항목: 영문이름, 주소, 일반전화<br>
            2) 서비스 이용과정이나 사업 처리과정에서 아래와 같은 정보들이 생성되어 수집될 수 있습니다.<br>
            - 웹사이트 이용 시: 서비스 이용기록, 접속로그, 쿠키, 접속IP정보, 결제기록<br>
            - 모바일 이용 시: 서비스 이용 기록, 접속 로그, 기기고유번호(디바이스 아이디 또는 IMEI)<br>
            3) 고객 상담 시 서비스 품질 향상을 위해 담당자 연결 후 통화내용이 녹음되어 아래와 같은 정보들을 수집합니다.<br>
            - 통화이력 및 녹음 파일<br><br>
            나. 수집방법<br>
            1) 홈페이지를 통한 회원가입, 서비스 이용 결제, 이벤트 응모, 전화, 팩스, 고객센터 문의 및 상담<br>
            2) 생성정보 수집 툴을 통한 수집
          </p></li> <li><h4>2. 개인정보의 수집 및 이용목적</h4> <p>
            당근영어는 수집한 개인정보를 다음의 목적을 위해 활용합니다.<br> <br>
            ο 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공, 구매 및 요금 결제, 본인인증, 물품배송 또는 청구지 등 발송<br><br>
            ο 회원 관리<br>
            회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지,<br>
            가입 의사 확인, 연령확인, 불만처리 등 민원처리, 고지사항 전달<br><br>
            ο 마케팅 및 광고에 활용<br>
            신규 서비스(제품) 개발 및 특화, 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재,<br>
            접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계<br><br>
            ο 기타<br>
            가입횟수 제한, 분쟁조정을 위한 기록보존, 회원의 각종 합격유무 확인 및 기타 통계자료 산출
          </p></li> <li><h4>3. 개인정보의 보유 및 이용기간</h4> <p>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.<br>
            파기절차 및 방법은 다음과 같습니다.<br> <br>
            ο 파기절차:회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후<br>
            별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한<br>
            정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다.<br>
            별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.<br>
            ο 파기방법:전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br>
            ο 보유기간:이용계약에 따른 회원자격이 유지되는 기간동안<br>
            그러나 회원탈퇴 후에도 관계법령의 규정에 의하여 보존할 필요가 있는 경우 당근영어는 아래와 같이 일정 기간동안 회원정보를 보관합니다.<br>
            회원 및 비회원 통화 이력은 상담 종료 후 30일동안 보관된 후 파기됩니다.
          </p></li> <li><h4>4. 개인정보의 파기절차 및 방법</h4> <p>
            개인정보의 보유 및 이용기간은 다음과 같습니다.<br> <br>
            ο 보유항목:수집항목과 같습니다.<br>
            ο 보유근거:이용약관 및 개인정보취급방침<br>
            ο 보유기간:이용계약에 따른 회원자격이 유지되는 기간동안 그러나 회원탈퇴 후에도 관계법령의 규정에 의하여<br>
            보존할 필요가 있는 경우 당근영어는 아래와 같이 일정 기간동안 회원정보를 보관합니다.<br>
            표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)<br>
            계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br>
            대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한 법률)<br>
            소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)<br>
            신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 (전자상거래등에서의 소비자보호에 관한 법률)<br>
            통신비밀보호법에 따른 통신사실확인자료 3개월
          </p></li> <li><h4>5. 개인정보</h4> <p>
            당근영어는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.<br> <br>
            이용자들이 사전에 동의한 경우<br>
            - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
          </p></li> <li><h4>6. 수집한 개인정보의 위탁</h4> <p>당근영어는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. <br>
            향후 그러한 필요가 생길 경우, 위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.</p></li> <li><h4>7. 이용자 및 법정대리인의 권리와 그 행사방법</h4> <p>이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를<br>
            조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.<br>
            이용자 혹은 만 14세 미만 아동의 개인정보 조회를 위해서는 ‘정보수정’ 을,<br>
            가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭 하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br>
            혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.<br>
            귀하가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전 까지 개인정보를 이용 또는 제공하지 않습니다. <br>
            또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는<br>
            정정 처리결과를 제3자에게 지체없이 통지하여 정정이 이루어지도록 하겠습니다.<br>
            당근영어는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는<br>
            당근영어가 수집하는 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고<br>
            그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p></li> <li><h4>8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h4> <p>당근영어는 귀하의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다. <br>
            쿠키란 당근영어의 웹사이트를 운영하는데 이용되는 서버가<br>
            귀하의 브라우저에 보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에 저장됩니다. <br>
            당근영어는 다음과 같은 목적을 위해 쿠키를 사용합니다.<br> <br>
            쿠키 등 사용 목적<br>
            - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적,<br>
            각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공<br> <br>
            쿠키 설정 거부 방법<br>
            - 쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의 옵션을 선택함으로써<br>
            모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br>
            - 설정방법 예(인터넷 익스플로러의 경우): 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보<br>
            (단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.)</p></li> <li><h4>9. 개인정보의 국외 이전에 대한 안내</h4> <p>- 이전되는 개인정보 항목 : 이름, 아이디<br>
            - 개인정보가 이전되는 국가 : 캐나다, 미국<br>
            - 이전일시 및 이전방법 : 수업 진행 시에만 이전<br>
            - 개인정보를 이전받는 자 : 해외 담당부서 (sangwhancha@carrotglobal.com)<br>
            - 개인정보를 이전받는 자의 개인정보 이용목적 및 보유 이용 기간<br>
            개인정보 수집 및 이용에 관한 동의 후 1년간 개인정보를 보유하고 이후 해당 정보를 지체 없이 파기합니다.<br>
            단, 법률에 의해 보존의무가 있는 경우에는 법령이 지정한 일정기간 동안 보존합니다.</p></li> <li><h4>10. 개인정보 위탁 현황</h4> <p>고객지원 및 서비스 제공을 위해 아래와 같이 개인정보 취급 업무를 외부 업체에 위탁하여 운영하고 있습니다.<br>
            위탁계약시 개인정보보호의 안전을 기하기 위하여 개인정보보호 관련 지시 엄수,<br>
            개인정보에 관한 유출금지 및 사고시의 책임부담 등을 명확히 규정하고 위탁계약 내용에 포함하고 있습니다.<br></p> <table><tbody><tr><th>수탁업체</th> <th>위탁목적</th></tr> <tr><td>㈜다우기술</td> <td>SMS 발송대행</td></tr> <tr><td>LG CNS</td> <td>AI Tutor 플랫폼 지원</td></tr> <tr><td>㈜올인원에듀테크</td> <td>AI 레벨테스트 플랫폼 지원</td></tr></tbody></table></li> <li><h4>11. 개인정보 관리 책임자</h4> <p>당근영어는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여<br>
            아래와 같이 관련 부서 및 개인정보관리책임자를 지정하고 있습니다.<br> <br>
            개인정보관리책임자 성명 : 이은경 본부장 <br>
            전화번호 : 02-518-2603 (평일 : 9시 ~ 6시까지) <br>
            이메일 : ashleylee@carrotglobal.com
          </p></li> <li><h4>12. 고지의 의무</h4> <p>현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는<br>
            변경사항 시행일의 7일전부터 홈페이지의 ‘공지사항’을 통해 고지할 것입니다.<br> <br>
            - 공고일자: 2014년 7월 31일<br>
            - 시행일자: 2014년 8월 8일
          </p></li></ul>
        </div>
        <p class="contact">※추가로 궁금하신 점은<br class="br">app.carrotenglish@gmail.com 으로 문의주세요.</p>
      </div>
    </div>

    <div class="footer mobile_footer">
      <div class="wrap">
        <div>
          <p>
            <span>Contact</span><br>
            <i class="fas fa-envelope"></i>&nbsp;app.carrotenglish@carrotglobal.com<br>
            <i class="fas fa-phone"></i>&nbsp;02-518-2603</p>
        </div>
        <div>
          <p>
            <br>
            당근농장 | ㈜캐럿솔루션즈<br>
            서울시 강남구 강남대로 488 대남빌딩 6층 ~ 9층 06120 | 사업자등록번호 621-86-16225<br>
            © <span class="bold">CARROT Solutions</span> Inc. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "download",

  mounted() {
    this.axios.get(this.$store.state.host + "/url")
    .then((res) => {
      let anroid = res.data.result[0];
      let ios = res.data.result[1];
      this.android_url = anroid.url;
      this.android_update_date = anroid.update_date;
      this.ios_url = ios.url;
      this.ios_update_date = ios.update_date;
    })
    .catch(error =>{ alert(`network error = ${error}`)});
  },

  data: () => ({
    android_url: '',
    android_update_date: '',
    ios_url: '',
    ios_update_date: ''
  }),

}
</script>

<style scoped>

#body {
  padding: 50px;
}

</style>
