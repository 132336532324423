<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <pop-curation-video-search :visible="isUser" @close="userClose" @companyInfo="addCompany"/>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">데일리라이팅 컨텐츠 등록</h3>
            </div>
          </div>

          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;난이도</th>
                        <td class="text-left">
                          <input v-model="dwContent.difficulty" type="radio" name="difficulty"
                                 value="B">초급
                          <input v-model="dwContent.difficulty" type="radio" name="difficulty"
                                 value="A">중급
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;구분</th>
                        <td class="text-left">
                          <input v-model="dwContent.ctype" type="radio" name="ctype" value="1">기업컨텐츠
                          <input v-model="dwContent.ctype" type="radio" name="ctype" value="2">일반컨텐츠
                          | Company <input type="hidden" v-model="dwContent.companyCode">
                          <input v-model="dwContent.company" type="text" readonly="readonly"
                                 class="input w200 ml10">
                          <input type="button" value="회사검색" class="btn-base" @click="isUser = true">
                          <input type="button" value="비우기" class="btn-base" @click="dwContent.company = null; dwContent.companyCode = null;">
                        </td>
                      </tr>
                      <tr class="pt10 cb">
                        <th>문장순서</th>
                        <td class="text-left">
                          <table class="tbl-view">
                            <colgroup>
                              <col style="width: 150px;">
                              <col>
                            </colgroup>
                            <tbody>
                            <tr>
                              <th>시작 문장<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q2Txt" style="width: 100%"
                                                    type="text" class="form-control textwidth100">
                              </td>
                            </tr>
                            <tr>
                              <th>순서1<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q2Itm1" style="width: 100%"
                                                    type="text" class="form-control textwidth100">
                              </td>
                            </tr>
                            <tr>
                              <th>순서2<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q2Itm2" style="width: 100%"
                                                    type="text" class="form-control textwidth100">
                              </td>
                            </tr>
                            <tr>
                              <th>순서3<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q2Itm3" style="width: 100%"
                                                    type="text" class="form-control textwidth100">
                              </td>
                            </tr>
                            <tr>
                              <th>순서4<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q2Itm4" style="width: 100%"
                                                    type="text" class="form-control textwidth100">
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                        Z
                      </tr>
                      <tr>
                        <th>문장&amp;키워드<br>
                          <span class="red">※ 오디오파일이 없을 시 문자음성<br> 자동변환(TTS)으로 재생됩니다.</span></th>
                        <td class="txt-left">
                          <table class="tbl-view">
                            <tbody>
                            <tr>
                              <th>한글문장<span class="red">*</span></th>
                              <td colspan="5" class="tl"><input style="width: 100%"
                                                                v-model="dwContent.q1Kor"
                                                                type="text"
                                                                class="form-control textwidth100">
                              </td>
                            </tr>
                            <tr>
                              <th>영문문장<span class="red">*</span></th>
                              <td colspan="5" class="tl"><input style="width: 100%"
                                                                v-model="dwContent.q1Eng"
                                                                type="text"
                                                                class="form-control textwidth100">
                              </td>
                            </tr>
                            <tr>
                              <th>영문강조<span class="red">*</span></th>
                              <td colspan="5" class="tl"><input style="width: 100%"
                                                                v-model="dwContent.q1Keyword"
                                                                type="text"
                                                                class="form-control textwidth100">
                              </td>
                            </tr>
                            <tr>
                              <th>Keyword1(한글)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q1Itm1Kor" type="text"
                                                    class="form-control textwidth100"></td>
                              <th>Keyword1(영문)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q1Itm1Eng" type="text"
                                                    class="form-control textwidth100"></td>
                              <!--                              <th>영문 오디오파일</th>
                                                            <td class="tl"><input type="file" id="q1_itm1_mp3"
                                                                                  class="form-control textwidth100">
                                                            </td>-->
                            </tr>
                            <tr>
                              <th>Keyword2(한글)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q1Itm2Kor" type="text"
                                                    class="form-control textwidth100"></td>
                              <th>Keyword2(영문)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q1Itm2Eng" type="text"
                                                    class="form-control textwidth100"></td>
                              <!--                              <th>영문 오디오파일</th>
                                                            <td class="tl"><input type="file" id="q1_itm2_mp3"
                                                                                  class="form-control textwidth100">
                                                            </td>-->
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;단어</th>
                        <td class="text-left">
                          <table class="tbl-view">
                            <tbody>
                            <tr>
                              <th>한글문장(괄호추가)<span class="red">*</span></th>
                              <td colspan="5" class="tl"><input style="width: 100%"
                                                                v-model="dwContent.q3Kor"
                                                                type="text"
                                                                class="form-control textwidth100">
                                <div class="pt5">&lt;작성예시&gt; 그는 새로운 모바일 디바이스를 담당하고 있는 [ ] 이다.</div>
                              </td>
                            </tr>
                            <tr>
                              <th>영문문장(괄호추가)<span class="red">*</span></th>
                              <td colspan="5" class="tl"><input style="width: 100%"
                                                                v-model="dwContent.q3Eng"
                                                                type="text"
                                                                class="form-control textwidth100">
                                <div class="pt5">&lt;작성예시&gt; He is a [ ] in charge of launching new
                                  mobile
                                  devices.
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>단어1(한글)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q3Itm1Kor" type="text"
                                                    class="form-control input"></td>
                              <th>단어1(영문)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q3Itm1Eng" type="text"
                                                    class="form-control textwidth100"></td>
                              <!--                              <th>영문 오디오파일</th>
                                                            <td class="tl"><input type="file" id="q3_itm1_mp3"
                                                                                  class="form-control textwidth100">
                                                            </td>-->
                            </tr>
                            <tr>
                              <th>단어2(한글)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q3Itm2Kor" type="text"
                                                    class="form-control textwidth100"></td>
                              <th>단어2(영문)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q3Itm2Eng" type="text"
                                                    class="form-control textwidth100"></td>
                              <!--                              <th>영문 오디오파일</th>
                                                            <td class="tl"><input type="file" id="q3_itm2_mp3"
                                                                                  class="form-control textwidth100">
                                                            </td>-->
                            </tr>
                            <tr>
                              <th>단어3(한글)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q3Itm3Kor" type="text"
                                                    class="form-control textwidth100"></td>
                              <th>단어3(영문)<span class="red">*</span></th>
                              <td class="tl"><input v-model="dwContent.q3Itm3Eng" type="text"
                                                    class="form-control textwidth100"></td>
                              <!--                              <th>영문 오디오파일</th>
                                                            <td class="tl"><input type="file" id="q3_itm3_mp3"
                                                                                  class="form-control textwidth100">
                                                            </td>-->
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> 어휘쓰기</th>
                        <td class="text-left">
                          <table class="tbl-view">
                            <tbody>
                            <tr>
                              <th>영문문장(괄호추가)<span class="red">*</span></th>
                              <td colspan="5" class="tl"><input style="width: 100%"
                                                                v-model="dwContent.q4Eng"
                                                                type="text"
                                                                class="form-control textwidth100">
                                <div class="pt5">&lt;작성예시&gt; He is a product manager [ ] launching
                                  new mobile
                                  devices.
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>답안<span class="red">*</span></th>
                              <td colspan="5" class="tl"><input style="width: 100%"
                                                                v-model="dwContent.q4Ans"
                                                                type="text"
                                                                class="form-control textwidth100">
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button @click="moveList()" type="submit"
                            class="btn btn-primary fl">목록
                    </button>
                    <button @click="insertContent()" type="submit" class="btn btn-primary fr ml-1">
                      저장
                    </button>
                    <!--                    <button type="submit" class="btn btn-primary fr ml-1" @click="showPreview()">
                                          미리보기
                                        </button>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Menu from '@/components/commons/IncMenu.vue'
import DailyWritingComponent from "@/components/model/dailyWritingComponent";
import popCurationVideoSearch from "@/components/pop/popCompanySearch";

export default {
  mixins: [DailyWritingComponent],
  name: 'CurationAdd',
  components: {
    Menu,
    popCurationVideoSearch
  },
  props: {
    msg: String
  },
  data: () => ({
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      filebrowserUploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor',
      ckfinder: {
        uploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor'
      },
      allowedContent: true,
      height : 600
    },
    imageUrl: '',
    isUser: false,
    companyInfo: {},
    dwContent: {
      idx: '',
      ndate: '',
      ctype: '1',
      companyCode: '1',
      company: '',
      q1Kor: '',
      q1Eng: '',
      q1Keyword: '',
      q1Itm1Kor: '',
      q1Itm1Eng: '',
      q1Itm1Mp3: '',
      q1Itm2Kor: '',
      q1Itm2Eng: '',
      q1Itm2Mp3: '',
      q2Txt: '',
      q2Itm1: '',
      q2Itm2: '',
      q2Itm3: '',
      q2Itm4: '',
      q3Kor: '',
      q3Eng: '',
      q3Itm1Kor: '',
      q3Itm1Eng: '',
      q3Itm1Mp3: '',
      q3Itm2Kor: '',
      q3Itm2Eng: '',
      q3Itm2Mp3: '',
      q3Itm3Kor: '',
      q3Itm3Eng: '',
      q3Itm3Mp3: '',
      q4Eng: '',
      q4Ans: '',
      difficulty: 'A'
    },
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    syncCompanyInfo: function (company) {
      this.companyInfo = company;
    },
    userClose: function (state) {
      this.isUser = state;
    },
    initPage: function () {
    },
  }
}
</script>

<style lang="scss" scoped>
/* Tables */
</style>
