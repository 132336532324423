import Vue from "vue";
import App from "./App.vue";
import store from "./store";

import "./plugins/axios";
import "./registerServiceWorker";

import axios from "axios";
import router from "./router";

import vueCkEditor from "ckeditor4-vue";
import vueMoment from "vue-moment";
import vueDaumPostcode from "vue-daum-postcode";

import vueDragula from "vue-dragula";
import uploadImage from "./components/upload/uploadImage";
import commons from "@/components/commons/axiosCommons";

//vee-validate
import { ValidationProvider, extend, localize } from "vee-validate";
import ko from "vee-validate/dist/locale/ko.json";
import { required, max } from "vee-validate/dist/rules";

Vue.prototype.$Axios = axios;
Vue.config.productionTip = false;

Vue.use(vueCkEditor);
Vue.use(vueDaumPostcode);
Vue.use(vueMoment);
Vue.use(vueDragula);

Vue.component(uploadImage.name, uploadImage);

//vee-validate
localize({
  ko,
});
localize("ko");
Vue.component("ValidationProvider", ValidationProvider);
extend("required", required);
extend("max", max);

Vue.mixin(commons);

new Vue({
  router,
  store,
  render: (h) => h(App),
  data: {
    dragula: Vue.vueDragula,
  },
}).$mount(`#app`);
