<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">공지사항/이벤트 관리</h3>
              <!-- <p class="lead">
        - CARROT 앱에 로그인 성공한 회원만 표시됩니다.<br>
        - 회원구분: CTM(전화영어 학습자), ITM(글로벌 오프라인 학습자), 통합 (전화영어 + 글로벌 오프라인 학습자)
    </p> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th width="60">제목</th>
                        <td>
                          <input @keyup.enter="getNotices(1, filter.title, filter.emp, filter.subject)" v-model="filter.title" type="email" class="form-control" placeholder="검색어를 입력하세요.">
                        </td>
                        <th>HQ매니저</th>
                        <td>
                          <input @keyup.enter="getNotices(1, filter.title, filter.emp, filter.subject)" v-model="filter.emp" type="email" class="form-control" placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary"
                            @click="getNotices(filter.page, filter.title, filter.emp, filter.subject)">
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <select v-model="filter.subject" class="form-control fl w-20per"
                            @change="getNotices(1, filter.title, filter.emp, filter.subject)">
                      <option value="">구분전체</option>
                      <option value="공지사항">공지사항</option>
                      <option value="이벤트">이벤트</option>
                    </select>
                    <button @click="$router.push('/noticeadd')" type="submit"
                            class="btn btn-primary fr ml-10">등록
                    </button>

                    <!-- <div class="float-right">
    <button type="button" class="btn btn-primary btn-icon-text" @click="excelDown()">
        <i class="btn-icon-prepend" data-feather="file-text"></i>
        Excel Download
    </button>
</div> -->
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th width="40">No</th>
                        <th>구분</th>
                        <th>제목</th>
                        <th>사용여부</th>
                        <th>등록일</th>
                        <th>HQ매니저</th>
                      </tr>
                      <tr v-for="(item,index) in notices" :key="item.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ item.subject }}</td>
                        <td><a :href="'/noticemodify/' + item.idx">{{ item.title }}</a></td>
                        <td>{{ item.isUse }}</td>
                        <td>{{ item.createDate }}</td>
                        <td> {{ item.emp.englishname }}({{ item.emp.koreanname }})</td>
                      </tr>
                      <tr v-if="lastpage === 0">
                        <td colspan="6">검색하신 게시글이 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <div v-if="this.lastpage !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'notice',
  components: {
    Menu,
    Paginate
  },
  props: {
    msg: String
  },
  data: () => ({
    notice: {
      idx: 0,
      idxEmp: 0,
      readCount: 0,
      title: "",
      subject: "",
      isUse: "",
      file1: "",
      file2: "",
      file3: "",
      content: "",
      createDate: "",
      updateDate: "",
      prevPage: 0,
      nextPage: 0,
      prevTitle: "",
      nextTitle: ""
    },
    filter: {
      title: '',
      emp: '',
      subject: '',
      page: 1
    },
    notices: [],
    lastpage: 1

  }),
  mounted() {
    this.getNotices(1)
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.getNotices(pageNum, this.filter.title, this.filter.emp, this.filter.subject);
    },
    getNotices: function (page, title, idxEmp, subject) {
      const url = this.$store.state.host + "/v1/admin/notices";
      var params = {
        title: (title === '' ? null : title),
        emp: (idxEmp === '' ? null : idxEmp),
        subject: (subject === '' ? null : subject),
        page: (page === '' ? null : page)
      };

      this.axios.get(url, {
        params
      })
      .then((res) => {
        if (res.data.status === 105) {
          this.notices = [];
          this.lastpage = 0;
        } else {
          this.result = res.data.result;
          this.notices = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
