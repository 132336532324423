<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">미리보기</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div class="preview">
                <div class="previewHeader">
                  <img src="../../assets/leftIcon.png" class="logo"/>
                  <p>99초만에 배우는 영어 한문장</p>
                </div>
                <div class="previewBody">
                  <div class="topVideo">
                    <iframe v-if="ensentence.videoType === 'YOUTUBE'" :src="'https://www.youtube.com/embed/' + splitUrl" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    <video v-else controls autoplay="autoplay">
                      <source :src="ensentence.url" type="video/mp4">
                    </video>
                  </div>
                  <div class="enInfo">
                    <h2>{{ ensentence.title }}</h2>
                    <!-- <span>My heart <span class="point">sank!</span></p> -->
                    <!-- <p >My heart <span>sank!</span></p> -->
                    <div v-html="ensentence.content"></div>
                    <div>
                      <span>조회수 0</span>
                    </div>
                  </div>
                  <div class="videoList">
                    <h5>다음 동영상</h5>
                    <ul>
                      <li class="clear">
                        <div class="fl">
                          <img :src="ensentence.thum"/>
                        </div>
                        <div class="fl info">
                          <h2>{{ ensentence.title }}</h2>
                          <span class="describe" v-html="ensentence.content"></span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary">확인</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">취소</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'previewModal',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    ensentence: {}
  },

  data: () => ({
    is_active: true,
  }),

  watch: {
    visible: function (newVal) {
      if (newVal == true) {
        this.visible = true;
        // this.doSearch();
      } else {
        this.visible = false;
      }

    }
  },

  computed: {
    splitUrl: function (){
      let temp_link = this.ensentence.url;
      let split = temp_link.split('v=');
      return  split[1];
    },
  },

  mounted() {
  },

  methods: {
    changeMenu(target) {
      this.is_active = target;
    },
    close() {
      this.$emit("close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}

.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}

.preview {
  width: 80%;
  margin: 0 auto;
  border: 1px solid #dddddd;

  .previewHeader {
    padding-top: 5%;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    overflow: auto;

    i {
      font-size: 22px;
    }

    img {
      float: left;
      width: 4%;
      padding-top: 5px;
    }

    p {
      float: left;
      margin: 0;
      font-family: "AppleSDGothicNeoB";
      font-size: 22px;
      margin-left: 25px;
    }
  }

  .previewBody {
    .topVideo {
      max-height: 235px;

      video {
        width: 100%;
        max-height: 235px;
      }

      iframe {
        width: 100%;
        max-height: 235px;
      }
    }

    .enInfo {
      padding: 0 15px;
      overflow: auto;

      h2 {
        margin-top: 0;
        font-family: "AppleSDGothicNeoB";
        margin-bottom: 0;
        margin-top: 18px;
        font-size: 24px;
      }

      p {
        margin-top: 10px;
        margin-bottom: 0;

        span {
          color: #ff802b;
        }

        span.point {
          color: #ff802b;
        }
      }

      div {
        overflow: auto;
        border-bottom: 1px solid #d2d2d2;
        padding-bottom: 20px;
        width: 100%;
        margin-top: 30px;

        img {
          width: 4%;
          float: left;
        }

        span {
          float: left;
          font-size: 17px;
        }
      }
    }

    .videoList {
      padding: 0 15px;
      padding-bottom: 30px;

      ul {
        padding-left: 0;
        list-style: none;

        li {
          margin-top: 15px;

          div {
            width: 25%;

            img {
              width: 100%;
            }

            h2 {
              text-overflow: inherit;
              overflow: inherit;
              font-size: 18px;
              font-family: "AppleSDGothicNeoB";
            }

            p {
              margin-top: 10px;

              span {
                color: #ff802b;
              }
            }
          }

          div.info {
            height: 70px;
            overflow-y: hidden;
            width: 75%;
            padding-left: 10px;
          }

          div.info .describe{
            text-overflow: ellipsis;
          }

        }
      }
    }
  }
}
</style>
