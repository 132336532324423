<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <Preview :visible="is_preview" @close="vue_close" :banner_idx="banner_idx" :banner_img="banner_img" :banner-type="bannerType"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">AI튜터 배너 관리</h3>
              <p class="lead">
                - 등록된 AI튜터 배너 리스트 입니다.
              </p>
            </div>
          </div>
          <div class="row">

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <input @keyup.enter="getBanners()" v-model="filter.searchTitle" type="text"
                             class="form-control w-45per"
                             placeholder="검색할 배너 타이틀을 입력하세요.">
                    </div>
                    <div class="form-group float-left clear w-100per">
                      <select @change="getBanners()" v-model="filter.isUse"
                              class="form-control fl w-15per">
                        <option value="">사용여부 선택</option>
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>

                      <select @change="getBanners()" v-model="filter.status"
                              class="form-control fl w-15per">
                        <option value="">사용현황 선택</option>
                        <option value="진행중">진행중</option>
                        <option value="종료">종료</option>
                        <option value="중단">중단</option>
                      </select>
                      <select @change="getBanners()" v-model="filter.idxEmp"
                              class="form-control fl w-15per">
                        <option value="-999">등록자 선택</option>
                        <option v-for="(item) in registers" :value="item.regid" :key="item.regid">
                        ({{item.englishname}}){{item.koreanname}}
                        </option>


                      </select>
                      <button type="submit" class="btn btn-primary ml-2 my-1" @click="getBanners()">검색</button>
                      <button type="submit" class="btn btn-primary my-1 fr" @click="goBannerAdd()">등록
                      </button>
                    </div>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th class="w-10px"><input type="checkbox" v-model="checkall" @change="setCheckAll"></th>
                        <th class="w-20px">No</th>
                        <th class="w-10per">배너종류</th>
                        <th class="w-10per">배너순서</th>
                        <th>배너타이틀</th>
                        <th>노출기간</th>
                        <th class="w-10per">사용여부</th>
                        <th class="w-10per">사용현황</th>
                        <th class="w-10per">마지막 등록일</th>
                        <th class="w-10per">등록자</th>
                      </tr>


                      <tr v-for="(item, index) in banners" :key="item.idx">
                        <td><input type="checkbox" v-model="item.check"></td>
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ (item.bannerType === 'belt-banner') ? '띠' : '메인슬라이딩' }}</td>
                        <td>{{ item.sort }}</td>
                        <td><a :href="'/aibannermodify/' + item.idx">{{ item.title }}</a></td>
                        <td>{{ item.startDate }} ~ {{ item.endDate }}</td>
                        <td>{{ item.isUse }}</td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.createDate }}</td>
                        <td>
                          <span v-if="item.emp != null">
                          {{  (item.emp.englishname != null ) ? item.emp.englishname :'' }}({{  (item.emp.koreanname != null ) ? item.emp.koreanname :'' }})
                          </span>
                        </td>
                      </tr>
                      <tr v-if="banners.length === 0">
                        <td colspan="10">검색 결과가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <button class="btn btn-danger float-left mh-100 my-2" @click="deleteCheckList()">삭제</button>
                    <div v-if="banners.length !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Preview from "../../components/preview/pop_preview.vue";
import Paginate from 'vuejs-paginate';

export default {
  name: 'aiBanner',
  components: {
    Menu,
    Preview,
    Paginate,
  },
  props: {
    msg: String
  },
  data: () => ({
    banners: [],
    registers: [],
    filter: {
      page: 1,
      isUse: '',
      idxEmp: -999,
      searchTitle:'',
      status:'',
    },
    is_preview: false,
    lastpage: 1,
    banner_idx: 0,
    banner_img: "",
    bannerType: '',
    checkall:false,
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    showPreview: function (banner) {
      this.banner_idx = banner.idx;
      this.banner_img = banner.img;
      this.bannerType = banner.type;
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    goBannerAdd: function () {
      this.$router.push("AiBannerAdd")
    },
    getBannerRegisters:function(){
      const url = this.$store.state.host + "/v1/aitutor/banner/registers";
      this.axios.get(url, {
      }).then((res) => {
        if (res.data.status === 0) {
          this.registers = res.data.result;
        } else {
          console.log('검색실패', res)
          this.banners = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    getBanners: function (page) {
      if (page !== undefined) {
        this.filter.page = page;
      }
      const url = this.$store.state.host + "/v1/aitutor/banner";
      var params = {
        isUse: (this.filter.isUse === '' ? null : this.filter.isUse),
        page: (this.filter.page === '' ? null : this.filter.page),
        status: (this.filter.status === '' ? null : this.filter.status),
        idxEmp: (this.filter.idxEmp === '-999' ? null : this.filter.idxEmp),
        searchTitle: (this.filter.searchTitle === '' ? null : this.filter.searchTitle),
      };

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.result = res.data.result;
          this.banners = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
          for(let i=0;i<this.banners.length;i++) {
            this.banners[i].check = this.checkall;
          }
        } else {
          console.log('검색실패', res)
          this.banners = [];
        }
      })
      .catch(error =>{
        alert(`network error = ${error}`)
      })
    },

    putBanner: function (idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;

      this.axios.put(url, this.banner)
      .then(function (res) {
        var data = res.data.result;
        console.log(data);
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    deleteBanner: function (idx) {
      const url = this.$store.state.host + "/v1/admin/banners/" + idx;

      this.axios.delete(url)
      .then(function (res) {
        var data = res.data.result;
        console.log(data);
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    selectBannerImage: function () {
      this.bannerImage = this.$refs.bannerImage.files[0];
    },

    postBannerImage: function () {
      let formData = new FormData();
      formData.append('img', this.bannerImage);

      const url = this.$store.state.host + "/v1/admin/banners/image";

      this.axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        if(res.data.status === 0){
          this.banner.img = res.data.result;
        }else{
          alert(res.data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    setCheckAll : function() {
      console.log(this.banners);
      for(let i=0;i<this.banners.length;i++) {
        this.banners[i].check = this.checkall;
      }
    },
    deleteCheckList :function () {

      let list = [];
      for(let i=0;i<this.banners.length;i++) {
        if(this.banners[i].check == true) {
          list.push(this.banners[i].idx);
        }
      }

      if(list.length==0){
        alert("선택된리스트가 없습니다")
        return;
      }
      console.log(list);

      const url = this.$store.state.host + "/v1/aitutor/banner/delete";
      if(confirm('선택한 '+list.length+'개 리스트를 삭제 하시겠습니까?')){
        this.axios.post(url,list
        ).then((res) => {
          if (res.data.status === 0) {
            alert('선택한 '+list.length+'개 리스트가 삭제되었습니다');
            this.getBanners();
          } else {
            alert(res.data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },
    initPage: function () {
      this.getBanners();
      this.getBannerRegisters();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
