<template>
  <div class="carrot">
    <location-add :visible="isLocation" @mount="mountLocation" @close="locationClose"/>
    <pop-user-search :visible="isUser" @input="mountPushUsers" @close="userClose"/>
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">푸시 등록</h3>
              <p class="lead">
                - 앱 사용자에게 푸시알림을 보낼 수 있습니다.<br>
                - 아이폰에서는 푸시제목 푸시내용만 표시 됩니다.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4>발송설정</h4>
                  <div class="table-responsive text-center mt-20">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;구분</th>
                        <td class="txt-left">
                          <div class="ml-05">
                            <label><input type="radio" v-model="push.pushType"
                                          value="now">즉시전송</label>
                            <label class="ml-1"><input type="radio" v-model="push.pushType"
                                                       value="nt-now">예약</label>
                            <label class="ml-1"><input :disabled="push.pushType !== 'nt-now'"
                                                       v-model="push.pushDate" type="datetime-local"
                                                       class=""/></label>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="push.targetType === 'A'">
                        <th><span class="red">*</span>&nbsp;발송대상</th>
                        <td class="txt-left">
                          <div class="ml-05">
                            <label><input type="radio" v-model="push.targetUser"
                                          value="A">모두</label>
                            <label class="ml-1"><input type="radio" v-model="push.targetUser"
                                                       value="I">ITM 학습자 전체</label>
                            <label class="ml-1"><input type="radio" v-model="push.targetUser"
                                                       value="C">CTM 학습자 전체</label>
                            <label class="ml-1"><input type="radio" v-model="push.targetUser"
                                                       value="T">통합 학습자 전체</label>
                            <label class="ml-1"><input type="radio" v-model="push.targetUser"
                                                       value="S">자체유입 학습자 전체</label>
                            <label class="ml-1"><input type="radio" v-model="push.targetUser"
                                                       value="AD">광고성 메시지 수신자</label>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="push.targetType === 'A'">
                        <th><span class="red">*</span>&nbsp;사용이력</th>
                        <td class="txt-left">
                          <div class="ml-05">
                            <div>
                              <label><input type="radio" v-model="push.targetDate"
                                            value="A">전체</label>
                            </div>
                            <div class="mt-1">
                              <label><input type="radio" v-model="push.targetDate"
                                            value="L">최근접속일</label>
                            </div>
                            <div class="mt-1">
                              <label><input type="radio" v-model="push.targetDate"
                                            value="J">최초등록일</label>
                            </div>

                            <div class="mt-1">
                              <input :disabled="push.targetDate==='A'" v-model="push.targetSdate" type="date" class=""/>
                              ~ <input :disabled="push.targetDate==='A'" v-model="push.targetEdate" type="date" class=""/>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4>푸시내용</h4>
                  <div class="table-responsive text-center mt-20">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th>
                          <span class="red">*</span>&nbsp;푸시제목<br>
                          <span class="red"> 40자 이내(공백 포함)</span>
                        </th>
                        <td class="txt-left">
                          <input v-model="push.title" type="text"
                                 class="form-control w-100per fl"/>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span class="red">*</span>&nbsp;푸시내용<br>
                          <span class="red">140자 이내(공백 포함)</span>
                        </th>
                        <td class="txt-left">
                          <ckeditor name="textarea" v-model="push.contents" :editor="editor"
                                    :config="editorConfig"></ckeditor>
                        </td>
                      </tr>
                      <tr>
                        <th>&nbsp;이동경로</th>
                        <td>
                          <div class="text-left">
                            <label><input @click="push.link = ''" v-model="push.linkType" type="radio" value="N">미사용</label>
                            <!--<label><input @click="push.link = ''" v-model="push.linkType" type="radio" value="O">외부링크</label>-->
                            <label><input @click="push.link = ''" v-model="push.linkType" type="radio" value="I">내부링크</label>
                          </div>
                          <div>
                            <button @click="locationClose(true)" v-if="push.linkType === 'I'"
                                    class="btn btn-dark w-100per">
                              {{ push.link === '' ? '이동경로를 선택해주세요' : push.link }}
                            </button>
                            <input v-if="push.linkType !== 'I'" :disabled="push.linkType === 'N'"
                                   v-model="push.link" type="text" class="form-control"
                                   placeholder="http://"/>
                          </div>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button @click="$router.push('/push')" type="submit" class="btn btn-primary fl">
                      목록
                    </button>
                    <button @click="postPush()" type="submit" class="btn btn-primary fr">발송</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Menu from '../../components/commons/IncMenu.vue';
import LocationAdd from '../../components/pop/popLocationAdd';
import PopUserSearch from '../../components/pop/popUserSearch'

export default {
  name: 'pushadd',

  data: () => ({
    imageUrl: '',

    push: {
      pushType: 'nt-now'
      , pushDate: null
      , pushTime: null
      , targetType: 'A'
      , targetUser: 'A'
      , targetDate: 'A'
      , targetSdate: null
      , targetEdate: null
      , title: ''
      , contents: ''
      , image: ''
      , linkType: 'N'
      , link: ''
      , pushUsers: []
    },

    /// loader
    loading: false,
    loader: {
      color: '#cc181e',
      color1: '#5bc0de',
      size: '5px',
      margin: '2px',
      radius: '2px'
    },

    /// editor
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      // The configuration of the editor.
      filebrowserUploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor',
      ckfinder: {
        uploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor'
      },
      allowedContent: true
    },

    isLocation: false,
    isUser: false,
  }),

  components: {
    Menu,
    LocationAdd,
    PopUserSearch,
  },

  props: {
    msg: String
  },

  mounted() {
    this.imageUrl = this.$store.state.host + "/v1/admin/pushes/image";
  },

  methods: {
    chooseImage: function () {
      document.getElementById("upload").click()
    },

    postPushImage: function () {
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.$refs.image.files[0]);
      const url = this.$store.state.host + "/v1/admin/pushes/image";
      this.axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        this.push.image = res.data.result;
        this.loading = false;
      })
      .catch((err) => {
        alert(`network error = ${err}`);
        this.loading = false;
      })

    },

    postPush: function () {
      ///validation
      if (confirm('발송 하시겠습니까?')) {
        if (this.push.pushType === 'nt-now' && (this.push.pushDate === null)) {
          alert('발송시간을 선택해주세요.')
        } else if (this.push.title === '') {
          alert("푸시제목을 입력해주세요.")
        } else if (this.push.targetType === 'A' && this.push.targetUser === '') {
          alert('발송대상을 선택해주세요.')
        } else if (!(this.push.targetDate === 'A') && this.push.targetSdate === null) {
          alert('기준 시작 시간을 선택해주세요.')
        } else if (!(this.push.targetDate === 'A') && this.push.targetEdate === null) {
          alert('기준 종료 시간을 선택해주세요.')
        } else if (this.push.targetDate === '') {
          alert('발송대상을 선택해주세요.')
        } else {
          const url = this.$store.state.host + "/v1/admin/pushes";
          this.push.pushDate = this.$moment(this.push.pushDate).format('YYYY-MM-DD HH:mm:ss');
          this.push.contents = this.push.contents.replace(/<br\/>/ig, "\n");
          this.push.contents = this.push.contents.replace(
              /<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/ig, "");
          this.axios.post(url, this.push)
          .then((res) => {
            if (res.data.status === 0) {
              alert("푸시 발송 입력이 완료되었습니다.");
              this.$router.push('/push');
            } else {
              alert("푸시 발송 입력중 문제가 발생했습니다." + res.data.result)
            }
          });
        }
      }
    },

    locationClose: function (state) {
      this.isLocation = state;
    },

    userClose: function (state) {
      this.isUser = state;
    },

    mountLocation: function (idx, subject) {
      this.push.link = '/' + subject + '?idx=' + idx
    },

    mountPushUsers: function (users) {
      this.push.pushUsers = [];
      users.forEach((user) => {
        this.push.pushUsers.push(user);
      })
      this.userClose();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
