<template>
  <div class="dim" v-if="visible">
    <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 700px">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">{{current_kname}}님 학습현황</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    @click="close()"><span aria-hidden="true">×</span></button>
          </div>
          <div class="modal-body">
            <div class="form-group clear ">
              <span class="fl"> 적립/차감을 보여주는 것으로 실제 값과 다를 수 있음</span>
              <button type="submit" class="btn btn-primary fr ml-10" @click="popupDWExcelDown()">엑셀 다운로드
              </button>
            </div>
            <div >
              <table class="table table-hover table-bordered  text-center">
                <tr>

                  <th>회사</th>
                  <th>이름</th>
                  <th>이메일</th>
                  <th>핸드폰 번호</th>
                </tr>
                <tr>
                  <td>{{current_company}}</td>
                  <td>{{current_kname}}</td>
                  <td>{{current_id}}</td>
                  <td>{{current_mobile}}</td>
                </tr>
              </table>
            </div>
            <div style="height: 500px; overflow-y: auto">
              <table class="table table-hover table-bordered mt-30 text-center">
                <tr>
                  <th style="width: 10%">No</th>
                  <th>학습/결석 날짜</th>
                  <th>적립/차감</th>
                  <th>총 갯수</th>
                </tr>
                <tr v-for="(item,index) in attends" :key="index">
                  <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                  <td>{{item.attenddate}}</td>
                  <td>
                    <span v-if="item.status_text=='적립'" >
                    {{item.status_text}}
                    </span>
                    <span v-else class="text-danger">
                    {{item.status_text}}
                    </span>
                  </td>
                  <td>{{item.accum_cnt}}</td>
                </tr>
                <tr v-if="lastpage == 0">
                    <td colspan="6">학습현황이 존재하지 않습니다.</td>
                </tr>
              </table>
            </div>
            <div v-show="lastpage > 0" style="padding-left:40%; padding-top:20px;">
              <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                        :click-handler="clickCallback" :prev-text="'Prev'"
                        :next-text="'Next'" :container-class="'pagination'"
                        :page-class="'page-link'">
              </paginate>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="close()" type="button" class="btn btn-primary">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  name: "DailyWritingPop",
  components: {
    Paginate,
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    current_idx:Number,
    current_company:String,
    current_kname:String,
    current_id:String,
    current_mobile:String
  },
  data: () => ({
    filter: {
      queryString: '',
      type: 'name',
    },
    lastpage: 0,
    items: [],
    pushUsers: new Map(),
    attends:[],
    cur_idx:0
  }),
  watch:{
    current_idx:function(val){
      // console.log(val);
      this.cur_idx = val;
      this.getDailyWritingAttend(1)
    }
  },
  methods: {
    close() {
      this.$emit("close", false);
      //this.pushUsers = new Map();
    },
    popupDWExcelDown(){
      location.href =
          this.$store.state.host +
          "/v1/admin/excel/dw/attend/overview/" +
          this.current_idx;
    },
    clickCallback(page){
      this.getDailyWritingAttend(page)
    },

    getDailyWritingAttend(page){
      console.log(page)
      const params = {
        page:page
      }
      this.axios.get(this.$store.state.host + "/v1/admin/dw/attend-overview/"+this.cur_idx,{params}).then((res)=>{
        console.log(res.data.status);
        if(res.data.status == 0){
          this.result = res.data.result;
          this.attends = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }else{
          this.lastpage = 0;
          this.attends = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  },
}
</script>

<style scoped>
</style>
