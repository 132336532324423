<template>
  <div>
    <input @change="selectImage" ref="file" type="file" hidden>
    <span class="upload-image" :style="imgStyle">
      <span v-if="initImg !== null" class="upload-image-delete">
        <a @click="deleteImage">X</a>
      </span>
        <a v-if="loading === false" @click="moveImage">
          <img v-if="initImg !== null" :src="initImg" :style="imgStyle">
          <img v-else src="https://kr.object.ncloudstorage.com/platform-storage/commons/img-placeholder.jpg" :style="imgStyle">
        </a>
      <clip-loader class="upload-loader" :loading="loading"/>
    </span>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "uploadImage",
  components: {
    ClipLoader
  },
  props: {
    url: {
      type: String,
      require: true,
      default: null
    },

    img: {
      type: String,
      require: true,
      default: null
    },

    file: {
      type: String,
      require: true,
      default: null
    },

    imgStyle: {
      type: String,
      require: true,
      default: null
    },
  },

  data: () => ({
    initImg: null,
    loading: false,
  }),

  mounted() {
  },

  computed: {
  },

  watch: {
    img: function (value) {
      this.initImg = value;
    }
  },

  methods: {
    moveImage: function () {
      this.$el.querySelector('input').click();
    },

    selectImage: function () {
      const file = this.$refs.file.files[0];
      console.log(file);
      let formData = new FormData();
      formData.append('img', file);
      formData.append('file', file);
      this.loading = true;
      this.axios.post(this.url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        if (res.data.status === 0) {
          this.initImg = res.data.result;
          this.$emit('mountImage', this.initImg);
          this.loading = false;
        } else {
          alert(res.data.result);
          this.loading = false;
        }
      })
      .catch((err) => {
        alert(`network error = ${err}`);
        this.loading = false;
      })
      this.$el.querySelector('input').value = null;
    },

    deleteImage: function () {
      console.log('**delete button')
      this.$emit('mountImage', null);
    }
  }
}
</script>

<style scoped>

a {
  cursor: pointer;
}

.upload-image {
  position: relative;
  float: left;
  margin-bottom: 5px;
  margin-right: 5px;
}

.upload-image::after {
  content: "";
  display: block;
  clear: both;
}

.upload-image img {
  border-radius: 0;
}

.upload-loader {
  margin-top: 30%;
}

.upload-image-delete {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 5%;
  right: 5%;
  text-align: center;
  background-color: white;
}

.upload-image-delete a {
  font-size: 15px;
}

</style>
