import Vue from 'vue'
import VueRouter from 'vue-router'
import Banner from '../pages/banner/bannerPage.vue';
import BannerAdd from "../pages/banner/bannerAddPage.vue";
import BannerModify from "../pages/banner/bannerModfiyPage.vue";
import Program from "../pages/program/programPage.vue";
import ProgramAdd from "../pages/program/programAddPage.vue";
import ProgramModify from "../pages/program/programModfiyPage.vue";
import ProgramUser from "../pages/program/programUserPage.vue";
import Bizcard from "../pages/bizCard/bizCardPage.vue";
import BizcardAdd from "../pages/bizCard/bizCardAddPage.vue";
import bannerModify from "../pages/bizCard/bizCardModfiyPage.vue";
import Ensentence from "../pages/enSentence/en-sentencePage.vue";
import EnsentenceAdd from "../pages/enSentence/en-sentenceAddPage.vue";
import EnsentenceModify from "../pages/enSentence/en-sentenceModfiyPage.vue";
import EnLecture from "../pages/enLecture/enLecturePage.vue";
import EnLectureAdd from "../pages/enLecture/enLectureAddPage.vue";
import EnLectureModify from "../pages/enLecture/enLectureModfiyPage.vue";
import Member from "../pages/member/memberPage.vue";
import MemberView from "../pages/member/memberViewPage.vue";
import Push from "../pages/push/pushPage.vue";
import PushAdd from "../pages/push/pushAddPage.vue";
import PushView from "../pages/push/pushViewPage.vue";
import Payment from "../pages/payment/paymentPage.vue";
import Product from "../pages/product/productPage.vue";
import ProductModify from "../pages/product/productModfiyPage.vue";
import Notice from "../pages/notice/noticePage.vue";
import NoticeAdd from "../pages/notice/noticeAddPage.vue";
import NoticeModify from "../pages/notice/noticeModfiyPage.vue";
import Login from "../pages/login/login.vue";
import Unknown from "../pages/erorr/404page.vue";
import dailyWritingPage from "../pages/dw/dailyWritingPage";
import dailySpeakingPage from "../pages/ds/dailySpeakingPage";
import appDownloadPage from "@/pages/appDownloadPage";
import appUploadPage from "@/pages/appUploadPage";
import Curation from "../pages/curation/curationPage.vue";
import CurationDetailPage from "@/pages/curation/curationDetailPage";
import CurationUsersPage from "@/pages/curation/curationUsersPage";
import CurationUsersDetailPage from "@/pages/curation/curationUsersDetailPage";
import CurationMailPad from "@/pages/mailped/curationMailPad";
import CurationPreview from "@/pages/mailped/curationPreview";
import DailyWritingAddPage from "@/pages/dw/dailyWritingAddPage";
import PronunciationAddPage from "@/pages/ds/pronunciationAddPage";
import CompositionAddPage from "@/pages/ds/compositionAddPage";
import DailyWritingEditPage from "@/pages/dw/dailyWritingEditPage"
import versionPage from "@/pages/version/versionPage";
import codePage from "@/pages/code/codePage";
import rewardPage from "@/pages/reward/rewardPage";
import privacyInfo from "@/pages/code/privacyInfo"
import dailyWritingOerview from "@/pages/dw/dailyWritingOverView";
import ReutersPage from "../pages/reuter/reuterPage.vue";
import ReutersDetailPage from "../pages/reuter/reuterDetailPage.vue";

import AiBanner from "../pages/aiBanner/aiBannerPage.vue";
import AiBannerAdd from "../pages/aiBanner/aiBannerAddPage.vue";
import AiBannerModify from "../pages/aiBanner/aiBannerModifyPage.vue";

import AiLecture from "../pages/aiLecture/aiLecturePage.vue";
import AiLectureAdd from "../pages/aiLecture/aiLectureAddPage.vue";
import AiLectureModify from "../pages/aiLecture/aiLectureModifyPage.vue";

import AiCourse from "../pages/aiCourse/aiCoursePage.vue";
import AiCourseAdd from "../pages/aiCourse/aiCourseAddPage.vue";
import AiCourseModify from "../pages/aiCourse/aiCourseModifyPage.vue";

import AiLectureFree from "../pages/aiLectureFree/aiLectureFreePage.vue";

import dailySpeakingOverView from "@/pages/dw/dailySpeakingOverView";

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'Unknown',
    component: Unknown
  },
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/banner',
    name: 'Banner',
    component: Banner
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/banneradd',
    name: 'BannerAdd',
    component: BannerAdd
  },
  {
    path: '/bannermodify/:idx',
    name: 'BannerModify',
    component: BannerModify
  },
  {
    path: '/program',
    name: 'Program',
    component: Program
  },
  {
    path: '/programadd',
    name: 'ProgramAdd',
    component: ProgramAdd
  },
  {
    path: '/programmodify/:idx',
    name: 'ProgramModfiy',
    component: ProgramModify
  },
  {
    path: '/programuser/:idx',
    name: 'ProgramUser',
    component: ProgramUser
  },
  {
    path: '/bizcard',
    name: 'Bizcard',
    component: Bizcard
  },
  {
    path: '/bizcardadd',
    name: 'BizcardAdd',
    component: BizcardAdd
  },
  {
    path: '/bizcardmodify/:idx',
    name: 'BizcardModfiy',
    component: bannerModify
  },
  {
    path: '/ensentence',
    name: 'Ensentence',
    component: Ensentence
  },
  {
    path: '/ensentenceadd',
    name: 'EnsentenceAdd',
    component: EnsentenceAdd
  },
  {
    path: '/ensentencemodify/:idx',
    name: 'EnsentenceModify',
    component: EnsentenceModify
  },
  {
    path: '/enlecture',
    name: 'EnLecture',
    component: EnLecture
  },
  {
    path: '/enlectureadd',
    name: 'EnLectureAdd',
    component: EnLectureAdd
  },
  {
    path: '/enlecturemodify/:idx',
    name: 'EnLectureModify',
    component: EnLectureModify
  },
  {
    path: '/curation',
    name: 'Curation',
    component: Curation
  },
  {
    path: '/curation/:idx',
    name: 'CurationsDetail',
    component: CurationDetailPage,
    props: true
  },
  {
    path: '/curation-user',
    name: 'CurationUsers',
    component: CurationUsersPage
  },
  {
    path: '/curation-user/:idx',
    name: 'CurationUsersDetail',
    component: CurationUsersDetailPage
  },
  {
    path: '/curation-mailPad/:link',
    name: 'CurationMailPad',
    component: CurationMailPad
  },
  {
    path: '/curation-preview/:idx',
    name: 'CurationMailPad',
    component: CurationPreview
  },
  {
    path: '/member',
    name: 'Member',
    component: Member
  },
  {
    path: '/memberview/:idx',
    name: 'MemberView',
    component: MemberView
  },
  {
    path: '/push',
    name: 'Push',
    component: Push
  },
  {
    path: '/pushadd',
    name: 'PushAdd',
    component: PushAdd
  },
  {
    path: '/pushview/:idx',
    name: 'PushView',
    component: PushView
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/product',
    name: 'Product',
    component: Product
  },
  {
    path: '/productmodify/:idx',
    name: 'ProductModify',
    component: ProductModify
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice
  },
  {
    path: '/noticeadd',
    name: 'NoticeAdd',
    component: NoticeAdd
  },
  {
    path: '/noticemodify/:idx',
    name: 'NoticeModfiy',
    component: NoticeModify
  },
  {
    path: '/dw',
    name: 'dailyWriting',
    component: dailyWritingPage
  },
  {
    path: '/dwadd',
    name: 'dailyWritingAdd',
    component: DailyWritingAddPage
  },
  {
    path:'/overview',
    name:'dailyWritingOerview',
    component: dailyWritingOerview
  },
  {
    path: '/dwinfo/:idx',
    name: 'dailyWritingEdit',
    component: DailyWritingEditPage
  },
  {
    path: '/ds',
    name:'dailySpeaking',
    component:dailySpeakingPage,
  },
  {
    path:'/dspadd',
    name:'pronunciationDetailAdd',
    component:PronunciationAddPage,
  },
  {
    path:'/dscadd',
    name:'compositionDetailAdd',
    component:CompositionAddPage,
  },
  {
    path: '/beta',
    name: 'platform',
    component: appDownloadPage
  },
  {
    path: '/betaupload',
    name: 'platform',
    component: appUploadPage
  },
  {
    path: '/version',
    name: 'version',
    component: versionPage
  },
  {
    path: '/code',
    name: 'code',
    component: codePage
  },
  {
    path: '/privacy',
    name: 'privacyInfo',
    component: privacyInfo
  },
  {
    path: '/reward',
    name: 'reward',
    component: rewardPage
  },
  {
    path: '/reuter',
    name: 'Reuters',
    component: ReutersPage
  },
  {
    path: '/reuter/:idx',
    name: 'ReuterDetail',
    component: ReutersDetailPage,
    props: true
  },
  {
    path: '/aiBanner',
    name: 'AiBanner',
    component: AiBanner
  },
  {
    path: '/aibanneradd',
    name: 'AiBannerAdd',
    component: AiBannerAdd
  },
  {
    path: '/aibannermodify/:idx',
    name: 'AiBannerModify',
    component: AiBannerModify
  },
  {
    path: '/aiLecture',
    name: 'AiLecture',
    component: AiLecture
  },
  {
    path: '/aiLectureadd',
    name: 'AiLectureAdd',
    component: AiLectureAdd
  },
  {
    path: '/ailecturemodify/:idx',
    name: 'AiLectureModify',
    component: AiLectureModify
  },
  {
    path: '/aiCourse',
    name: 'AiCourse',
    component: AiCourse
  },
  {
    path: '/aiCourseadd',
    name: 'AiCourseAdd',
    component: AiCourseAdd
  },
  {
    path: '/aiCoursemodify/:idx',
    name: 'AiCourseModify',
    component: AiCourseModify
  },
  {
    path: '/aiLectureFree',
    name: 'AiLectureFree',
    component: AiLectureFree
  },
  {
    path:'/ds/overview',
    name:'dailySpeakingOverView',
    component: dailySpeakingOverView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
