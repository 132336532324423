<template>
  <div v-if="visible">
    <div class="dim">
      <div
        class="modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">카테고리 등록</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
              >
                <span aria-hidden="true" @click="close()">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>카테고리</th>
                      <td class="text-left" style="display:flex; align-items:center;">
                        <input
                          type="text"
                          class="form-control"
                          v-model="newCompositionCategory"
                        />

                      <button class="btn btn-primary ml-10" @click="insertCompositionCategory()">등록</button>
                      </td>
                    </tr>

                  </thead>
                </table>
              </div>

              <div class="mt-20" style="max-height: 450px; overflow-y: scroll; border: 1px solid #e8ebf1;">
                <table class="table table-hover table-bordered text-center">
                  <tr style="position:sticky; top:0;">
                    <th style="width: 40px">No</th>
                    <th>카테고리</th>
                    <th>등록일</th>
                    <th>HQ매니저</th>
                    <th>삭제</th>
                  </tr>

                  <tr v-for="item in compositionCategories" :key="item.idx">
                    <td style="width: 40px">{{ item.idx }}</td>
                    <td>{{ item.categoryName }}</td>
                    <td>{{ item.createDate }}</td>
                    <td>{{ item.engName }} ({{ item.korName }})</td>
                    <td>
                      <button class="btn btn-danger" @click="deleteCompositionCategory(item.idx)">삭제</button>
                    </td>
                  </tr>
                </table>
              </div>
              <div
                v-show="sLastpage > 0"
                style="display:flex; justify-content:center; padding-top: 20px;"
              >
               <!-- pageing 데이터 처리 전혀 안되어있어서 주석처리 -Eruda -->
                <!-- <paginate
                  :page-count="sLastpage"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="clickCallback"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'page-link'"
                >
                </paginate> -->
              </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary">확인</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">취소</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";
// import Paginate from "vuejs-paginate";

export default {
  mixins: [DailySpeakingComponent],
  name: "compositionAddModal",
  components: {
    // Paginate,
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      newCompositionCategory: "",
      guideText: "",
      sLastpage: 1,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    clickCallback: function (pageNum) {
      console.log(pageNum);
      this.compositionCategoryPage = pageNum;

      this.getCompositionCategoryList();
    },


    clearCompositionCategoryInput(){
        this.newCompositionCategory='';
    },
  },

  watch: {
    visible() {
      if (this.visible === true) {
        this.getCompositionCategoryList();
      }
    },
  },
};
</script>

<style lang="scss">
.modal-dialog {
  max-width:880px;
}

.pagination{
    li{
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: #007bff;
      background-color: #fff;
      border: 1px solid #dee2e6;
    }
  }
</style>