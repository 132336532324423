<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">상품 관리</h3>
              <!-- <p class="lead">
        - CARROT 앱에 로그인 성공한 회원만 표시됩니다.<br>
        - 회원구분: CTM(전화영어 학습자), ITM(글로벌 오프라인 학습자), 통합 (전화영어 + 글로벌 오프라인 학습자)
    </p> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%">No</th>
                        <th>상품명</th>
                        <th style="width: 5%">사용여부</th>
                        <th style="width: 5%">무/유료</th>
                        <th style="width: 10%">가격</th>
                        <th style="width: 5%">최근 업데이트일</th>
                      </tr>
                      <tr v-for="(item,index) in merchants" :key="item.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td><a :href="'/productModify/' + item.idx">{{ item.name }}</a></td>
                        <td>{{ item.isUse }}</td>
                        <td>{{ item.isFree }}</td>
                        <td>{{ item.price }}</td>
                        <td>{{ item.updateDate }}</td>
                      </tr>
                      <tr v-if="lastpage === 0">
                        <td colspan="6">상품정책이 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <!-- <carrot-pagination v-model="search.page" :page="search.page" :total="total" :pagesize="50" @change="onPage" ></carrot-pagination> -->
                    <div v-if="lastpage === 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'product',
  components: {
    Paginate,
    Menu,
  },
  props: {
    msg: String
  },
  data: () => ({
    filter: {
      page: 1
    },
    merchants: [],
    lastpage: 1

  }),
  mounted() {
    this.initPage();
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    initPage: function () {
      this.getMerchants(this.filter.page);
    },

    getMerchants: function (page) {
      const url = this.$store.state.host + "/v1/admin/merchants/merchants";

      var params = {
        page: page
      }

      this.axios.get(url, {
        params
      })
      .then((res) => {
        if (res.data.status === 105) {
          this.merchants = [];
          this.lastpage = 0;
        } else {
          this.result = res.data.result;
          this.merchants = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
