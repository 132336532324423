<template>
  <div v-if="visible">
    <div class="dim">
      <div
        class="modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">일괄 등록</h4>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
              >
                <span aria-hidden="true" @click="close()">×</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="text-center">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>컨텐츠 일괄등록</th>
                      <td class="text-left" style="display:flex; align-items:center;">
                        <input
                          type="file"
                          accept=".xlsx,.xls "
                          class="form-control wd-70p inputfileset"
                          @change="readFile($event)"
                        />
                        <span 
                        class="spnset"
                        @click="excelDwonLoad"
                        >[일괄등록 엑셀 양식]
                        <img 
                        class="imgset"
                        src="../../assets/dwonload_img.png"></span>
                        
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <br/>
              <div>
              <p> ⦁ Excel Data 화면입니다.</p>
              <p> ⦁ 등록된 카테고리가 없으면 카테고리에 빨간색 표시가 됩니다 (카테고리 먼저 등록해주세요).</p>
              <p> ⦁ 등록 될 데이터에 이상이 있으면 엑셀 파일 변경 후 다시 업로드 해주세요.</p>
              <p> ⦁ 빈 데이터가 보이는 경우엔 엑셀 File에서 비워주세요. [ delete a row (Shift + Space, Ctrl + - ) ]</p>
              </div>
              <div class="mt-20" style="max-height: 450px; overflow-y: scroll; border: 1px solid #e8ebf1;">
                <table class="table table-hover table-bordered text-center">
                  <colgroup>
                    <col width = "20%">
                    <col width = "10%">
                    <col width = "70%">
                  </colgroup>
                  <tr style="position:sticky; top:0;">
                    <th>카테고리</th>
                    <th>사용여부</th>
                    <th colspan="2">컨텐츠</th>
                  </tr>
                    <!-- 아래처럼 구상하면 더블클릭시 인풋으로 바꿔서 데이터 조작 가능 
                    <td v-if="clicktd" @dblclick="test($event)" class="tdset">{{testdata1}}</td>
                    <td v-if="clickinput" @focusout="test1()" ><input type="text" v-model="testdata1"></td> -->

              <tbody class="tbodyset" v-for="(item,index) in excelDatas" v-bind:key="index">
                  <tr>
                    <td rowspan="9" :class="item.isCategory ? '' : 'tdback'">{{item.excelData[0]}}</td>
                    <td rowspan="9">{{item.excelData[1]}}</td>
                    <td>{{item.excelData[2]}}</td>
                    <td>{{item.excelData[3]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[4]}}</td>
                    <td>{{item.excelData[5]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[6]}}</td>
                    <td>{{item.excelData[7]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[8]}}</td>
                    <td>{{item.excelData[9]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[10]}}</td>
                    <td>{{item.excelData[11]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[12]}}</td>
                    <td>{{item.excelData[13]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[14]}}</td>
                    <td>{{item.excelData[15]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[16]}}</td>
                    <td>{{item.excelData[17]}}</td>
                  </tr>
                  <tr>
                    <td>{{item.excelData[18]}}</td>
                    <td>{{item.excelData[19]}}</td>
                  </tr>
              </tbody>                
                </table>
              </div>
              <div class="divset">
                <!-- 클릭이벤트 만들어서 등록 -->
                <button 
                class="btn btn-primary btnset"
                @click="insertExcelData"
                >등록</button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";

export default {
  mixins: [DailySpeakingComponent],
  name: "compositionAddMultiModal",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  data() {
    return {
      // testdata1: `오늘부터 마케팅 매니저로 이 팀에 합류하게 되었습니다.`,
      clicktd: true,
      clickinput: false,
      modifyModal: true,
      excelFile: '',
      FileUpload: '',
      Categories:[],
      iscategoryName : false,
      excelDatas:[],
    };
  },

  methods: {
    close() {
      this.excelDatas= []; // 클로즈 할때 excelDatas 비워줌 
      this.$emit("close");
      
    },
  excelDwonLoad(){
    //현재는 샘플 엑셀데이터 1개만 내려주면되어서 강제로 주소를 넣어두고 사용하는데, 
    //참고해서 사용할땐, Url Data Response 받은 데이터를 넣어주면 유연하게 사용가능 
    this.downloadExcel(`https://platform-api.carrotenglish.net/download-object?fileName=DSexcelForm.xlsx&folderName=dailySpeaking%2FtranslationImg`,`DSexcelForm.xlsx`);
  },
  downloadExcel(url, filename) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
    })
    .catch(console.error);
  },      
  
  readFile(event) {
    this.excelDatas=[];
    let XLSX = require("xlsx");
    const file = event.target.files[0];
    this.FileUpload = event.target.files;
    this.excelFile = file;
    const reader = new FileReader();
    let tmpResult = {};

    reader.onload = async (e) => {
        let data = e.target.result;
        data = new Uint8Array(data);
        // get excel file
        let excelFile = XLSX.read(data, { type: "array" });

        excelFile.SheetNames.forEach(function(sheetName) {
            const roa = XLSX.utils.sheet_to_json(
                excelFile.Sheets[sheetName],
                { header: 1 }
            );
            if (roa.length) tmpResult[sheetName] = roa;
        });
        
        tmpResult.Sheet1.shift(); // 엑셀업로드로 받아온 헤더데이터를 제거
        //this.excelDatas에 필요한 데이터들 Object로 Push 
        for(let i =0; i < tmpResult.Sheet1.length; i++){
          this.excelDatas.push({excelData: tmpResult.Sheet1[i] , isCategory: await this.chkCategory(tmpResult.Sheet1[i][0])});
        }
    };
    reader.readAsArrayBuffer(file);
  },
  insertExcelData(){
    let isFileUpload = this.FileUpload.length == 0 ? true : false 
    // insertCompositionDetailExcel 에서 일괄등록이 완료된 시점 과 close() 시점에  this.FileUpload 비워줌 
     if(isFileUpload){
        alert('엑셀 파일을 등록하세요.');
     }
    else {
      const formData = new FormData();   
      formData.append('file',this.excelFile);
      //일괄 업로드 API Call 
      this.insertCompositionDetailExcel(formData);
    }
  },
  
  async chkCategory(categoryName){
    const url =  this.$store.state.host + `/v1/admin/ds/translation/chkCategoryName?categoryName=${categoryName}` 
   return await this.axios.get(url, {headers: {'Content-Type': 'multipart/form-data'}})
    .then(res=>{
       this.iscategoryName = res.data.result;
       return this.iscategoryName
    })
    .catch(error =>{ alert(`network error = ${error}`)});
  } 
  },

  watch: {
    visible() {
      if (this.visible === true) {
        this.getCompositionCategoryList();
      }
    },
  },
};
</script>

<style lang="scss">
.modal-dialog {
  max-width:880px;
}

.divset{
  width:90px !important;
  margin:auto !important;
  }
.btnset{
  margin-top: 20px;    
  }
.spnset{
  font-size: 90%;
  margin-left: 20px;    
  font-weight: 400; 
  cursor:pointer;
}
.tdset{
  cursor:pointer !important;
}
.tdback{
  background-color: firebrick;
}
.imgset{
  width: 20px !important;
  height: 20px !important;
  object-fit: cover !important;
  border-radius: 0% !important;
  margin-left: 5px;   
}
::-webkit-file-upload-button{ 
    cursor: pointer !important; 
}
.inputfileset{
  cursor:pointer;  
}
.tbodyset{
  border:3px solid #44444c;
}
.guide_pop {
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
background-color: #fff;
width: 22.7rem;
padding: 1.12rem;
box-shadow: 0rem 0.18rem 1.93rem rgba(0, 0, 0, 0.16);
border-radius: 0.31rem;
}
</style>