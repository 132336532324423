<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">결제 관리</h3>
              <!-- <p class="lead">
        - CARROT 앱에 로그인 성공한 회원만 표시됩니다.<br>
        - 회원구분: CTM(전화영어 학습자), ITM(글로벌 오프라인 학습자), 통합 (전화영어 + 글로벌 오프라인 학습자)
    </p> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th width="60">결제일</th>
                        <td class="text-left">
                          <input v-model="filter.sdate" type="date" class="" placeholder=""> ~ <input v-model="filter.edate" type="date" class="" placeholder="">
                        </td>
                        <th>이름</th>
                        <td>
                          <input @keyup.enter="getPayments(1)" v-model="filter.userName" type="text" class="form-control"
                                 id="colFormLabel" placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      <tr>
                        <th>상품명</th>
                        <td colspan="3">
                          <select @change="getPayments(1)" v-model="filter.title">
                            <option value="">상품을 선택해주세요</option>
                            <option value="daily writing">Daily Writing</option>
                            <option value="reuters">로이터</option>
                            <option value="curation">찾아가는 큐레이션</option>
                          </select>
                          <!--<input @keyup.enter="getPayments(filter.page)" v-model="filter.title" type="email" class="form-control"
                                 id="colFormLabel" placeholder="검색어를 입력하세요.">-->
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary" @click="getPayments(filter.page)">
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%">NO</th>
                        <th style="width: 10%">결제일</th>
                        <th style="width: 20%">결제코드</th>
                        <th style="width: 10%">이름</th>
                        <th style="width: 20%">상품명</th>
                        <th style="width: 5%">결제방법</th>
                        <th style="width: 10%">결제금액</th>
                        <th style="width: 5%">개월수</th>
                        <th style="width: 5%">결제상태</th>
                        <th>결제취소</th>
                      </tr>
                      <tr v-for="(pay, index) in pays" :key="pay.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ pay.createDate }}</td>
                        <td>{{ pay.iamportMerchantUid }}</td>
                        <td>{{ pay.user.userName }}</td>
                        <td>{{ pay.productName }}</td>
                        <td>{{ pay.payType }}</td>
                        <td>{{ pay.priceOrigin }}</td>
                        <td>{{ pay.installment }}</td>
                        <td>{{ pay.payStatusString }}</td>
                        <td><button @click="payCancel(pay.iamportMerchantUid)" class="btn btn-outline-danger" v-if="pay.payStatus === 'S' && !pay.payType === 'google' && !pay.payType === 'apple'">결제취소</button></td>
                      </tr>
                      <tr v-if="lastpage === 0">
                        <td colspan="8">검색된 결제 정보가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div v-if="lastpage > 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'member',
  components: {
    Menu,
    Paginate,

  },
  props: {
    msg: String
  },
  data: () => ({
    filter: {
      sdate: '',
      edate: '',
      title: '',
      userName: '',
      page: 1
    },
    pays: [],
    lastpage: 1

  }),
  mounted() {
    this.initPage();
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    initPage: function () {
      this.getPayments(this.filter.page);
    },

    getPayments: function (page) {
      const url = this.$store.state.host + "/v1/admin/pays";
      const params = {
        sdate: (this.filter.sdate === '') ? null : this.filter.sdate,
        edate: (this.filter.edate === '') ? null : this.filter.edate,
        userName: (this.filter.userName === '') ? null : this.filter.userName,
        title: (this.filter.title === '') ? null : this.filter.title,
        page: page
      }

      this.axios.get(url, {params})
      .then((res) => {
        if(res.data.status === 105){
          this.pays = [];
          this.lastpage = 0;
        }else{
          this.result = res.data.result;
          this.pays = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    payCancel: function (merchantUid) {
      const url = this.$store.state.host + "/v1/admin/pays/" + merchantUid;
      this.axios.delete(url)
      .then((res) => {
        if(res.data.status === 0){
          this.getPayments(this.filter.page);
        }else{
          alert(res.data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
