<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="modal-title" id="myModalLabel">
                <h4>무료 강의 선택하기</h4>
                <p>무료강의로 사용할 강의를 선택해주세요.</p>
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div class="form-group float-left clear w-100per">
                <input @keyup.enter="getLectures()" v-model="filter.searchTitle" type="text"
                       class="form-control fl w-50per"
                       placeholder="검색할 강의 타이틀을 입력하세요.">
              </div>
              <div class="form-group float-left clear w-100per">
                <select @change="getLectures()" v-model="filter.isUse"
                        class="form-control fl w-30per">
                  <option value="">사용여부 선택</option>
                  <option value="Y">Y</option>
                  <option value="N">N</option>
                </select>
                <span class="fl ml-2 mt-2" >등록일:</span>
                <input  class="form-control fl ml-2 w-15per" v-model="filter.startDate" type="date" placeholder="yyyy-mm-dd"/>
                <span  class="fl ml-1 mr-1">~</span>
                <input  class="form-control fl w-15per" v-model="filter.endDate" type="date" placeholder="yyyy-mm-dd"/>
                <button type="submit" class="btn btn-primary ml-2 my-1" @click="getLectures()">검색</button>
              </div>
              <div class="form-group float-left clear w-100per">
                <span class="fl mt-2" >사용일:</span>
                <input  class="form-control fl w-15per ml-2" v-model="input_data.useDate" type="date" placeholder="yyyy-mm-dd"/>
              </div>
              <div class="mt-20" style="max-height: 450px; overflow-y: scroll; border: 1px solid #e8ebf1;">
              <table class="table table-hover table-bordered mt-30 text-center">
                  <tr>
                    <th class="w-10px">등록</th>
                    <th class="w-10per">강좌번호</th>
                    <th class="w-10per">강의번호</th>
                    <th class="w-20per">강좌타이틀</th>
                    <th class="w-20per">강의타이틀</th>
                    <th class="w-10per">사용여부</th>
                    <th class="w-10per">퀴즈</th>
                    <th class="w-15per">AI Speaking</th>
                    <th class="w-15per">등록일</th>
                  </tr>
                  <tr v-for="(item) in lectures" :key="item.idx">
                    <td><a class="cursor-pointer" style="text-decoration: underline" @click="freeLectureAdd(item.lecture_idx, item.course_idx)">등록</a></td>
                    <td>C{{ item.course_idx }}</td>
                    <td>L{{ item.lecture_idx }}</td>
                    <td><a :href="'/aicoursemodify/' + item.course_idx" target="_blank">{{ item.subject }}</a></td>
                    <td><a :href="'/ailecturemodify/' + item.lecture_idx" target="_blank">{{ item.title }}</a></td>
                    <td>{{ item.is_use }}</td>
                    <td>{{ item.is_quiz }}</td>
                    <td>{{ item.is_speaking }}</td>
                    <td>{{ item.create_date }}</td>
                  </tr>
                  <tr v-if="lectures.length === 0">
                    <td colspan="8">검색 결과가 존재하지 않습니다.</td>
                  </tr>
              </table>
              </div>
              <div v-if="lectures.length !== 0" style="padding-left:40%; padding-top:20px;">
                <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                          :click-handler="clickCallback" :prev-text="'Prev'"
                          :next-text="'Next'" :container-class="'pagination'"
                          :page-class="'page-link'">
                </paginate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Paginate from 'vuejs-paginate';
export default {
  name: 'tutorModal',
  components: {
    Paginate,
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
  },

  data: () => ({
    lectures: [],
    filter: {
      page: 1,
      searchTitle: '',
      isUse: ''
    },

    input_data:{
      useDate: "",
      lectureIdx:0,
      courseIdx:0
    },
    is_preview: false,
    lastpage: 1,
    banner_idx: 0,
    banner_img: "",
    bannerType: '',
    checkall:false,
    lec_total:0,
  }),

  watch: {
    visible: function (newVal) {
      if (newVal == true) {
        this.visible = true;
        // this.doSearch();
      } else {
        this.visible = false;
      }
    }
  },
  mounted() {
    let nd = new Date();
    let today = nd.getFullYear()
        + '-' + (nd.getMonth()>8?nd.getMonth()+1:'0'+(nd.getMonth()+1))
        + '-' + (nd.getDate()>9?nd.getDate():'0'+nd.getDate());

    console.log(today);
    this.input_data.useDate=today;
    this.getLectures();
  },

  methods: {
    setCheckAll : function() {
      console.log(this.lectures);
      for(let i=0;i<this.lectures.length;i++) {
        this.lectures[i].check = this.checkall;
      }
    },
    initPage: function () {
      this.getLectures();
    },
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    getLectures: function (page) {
      if (page !== undefined) {
        this.filter.page = page;
      }
      const url = this.$store.state.host + "/v1/aitutor/lecture/course-lecture-list";
      var params = {
        isUse: (this.filter.isUse === '' ? null : this.filter.isUse),
        page: (this.filter.page === '' ? null : this.filter.page),
        searchTitle: (this.filter.searchTitle === '' ? null : this.filter.searchTitle)
      };

      this.axios.get(url, {
        params
      }).then((res) => {
        if (res.data.status === 0) {
          this.result = res.data.result;
          this.lectures = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
          this.lec_total=res.data.lec_total;
          for(let i=0;i<this.lectures.length;i++) {
            this.lectures[i].check = this.checkall;
          }
        } else {
          console.log('검색실패', res)
          this.lec_total=res.data.lec_total;
          this.lectures = [];
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    pickCategory(category) {
      this.$emit('pick', category);
      this.close();
    },
    close() {
      this.$emit("close", false);
    },
    freeLectureAdd: function(lecture_idx, course_idx){

      this.input_data.lectureIdx=lecture_idx;
      this.input_data.courseIdx=course_idx;
      console.log(lecture_idx, course_idx);
      //this.$emit('selected', {lectures : list});
      //this.$emit("close", false);
      const url = this.$store.state.host + "/v1/aitutor/lecture-free";
      if (this.input_data.useDate === "") {
        alert('사용일을 입력해주세요.')
      } else {
        this.axios.post(url, this.input_data)
            .then((res) => {
              const data = res.data;
              if (data.status === 0) {
                alert("무료강의가 설정이 완료되었습니다.")
                this.goBanners();
              } else {
                alert(data.result)
              }
            })
          .catch(error =>{ alert(`network error = ${error}`)});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}
</style>
