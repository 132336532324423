<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">데일리 스피킹 학습 현황</h3>
              <p class="lead">
                - <span class="bold">학습완료수</span>는 Daily Speaking 학습 완료 시(마지막 페이지 도달 시) +1 카운트됩니다.<br>
                - <span class="bold">미완료수</span>는 일일 학습 미완료 시 카운트되며 학습완료수에서 -1 차감됩니다.<br>
                - <span class="bold">리워드 카운팅</span>은 학습완료수에서 미접속수를 뺀 수로 100 달성 시 리워드 완료 +1 카운트됩니다.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th style="width: 10%">기간</th>
                      <td colspan="7">
                        <input type="date" v-model="overviewFilter.sdate"> ~ <input type="date" v-model="overviewFilter.edate">
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 10%">K-name</th>
                      <td class="tl">
                        <input v-model="overviewFilter.kname" type="text"
                               @keyup.enter="getDailySpeakingOverView()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                      <th style="width: 10%">Company</th>
                      <td class="tl">
                        <input v-model="overviewFilter.company" type="text"
                               @keyup.enter="getDailySpeakingOverView()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                      <th style="width: 10%">Mobile</th>
                      <td class="tl">
                        <input v-model="overviewFilter.mobile" type="text"
                               @keyup.enter="getDailySpeakingOverView()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <div class="text-right mt-2">
                    <button @click="getDailySpeakingOverView()" type="submit" class="btn btn-primary">
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <div class="form-group fr clear ">
                    <button type="submit" class="btn btn-primary fr ml-10" @click="excelDown()">엑셀 다운로드
                    </button>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <colgroup>
                        <col style="width:20px">
                        <col style="width:50px">
                        <col style="width:50px">
                        <col style="width:80px">
                        <col style="width:50px">
                        <col style="width:20px">
                        <col style="width:20px">
                        <col style="width:20px">
                        <col style="width:20px">
                        <col style="width:100px">
                        <col style="width:50px">
                      </colgroup>
                      <tr>
                        <th style="width:20px">No</th>
                        <th>Company</th>
                        <th>K-name</th>
                        <th>ID(E-mail)</th>
                        <th>Mobile</th>
                        <th>학습 완료</th>
                        <th>미접속일</th>
                        <th>총 학습</th>
                        <th>리워드 완료</th>
                        <th>최근 학습일</th>
                        <th>학습 현황 확인</th>
                      </tr>
                      <tr v-for="(item,index) in dsOverView" :key="index">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage) - index }}</td>
                        <td>{{ item.company }}</td>
                        <td>{{ item.kname }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.mobile }}</td>
                        <td>{{ item.cnt_lesson }}</td>
                        <td>{{ item.cnt_absence }}</td>
                        <td>{{ item.cnt_attend }}</td>
                        <td>{{ item.cnt_reward }}</td>
                        <td>{{ $moment(item.updatedate).format('YYYY-MM-DD HH:mm') }}</td>
                        <td>
                          <button class="btn btn-primary"
                                  @click="attendCheck(item.idx_member,item.company,item.kname,item.email,item.mobile)">
                            View
                          </button>
                        </td>
                      </tr>
                      <tr v-if="lastpage === 0">
                        <td colspan="12">리워드가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div v-show="lastpage > 0" style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DailySpeakingPop :visible="isPop" @close="isPop=false" :current_idx="current_idx"
                      :current_company="current_company" :current_kname="current_kname" :current_id="current_id"
                      :current_mobile="current_mobile"></DailySpeakingPop>
  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";
import DailySpeakingPop from "@/components/pop/popDailySpeaking.vue";


export default {
  mixins: [DailySpeakingComponent],
  name: 'dailySpeakingOverView',
  components: {
    Paginate,
    Menu,
    DailySpeakingPop
  },
  props: {
    msg: String
  },
  data: () => ({
    dws: [],
    lastpage: 1,
    isPop: false,
    current_idx: 0,
    current_company: '',
    current_kname: '',
    current_id: '',
    current_mobile: ''
  }),
  mounted() {
    this.overviewFilter.sdate = this.$moment().format('YYYY-MM-DD');
    this.overviewFilter.edate = this.$moment().format('YYYY-MM-DD');
    this.initPage(1);
  },
  methods: {
    attendCheck(idx, company, kname, id, mobile) {
      // console.log(idx);
      this.current_idx = idx;
      this.current_company = company;
      this.current_kname = kname;
      this.current_id = id;
      this.current_mobile = mobile;
      this.isPop = true;
    },
    clickCallback: function (pageNum) {
      this.filter.page = pageNum;
      this.initPage(pageNum);
    },
    initPage: function (pageNum) {
      this.getDailySpeakingOverView(pageNum);
    },
    excelDown: function () {
      this.getOverViewExcel();
    },
    excelDateDown: function () {
      this.getOverViewDateExcel();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.bold {
  font-weight: bold;
}

.tab-list {
  display: flex;

  .tab-item {
    width: 100px;
    height: 25px;
    text-align: center;
    border: #000 solid 1px;
    border-radius: 2px;

    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }
  }

  .tab-item:last-child {
    margin-left: 2px;
  }

  .on {
    background: #f2f2f2;
  }
}


</style>
