import { render, staticRenderFns } from "./codePage.vue?vue&type=template&id=7387d6ee&scoped=true&"
import script from "./codePage.vue?vue&type=script&lang=js&"
export * from "./codePage.vue?vue&type=script&lang=js&"
import style0 from "./codePage.vue?vue&type=style&index=0&id=7387d6ee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7387d6ee",
  null
  
)

export default component.exports