<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <CategoryAdd :visible="categoryVisible" @close="vue_close"></CategoryAdd>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">교육 프로그램 관리</h3>
              <p class="lead" style="color: red">
                프로그램 구성 유의사항<br>
                - ios는 애플 정책 상 1:1 프로그램만 노출 가능 (패키지, 그룹, 이러닝 프로그램 등록 불가)<br>
              </p>
              <!-- 
              <p class="lead">
                - 메인페이지에 노출 될 각종 교육신청, 이벤트 페이지를 등록할 수 있습니다.<br>
              <p class="lead" style="color: red">
                프로그램 구성 가이드<br>
                - 프로그램은 온라인, 오프라인 상관없이 1:1 프로그램만 등록 가능<br>
                - 1:1 프로그램이어도 패키지 프로그램 불가 (ex) 레벨테스트+전화영어+i:MOOC X<br>
                - 동영상 강의 등록 불가
              </p> -->
            </div>
          </div>
          <div class="row">

            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <select @change="getPrograms(1)" v-model="filter.isUse"
                              class="form-control fl w-20per">
                        <option value="">전체 사용여부</option>
                        <option value="Y">Y</option>
                        <option value="N">N</option>
                      </select>

                      <select @change="getPrograms(1)" v-model="filter.status"
                              class="form-control fl ml-10 w-20per">
                        <option value="">전체 신청상태</option>
                        <option value="process">진행중</option>
                        <option value="ready">예정</option>
                        <option value="end">종료</option>
                      </select>

                      <button @click="$router.push('/programadd')" type="submit"
                              class="btn btn-primary fr ml-10">등록
                      </button>
                      <button type="submit" class="btn btn-primary fr" @click="categoryAdd()">카테고리
                        등록
                      </button>
                    </div>
                    <!-- <div class="float-right">
                    <button type="button" class="btn btn-primary btn-icon-text" @click="excelDown()">
                        <i class="btn-icon-prepend" data-feather="file-text"></i>
                        Excel Download
                    </button>
                </div> -->
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th width="40">No</th>
                        <th>카테고리</th>
                        <th>노출순서</th>
                        <th>프로그램 명</th>
                        <th>사용여부</th>
                        <th>신청기간</th>
                        <th>진행상태</th>
                        <th>HQ매니저</th>
                        <th>안드로이드</th>
                        <th>IOS</th>
                        <th style="max-width:200px;">최근 업데이트일</th>
                        <th>신청자</th>
                        <th>취소자</th>
                      </tr>
                      <tr v-for="(program, index) in programs" :key="program.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ program.category.name }}</td>
                        <td>{{ program.ordernum }}</td>
                        <td><a :href="'/programmodify/' + program.idx">{{ program.programName }}</a>
                        </td>
                        <td>{{ (program.isUse === 'Y') ? '사용' : '사용안함' }}</td>
                        <td>{{ (program.isTime === 'Y') ? program.applySdate + ' ~ ' + program.applyEdate : '제한없음' }}</td>
                        <td>{{ program.status }}</td>
                        <td>{{
                            (program.emp !== null) ? program.emp.englishname : null
                          }}({{ (program.emp !== null) ? program.emp.koreanname : null }})
                        </td>
                        <td>{{ program.isAndroid }}</td>
                        <td>{{ program.isIos }}</td>
                        <td>{{ program.updateDate }}</td>
                        <td><a :href="'/programuser/' + program.idx">{{ program.currentCount }}</a>
                        </td>
                        <td>{{ program.cancelCount }}</td>
                      </tr>
                      <tr v-if="lastpage ===0">
                        <td colspan="11">해당 프로그램 정보가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div v-if="lastpage !== 0" style="padding-left:40%; padding-top:20px;">
                      <paginate
                          :page-count="lastpage"
                          :page-range="3"
                          :margin-pages="2"
                          :click-handler="clickCallback"
                          :prev-text="'Prev'"
                          :next-text="'Next'"
                          :container-class="'pagination'"
                          :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import CategoryAdd from '../../components/pop/popCategoryAdd'
import Paginate from 'vuejs-paginate';

export default {
  name: 'program',
  components: {
    Menu,
    Paginate,
    CategoryAdd
  },
  props: {
    msg: String
  },
  data: () => ({
    categoryVisible: false,
    filter: {
      isUse: '',
      status: '',
      page: 1
    },
    programs: [],
    lastpage: 1
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    clickCallback: function (pageNum) {
      // console.log(pageNum)
      this.filter.page = pageNum;
      this.initPage();
    },
    initPage: function () {
      /// 프로그램 받기
      this.getPrograms();
    },

    getPrograms: function (page) {
      if(page !== undefined) this.filter.page = page;
      var params = {
        isUse: (this.filter.isUse === '') ? null : this.filter.isUse,
        status: (this.filter.status === '') ? null : this.filter.status,
        page: this.filter.page,
      };

      this.axios.get(this.$store.state.host + "/v1/admin/programs", {params})
      .then((res) => {
        if(res.data.status === 105){
          this.programs = [];
          this.lastpage = 0;
        }else{
          this.result = res.data.result;
          this.programs = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    categoryAdd: function () {
      this.categoryVisible = true;
    },

    vue_close: function (state) {
      this.categoryVisible = state;
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
