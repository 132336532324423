<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">미리보기</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div class="preview">
                <div class="previewHeader">
                  <img src="../../assets/leftIcon.png" class="logo"/>
                  <p>지금 신청 가능한 프로그램 </p>
                </div>
                <div class="previewBody">
                  <div class="wrapImg">
                    <img :src="program.upperImage"/>
                  </div>
                  <div class="wrap">
                    <h2>{{ program.programName }}</h2>
                    <div class="applyMenu">
                      <ul>
                        <li @click="changeMenu(true)"><span v-bind:class="{ active: is_active }">
                                                    <div class="spanBoard" v-if="is_active"></div>
                                                    개요
                                                </span></li>
                        <li @click="changeMenu(false)"><span v-bind:class="{ active: !is_active }">
                                                    <div class="spanBoard" v-if="!is_active"></div> 가격
                                                </span></li>
                      </ul>
                    </div>

                    <div class="applyBox" v-if="is_active">
                      <div style="overflow-y: auto; height: 190px;">
                        <div class="applyTextDiv">
                          <div class="applyTitle">신청기간</div>
                          <div class="applyContent">{{ program.applySdate }} ~ {{program.applyEdate }}<br>
                            (마감 <span>30</span>일 전)
                          </div>
                        </div>

                        <div class="applyTextDiv">
                          <div class="applyTitle">교육기간</div>
                          <div class="applyContent">{{ program.sdate }} ~ {{ program.edate }}</div>
                        </div>

                        <div class="applyTextDiv">
                          <div class="applyTitle">교육장소</div>
                          <div class="applyContent">{{ program.location }}</div>
                        </div>

                        <div class="applyTextDiv">
                          <div class="applyTitle">모집인원</div>
                          <div v-if="program.isLimit === 'Y'" class="applyContent">
                            총 <span>0</span>명 ㅣ <b>{{ program.maxCount }}</b>명 신청가능
                          </div>
                          <div v-else class="applyContent">
                            인원제한 없음
                          </div>
                        </div>
                        <div class="applyTextDiv">
                          <div class="applyTitle">무/유료</div>
                          <div class="applyContent">{{ (program.isFree === 'Y' ? '무료' : '유료') }}</div>
                        </div>
                        <div class="applyTextDiv" v-html="program.content" style="overflow: hidden; ">
                        </div>
                      </div>
                      <div class="applyBtn">
                        <p>프로그램 신청하기</p>
                      </div>
                    </div>

                    <div class="applyBox" v-if="!is_active">

                      <div v-if="program.isFree === 'N'" class="is_pay">
                        <div v-if="program.detail.policy1 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy1 }}</div>
                          <div class="applyContent">{{ program.detail.price1 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy2 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy2 }}</div>
                          <div class="applyContent">{{ program.detail.price2 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy3 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy3 }}</div>
                          <div class="applyContent">{{ program.detail.price3 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy4 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy4 }}</div>
                          <div class="applyContent">{{ program.detail.price4 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy5 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy5 }}</div>
                          <div class="applyContent">{{ program.detail.price5 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy6 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy6 }}</div>
                          <div class="applyContent">{{ program.detail.price6 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy7 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy7 }}</div>
                          <div class="applyContent">{{ program.detail.price7 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy8 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy8 }}</div>
                          <div class="applyContent">{{ program.detail.price8 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy9 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy9 }}</div>
                          <div class="applyContent">{{ program.detail.price9 }} 원</div>
                        </div>
                        <div v-if="program.detail.policy10 !== null" class="applyTextDiv">
                          <div class="applyTitle">{{ program.detail.policy10 }}</div>
                          <div class="applyContent">{{ program.detail.price10 }} 원</div>
                        </div>
                      </div>
                      <div v-if="program.isFree === 'Y'" class="is_not_pay">
                        <h4>본 프로그램은 무료로 진행되는<br> 교육 프로그램입니다.</h4>
                      </div>

                      <div class="applyBtn">
                        <p>프로그램 신청하기</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary">확인</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">취소</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'previewModal',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    program: {},
  },

  data: () => ({
    is_active: true
  }),
  watch: {
    visible: function (newVal) {
      console.log(this.program);
      if (newVal == true) {
        this.visible = true;
        // this.doSearch();
      } else {
        this.visible = false;
      }

    }
  },
  mounted() {
    // console.log(this.tutor_email+"/");
  },

  methods: {
    changeMenu(target) {
      this.is_active = target;
    },
    close() {
      this.$emit("close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}

.preview {
  width: 80%;
  margin: 0 auto;
  border: 1px solid #dddddd;

  .previewHeader {
    padding-top: 5%;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    overflow: auto;

    i {
      font-size: 22px;
    }

    img {
      float: left;
      width: 4%;
      padding-top: 5px;
    }

    p {
      float: left;
      margin: 0;
      font-family: "AppleSDGothicNeoB";
      font-size: 22px;
      margin-left: 25px;
    }
  }

  .previewBody {
    .wrapImg {
      height: 216px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .wrap {
      padding-left: 15px;
      padding-right: 15px;

      h2 {
        font-family: "AppleSDGothicNeoB";
        margin-bottom: 25px;
        font-size: 24px;
        margin-top: 18px;
      }

      .applyMenu {
        width: 100%;

        ul {
          width: 100%;
          padding: 0;
          margin: 0;

          border-bottom: 1px solid #dddddd;

          li {
            width: 50%;
            display: inline-block;
            position: relative;
            list-style: none;
            font-family: "AppleSDGothicNeoB";
            text-align: center;
            cursor: pointer;

            span {
              width: 35%;
              display: inline-block;
              color: #dfdfdf;
              position: relative;
              padding-bottom: 10px;
              overflow: visible;
            }

            .spanBoard {
              position: absolute;
              bottom: -2.5px;
              left: 0;
              width: 100%;
              height: 5px;
              background: #ff6600;
            }

            span.active {
              color: black;
            }
          }
        }
      }

      .applyBox {
        margin-top: 15px;
        border-radius: 5px;
        padding: 0;
        min-height: 264px;
        max-height: 264px;
        position: relative;
        box-sizing: border-box;
        overflow: hidden;

        .applyTextDiv {
          padding: 0 30px;
          width: 100%;
          overflow: auto;
          padding-top: 10px;

          div {
            float: left;
          }

          div.applyTitle {
            font-family: "AppleSDGothicNeoB";
            width: 25%;
          }

          div.applyContent {
            span {
              color: #ff6600;
              font-family: "AppleSDGothicNeoB";

            }
          }
        }

        .applyBtn {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-top: 0px;
          text-align: center;
          font-size: 17px;
          background: #ff6600;
          border-radius: 5px;
          position: absolute;
          bottom: 20px;
          left: 0;
          color: white;

          p {
            font-family: "AppleSDGothicNeoB";
          }
        }

        div.notPayBtn {
          position: absolute;
          bottom: 0;
        }

        h4 {
          text-align: center;
          font-family: "AppleSDGothicNeoB";
        }

        .is_not_pay {
          padding-top: 15%;
        }
      }
    }
  }
}
</style>
