<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <SeriesSearch :visible="seriesVisible" @pick="pick" @close="vueSeriesClose"></SeriesSearch>
      <Preview :visible="is_preview" @close="vue_close" :bizcard="bizcard"
               :bizcardImageLimit="bizcardImageLimit"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">Biz 카드뉴스 등록</h3>
            </div>
          </div>
          <div class="row mt-20">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="text-center">
                    <table class="table table-bordered">
                      <thead>
                      <!--<tr>
                        <th><span class="red">*</span>&nbsp;카테고리</th>
                        <td>
                          <select v-model="bizcard.idxSeries" class="form-control  w-20per">
                            <option value="">카테고리명을 선택해주세요</option>
                            <option v-for="series in bizcardsSeries" :value="series.idx"
                                    :key="series.idx">{{ series.name }}
                            </option>
                          </select>
                        </td>
                      </tr>-->
                      <tr>
                        <th><span class="red">*</span>&nbsp;제목</th>
                        <td>
                          <input v-model="bizcard.title" type="text" class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;썸네일<p class="red">* jpg, png 380*380 권장
                          가능</p></th>
                        <td>
                          <upload-image :img-style="'width:190px; height:190px;'" :url="imageUrl" :img="bizcard.thum" @mountImage="bizcard.thum = $event"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;사용여부</th>
                        <td class="txt-left">
                          <label><input v-model="bizcard.isUse" type="radio" value="Y">사용&nbsp;</label>
                          <label><input v-model="bizcard.isUse" type="radio" value="N">사용안함</label>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;이미지</th>
                        <td class="text-left">
                          <div>
                            <div>
                              <input id="multiUpload" type="file" ref="files" @change="totalUpload" multiple hidden>
                              <button class="btn btn-outline-primary mb-1" @click="clickT_Upload">전체 업로드</button>
                              <button class="btn btn-outline-primary mb-1 ml-1" @click="clickT_Delete">전체 삭제</button>
                            </div>
                            <biz-image :url="imageUrl" :img="bizcard.image1 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image1  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image2 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image2  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image3 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image3  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image4 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image4  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image5 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image5  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image6 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image6  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image7 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image7  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image8 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image8  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image9 "
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image9  = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image10"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image10 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image11"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image11 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image12"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image12 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image13"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image13 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image14"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image14 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image15"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image15 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image16"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image16 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image17"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image17 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image18"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image18 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image19"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image19 = $event"/>
                            <biz-image :url="imageUrl" :img="bizcard.image20"
                                       :img-style="'width:100px; height:100px;'" @mountImage="bizcard.image20 = $event"/>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> Pick4U</th>
                        <td class="text-left">
                          <input v-model="bizcard.pick4ueng" type="text" class="form-control w-100per" placeholder="Pick4U 영문 키워드를 입력해주세요"/>
                          <input v-model="bizcard.pick4ukor" type="text" class="form-control w-100per" placeholder="Pick4U 한글 키워드를 입력해주세요"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span> Say 닥터</th>
                        <td class="text-left">
                          <input v-model="bizcard.say1" type="text" class="form-control w-100per" placeholder="말하기 기능에서 음성 매칭할 문구를 입력해주세요."/>
                          <input v-model="bizcard.say2" type="text" class="form-control w-100per" placeholder="말하기 기능에서 음성 매칭할 문구를 입력해주세요."/>
                          <input v-model="bizcard.say3" type="text" class="form-control w-100per" placeholder="말하기 기능에서 음성 매칭할 문구를 입력해주세요."/>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>

                  <div class="clear mt-20">
                    <button @click="$router.push('/bizcard')" type="submit"
                            class="btn btn-primary fl">목록
                    </button>
                    <button @click="postBizcard()" type="submit" class="btn btn-primary fr ml-1">
                      저장
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-1" @click="showPreview()">
                      미리보기
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Preview from "../../components/preview/pop_preview_biz.vue";
import SeriesSearch from "@/components/pop/pop_seriesSearch";
import BizImage from "../../components/upload/uploadImage";
import host from "@/store/host";
import UploadImage from '../../components/upload/uploadImage'

export default {
  name: 'bannerAdd',
  components: {
    Menu,
    Preview,
    SeriesSearch,
    BizImage,
    UploadImage,
  },
  props: {
    msg: String
  },
  data: () => ({
    bizcard: {
      idxSeries: "",
      isUse: "Y",
      series: {
        name: ''
      },
      say1: '',
      say2: '',
      say3: '',
      image1 :null,
      image2 :null,
      image3 :null,
      image4 :null,
      image5 :null,
      image6 :null,
      image7 :null,
      image8 :null,
      image9 :null,
      image10:null,
      image11:null,
      image12:null,
      image13:null,
      image14:null,
      image15:null,
      image16:null,
      image17:null,
      image18:null,
      image19:null,
      image20:null,
      thum:null,
    },
    imageUrl: '',
    bizcardsSeries: [],
    bizcardImageLimit: 0,
    isLoading: false,
    is_preview: false,
    seriesVisible: false,
    pick4ueng: '',
    pick4ukor: '',
  }),

  mounted() {
    this.initPage();
    this.imageUrl = host + "/v1/admin/bizcards/image/image";
  },

  methods: {
    ///init
    initPage: function () {
      this.selectBizcards();
    },

    ///component
    showPreview: function () {
      this.is_preview = true;
    },
    vue_close: function (state) {
      this.is_preview = state;
    },
    vueSeriesClose: function (state) {
      this.seriesVisible = state;
    },
    pick: function (series) {
      this.bizcard.idxSeries = series.idx;
      this.bizcard.series = series;
    },

    ///image
    clickT_Delete: function (){
      for(let i=1; i <= 20; i++){
        this.bizcard['image' + i] = null;
      }
    },
    clickT_Upload: function (){
      document.getElementById('multiUpload').click();
    },
    totalUpload: function () {
      const files = this.$refs.files.files;
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let currentCount = i;
        let formData = new FormData();
        formData.append('img', file);

        this.axios.post(this.imageUrl, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((res) => {
          if (res.data.status === 0) {
            ///빈곳에 넣기

            while (currentCount <= 20){
              if(this.bizcard['image' + (currentCount + 1)] === null){
                this.bizcard['image' + (currentCount + 1)] = res.data.result;
                break;
              }
              currentCount++;
            }
          } else {
            alert(res.data.result);
          }
        })
        .catch((err) => {
          alert(`2MB 이상 이미지는 등록할 수 없습니다. err = ${err}`);
        })
      }
      document.getElementById("multiUpload").value = null;
    },

    ///bizcard
    selectBizcards: function () {
      const url = this.$store.state.host + "/v1/admin/bizcards/series";

      this.axios.get(url)
      .then((res) => {
        this.bizcardsSeries = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    postBizcard: function () {
      /*if (this.bizcard.idxSeries === "" || this.bizcard.idxSeries === null) {
        alert("카테고리명을 선택해주세요.")
      } else*/
      if (this.bizcard.title === "" || this.bizcard.title === null) {
        alert("제목명을 선택해주세요.")
      } else if (this.bizcard.thum === "" || this.bizcard.thum === null) {
        alert("썸네일을 업로드해주세요.")
      } else if (this.bizcard.thum === "" || this.bizcard.thum === null) {
        alert("썸네일을 업로드해주세요.")
      } else {
        const url = this.$store.state.host + "/v1/admin/bizcards";
        this.axios.post(url, this.bizcard)
        .then((res) => {
          var data = res.data;
          if (data.status === 150) {
            alert("비즈카드 작성에 실패했습니다. " + data.result)
          } else {
            alert("비즈카드 작성이 완료되었습니다.")
            this.$router.push("/bizcard");
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    selectImage: function () {
      let formData = new FormData();
      let url = this.$store.state.host + "/v1/admin/bizcards/image/thum";
      formData.append('img', this.$refs.thum.files[0]);
      this.isLoading = true;
      this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        this.bizcard.thum = res.data.result;
        this.isLoading = false;
      })
      .catch((err) => {
        alert(`network error = ${err}`);
        this.isLoading = false;
      });
    },

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
/* Tables */
</style>
