<template>
  <div class="dim" v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">회사 검색</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="justify-content-center" style="display: flex">
              <div class="">
                <input v-model="companyName" type="text"
                       placeholder="회사명을 입력하세요."
                       class="input"
                       @keyup.enter="selectCompanyEcarrot()"
                >
              </div>
              <div style="">
                <button @click="selectCompanyEcarrot()" class="btn btn-dark">검색</button>
              </div>
            </div>
            <div class="overflow-auto" style="min-height: 200px; max-height: 500px">
              <table class="table table-hover table-bordered">
                <tbody>
                <tr v-for="(company, index) in this.companies" :key="index">
                  <td @click="pick(company)" style="cursor: pointer" colspan="2"><i class="fas fa-check"
                                                             style="display: none;"></i>
                    <span class="link">{{ company.companyname }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popUserSearch",
  components: {},
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
  },
  data: () => ({
    companies: [],
    companyName: null,
    filter: {
      queryString: '',
      type: 'name',
    },
    lastpage: 0,
  }),

  mounted() {
  },

  methods: {
    pick(companyInfo) {
      this.$emit("companyInfo", companyInfo);
      this.$emit("close", false);
    },

    selectCompanyEcarrot() {
      const params = {
        company: this.companyName
      }
      const url = this.$store.state.host + "/v1/admin/dw/companies";
      this.axios.get(url, {params})
      .then((res) => {
        this.companies = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    close() {
      this.$emit("close", false);
    },
  },
}
</script>

<style scoped>
</style>
