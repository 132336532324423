<script>
import host from '../../store/host';

export default {
  name: "commons",
  methods: {
    successResponseHandle: function (response) {
      if (response.status === 200) {
        if (response.data.status === 0) {
          return response
        } else {
          console.error('result error : ' + response.data.result)
          return 'fail'
        }
      } else {
        console.error('request error : ' + response.status)
        return 'fail'
      }
    },

    getRequest: function (url, params) {
      const getUrl = host + url
      return new Promise((resolve) =>{
        this.axios.get(getUrl, {params})
        .then((response) => {
          const result = this.successResponseHandle(response)
          return resolve(result);
        })
        .catch((err) => {
          console.error('request error : ' + err)
          alert(`network error = ${err}`);
          throw err;
        });
      });
    },

    getParams: function (objects) {
      const changeParams = objects;
      for (let [key, value] of Object.entries(changeParams)) {
        objects[key] = (value === "" || value === null) ? null : value;
      }
      return changeParams;
    },
  },
}
</script>

