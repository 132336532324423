<script>
import axiosCommons from "@/components/commons/axiosCommons";

export default {
  name: "curationComponent",

  mixins: [axiosCommons],

  data: () => ({
    member: {
      kubun: '통합',
      division: 'B2B',
      companyName: '캐럿글로벌',
      name: '박보리',
      email: 'rachelpark@carrotglobal.com',
      mobile: '01028764076',
      isAcceptDailyWrite: 'Y',
      isAcceptAD: 'Y',
      attentions1: '전자/통신/반도체',
      attentions2: '전자/통신/반도체',
      attentions3: '전자/통신/반도체'
    },

    filter: {
      page: 1,
      title: null,
      name: null,
      email: null,
      industries: null,
      sdate: null,
      edate: null,
    },

    subscriberFilter: {
      kubun: '',
      page: 1,
      sdate: null,
      edate: null,
      name: null,
      email: null,
      division: '',
      isSubscribe: '',
      type: ''
    },

    curation: {
      createDate: '',
      title: '',
      industries: '',
      mailSendCount: 0,
      mailReadCount: 0,
      mailReadRate: '0%',
      subSendCount: 0,
      subTotalCount: 0
    },

    paymentResultSet: {
      totalSubScribeCount: 0,
      subScriberCount : 0,
      ntSubScriberCount : 0,
      payScriberCount: 0,
      totalPayment: 0,
    },

    curations: [],

    records: [],

    subscribes: [
      /*{
        merchantName: '찾아가는 큐레이션',
        duration: '3개월',
        startDate: '0000-00-00',
        endDate: '0000-00-00',
        isSubscribe: '구독중'
      },
      {
        merchantName: '로이터 통신',
        duration: '3개월',
        startDate: '0000-00-00',
        endDate: '0000-00-00',
        isSubscribe: '구독종료'
      },*/
    ],

    subscribers: [],

    curationSubscribe: [
      {
        startDate: '0000-00-00',
        title: '세계 최고의 실온 초전도체',
        attentions: [
          '전자/통신/반도체', '서비스 교육'
        ],
        isOpen: 'Open',

      }
    ],

    lastpage: 0

  }),

  methods: {
    curationPageCallback: function (pageNum) {
      this.getCurations(pageNum);
    },

    /// GET
    getCurations: function (page) {
      let industries = '';
      for (const element of document.querySelectorAll('input[name=industries]:checked')) {
        industries += (element.value + ',')
      }

      const params = {
        page: page,
        industries: industries,
        title: this.filter.title,
        sdate: this.filter.sdate,
        edate: this.filter.edate,
      }

      this.getRequest('/v1/admin/curations', params)
      .then((res) => {
        this.curations = res.data.result.value;
        this.lastpage = res.data.result.lastPage;
      })
    },

    getCurationDetail: function (idxCuration, idxUser) {
      this.getRequest(`/v1/admin/curations/` + idxCuration, {
        'idxUser': idxUser
      })
      .then((res) => {
        this.curation = res.data.result.curation;
      })
    },

    getCurationSendSubscribe: function (page, idxCuration, name, email, isSend) {
      const params = {
        'page': page,
        'idxCuration': idxCuration,
        'name': name,
        'email': email,
        'isSend': isSend
      }

      this.getRequest('/v1/admin/curations/mail/logs', params)
      .then((res) => {
        this.records = res.data.result.value;
        this.lastpage = res.data.result.lastPage;
      })
    },

    getCurationUserDetail: function (page=1, idxUser) {
      const params = {
        'page': page,
        'idxUser': idxUser
      }

      this.getRequest('/v1/admin/curations/mail/logs', params)
      .then((res) => {
        this.records = res.data.result.value;
      })
    },

    getCurationSubscribers: function () {
      this.getRequest('/v1/admin/subscribes', this.subscriberFilter)
      .then((res) => {
        this.subscribers = res.data.result.value.subscribes;
        this.paymentResultSet = res.data.result.value.paymentResultSet;
        this.lastpage = res.data.result.lastPage;
      })
    },

    showPreview: function (idx) {
      this.$router.push('/curation-preview/' + idx);
    },

    /// MOVE
    moveCuration: function (idx) {
      this.$router.push('/curation/' + idx);
    },
    moveCurations: function () {
      location.href = 'http://admin.imooc.co.kr/rest/auth/login?kname=' + localStorage.getItem(
          'kname') + '&email=' + localStorage.getItem('uid');
    },
    moveCurationCheck: function (idxUser) {
      this.$router.push('/curation-user/' + idxUser);
    }
  },
}
</script>

