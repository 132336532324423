<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">미리보기</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div class="preview">
                <div class="previewHeader">
                  <img src="../../assets/leftIcon.png" class="logo"/>
                  <p>공지사항 / 이벤트</p>
                </div>
                <div class="previewBody">
                  <div class="noticeTitle">
                    <p>{{ notice.title }}</p>
                    <span>{{ notice.subject }}</span>
                    <p class="noticeDate">{{ notice.createDate }}</p>
                  </div>
                  <div class="noticeView">
                    <p v-html="notice.content"></p>
                  </div>
                  <div class="nextNotice">
                    <img src="../../assets/upIcon.png"/>
                    <p>다음 내용</p>
                  </div>
                  <div class="prevNotice">
                    <img src="../../assets/downIcon.png"/>
                    <p>이전 내용</p>
                  </div>
                  <div class="noticeBtn">
                    <p>목록</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary">확인</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">취소</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'previewModal',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    notice: {}
  },

  data: () => ({
    is_active: true
  }),
  watch: {
    visible: function (newVal) {
      if (newVal == true) {
        this.visible = true;
        // this.doSearch();
      } else {
        this.visible = false;
      }

    }
  },
  mounted() {
    // console.log(this.tutor_email+"/");
  },

  methods: {
    changeMenu(target) {
      this.is_active = target;
    },
    close() {
      this.$emit("close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}

.preview {
  width: 80%;
  margin: 0 auto;
  border: 1px solid #dddddd;

  .previewHeader {
    padding-top: 5%;
    padding-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    overflow: auto;

    i {
      font-size: 22px;
    }

    img {
      float: left;
      width: 4%;
      padding-top: 5px;
    }

    p {
      float: left;
      margin: 0;
      font-family: "AppleSDGothicNeoB";
      font-size: 22px;
      margin-left: 25px;
    }
  }

  .previewBody {
    padding-left: 20px;
    padding-right: 20px;

    .noticeTitle {
      padding: 15px 30px;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;

      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2px;
        padding-bottom: 2px;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #ccc;
      }

      .noticeDate {
        display: inline;
        margin-left: 10px;
        color: #ccc;
      }
    }

    .noticeView {
      padding: 15px 30px;
      overflow: auto;
      height: auto;
      border-bottom: 1px solid #ddd;

      p {
        line-height: 150%;
        word-break: break-all;
      }
    }

    .nextNotice {
      padding: 15px 30px;
      border-bottom: 1px solid #ddd;

      i {
        font-size: 18px;
      }

      img {
        width: 8%;
      }

      p {
        display: inline-block;
        width: 83%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 20px;
      }
    }

    .prevNotice {
      padding: 15px 30px;
      border-bottom: 1px solid #ddd;

      i {
        font-size: 18px;
      }

      img {
        width: 8%;
      }

      p {
        display: inline-block;
        width: 83%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 20px;
      }
    }

    .noticeBtn {
      text-align: right;
      padding-top: 15px;
      padding-bottom: 15px;
      font-family: "AppleSDGothicNeoB";

    }
  }
}
</style>
