<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">리워드 관리</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <table v-if="rewardTopic==='Writing'" class="table table-bordered">
                    <thead>
                    <tr>
                      <th class="w-10per">기간</th>
                      <td colspan="7">
                        <input type="date" v-model="wRewardsFilter.sdate"> ~ <input type="date"
                                                                                   v-model="wRewardsFilter.edate">
                      </td>
                    </tr>

                    <!--
                    <tr>
                      <th style="width: 10%">구분</th>
                      <td colspan="7">
                        <select class="select" v-model="rewardsFilter.kubun">
                          <option value="0">전체</option>
                          <option value="0">글로벌(ITM)</option>
                          <option value="0">전화</option>
                        </select>
                      </td>
                    </tr>-->

                    <tr>
                      <th class="w-10per">K-name</th>
                      <td class="tl">
                        <input v-model="wRewardsFilter.kname" type="text"
                               @keyup.enter="getDailyWritingRewards()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                      <th class="w-10per">Company</th>
                      <td class="tl">
                        <input v-model="wRewardsFilter.company" type="text"
                               @keyup.enter="getDailyWritingRewards()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
<!--                      <th style="width: 10%">HQ담당자</th>
                      <td class="tl">
                        <input v-model="rewardsFilter.emp" type="text" placeholder="검색 키워드를 입력하세요."
                               @keyup.enter="getDailyWritingRewards()"
                               class="form-control">
                      </td>-->
                      <th class="w-10per">Mobile</th>
                      <td class="tl">
                        <input v-model="wRewardsFilter.mobile" type="text"
                               @keyup.enter="getDailyWritingRewards()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                    </tr>
                    </thead>
                  </table>

                  <table v-else class="table table-bordered">
                    <thead>
                    <tr>
                      <th class="w-10per">기간</th>
                      <td colspan="7">
                        <input type="date" v-model="sRewardsFilter.sdate"> ~ <input type="date"
                                                                                   v-model="sRewardsFilter.edate">
                      </td>
                    </tr>

                    <!--
                    <tr>
                      <th style="width: 10%">구분</th>
                      <td colspan="7">
                        <select class="select" v-model="rewardsFilter.kubun">
                          <option value="0">전체</option>
                          <option value="0">글로벌(ITM)</option>
                          <option value="0">전화</option>
                        </select>
                      </td>
                    </tr>-->

                    <tr>
                      <th class="w-10per">K-name</th>
                      <td class="tl">
                        <input v-model="sRewardsFilter.kname" type="text"
                               @keyup.enter="getDailyWritingRewards()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                      <th class="w-10per">Company</th>
                      <td class="tl">
                        <input v-model="sRewardsFilter.company" type="text"
                               @keyup.enter="getDailyWritingRewards()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
<!--                      <th style="width: 10%">HQ담당자</th>
                      <td class="tl">
                        <input v-model="rewardsFilter.emp" type="text" placeholder="검색 키워드를 입력하세요."
                               @keyup.enter="getDailyWritingRewards()"
                               class="form-control">
                      </td>-->
                      <th class="w-10per">Mobile</th>
                      <td class="tl">
                        <input v-model="sRewardsFilter.mobile" type="text"
                               @keyup.enter="getDailyWritingRewards()"
                               placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                    </tr>
                    </thead>
                  </table>

                  <div class="text-right mt-2">
                    <button @click="rewardTopic==='Writing'?getDailyWritingRewards():getDailySpeakingRewards()" type="submit" class="btn btn-primary">
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 탭 전환 버튼 그룹 -->
          <div class="toggle-group mt-20">
            <button v-bind:class="rewardTopic==='Writing'?'activeToggleBtn':'inactiveToggleBtn'" @click="rewardTopic='Writing'; initPage();">Daily Writing</button>
            <button v-bind:class="rewardTopic==='Speaking'?'activeToggleBtn':'inactiveToggleBtn'" @click="rewardTopic='Speaking'; initPage();">Daily Speaking</button>
          </div>

          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="form-group fr clear ">
                    <button type="submit" class="btn btn-primary fr ml-10"
                            @click="rewardTopic==='Writing'?updateDailyWritingRewardsStatus():updateDailySpeakingRewardsStatus()">발송완료
                    </button>
                    <button type="submit" class="btn btn-github fr ml-10"
                            @click="rewardTopic==='Writing'?downloadDailyWritingRewardsStatus():downloadDailySpeakingRewardsStatus()">엑셀 다운로드 
                    </button>
                  </div>
                  <div class="tab-list fl mb-1">
                    <div class="tab-item"
                         @click="getUndeliveredData()"
                         :class="{ on : rewardTopic==='Writing'?!wRewardsFilter.isSend:!sRewardsFilter.isSend }">발송대기
                    </div>
                    <div class="tab-item"
                         @click="getDeliveredData()"
                         :class="{ on : rewardTopic==='Writing'?!wRewardsFilter.isSend:!sRewardsFilter.isSend }">발송완료
                    </div>
                  </div>
                  <div v-if="rewardTopic==='Writing'" class="table-responsive text-center">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th><input id="all-select" type="checkbox"
                                   @click="allSelectDailyWritingRewards()"
                                   :disabled="wRewardsFilter.isSend"></th>
                        <th>No</th>
                        <th>Company</th>
                        <th>K-name</th>
                        <th>ID(E-mail)</th>
                        <th>Mobile</th>
                        <th>학습 완료</th>
                        <th>미접속일</th>
                        <th>총 학습</th>
                        <th>리워드 완료</th>
                        <th>리워드 완료일</th>
                        <th>발송여부</th>
                      </tr>
                      <tr v-for="(item,index) in dwnRewards" :key="item.idx">
                        <td><input class="check-rewards" type="checkbox" @change="checkCheckbox"
                                   :value="item.idx" v-show="!wRewardsFilter.isSend"></td>
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage) - index }} </td>
                        <td>{{ item.dwnMember.company }}</td>
                        <td>{{ item.dwnMember.kname }}</td>
                        <td>{{ item.dwnMember.email }}</td>
                        <td>{{ item.dwnMember.mobile }}</td>
                        <td>{{ item.dwnMember.cntLesson }}</td>
                        <td>{{ item.dwnMember.cntAbsence }}</td>
                        <td>{{ item.dwnMember.cntAttend }}</td>
                        <td>{{ item.dwnMember.cntReward }}</td>
                        <td>{{ item.rewarddate }}</td>
                        <td>{{ (item.deliverydate !== null && item.deliverydate !== '0000-00-00 00:00:00') ? item.deliverydate : '발송대기' }}</td>
                      </tr>
                      <tr v-if="wLastpage === 0">
                        <td colspan="12">리워드가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div v-show="wLastpage > 0" class="dwrPgWrapper">
                      <paginate :page-count="wLastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>

                  <div v-else class="table-responsive text-center">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th><input id="all-select" type="checkbox"
                                   @click="allSelectDailySpeakingRewards()"
                                   :disabled="sRewardsFilter.deliveryStatus==='Y'"></th>
                        <th>No</th>
                        <th>Company</th>
                        <th>K-name</th>
                        <th>ID(E-mail)</th>
                        <th>Mobile</th>
                        <th>학습 완료</th>
                        <th>미접속일</th>
                        <th>총 학습</th>
                        <th>리워드 완료</th>
                        <th>리워드 완료일</th>
                        <th>발송여부</th>
                      </tr>
                      <tr v-for="(item,index) in dsRewards.value" :key="item.idx">
                        <td><input class="check-rewards" type="checkbox" @change="checkCheckbox"
                                   :value="item.idx" v-show="sRewardsFilter.deliveryStatus!=='Y'"></td>
                        <td>{{ (dsRewards.total - (dsRewards.currentPage - 1) * dsRewards.perPage) - index }} </td>
                        <td>{{ item.companyName }}</td>
                        <td>{{ item.userName }}</td>
                        <td>{{ item.uid }}</td>
                        <td>{{ item.mobile }}</td>
                        <td>{{ item.cntY }}</td>
                        <td>{{ item.cntN }}</td>
                        <td>{{ item.cntTot }}</td>
                        <td>{{ item.rewardTot }}</td>
                        <td>{{ item.rewardDate }}</td>
                        <td>{{ (item.deliveryStatus !== null && item.deliveryStatus !== '0000-00-00 00:00:00') ? item.deliveryStatus : '발송대기' }}</td>
                      </tr>
                      <tr v-if="sLastpage === 0">
                        <td colspan="12">리워드가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div class="dsrPgWrapper" v-show="sLastpage > 0">
                      <paginate :page-count="sLastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';
import DailyWritingComponent from "@/components/model/dailyWritingComponent";
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";

export default {
  mixins: [DailyWritingComponent, DailySpeakingComponent],
  name: 'product',
  components: {
    Paginate,
    Menu,
  },
  props: {
    msg: String
  },
  data: () => ({
    rewardTopic:'Writing',
    sLastpage:1,
    wLastpage:1,
  }),

  mounted(){
    this.initPage();
  },

  methods: {
    clickCallback: function (pageNum) {
      console.log(pageNum);
      if (this.rewardTopic==='Writing'){
        this.wRewardsFilter.page = pageNum;
      }
      else {
        this.sRewardsFilter.page = pageNum;
      }
      this.initPage();
    },

    initPage: function () {
      if (this.rewardTopic==='Writing'){
        this.getDailyWritingRewards();
      }
      else{
        this.getDailySpeakingRewards();
      }
    },

    getDeliveredData(){
      if (this.rewardTopic==='Writing'){
        this.wRewardsFilter.isSend=true;
        this.getDailyWritingRewards();
      }
      else {
        this.sRewardsFilter.deliveryStatus='Y';
        this.getDailySpeakingRewards();
      }
    },

    getUndeliveredData(){
      if (this.rewardTopic==='Writing'){
        this.wRewardsFilter.isSend=false;
        this.getDailyWritingRewards();
      }
      else {
        this.sRewardsFilter.deliveryStatus='N';
        this.getDailySpeakingRewards();
      }
    },

    handleChange(e,type){
      switch (type) {
        case 'sdate': if (this.rewardTopic==='Writing'){
          this.wRewardsFilter.sdate=e.target.value;
        }else{
          this.sRewardsFilter.sdate=e.target.value;
        }
        break;

        case 'edate': if (this.rewardTopic==='Writing'){
          this.wRewardsFilter.edate=e.target.value;
        }else{
          this.sRewardsFilter.edate=e.target.value;
        }
        break;

        case 'kname': if (this.rewardTopic==='Writing'){
          this.wRewardsFilter.kname=e.target.value;
        }else{
          this.sRewardsFilter.kname=e.target.value;
        }
        break;

        case 'company': if(this.rewardTopic==='Writing'){
          this.wRewardsFilter.company=e.target.value;
        }else{
          this.sRewardsFilter.company=e.target.value
        }
        break;

        case 'mobile': if(this.rewardTopic==='Writing'){
          this.wRewardsFilter.mobile=e.target.value;
        }else{
          this.sRewardsFilter.mobile=e.target.value
        }
        break;
      }
    },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
.tab-list {
  display: flex;

  .tab-item {
    width: 100px;
    height: 25px;
    text-align: center;
    border: #000 solid 1px;
    border-radius: 2px;

    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }
  }

  .tab-item:last-child {
    margin-left: 2px;
  }

  .on {
    background: #f2f2f2;
  }
}

.toggle-group {
  display: flex;

  .activeToggleBtn, .inactiveToggleBtn{
    padding: 5px 30px;
    width:180px;
    border:none;
    border-radius:10px 10px 0 0;
    transition:background,color 0.2s ease-in-out;
  }

  .activeToggleBtn{
    background:#e9e7e7cc;
  }
  .inactiveToggleBtn{
    background:white;
    color:#C9C8C8;
  }
}

.dwrPgWrapper, .dsrPgWrapper{
  padding-left:40%;
  padding-top:20px;
}
</style>
