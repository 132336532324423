<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">카테고리 검색</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <table class="table table-hover table-bordered">
                <tr>
                  <th>카테고리</th>
                  <td><input v-model="seriesInput.name" type="text" class="form-control" id="colFormLabel"/></td>
                  <td class="txt-center">
                    <button @click="getSeries()" class="btn btn-primary">검색</button>
                  </td>
                </tr>
              </table>

              <table class="table table-hover mt-30 text-center">
                <tr>
                  <th>No</th>
                  <th>카테고리</th>
                  <th>등록일</th>
                  <th>HQ매니저</th>
                  <th>선택</th>
                </tr>
                <tr v-for="(item, index) in series" :key="item.idx">
                  <td>{{ series.length - index }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.createDate }}</td>
                  <td>{{ item.emp.englishname }}({{item.emp.koreanname}})</td>
                  <td><button class="btn btn-outline-primary" @click="selectSeries(item)">선택</button></td>
                </tr>

              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'tutorModal',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    pickSeries: {}
  },

  data: () => ({
    series: [],
    seriesInput: {},
  }),

  watch: {
    visible: function (newVal) {
      if (newVal === true) {
        this.visible = true;
        // this.doSearch();
      } else {
        this.visible = false;
      }

    }
  },
  mounted() {
    this.getSeries();
  },

  methods: {
    close() {
      this.$emit("close", false);
    },

    getSeries: function () {
      this.axios.get(this.$store.state.host + "/v1/admin/bizcards/series")
      .then((res) => {
        this.series = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    selectSeries: function (series) {
      this.$emit("pick", series);
      this.$emit("close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}
</style>
