<template>
  <div id="main">
    <div id="body">
      <div class="detailMain">
        <h1 class="detailTitle">이용약관</h1>
      </div>
      <div class="detailContent faqContent">
        <div class="wrap faqWrap">
          <div class="detailComponet">
            <span class="bold" style="font-size: 16px;">제 1 조(목적)</span><br>
            이 약관은 ㈜캐럿솔루션즈에서 제공하는 영어교육 서비스를 이용함에 있어 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 2 조(정의)</span><br>
            ① 이용자 : 이용자란 웹사이트에 접속하여 이 약관에 따라 (주)캐럿솔루션즈가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br>
            ② 회원 : 회원이란 (주)캐럿솔루션즈에 개인정보를 제공하여 회원등록을 한 자로서, (주)캐럿솔루션즈의 서비스를 지속적으로 제공받으며, (주)캐럿솔루션즈가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.<br>
            ③ 비회원 : 비회원이라 함은 회원에 가입하지 않고 (주)캐럿솔루션즈가 제공하는 서비스를 이용하는 자를 말합니다.<br>
            ④ 수강신청 : 수강신청이란 회원이 (주)캐럿솔루션즈에서 제공하는 온라인 유/무료 서비스를 이용하기 위하여 (주)캐럿솔루션즈가 정한 별도의 기준 및 절차에 따라 이용을 신청하는 것을 의미합니다.<br>
            ⑤ 수강료 : (주)캐럿솔루션즈가 제공하는 유료강의를 이용하기 위한 대가로 (주)캐럿솔루션즈가 정한 별도의 요금체계에 의해 특정기간 단위로 부과 되는 금액을 의미합니다.<br>
            ⑥ 수강승인 : 회원이 (주)캐럿솔루션즈에게 수강신청을 요구하고 (주)캐럿솔루션즈가 유/무료강의 이용을 승인하는 것을 의미합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 3 조(약관의 명시와 개정)</span><br>
            ① (주)캐럿솔루션즈는 이 약관의 내용과 상호, 영업소, 소재지, 대표자의 성명, 사업자등록번호, 연락처(전화, 팩스, 전자우편 주소 등) 등을 이용자가 알 수 있도록 (주)캐럿솔루션즈 웹사이트에 게시합니다.<br>
            ② (주)캐럿솔루션즈는 약관의 구제등에 관한 법률, 전자거래기본법, 전자 서명 법, 정보통신망이용촉진등에 관한 법률, 방문판매등에 관한 법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br>
            ③ 가(주)캐럿솔루션즈가 이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 (주)캐럿솔루션즈 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.<br>
            ④ 가(주)캐럿솔루션즈가 이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정 약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 (주)캐럿솔루션즈에 송신하여 (주)캐럿솔루션즈의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.<br>
            ⑤ ""회원""은 변경된 약관이 공지된 후 15일 이내에 변경된 약관에 대한 거부의사를 표명할 수 있으며 ""회원""이 거부의사를 표명할 경우 (주)캐럿솔루션즈는 ""회원""과의 계약을 해지할 수 있습니다. 만약 ""회원""이 변경된 약관이 공지된 후 15일 이내에 거부의사를 표시하지 않는 경우에는 변경된 약관에 동의하는 것으로 간주합니다.<br>
            ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 전자거래소비자보호지침 및 관계법령 또는 상관례에 따릅니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 4 조(서비스의 제공 및 변경)</span><br>
            ① (주)캐럿솔루션즈는 다음과 같은 업무를 수행합니다.<br>
            1. 학습정보 제공과 유/무료 서비스 정보제공 및 구매계약의 체결<br>
            2. 구매계약이 체결된 온라인 유/무료 서비스 제공<br>
            3. 기타 (주)캐럿솔루션즈가 정하는 업무<br>
            ② (주)캐럿솔루션즈는 (주)캐럿솔루션즈가 제공하는 서비스 및 기술적 사양 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 서비스 내용을 변경할 수 있습니다. 이 경우에는 변경된 서비스의 내용 및 제공일자를 명시하여 현재의 재화 및 용역의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.<br>
            ③ (주)캐럿솔루션즈가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 (주)캐럿솔루션즈의 내부적인 사유로 변경할 경우에는 (주)캐럿솔루션즈는 이로 인하여 이용자가 입은 손해를 배상합니다. 단, (주)캐럿솔루션즈의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다..<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 5 조(서비스의 중단)</span><br>
            ① (주)캐럿솔루션즈는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br>
            ② 제 1항에 의한 서비스 중단의 경우에는 (주)캐럿솔루션즈는 제 8조에 정한 방법으로 이용자에게 통지합니다.<br>
            ③ (주)캐럿솔루션즈는 제 1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제 3자가 입은 손해에 대하여 배상합니다. 단, (주)캐럿솔루션즈의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 6 조(회원가입)</span><br>
            ① 이용자는 이 약관에 동의한다는 의사표시를 한 후 (주)캐럿솔루션즈가 정한 가입 양식에 따라 회원정보를 기입함으로써 회원가입을 신청합니다.<br>
            ② (주)캐럿솔루션즈는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 온라인 및 모바일회원으로 등록합니다.<br>
            1. 가입신청자가 이 약관 제7조 제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조 제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 (주)캐럿솔루션즈의 회원 재가입 승낙을 얻은 경우에는 예외로 한다.<br>
            2. 등록내용에 허위, 기재누락, 오기가 있는 경우<br>
            3. 기타 회원으로 등록하는 것이 (주)캐럿솔루션즈의 기술상 현저한 지장이 있다고 판단되는 경우<br>
            ③ 회원가입계약의 성립시기는 (주)캐럿솔루션즈의 승낙이 회원에게 도달한 시점으로 합니다.<br>
            ④ 회원은 제 15조 제 1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편, 기타 방법으로 (주)캐럿솔루션즈에 그 변경사항을 알려야합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 7 조(회원탈퇴 및 자격 상실 등)</span><br>
            ① 회원은 (주)캐럿솔루션즈에 언제든지 탈퇴를 요청할 수 있으며 는(주)캐럿솔루션즈는 즉시 회원탈퇴를 처리합니다.<br>
            ② 회원이 다음 각 호의 사유에 해당하는 경우, 는(주)캐럿솔루션즈는 회원자격을 제한 및 정지시킬 수 있습니다.<br>
            1. 가입 신청 시에 허위 내용을 등록한 경우<br>
            2. (주)캐럿솔루션즈를 이용하여 구입한 재화, 용역 등의 대금, 기타 (주)캐럿솔루션즈 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br>
            3. 다른 사람의 (주)캐럿솔루션즈 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우<br>
            4. (주)캐럿솔루션즈를 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br>
            ③ (주)캐럿솔루션즈가 회원자격을 제한, 정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 는(주)캐럿솔루션즈는 회원자격을 상실 시킬 수 있습니다.<br>
            ④ 주)캐럿솔루션즈가 회원자격을 상실 시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 소명할 기회를 부여합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 8 조(회원에 대한 통지)</span><br>
            ① (주)캐럿솔루션즈는 회원에 대한 통지를 하는 경우, 회원이 (주)캐럿솔루션즈에 제출한 전자우편 주소 및 SMS로 할 수 있습니다.<br>
            ② (주)캐럿솔루션즈는 불특정다수 회원에 대한 통지의 경우 1주일이상 (주)캐럿솔루션즈 게시판에 게시 함으로서 개별 통지에 갈음할 수 있습니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 9 조(수강신청)</span><br>
            ① 회원은 (주)캐럿솔루션즈가 지정한 수강신청서를 작성, 제출함으로써 온라인 유/무료 강의를 수강신청 할 수 있습니다.<br>
            ② (주)캐럿솔루션즈는 수강신청에 대하여 다음 각 호에 해당하지 않는 한 수강신청을 승낙합니다.<br>
            1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br>
            2. 수강신청에 승낙하는 것이 (주)캐럿솔루션즈 기술상 현저히 지장이 있다고 판단하는 경우<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 10 조(수강료의 납부)</span><br>
            ① 회원은 (주)캐럿솔루션즈가 제공하는 온라인 유료강의를 수강하기 위해서는 지정된 수강료를 지불해야만 합니다.<br>
            ② 수강료는 (주)캐럿솔루션즈가 지정한 요금체계에 의하며 (주)캐럿솔루션즈에 공지한 가격을 기준으로 합니다.<br>
            ③ 수강료 지급방법은 다음 각 호의 하나로 할 수 있습니다.<br>
            1. 신용카드 결제<br>
            2. 무통장 입금<br>
            3. 온라인 계좌이체<br>
            4. 무통장 입금<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 11 조(수신확인통지, 수강신청 변경 및 취소)</span><br>
            ① (주)캐럿솔루션즈는 회원의 수강신청이 있는 경우 회원에게 수신확인 통지를 합니다.<br>
            ② 수신확인통지를 받은 회원은 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후 즉시 수강신청 변경 및 취소를 요청 할 수 있습니다.<br>
            ③ 캐럿은 수강승인 전 회원의 수강신청 변경 및 취소 요청이 있는 때에는 지체 없이 그 요청에 따라 처리합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 12 조(수강승인)</span><br>
            ① (주)캐럿솔루션즈는 회원으로부터 지정된 수강료 입금이 확인되는 즉시, 수강료를 지불한 유료강의에 대한 수강을 승인합니다.<br>
            ② (주)캐럿솔루션즈는 회원이 신용카드 및 온라인 계좌이체, 유/무선 전화결제, Cyberpass, ICash로 수강료를 지급할 경우, 결제완료와 동시 에 수강료를 지불한 유료강의에 대한 수강을 즉시 승인합니다.<br>
            ③ (주)캐럿솔루션즈는 회원이 무통장 입금으로 수강료를 지급할 경우, 수강료 입금확인과 동시에 수강료를 지불한 유료강의에 대한 수강을 즉시 승인합니다.<br>
            ④ (주)캐럿솔루션즈는 회원의 수강신청에 따른 승인이 이루어졌을 경우, 이를 회원에게 통지합니다.<br>
            ⑤ 수강이 승인된 정해진 관리system 의해서 학습을 시작할 수 있으며, 지정된 학습기간이 종료된 후에는 별도의 재학습 기간을 제공해 드리지 않고 있습니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 13 조(수강취소 및 환불)</span><br>
            환불의 규정은 아래와 같으며 이는 평생교육 사업법에 의거하여 규정되었습니다.<br>
            ① 전화강의 서비스 이용료 징수기간이 1개월 이내인 경우<br>
            1) 수업시작 전 - 기납입한 이용료 전액<br>
            2) 총 수업시간의 1/3이 경과하기 전 - 기납입한 이용료의 2/3에 해당되는 금액<br>
            3) 총 수업시간의 1/2이 경과하기 전 - 기납입한 이용료의 1/2에 해당되는 금액<br>
            4) 총 수업시간의 1/2이 경과한 후 - 반환하지 아니함<br>
            ② 전화강의 서비스 이용료 징수기간이 1개월을 초과하는 경우<br>
            1) 수업시작 전 - 기납입한 이용료 전액<br>
            2) 수업시작 후 - 환불사유가 발생한 그 달의 환불대상 이용료<br>
            (이용료 징수기간이 1개월 이내인 경우에 준하여 산출된 이용료)와 나머지 달의 이용료 전액을 합산한 금액 )<br>
            단, 장기수강에 따른 할인율이 적용된 경우, 환불사유 적용시점에서 수업이 완료된 개월에 대해 할인액을 재적용하여 환불전 적용된 할인액과의 차액을 환불금액에서 차감합니다. 단, 재적용되는 할인액은 신청한 상품의 수강신청페이지상에서 완료된 개월에 준한 할인율을 기준으로 합니다.<br>
            당근 포인트를 사용한 주문건의 경우, 위 환불 규정에 따라 환불액을 산정 후 해당 건 주문결제시 포인트 사용액의 비중만큼을 환불액에서 포인트로 지급합니다.<br>
            환불금액은 환불 신청 후 일주일 내에 고객님이 요청한 통장으로 입금이 되며, 카드로 결제한 고객분은 교재금액 및 종료된 수업횟수의 수강료를 (주)캐럿솔루션즈 통장계좌(우리은행 1005-603-312534, ㈜캐럿솔루션즈)로 입금하시면 승인취소가 가능합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 14 조(개인정보보호)</span><br>
            ① (주)캐럿솔루션즈는 이용자의 정보수집 시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.<br>
            다음 사항을 필수사항으로 하며 그 외 사항 은 선택사항으로 합니다.<br>
            1. 성명<br>
            2. 주소(국내 거주자에 한함)<br>
            3. 연락처<br>
            4. 희망 ID<br>
            5. 비밀번호<br>
            6. e-mail<br>
            ② (주)캐럿솔루션즈는 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.<br>
            ③ 제공된 개인정보는 당해 이용자의 동의 없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 (주)캐럿솔루션즈가 집니다. 다만, 다음의 경우에는 예외로 합니다.<br>
            1. 서비스 협력 업체와 학사관리에 필요한 최소한의 이용자 정보(성명, e-mail 주소)를 알려주는 경우<br>
            2. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br>
            ④ (주)캐럿솔루션즈는 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받는 자, 제공 목적 및 제공할 정보의 내용) 등 정보통신망이용촉진법에 관한 법률 제16조 제3항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.<br>
            ⑤ 이용자는 언제든지 (주)캐럿솔루션즈가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 (주)캐럿솔루션즈는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 (주)캐럿솔루션즈는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.<br>
            ⑥ (주)캐럿솔루션즈는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.<br>
            ⑦ (주)캐럿솔루션즈 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다..<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 15 조 (주)캐럿솔루션즈의 의무</span><br>
            ① (주)캐럿솔루션즈는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적 으로 재화•용역을 제공하는데 최선을 다하여야 합니다.<br>
            ② (주)캐럿솔루션즈는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.<br>
            ③ (주)캐럿솔루션즈는 상품이나 용역에 대하여 「표시•광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시•광고행위를 함으로써 이용자 가 손해를 입은 때에는 이를 배상할 책임을 집니다.<br>
            ④ (주)캐럿솔루션즈는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 16 조( 회원의 ID 및 비밀번호에 대한 의무)</span><br>
            ① 제15조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.<br>
            ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br>
            ③ 회원이 자신의 ID 및 비밀번호를 도난 당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 (주)캐럿솔루션즈에 통보하고 (주)캐럿솔루션즈의 안내가 있는 경우에는 그에 따라야 합니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 17 조(이용자의 의무) 이용자는 다음 행위를 하여서는 안됩니다.</span><br>
            1. 신청 또는 변경 시 허위내용의 등록<br>
            2. (주)캐럿솔루션즈에 게시된 정보의 변경<br>
            3. (주)캐럿솔루션즈가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시<br>
            4. (주)캐럿솔루션즈 기타 제3자의 저작권 등 지적재산권에 대한 침해<br>
            5. (주)캐럿솔루션즈 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br>
            6. 외설 또는 폭력적인 메시지•화상•음성 기타 공서양속에 반하는 정보를 (주)캐럿솔루션즈에 공개 또는 게시하는 행위<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 18 조(주)캐럿솔루션즈와 연결사이트 간의 관계)</span><br>
            ① (주)캐럿솔루션즈와 연결 사이트란 하이퍼 링크(예 : 하이퍼 링크의 대상에는 문자, 그림 및 동화상등이 포함됨)방식 등으로 연결된 경우를 말합니다.<br>
            ② (주)캐럿솔루션즈는 연결 사이트가 독자적으로 제공하는 서비스에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않습니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 19 조(저작권의 귀속 및 이용제한)</span><br>
            ① (주)캐럿솔루션즈가 작성한 저작물에 대한 저작권 기타 지적재산권은 (주)캐럿솔루션즈에 귀속됩니다.<br>
            ② 이용자는 를(주)캐럿솔루션즈를 이용함으로써 얻은 정보를 (주)캐럿솔루션즈의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리 목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 20 조(분쟁해결)</span><br>
            ① (주)캐럿솔루션즈는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치•운영합니다.<br>
            ② (주)캐럿솔루션즈는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 줍니다.<br>
            ③ (주)캐럿솔루션즈와 이용자간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에 의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.<br>
            <br>
            <span class="bold" style="font-size: 16px;">제 21 조(재판권 및 준거 법)</span><br>
            ① (주)캐럿솔루션즈와 이용자간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다.<br>
            ② (주)캐럿솔루션즈와 이용자간에 제기된 전자거래 소송에는 한국 법을 적용합니다.<br>
            <br>
            부 칙(시행일) 본 약관은 2001년 2월 13일부터 적용합니다.
          </div>
        </div>
        <p class="contact">※추가로 궁금하신 점은<br class="br">app.carrotenglish@gmail.com 으로 문의주세요.</p>
      </div>
    </div>

    <div class="footer mobile_footer">
      <div class="wrap">
        <div>
          <p>
            <span>Contact</span><br>
            <i class="fas fa-envelope"></i>&nbsp;app.carrotenglish@carrotglobal.com<br>
            <i class="fas fa-phone"></i>&nbsp;02-518-2603</p>
        </div>
        <div>
          <p>
            <br>
            당근농장 | ㈜캐럿솔루션즈<br>
            서울시 강남구 강남대로 488 대남빌딩 6층 ~ 9층 06120 | 사업자등록번호 621-86-16225<br>
            © <span class="bold">CARROT Solutions</span> Inc. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "download",

  mounted() {
    this.axios.get(this.$store.state.host + "/url")
    .then((res) => {
      let anroid = res.data.result[0];
      let ios = res.data.result[1];
      this.android_url = anroid.url;
      this.android_update_date = anroid.update_date;
      this.ios_url = ios.url;
      this.ios_update_date = ios.update_date;
    })
    .catch(error =>{ alert(`network error = ${error}`)});
  },

  data: () => ({
    android_url: '',
    android_update_date: '',
    ios_url: '',
    ios_update_date: ''
  }),

}
</script>

<style scoped>

#body {
  padding: 50px;
}

</style>
