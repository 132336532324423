<template>
  <div v-if="isLoading === false"
       style="width: 100%; max-width: 650px; min-width: 320px;
       margin: 0px auto; font-size: 14px; letter-spacing: -0.5px;
       line-height: 22px; word-break: keep-all;">
    <table>
      <tbody>
      <tr>
        <td>
          <div
              style="width:100%; max-width: 650px; min-width: 320px; font-family:맑은 고딕,Malgun Gothic,san-serif; font-size: 14px; letter-spacing: -0.5px; line-height: 22px; word-break: keep-all;">
            <div style="margin-bottom: 10px;">
              <img src="http://www.imooc.co.kr/resource/imooc/img/curation_mail.png" alt="">
            </div>
            <div
                style="background: #1d1e20; padding: 30px 0; clear: both; position: relative; height: 431px;">
              <div style="width: 100%; margin: 0 auto;">
                <embed width="100%" height="300px;" :src="curation.video">
              </div>
              <div style="text-align: center; width: 90%; margin:0 auto;">
                <p style="margin-block-start: 0em; margin-block-end: 0em; margin: 20px 0 0 0; word-break: keep-all; font-size: 28px; font-weight: bold; line-height: 38px; color: #fff;">
                  {{ curation.title }}</p>
                <p style="margin-block-start: 0em; margin-block-end: 0em; margin: 10px 0 0 0;  word-break: keep-all; color: #a8a8a8;">
                  {{ curation.etitle }}</p>
              </div>
            </div>

            <div style="margin-top: 30px; padding-top: 30px;">
              <div>
                <p style="margin-block-start: 0em; margin-block-end: 0em; margin: 0; font-weight: bold; font-size: 20px; float: left;">
                  <img src="http://www.imooc.co.kr/resource/imooc/img/curation_video.png"
                       style="margin-right: 10px;">Video Script</p>
                <a target="_blank" @click="change()"
                   style="float: right; font-size: 12px; color:#000; margin-bottom: 5px; text-decoration: underline; cursor: pointer ">번역보기</a>
              </div>
              <div id="escript"
                   style="border: 1px solid #ddd; padding: 15px; clear: both; word-break: keep-all;">
                <p style="margin-block-start: 0em; margin-block-end: 0em; margin: 0; white-space:pre-line; text-align: left;">
                  {{ curation.kscript }}
                </p>
              </div>
              <div id="kscript"
                   style="display: none; border: 1px solid #ddd; padding: 15px; clear: both; word-break: keep-all;">
                <p style="margin-block-start: 0em; margin-block-end: 0em; margin: 0; white-space:pre-line; text-align: left;">
                  {{ curation.escript }}
                </p>
              </div>
            </div>

            <div
                style="background: rgb(118, 120, 128); padding: 10px 20px; text-align: center; color: rgb(255, 255, 255); line-height: 20px; font-size: 13px; margin-top: 50px;">
          <span
              style="color: rgb(204, 204, 204); padding-bottom: 5px;">We Serve The Global Success</span><br>
              <p style="margin-block-start: 0em; margin-block-end: 0em; color: rgb(204, 204, 204);">
                ⓒ
                by
                <a target="_blank" href="http://www.carrotenglish.com/"
                   style="text-decoration:none; color:#ffa759;"><span
                    style="font-weight:bold;">CARROT</span> Global</a></p>
              <div
                  style="margin: 10px auto; width: 60px; border-top-color: rgb(150, 150, 150); border-top-width: 1px; border-top-style: solid;"></div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "curationMailPad",

  data: () => ({
    curation: {},
    isLoading: true
  }),

  methods: {
    change: function () {
      const display = getComputedStyle(document.querySelector('#escript')).display;
      if (display === 'block') {
        document.querySelector('#escript').style.display = 'none';
        document.querySelector('#kscript').style.display = 'block';
      } else {
        document.querySelector('#escript').style.display = 'block';
        document.querySelector('#kscript').style.display = 'none';
      }
    },
  },

  mounted() {
    const url = this.$store.state.host + "/curations?idx=" + this.$route.params.idx;
    this.axios.get(url)
    .then((res) => {
      if (res.data.status === 0) {
        this.curation = res.data.result.curation;
        this.isLoading = false;
      } else {
        alert(res.data.result);
      }
    })
    .catch(error =>{ alert(`network error = ${error}`)});
  }
}
</script>

<style scoped>

</style>
