<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">미리보기</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <div class="preview">
                <div class="previewBody">
                  <img src="../../assets/leftIcon_white.png" alt="img" class="prevBtn"/>
                  <div class="slider">
                    <slick ref="slick" :options="slickOptions">
                      <!-- <img src="../../assets/bizBg.png" alt=""> -->
                      <div class="bizimg">
                        <div class="image">
                          <img style="background-color: black; opacity: 50%"
                              :src="(bizcard.image1 === 'undefined') ? '../../assets/bizBg.png' : bizcard.image1"
                              alt="">
                          <p class="pageCount"><span style="height: 100%"></span></p>
                          <div class="slideInfo">
                            <div class="slideTitle">
                              <h2>{{ bizcard.title }}</h2>
                              <p>{{ bizcardSeriesName }}</p>
                              <p>73000명 읽음 | 2020. 09. 10</p>
                            </div>
                            <div class="slideLeft">
                              <img src="../../assets/rightIcon_white.png"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image1 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image1" alt="">
                            <p class="pageCount">1<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image2 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image2" alt="">
                            <p class="pageCount">2<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image3 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image3" alt="">
                            <p class="pageCount">3<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image4 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image4" alt="">
                            <p class="pageCount">4<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image5 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image5" alt="">
                            <p class="pageCount">5<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image6 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image6" alt="">
                            <p class="pageCount">6<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image7 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image7" alt="">
                            <p class="pageCount">7<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image8 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image8" alt="">
                            <p class="pageCount">8<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image9 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image9" alt="">
                            <p class="pageCount">9<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image10 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image10" alt="">
                            <p class="pageCount">10<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image11 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image11" alt="">
                            <p class="pageCount">11<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image12 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image12" alt="">
                            <p class="pageCount">12<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image13 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image13" alt="">
                            <p class="pageCount">13<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image14 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image14" alt="">
                            <p class="pageCount">14<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image15 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image15" alt="">
                            <p class="pageCount">15<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image16 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image16" alt="">
                            <p class="pageCount">16<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image17 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image17" alt="">
                            <p class="pageCount">17<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image18 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image18" alt="">
                            <p class="pageCount">18<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image19 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image19" alt="">
                            <p class="pageCount">19<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                      <div v-if="bizcard.image20 !== null">
                        <div class="bizimg">
                          <div class="image">
                            <img :src="bizcard.image20" alt="">
                            <p class="pageCount">20<span>/{{ bizcardImageLimit }}</span></p>
                          </div>
                        </div>
                      </div>
                    </slick>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Slick from 'vue-slick';

export default {
  name: 'previewModal',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    bizcard: {},
    bizcardImageLimit: Number,
  },
  components: {
    Slick
  },
  data: () => ({
    is_active: true,
    slickOptions: {
      // Any other options that can be got from plugin documentation
    },
    bizcardSeriesName: String,
  }),
  watch: {
    visible: function () {
      this.initPage();
    }
  },
  mounted() {
    // console.log(this.tutor_email+"/");
  },

  methods: {
    initPage() {
      const url = this.$store.state.host + "/v1/admin/bizcards/series/" + this.bizcard.idxSeries;
      this.axios.get(url)
      .then((res) => {
        if (res.data.status === 0) {
          this.bizcardSeriesName = res.data.result.name;
        } else {
          alert(res.data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    changeMenu(target) {
      this.is_active = target;
    },
    close() {
      this.$emit("close", false);
    },
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}

.preview {
  width: 80%;
  margin: 0 auto;
  border: 1px solid #dddddd;

  .previewBody {
    position: relative;
    overflow: hidden;

    .prevBtn {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 5%;
      left: 15px;
      top: 50px;
    }

    .slider {
      width: 100%;
      height: 100%;
      position: relative;

      .sliderThum {
        background: black;
        height: 600px;
        display: flex;
        text-align: center;
        vertical-align: middle;
      }

      .sliderThum img {


      }

      .slideInfo {
        width: 373px;
        padding-left: 15px;
        position: absolute;
        text-align: left;
        top: 40%;
        overflow: auto;
        padding-right: 15px;

        .slideTitle {
          width: 80%;
          float: left;

          h2 {
            color: white;
            font-family: "AppleSDGothicNeoB";
            font-size: 20px;
          }

          p {
            color: white;
          }
        }

        .slideLeft {
          width: 20%;
          text-align: right;
          float: left;

          img {
            width: 45%;
            margin-top: 30px;
          }
        }
      }

      img {
        width: 100%;
      }

      .bizimg {
        background: black;
        height: 600px;
        display: table !important;

        .image {
          display: table-cell !important;
          text-align: center;
          vertical-align: middle;

          img {
            width: 100%;
          }

          .pageCount {
            color: white;

            span {
              color: gray;
              font-family: "";
            }
          }
        }
      }
    }
  }
}
</style>
