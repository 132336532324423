<script>
import axiosCommons from "@/components/commons/axiosCommons";

export default {
  name: "memberComponent",

  mixins: [axiosCommons],

  data: () => ({
    member: {
      mobile: ''
    },
    pushes: {},
    subscribes: [],
    members: [],
    count: {},
    memberFilter: {
      name: '',
      email: '',
      companyname: '',
      mobile: '',
      division: '',
      membership: '',
      page: 1
    },
    lastpage: 1
  }),

  methods: {
    getMember: function (idxUser) {
      this.axios.get(this.$store.state.host + "/v1/admin/users/" + idxUser)
      .then((res) => {
        this.member = res.data.result.user;
        this.pushes = res.data.result.pushes;
      })
      .catch(error =>{ alert(`network error = ${error}`)});

      this.axios.get(this.$store.state.host + "/v1/admin/subscribes/" + idxUser)
      .then((res) => {
        this.subscribes = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getMembers: function (page) {
      if (page !== undefined) {
        this.memberFilter.page = page;
      }
      const url = this.$store.state.host + "/v1/admin/users";
      var params = {
        page: (this.memberFilter.page === '' ? null : this.memberFilter.page),
        uid: (this.memberFilter.uid === '' ? null : this.memberFilter.uid),
        companyname: (this.memberFilter.companyname === '' ? null : this.memberFilter.companyname),
        mobile: (this.memberFilter.mobile === '' ? null : this.memberFilter.mobile),
        division: (this.memberFilter.division === '' ? null : this.memberFilter.division),
        membership: (this.memberFilter.membership === '' ? null : this.memberFilter.membership),
        name: (this.memberFilter.name === '' ? null : this.memberFilter.name)
      };

      this.axios.get(url, {params})
      .then((res) => {
        if (res.data.status === 105) {
          this.members = [];
          this.count = {
            itmCount: 0,
            ctmCount: 0,
            totalCount: 0,
            selfCount: 0,
          };
          this.lastpage = 0
        } else {
          this.result = res.data.result.userList;
          this.members = res.data.result.userList.value;
          this.count = res.data.result.statics;
          this.lastpage = res.data.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    subject: function (item) {
      switch (item) {
        case 'curation':
          return '찾아가는 큐레이션';
        case 'curation-year':
          return '찾아가는 큐레이션';
        case 'bizcard':
          return 'Biz 카드뉴스';
        case 'bizcard-year':
          return 'Biz 카드뉴스';
        default:
          return '알수없음';
      }
    },

    putMember: function () {
      const url = this.$store.state.host + "/v1/admin/users/" + this.$route.params.idx
          + "/subscribe";

      var params = {
        dailyWright: this.member.isAcceptDailyWrite,
        ad: this.member.isAcceptAd,
        name: this.member.userName,
        mobile: this.member.mobile
      }

      this.axios.put(url, params)
      .then(function (res) {
        var data = res.data;
        if (data.status === 0) {
          alert("수정이 완료되었습니다.")
        } else {
          alert("수정에 실패했습니다. " + data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }
}
</script>

