<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">[{{program.programName}}] 신청자 리스트</h3>
              <!-- <p class="lead">
                  - 메인페이지에 노출 될 각종 교육신청, 이벤트 페이지를 등록할 수 있습니다.<br>
              </p> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th width="60">이름</th>
                        <td>
                          <input @keyup.enter="getProgramApply(1)" v-model="filter.name" type="email" class="form-control" id="colFormLabel"
                                 placeholder="검색어를 입력하세요.">
                        </td>
                        <th>ID(Email)</th>
                        <td>
                          <input @keyup.enter="getProgramApply(1)" v-model="filter.email" type="email" class="form-control" id="colFormLabel"
                                 placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      <tr>
                        <th>회사명</th>
                        <td>
                          <input @keyup.enter="getProgramApply(1)" v-model="filter.companyName" type="email" class="form-control" id="colFormLabel"
                                 placeholder="검색어를 입력하세요.">
                        </td>
                        <th>Mobile</th>
                        <td>
                          <input @keyup.enter="getProgramApply(1)" v-model="filter.mobile" type="email" class="form-control" id="colFormLabel"
                                 placeholder="검색어를 입력하세요.">
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-right mt-2">
                    <button type="submit" class="btn btn-primary" @click="getProgramApply(1)">검색</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="clear mb-2">
                    <div class="form-group float-left clear w-100per">
                      <select @change="getProgramApply()" v-model="filter.status" class="form-control fl w-20per">
                        <option value="">전체 신청 상태</option>
                        <option value="S">수강</option>
                        <option value="R">취소요청</option>
                        <option value="C">취소</option>
                      </select>

                      <select @change="getProgramApply()" v-model="filter.division" class="form-control fl ml-10 w-20per">
                        <option value="">전체 회원구분</option>
                        <option value="CTM">CTM</option>
                        <option value="ITM">ITM</option>
                        <option value="통합">통합</option>
                        <option value="자체유입">자체유입</option>
                      </select>

                      <button @click="getApplyExcel()" type="submit" class="btn btn-primary fr ml-10">Excel 다운로드</button>
                    </div>
                    <!-- <div class="float-right">
                    <button type="button" class="btn btn-primary btn-icon-text" @click="excelDown()">
                        <i class="btn-icon-prepend" data-feather="file-text"></i>
                        Excel Download
                    </button>
                </div> -->
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th v-for="(item) in applyListHeader" :key="item">
                          <div class="text-elipsis" :title="item">
                            {{ item }}
                          </div>
                        </th>
                      </tr>
                      <tr v-for="(apply,applyIdx) in applyUsers" :key="applyIdx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - applyIdx }}</td>
                        <td>{{ apply.applyDate }}</td>
                        <td>{{ apply.division }}</td>
                        <td>{{ apply.companyName }}</td>
                        <td><a :href="'/memberview/' + apply.idxUser">{{ apply.userName }}</a></td>
                        <td>{{ apply.email }}</td>
                        <td>{{ apply.mobile }}</td>
                        <td v-if="apply.status === '취소요청'"><button @click="deleteProgramApply(apply.idx)" class="btn btn-outline-primary">신청취소</button></td>
                        <td v-else>{{ apply.status }}</td>
                        <td>{{ apply.answer1 }}</td>
                        <td>{{ apply.answer2 }}</td>
                        <td>{{ apply.answer3 }}</td>
                        <td>{{ apply.answer4 }}</td>
                        <td>{{ apply.answer5 }}</td>
                        <td>{{ apply.answer6 }}</td>
                        <td>{{ apply.answer7 }}</td>
                        <td>{{ apply.answer8 }}</td>
                        <td>{{ apply.answer9 }}</td>
                        <td>{{ apply.answer10 }}</td>
                      </tr>

                      <tr v-if="applyUsers.length === 0">
                        <td colspan="18">검색하신 신청자 리스트가 존재하지 않습니다.</td>
                      </tr>
                    </table>

<!--                     
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th class="w-10per">No</th>
                        <th class="w-10per">신청일</th>
                        <th class="w-10per">회원구분</th>
                        <th class="w-10per">회사명</th>
                        <th class="w-10per">이름</th>
                        <th>ID(이메일)</th>
                        <th class="w-10per">Mobile</th>
                        <th class="w-10per">상태</th>
                      </tr>
                      <tr v-for="(apply,index) in applyUsers" :key="apply.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ apply.applyDate }}</td>
                        <td>{{ apply.division }}</td>
                        <td>{{ apply.companyName }}</td>
                        <td><a :href="'/memberview/' + apply.idxUser">{{ apply.userName }}</a></td>
                        <td>{{ apply.email }}</td>
                        <td>{{ apply.mobile }}</td>
                        <td v-if="apply.status === '취소요청'"><button @click="deleteProgramApply(apply.idx)" class="btn btn-outline-primary">신청취소</button></td>
                        <td v-else>{{ apply.status }}</td>
                      </tr>
                      <tr v-if="applyUsers.length === 0">
                        <td colspan="8">검색하신 신청자 리스트가 존재하지 않습니다.</td>
                      </tr>
                    </table> -->
                  </div>
                  <div v-if="lastpage !== 0" style="padding-left:40%; padding-top:20px;">
                    <paginate
                        :page-count="lastpage"
                        :page-range="3"
                        :margin-pages="2"
                        :click-handler="clickCallback"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination'"
                        :page-class="'page-link'">
                    </paginate>
                  </div>
                  <button @click="$router.push('/program')" type="submit" class="btn btn-primary mt-1 fl">목록</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';

export default {
  name: 'programUser',
  components: {
    Menu,
    Paginate,
  },
  props: {
    msg: String
  },
  data: () => ({
    program:{},
    applyUsers:[],
    filter:{
      page:1,
      name:'',
      email:'',
      companyName:'',
      mobile:'',
      status:'',
      division:'',
    },
    lastpage: 1,
    applyListHeader:['No','신청일','회원구분','회사명','이름','ID(이메일)','Mobile','상태']
  }),
  mounted() {
    this.initPage();
    this.getProgram();
    this.getProgramApply();
  },
  methods: {
    initPage: function () {
    },

    /**
     * @description : ListHeader 세팅
     */
    setApplyListHeader(addList){
      console.log('addList : ' , addList );
      for(let i = 0 ; i < addList.length ; i++){
        this.applyListHeader.push(addList[i]);
      }

      console.log('applyListHeader : ' , this.applyListHeader);
    },

    clickCallback: function (pageNum) {
      this.getProgramApply(pageNum);
    },

    getProgram: function () {
      const url = this.$store.state.host + "/v1/admin/programs/" + this.$route.params.idx;
      this.axios.get(url)
      .then((res) => {
        var data = res.data;
        const result = data.result.detail;
        const sendArr = [];
        for(let item in result){
          if(item.indexOf('question')>-1){
            sendArr.push(result[item] ? result[item] : item);
          }
        }
        /* Header set */
        this.setApplyListHeader(sendArr);

        if (data.status === 0) {
          this.program = res.data.result;
        } else {
          alert(data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    deleteProgramApply: function (idx) {
      const url = this.$store.state.host + "/v1/admin/programs/apply/" + idx;
      this.axios.delete(url)
      .then((res) => {
        let data = res.data;
        if (data.status === 0) {
          this.getProgramApply(this.filter.page);
        } else {
          alert(data.result);
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getProgramApply: function (page) {
      const params = {
        page: page,
        name: (this.filter.name !== '') ? this.filter.name : null,
        uid: (this.filter.email !== '') ? this.filter.email : null,
        companyName: (this.filter.companyName !== '') ? this.filter.companyName : null,
        mobile: (this.filter.mobile !== '') ? this.filter.mobile : null,
        status: (this.filter.status !== '') ? this.filter.status : null,
        division: (this.filter.division !== '') ? this.filter.division : null,
      }

      const url = this.$store.state.host + "/v1/admin/programs/apply/" + this.$route.params.idx;
      this.axios.get(url, {params})
      .then((res) => {
        if(res.data.status === 105){
          this.applyUsers = [];
          this.lastpage = 0;
        }else{
          this.result = res.data.result;
          this.applyUsers = res.data.result.value;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getApplyExcel: function () {
      var params = {
        name: (this.filter.name !== '') ? this.filter.name : null,
        uid: (this.filter.email !== '') ? this.filter.email : null,
        companyName: (this.filter.companyName !== '') ? this.filter.companyName : null,
        mobile: (this.filter.mobile !== '') ? this.filter.mobile : null,
        status: (this.filter.status !== '') ? this.filter.status : null,
        division: (this.filter.division !== '') ? this.filter.division : null,
      }

      const url = this.$store.state.host + "/v1/admin/excel/apply/" + this.$route.params.idx;
      var config = {
        params,
        url : url,
        responseType: 'blob'
      }

      this.axios.get(url, config)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.program.programName + ' 신청자리스트.xlsx');
        document.body.appendChild(link);
        link.click();
        console.log(link);
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">
table.table.table-hover.table-bordered{
  /*
  * 9번 이상부터는 
  */
  th:nth-child(n+9){
    .text-elipsis{
      width:120px;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
}
</style>
