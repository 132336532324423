<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">로이터 관리</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th rowspan="2">NO</th>
                        <th rowspan="2">코드명</th>
                        <th rowspan="2">노출일</th>
                        <th rowspan="2">Title</th>
                        <th colspan="3">메일</th>
                        <th rowspan="2">구독자<br>조회수</th>
                        <th rowspan="2">구독자<br>누적 조회수</th>
                        <th rowspan="2">누적<br>조회수</th>
                        <th rowspan="2">미리 보기</th>
                      </tr>
                      <tr>
                        <th>발송 수</th>
                        <th>오픈 수</th>
                        <th>오픈율</th>
                      </tr>
                      <tr v-for="(item, index) in this.reuters" :key="item.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage  ) - index }}</td>
                        <td>{{ item.newsCode }}</td>
                        <td>{{ item.IS_FARMDATE }}</td>
                        <td><a style="color:dodgerblue; cursor: pointer" @click="moveReuter(item.news_seq)">{{ item.eng_title }}</a></td>
                        <td>{{ item.reuter_mail_send_count }}</td>
                        <td>{{ item.reuter_mail_read_count }}</td>
                        <td>{{ item.reuter_mail_read_rate }}</td>
                        <td>{{ item.reuter_sub_send_count }}</td>
                        <td style="width: 5%">{{ item.reuter_sub_total_count }}</td>
                        <td>{{ item.HIT_FARM }}</td>
                        <td>
                          <button @click="showPreview(item.news_seq)">View</button>
                        </td>
                      </tr>
                    </table>

                    <div style="padding-left:35%; padding-top:20px;">
                      <paginate :page-count="lastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';
import ReuterComponent from "@/components/model/reuterComponent";

export default {
  mixins: [ReuterComponent],
  name: 'Rueter',
  components: {
    Menu,
    Paginate
  },

  props: {
    msg: String
  },

  mounted() {
    this.getReuters(this.page)
  },
}
</script>

<style scoped lang="scss">

</style>
