<template>
  <div class="carrot">
    <div class="main-wrapper">
      <Menu></Menu>
      <PronunciationAddModal :visible="cpModalVisible" @close="cpModalClose"></PronunciationAddModal>
      <CompositionAddModal :visible="ccModalVisible" @close="ccModalClose"></CompositionAddModal>
      <CompositionMultiAddModal :visible="cmModalVisible" @close="cmModalClose"></CompositionMultiAddModal>
      <div class="page-wrapper">
        <div class="page-content">
          
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">데일리 스피킹 관리</h3>
            </div>
          </div>

          <!-- 탭 전환 버튼 그룹 -->
          <div class="toggle-group mt-20">
            <button v-bind:class="curTopic==='pronunciation'?'activeToggleBtn':'inactiveToggleBtn'" @click="changeToPronunciation()">발음</button>
            <button v-bind:class="curTopic==='composition'?'activeToggleBtn':'inactiveToggleBtn'" @click="changeToComposition()">입영작</button>
          </div>

          <!-- 발음 검색조건 테이블 -->
          <div class="row" v-if="curTopic==='pronunciation' ">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="w-10per">발음</th>
                        <td>
                          <select class="select" v-model="filter.selectedPronunciation">
                            <option value="">전체</option>
                            <option v-for="item in categoryPronunciations" :key="item.idx" :value="item.idx">{{item.category}}</option>
                          </select>
                        </td>
                        <th class="w-10per">사용여부</th>
                        <td>
                          <select class="select" v-model="filter.useStatus">
                            <option value="">전체</option>
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th class="w-10per">발송일</th>
                        <td>
                          <input 
                            type="date" 
                            class="form-control dateInput" 
                            id="session-date" name="session-date" 
                            v-model="filter.sendDate">
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div class="text-right mt-2">
                    <button
                      @click="saveFormQuery(); getDailySpeakingPronunciation(filter);"
                      type="submit"
                      class="btn btn-primary"
                    >
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 입영작 검색조건 테이블 -->
          <div class="row" v-else>                        
              <div class="col-md-12 grid-margin stretch-card">                
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="w-10per">카테고리</th>
                        <td>
                          <select class="select" v-model="filter.selectedComposition">
                            <option value="">전체</option>
                            <option v-for="item in compositionCategories" :key="item.idx" :value="item.idx">{{item.categoryName}}</option>
                          </select>
                        </td>
                        <th class="w-10per">사용여부</th>
                        <td>
                          <select class="select" v-model="filter.useStatus">
                            <option value="">전체</option>
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <th class="w-10per">발송일</th>
                        <td>
                          <input 
                            v-model="filter.sendDate" 
                            type="date" 
                            class="form-control dateInput" 
                            id="session-date" name="session-date">
                        </td>
                      </tr>
                    </thead>
                  </table>
                  <div class="text-right mt-2">
                    <button
                      @click="saveFormQuery(); getDailySpeakingComposition(filter);"
                      type="submit"
                      class="btn btn-primary"
                    >
                      검색
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        <!-- 컨텐츠 리스트 테이블 -->
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">                
                <div class="card-body">
                  <div class="form-group float-left clear w-100per">

                    <button 
                      type="submit" 
                      class="btn btn-secondary fr ml-10"
                      @click="$store.commit('saveQuery',{...$store.state.dsState.dsQuery, curTopic:curTopic}); moveToDetailPage();">
                      {{curTopic==='pronunciation'?'등록':'개별등록'}}
                    </button>
                    <button 
                      v-if="curTopic ==='composition' "
                      type="submit" 
                      @click="modalOnMultiAdd()"
                      class="btn btn-secondary fr ml-10">
                        일괄등록
                    </button>                    
                    <button 
                      type="submit" 
                      class="btn btn-secondary fr ml-10"        
                      @click="modalOn()">
                        {{curTopic==='pronunciation'?'발음등록':'카테고리 등록'}}
                    </button>                    
                  </div>
                  <div class="table-responsive text-center">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th>No</th>
                        <th>발송일</th>
                        <th>등록일</th>
                        <th>{{curTopic==='pronunciation'?'발음':'카테고리'}}</th>
                        <th>사용여부</th>
                        <th>컨텐츠</th>
                      </tr>
                      <tr v-for="(item , index) in dss" :key="item.idx">
                        <td>{{index + 1}}</td>
                        <td>{{item.sendDate?item.sendDate:'-'}}</td>
                        <td>{{item.createDate?item.createDate:'-'}}</td>
                        <td>{{curTopic==='pronunciation'?item.category:item.categoryName}}</td>
                        <td>{{item.useStatus}}</td>
                        <td><span class="goBtn" @click="$store.commit('saveQuery',{...$store.state.dsState.dsQuery, curTopic:curTopic}); saveItemInfo(item); moveToDetailPage();">Go ></span></td>
                      </tr>
                      <tr v-if="sLastpage === 0" >
                        <td v-if = "curTopic==='pronunciation'" colspan="6" >발음 컨텐츠가 존재하지 않습니다.</td>
                        <td v-else colspan="6" >입영작 컨텐츠가 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div class="dsPgWrapper">
                      <paginate :page-count="sLastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
</template>

<script>
import Menu from "../../components/commons/IncMenu.vue";
import Paginate from 'vuejs-paginate';
import PronunciationAddModal from "../../components/pop/popPronunciationAdd.vue";
import CompositionAddModal from "../../components/pop/popCompositionAdd.vue";
import CompositionMultiAddModal from "../../components/pop/popCompositionMultiAdd.vue";
import DailySpeakingComponent from "@/components/model/dailySpeakingComponent";

export default {
  mixins:[DailySpeakingComponent],
  components: {
    Menu,
    Paginate,
    PronunciationAddModal,
    CompositionAddModal,
    CompositionMultiAddModal,
  },

  data(){
    return{
      dss:[],
      sLastpage: 1,
      cpModalVisible:false,
      ccModalVisible:false,
      cmModalVisible:false,
      // selectRegistration  1.individual 개별등록  2.collectively 일괄등록
      selectRegistration: 'individual'
    }
  },

  methods:{
    //발음등록 모달 On
    modalOn(){
        if (this.curTopic==='pronunciation'){
          this.cpModalVisible=true;
        }else{
          this.ccModalVisible=true;
        }
        
      },
    modalOnMultiAdd(){
      this.cmModalVisible=true;
    }, 
    //발음등록 모달 Off
    cpModalClose(){
      this.cpModalVisible = false;
    },
    //입영작 카테고리 모달 Off
    ccModalClose(){
      this.ccModalVisible = false;
    },
    //일괄등록 카테고리 모달 Off
    cmModalClose(){
      this.cmModalVisible = false;
    },

    clickCallback: function (pageNum) {
      this.filter.page = pageNum;
      if(this.curTopic == `composition`){
          this.getDailySpeakingComposition(this.filter);
      }
      else if(this.curTopic == `pronunciation`){
        this.getDailySpeakingPronunciation(this.filter);
      }
    },
  },

  beforeMount(){
    //스토어 아이템 정보 초기화
    this.$store.commit('resetItemInfo');
    
    //검색 쿼리 정보 존재 확인 후 있으면 적용
    if (this.hasQuery){
      const query=this.$store.state.dsState.dsQuery
      
      if (query.curTopic==='pronunciation'){
        this.curTopic=query.curTopic;
        this.filter={
          ...this.filter,
          selectedPronunciation:query.selectedPronunciation,
          useStatus:query.useStatus,
          sendDate:query.sendDate
        }
        this.getCategoryPronunciationList();
        this.getDailySpeakingPronunciation(this.filter);
      }
      else if (query.curTopic==='composition'){
        this.curTopic=query.curTopic;
        this.filter={
          ...this.filter,
          selectedComposition:query.selectedComposition,
          useStatus:query.useStatus,
          sendDate:query.sendDate
        }
        this.getCompositionCategoryList();
        this.getDailySpeakingComposition(this.filter);
      }
    } 
    else {
      this.changeToPronunciation();
    }
  },

  computed: {
    hasQuery(){
      return Object.keys(this.$store.state.dsState.dsQuery).length>0;
    }
  }

};
</script>

<style scoped lang="scss">
.toggle-group {
  display: flex;

  .activeToggleBtn, .inactiveToggleBtn{
    padding: 5px 30px;
    width:150px;
    border:none;
    border-radius:10px 10px 0 0;
    transition:background,color 0.3s linear;
  }

  .activeToggleBtn{
    background:#e9e7e7cc;
  }
  .inactiveToggleBtn{
    background:white;
    color:#C9C8C8;
  }
}

.dateInput#session-date{
  position:relative;
  overflow:hidden;
}
.dateInput#session-date::-webkit-calendar-picker-indicator{
  width:100%;
  display:block;
  top:0;
  left:0;
  background: #0000;
  position:absolute;
  transform: scale(12);
  cursor:pointer;
}

.goBtn{
  color:#4286f4;
  cursor:pointer;
}

.dsPgWrapper{
  display:flex;
  padding-left:40%;
  margin-top:30px;
}
</style>