<template>
  <div v-if="visible">
    <div class="dim">
      <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel">카테고리 등록</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                      @click="close()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body">
              <table class="table table-hover table-bordered">
                <tr>
                  <th>카테고리</th>
                  <td><input v-model="category.name" type="text" class="form-control"
                             id="colFormLabel"/></td>
                  <td class="txt-center">
                    <button @click="postCategory()" class="btn btn-primary">추가</button>
                  </td>
                </tr>
              </table>

              <table class="table table-hover table-bordered mt-30 text-center">
                <tr>
                  <th>No</th>
                  <th>카테고리</th>
                  <th>등록일</th>
                  <th>HQ매니저</th>
                  <th>삭제</th>
                </tr>
                <tr v-for="(category, index) in popCategories" :key="category.idx">
                  <td>{{ popCategories.length - index }}</td>
                  <td>{{ category.name }}</td>
                  <td>{{ category.createDate }}</td>
                  <td>{{ category.empName }}</td>
                  <td>
                    <button class="btn btn-outline-primary" @click="deleteCategory(category.idx)">
                      삭제
                    </button>
                  </td>
                </tr>

              </table>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-primary">확인</button>
                <button type="button" class="btn btn-default" data-dismiss="modal">취소</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'tutorModal',
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
  },

  data: () => ({
    popCategories: [],
    category: {},
  }),
  watch: {
    visible: function (newVal) {
      this.visible = newVal === true;
    }
  },
  mounted() {
    this.axios.get(this.$store.state.host + "/v1/admin/programs/category")
    .then((res) => {
      this.popCategories = res.data.result;
    })
    .catch(error =>{ alert(`network error = ${error}`)});
  },

  methods: {
    close() {
      this.$emit("close", false);
    },

    getCategories: function () {
      this.axios.get(this.$store.state.host + "/v1/admin/programs/category")
      .then((res) => {
        this.popCategories = res.data.result;
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    postCategory: function () {
      const url = this.$store.state.host + "/v1/admin/programs/category";
      this.axios.post(url, this.category)
      .then((res) => {
        if (res.data.status === 100) {
          alert(res.data.result)
        } else {
          this.getCategories();
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
      this.category.name = '';
    },

    deleteCategory: function (idx) {
      if (confirm('삭제 하시겠습니까?')) {
        const url = this.$store.state.host + "/v1/admin/programs/category/" + idx;
        this.axios.delete(url)
        .then((res) => {
          if (res.data.status === 0) {
            this.getCategories();
          } else {
            alert(res.data.result);
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}

.modal {
  display: block;
}
</style>
