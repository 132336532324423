<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <Preview :visible="is_preview" @close="vue_close" :preview_notice="preview_notice" :notice="notice"></Preview>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">공지사항/이벤트 등록</h3>
              <!-- <p class="lead">
                  - CARROT 앱에 로그인 성공한 회원만 표시됩니다.<br>
                  - 회원구분: CTM(전화영어 학습자), ITM(글로벌 오프라인 학습자), 통합 (전화영어 + 글로벌 오프라인 학습자)
              </p> -->
            </div>
          </div>
          <div class="row mt-10">
            <div class="col-md-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive text-center mt-20">
                    <table class="table table-bordered">
                      <thead>
                      <tr>
                        <th><span class="red">*</span>&nbsp;구분</th>
                        <td class="txt-left">
                          <label><input type="radio" v-model="notice.subject" value="공지사항">공지사항</label>
                          <label class="ml-1"><input type="radio" v-model="notice.subject" value="이벤트">이벤트</label>
                          <!--<button @click="notice.subject = '공지사항'" class="btn w-30 btn-dark "
                                  :class="{ 'disabled' : notice.subject !== '공지사항'}"> 공지사항
                          </button>
                          <button @click="notice.subject = '이벤트'" class="btn w-30 btn-dark ml-1"
                                  :class="{ 'disabled' : notice.subject !== '이벤트'}"> 이벤트
                          </button>-->
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;제목</th>
                        <td class="txt-left">
                          <input v-model="notice.title" type="text" class="form-control w-100per"/>
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>&nbsp;사용여부</th>
                        <td class="txt-left">
                          <label><input type="radio" v-model="notice.isUse" value="Y">사용</label>
                          <label class="ml-1"><input type="radio" v-model="notice.isUse" value="N">사용안함</label>
                          <!--<button @click="notice.isUse = 'Y'" class="btn w-30 btn-dark "
                                  :class="{ 'disabled' : notice.isUse === 'N'}"> 사용
                          </button>
                          <button @click="notice.isUse = 'N'" class="btn w-30 btn-dark ml-1"
                                  :class="{ 'disabled' : notice.isUse === 'Y'}"> 사용안함
                          </button>-->
                        </td>
                      </tr>
                      <tr>
                        <th><span class="red">*</span>내용</th>
                        <td class="txt-left editor">
                          <ckeditor :editor="editor" v-model="notice.content"
                                    :config="editorConfig"></ckeditor>
                        </td>
                      </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="clear mt-20">
                    <button type="submit" class="btn btn-primary fl"
                            @click="$router.push('/notice')">목록
                    </button>
                    <button type="submit" class="btn btn-primary fr ml-10" @click="postNotice()">
                      등록
                    </button>
                    <button type="submit" class="btn btn-primary fr" @click="showPreview()">미리보기</button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Menu from '../../components/commons/IncMenu.vue'
import Preview from "../../components/preview/pop_preview_notice.vue";

export default {
  name: 'notice',
  components: {
    Menu,
    Preview
  },
  props: {
    msg: String
  },
  data: () => ({
    notice: {
      idx: 0,
      idxEmp: 0,
      readCount: 0,
      title: "",
      subject: "공지사항",
      isUse: "Y",
      file1: "",
      file2: "",
      file3: "",
      content: "",
      createDate: "",
      updateDate: "",
      prevPage: 0,
      nextPage: 0,
      prevTitle: "",
      nextTitle: ""
    },
    is_preview: false,
    preview_notice: {},
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      // The configuration of the editor.
      filebrowserUploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor',
      ckfinder: {
        uploadUrl: 'https://platform-api.carrotenglish.net/v1/admin/editor'
      },
      allowedContent: true,
      height : 600
    },
  }),
  mounted() {
  },
  methods: {
    showPreview: function () {
      this.preview_notice = this.notice;
      this.is_preview = true;
    },

    vue_close: function (state) {
      this.is_preview = state;
    },

    initPage: function () {

    },

    postNotice: function () {
      const url = this.$store.state.host + "/v1/admin/notices";

      if(this.notice.title === null || this.notice.title ===  ''){
        alert('제목을 입력해주세요.');
      }else if(this.notice.content === null || this.notice.content === ''){
        alert('내용을 입력해주세요.');
      }else{
        this.axios.post(url, this.notice)
        .then((res) => {
          let data = res.data;
          if (data.status === 150) {
            alert("공지사항 작성에 실패했습니다. " + data.result)
          } else {
            alert("공지사항 작성이 완료되었습니다.")
            this.$router.push("/notice");
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }
    },

    selectImage: function (type) {
      let formData = new FormData();
      const url = this.$store.state.host + "/v1/admin/notices/file";
      let fileRefs;
      if (type === "file1") {
        fileRefs = this.$refs.file1.files[0];
      } else if (type === "file2") {
        fileRefs = this.$refs.file2.files[0];
      } else if (type === "file3") {
        fileRefs = this.$refs.file3.files[0];
      }

      formData.append('file', fileRefs);

      this.axios.post(url, formData, {headers: {'Content-Type': 'multipart/form-data'}})
      .then((res) => {
        if (type === "file1") {
          this.notice.file1 = res.data.result;
        } else if (type === "file2") {
          this.notice.file2 = res.data.result;
        } else if (type === "file3") {
          this.notice.file3 = res.data.result;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
