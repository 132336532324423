<template>
  <div class="carrot">

    <div class="main-wrapper">
      <Menu></Menu>
      <div class="page-wrapper">
        <div class="page-content ">
          <div class="row">
            <div class="col-xl-12">
              <h3 class="page-title">데일리 라이팅 관리</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <table class="table table-bordered">
                    <thead>
                    <tr>
                      <th style="width: 10%">구분</th>
                      <td>
                        <select class="select" v-model="filter.ctype">
                          <option value="0">전체</option>
                          <option value="2">일반컨텐츠</option>
                          <option value="1">기업컨텐츠</option>
                        </select>
                      </td>
                      <th style="width: 10%">Company</th>
                      <td>
                        <input v-model="filter.company" type="text" placeholder="검색할 회사명을 입력하세요."
                               class="form-control textwidth100">
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 10%">키워드</th>
                      <td colspan="3" class="tl">
                        <input v-model="filter.keyword" type="text" placeholder="검색 키워드를 입력하세요."
                               class="form-control">
                      </td>
                    </tr>
                    </thead>
                  </table>
                  <div class="text-right mt-2">
                    <button @click="getDailyWritings(filter)" type="submit" class="btn btn-primary"> 검색</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt-20">
              <div class="card">
                <div class="card-body">
                  <div class="form-group float-left clear w-100per">
                    <button type="submit" class="btn btn-primary fr ml-10"
                            @click="$router.push('/dwadd')">등록
                    </button>
                  </div>
                  <div class="table-responsive text-center" style="overflow-y: hidden;">
                    <table class="table table-hover table-bordered">
                      <tr>
                        <th style="width: 5%">No</th>
                        <th style="width: 5%">등급</th>
                        <th style="width: 5%">발송날짜</th>
                        <th style="width: 5%">작성날짜</th>
                        <th>해석</th>
                        <th>스크립트</th>
                        <th style="width: 5%">사용유무</th>
                      </tr>
                      <tr v-for="(item,index) in dws" :key="item.idx">
                        <td>{{ (result.total - (result.currentPage - 1) * result.perPage) - index }}</td>
                        <td>{{ (item.difficulty === 'A') ? '중급' : '초급'}}</td>
                        <td>{{
                            (item.ndate !== "") ? $moment(item.ndate).format('YYYY-MM-DD') : ''
                          }}
                        </td>
                        <td>{{
                            (item.createdate !== null) ? $moment(item.createdate).format(
                                'YYYY-MM-DD') : ''
                          }}
                        </td>
                        <td><a :href="'/dwinfo/' + item.idx">{{ item.q1Kor }}</a></td>
                        <td>{{ item.q1Eng }}</td>
                        <td>{{ item.isUse }}</td>
                      </tr>
                      <tr v-if="wLastpage === 0">
                        <td colspan="6">데일리 라이팅이 존재하지 않습니다.</td>
                      </tr>
                    </table>

                    <div style="padding-left:40%; padding-top:20px;">
                      <paginate :page-count="wLastpage" :page-range="3" :margin-pages="2"
                                :click-handler="clickCallback" :prev-text="'Prev'"
                                :next-text="'Next'" :container-class="'pagination'"
                                :page-class="'page-link'">
                      </paginate>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Menu from '../../components/commons/IncMenu.vue'
import Paginate from 'vuejs-paginate';
import DailyWritingComponent from "@/components/model/dailyWritingComponent";

export default {
  mixins: [DailyWritingComponent],
  name: 'product',
  components: {
    Paginate,
    Menu,
  },
  props: {
    msg: String
  },
  data: () => ({
    dws: [],
    wLastpage: 1
  }),
  mounted() {
    this.initPage();
  },
  methods: {
    clickCallback: function (pageNum) {
      this.filter.page = pageNum;
      this.initPage();
    },
    initPage: function () {
      this.getDailyWritings(this.filter);
    },
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

</style>
