<template>
  <div class="dim" v-if="visible">
    <div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog reason-modal" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel">탈퇴사유</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close()"><span aria-hidden="true">×</span></button>
          </div>
          <div class="modal-body">
            <div>
              <div class="dp-block">
                <label for="rb1">
                  <input type="radio" id="rb1" value="원하는 서비스가 아니어서" v-model="reasonSelected" />
                  <span class="ml-1">원하는 서비스가 아니어서</span>
                </label>
              </div>

              <div class="dp-block">
                <label for="rb2">
                  <input type="radio" id="rb2" value="사용빈도가 낮아서" v-model="reasonSelected" />
                  <span class="ml-1">사용빈도가 낮아서</span>
                </label>
              </div>

              <div class="dp-block">
                <label for="rb3">
                  <input type="radio" id="rb3" value="알림이 너무 자주 와서" v-model="reasonSelected" />
                  <span class="ml-1">알림이 너무 자주 와서</span>
                </label>
              </div>

              <div class="dp-block">
                <label for="rb4">
                  <input type="radio" id="rb4" value="앱 사용이 불편해서" v-model="reasonSelected" />
                  <span class="ml-1">앱 사용이 불편해서</span>
                </label>
              </div>
              <div class="dp-block">
                <label for="rb5">
                  <input type="radio" id="rb5" value="콘텐츠가 도움이 안되서" v-model="reasonSelected" />
                  <span class="ml-1">콘텐츠가 도움이 안되서</span>
                </label>
              </div>

              <div class="dp-block">
                <label for="rb6">
                  <input type="radio" id="rb6" value="개인정보가 걱정되서" v-model="reasonSelected" />
                  <span class="ml-1">개인정보가 걱정되서</span>
                </label>
              </div>
              <div class="dp-block">
                <label for="rb7">
                  <input type="radio" id="rb7" value="직접입력" v-model="reasonSelected" />
                  <span class="ml-1">직접입력</span>
                </label>
              </div>

              <validation-provider rules="required" ref="reason" name="탈퇴사유">
                <input type="hidden" v-model="reasonSelected" />
              </validation-provider>

              <validation-provider :rules="reasonSelected === '직접입력' ? 'required|max:150' : ''" ref="reasonOther" name="기타사유">
                <div class="dp-block">
                  <textarea class="form-control" :disabled="reasonSelected !== '직접입력' ? true : false" v-model="reasonOther" placeholder="기타사유를 입력해주세요."></textarea>
                </div>
              </validation-provider>

              <div class="clear">
                <button type="button" class="btn btn-danger fr mt-3" @click="submit">회원 탈퇴</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reason",
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false,
    },
    userIdx: {
      type: String,
      require: true,
      default: "-1",
    },
  },
  data: () => ({
    reasonSelected: "",
    reasonOther: "", //기타입력
  }),
  //데이터 감시
  watch: {
    reasonSelected(curVal) {
      if (curVal !== "직접입력") {
        this.reasonOther = "";
      }
    },
  },
  methods: {
    /**
     * @description : 회원탈퇴 API
     */
    apiResign() {
      const url = this.$store.state.host + "/v1/admin/users/" + this.userIdx + "?leaveReason=" + this.reasonSelected;

      if (confirm("해당 회원을 탈퇴처리 하시겠습니까?")) {
        this.axios
          .delete(url)
          .then((res) => {
            const data = res.data;
            if (data.status === 0) {
              alert("탈퇴처리가 완료되었습니다.");
              this.$emit("close", { modalvisible: false, deleteYn: "Y" });
            } else {
              alert("탈퇴처리가 실패하였습니다.");
            }
          })
          .catch((err) => {
            console.error("delete user error : ", err);
            alert(`network error = ${err}`);
          });
      }
    },
    /**
     * @description : 모달 close
     */
    close() {
      this.$emit("close", { modalvisible: false, deleteYn: "N" });
    },

    /**
     * @description: submit
     */
    async submit() {
      const result = await this.$refs.reason.validate();

      if (!result.valid) {
        alert("탈퇴사유를 선택해주세요.");
        return false;
      }

      const { validate: reasonOtherValidate } = this.$refs.reasonOther;
      const otherReason = await reasonOtherValidate();
      if (!otherReason.valid) {
        alert(otherReason.errors[0]);
        return false;
      }

      this.apiResign();
    },
  },
};
</script>

<style lang="scss" scoped>
.dim {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: border-box;
  z-index: 1000;
}
.modal {
  display: block;
}

.reason-modal {
  max-width: 400px;
  min-width: 400px;
}

.dp-block {
  display: block;
}
</style>
