"use strict";

import Vue from 'vue';
import axios from "axios";
import router from "@/router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      var auth = localStorage.getItem("authorization");
      if (auth !== undefined) {
        config.headers.authorization = auth;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

_axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      var auth = localStorage.getItem("authorization");
      if (auth !== undefined) {
        config.headers.authorization = auth;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      console.log(response);

      ///새토큰 발급
      if (response.data.newToken != null) {
        localStorage.setItem("authorization", response.data.newToken)
      }

      ///에러처리
      if (response.data.status === 106) {
        router.push('/login');
      }

      return response;
    },
    function (error) {

      // Do something with response error
      if (error.response) {
        // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
        console.log('요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.');
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // 요청이 이루어 졌으나 응답을 받지 못했습니다.
        // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
        // Node.js의 http.ClientRequest 인스턴스입니다.
        console.log('요청이 이루어 졌으나 응답을 받지 못했습니다.', error.request);
      } else {
        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
        console.log('오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.', error.message);
      }
      console.log(error.config);

      return Promise.reject(error);
    }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
