<script>

export default {
  name: "dailyWritingComponent",

  data: () => ({
    member: {
      mobile: ''
    },
    pushes: {},
    subscribes: [],
    members: [],
    count: {},
    filter: {
      page: 1,
      ctype: "0",
      company: null,
      keyword: null,
    },
    wRewardsFilter: {
      page: 1,
      kname: null,
      company: null,
      keyword: null,
      mobile: null,
      sdate:null,
      edate:null,
      isSend: false
    },
    memberFilter: {
      name: '',
      email: '',
      companyname: '',
      mobile: '',
      division: '',
      membership: '',
      page: 1
    },
    overviewFilter:{
      sdate:'',
      edate:'',
      kname:'',
      company:'',
      mobile:'',
      page:1
    },
    wLastpage: 1,
    dwContent: {
      idx: '',
      ndate: '',
      ctype: '1',
      companyCode: '1',
      company: '',
      q1Kor: '',
      q1Eng: '',
      q1Keyword: '',
      q1Itm1Kor: '',
      q1Itm1Eng: '',
      q1Itm1Mp3: '',
      q1Itm2Kor: '',
      q1Itm2Eng: '',
      q1Itm2Mp3: '',
      q2Txt: '',
      q2Itm1: '',
      q2Itm2: '',
      q2Itm3: '',
      q2Itm4: '',
      q3Kor: '',
      q3Eng: '',
      q3Itm1Kor: '',
      q3Itm1Eng: '',
      q3Itm1Mp3: '',
      q3Itm2Kor: '',
      q3Itm2Eng: '',
      q3Itm2Mp3: '',
      q3Itm3Kor: '',
      q3Itm3Eng: '',
      q3Itm3Mp3: '',
      q4Eng: '',
      q4Ans: '',
      isUse: '',
      difficulty: 'A'
    },
    dwnRewards: [],
    dwnOverView:[]
  }),

  methods: {
    checkCheckbox: function () {
      let checkReward = document.querySelectorAll(".check-rewards");
      let checkNum = 0;
      for (const element of checkReward) {
        if(element.checked){
          checkNum++;
        }
      }
      document.querySelector("#all-select").checked = (checkNum === checkReward.length);
    },
    allSelectDailyWritingRewards: function () {
      let checked = document.querySelector("#all-select").checked;
      for (const element of document.querySelectorAll(".check-rewards")) {
        element.checked = checked;
      }
    },
    updateDailyWritingRewardsStatus: function () {
      let idxRewards = [];

      let checkReward = document.querySelectorAll(".check-rewards");
      for (const element of checkReward) {
        if(element.checked){
          idxRewards.push(element.value)
        }
      }

      if(idxRewards.length > 0){
        const params = this.getParams({
          idxRewards: idxRewards
        })
        const url = this.$store.state.host + "/v1/admin/dw/rewards";
        this.axios.put(url, params)
        .then((res) => {
          console.log(res)
          if (res.data.status === 0) {
            this.getDailyWritingRewards();
          }
        })
        .catch(error =>{ alert(`network error = ${error}`)});
      }else{
        alert('리워드 대상자를 선택해주세요.')
      }
    },
    downloadDailyWritingRewardsStatus: function () {
      const params = this.getParams(this.wRewardsFilter);
      const searchParams = new URLSearchParams(params);
      let rewardsUrl = "/v1/admin/excel/rewards?" + searchParams.toString();
      console.log(this.$store.state.host + rewardsUrl);
      location.href=this.$store.state.host + rewardsUrl;

    },
    getDailyWritingRewards: function () {
      const params = this.getParams(this.wRewardsFilter);
      const url = this.$store.state.host + "/v1/admin/dw/rewards";
      this.axios.get(url, {params})
      .then((res) => {
        if (res.data.status === 105) {
          this.dwnRewards = [];
          this.wLastpage = 0;
        } else {
          console.log(res.data,'daily')
          this.result = res.data.result;
          this.dwnRewards = res.data.result.value;
          this.wLastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getDailyWritingOverView(pageNum){
      const params = this.getParams(this.overviewFilter);
      params['page'] = pageNum;
      console.log(params);
      const url = this.$store.state.host + "/v1/admin/dw/attends";
      this.axios.get(url,{params}).then((res)=>{
        // console.log(res);
        if(res.data.status === 105){
          this.dwnOverView = [];
          this.lastpage = 0;
        }else{
          this.dwnOverView = res.data.result.value;
          this.result = res.data.result;
          this.lastpage = res.data.result.lastPage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },

    getOverViewExcel(){
      // const params = this.getParams(this.overviewFilter);
      // console.log(params);
      // const url = this.$store.state.host + "/v1/admin/excel/attends";
      // this.axios.get(url,{params}).then((res)=>{
      //   console.log(res);

      // });
      // console.log("aa");
      console.log(this.$store.state.host +"/v1/admin/excel/attends?kname="
      +this.overviewFilter.kname
      +"&sdate="+this.overviewFilter.sdate
      +"&edate="+this.overviewFilter.edate
      +"&mobile="+this.overviewFilter.mobile
      +"&company="+this.overviewFilter.company);



      location.href=this.$store.state.host +"/v1/admin/excel/attends?kname="
      +this.overviewFilter.kname
      +"&sdate="+this.overviewFilter.sdate
      +"&edate="+this.overviewFilter.edate
      +"&mobile="+this.overviewFilter.mobile
      +"&company="+this.overviewFilter.company
    },

    getOverViewDateExcel(){
      location.href=this.$store.state.host +"/v1/admin/excel/attends-date?kname="
          +this.overviewFilter.kname
          +"&sdate="+this.overviewFilter.sdate
          +"&edate="+this.overviewFilter.edate
          +"&mobile="+this.overviewFilter.mobile
          +"&company="+this.overviewFilter.company
    },


    moveList: function () {
      this.$router.push("/dw")
    },
    getDailyWritings: function (filter) {
      const params = {
        page: filter.page,
        ctype: (filter.ctype !== "0") ? filter.ctype : null,
        keyword: filter.keyword,
        company: filter.company,
      }

      const url = this.$store.state.host + "/v1/admin/dw";
      this.axios.get(url, {params})
      .then((res) => {
        if (res.data.status === 105) {
          this.dws = [];
          this.wLastpage = 0;
        } else {
          this.result = res.data.result;
          this.dws = res.data.result.value;
          this.wLastpage = res.data.result.wLastpage;
        }
      })
      .catch(error =>{ alert(`network error = ${error}`)});
    },
    insertContent: function () {
      console.log(this.dwContent)
      const url = this.$store.state.host + "/v1/admin/dw";
      this.axios.post(url, this.dwContent)
      .then((res) => {
        if (res.data.status === 0) {
          alert('데일리 라이팅 컨텐츠 등록이 완료되었습니다.')
          this.$router.push("/dw")
        }
      })
      .catch((err) => {
        alert(`network error = ${err}`);
        console.log('err : ' + err);
      });
    },
    updateContent: function () {
      const url = this.$store.state.host + "/v1/admin/dw";
      this.axios.put(url, this.dwContent)
      .then((res) => {
        if (res.data.status === 0) {
          alert('데일리 라이팅 컨텐츠 업데이트가 완료되었습니다.')
          this.$router.push("/dw")
        }
      })
      .catch((err) => {
        alert(`network error = ${err}`);
        console.log('err : ' + err);
      });
    },
    selectContentOne: function (idx) {
      const url = this.$store.state.host + "/v1/admin/dw/" + idx;
      this.axios.get(url, {})
      .then((res) => {
        this.dwContent = res.data.result;
      })
      .catch((err) => {
        alert(`network error = ${err}`);
        console.log('err : ' + err);
      });
    },
    addCompany: function (companyInfo) {
      this.dwContent.company = companyInfo.companyname;
      this.dwContent.companyCode = companyInfo.regid;
    },
  }
}
</script>

