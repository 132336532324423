<template>
  <div id="main">
    <div id="index">
      <h2>통합플랫폼 베타버전</h2><br>
      <h3><a :href="android_url">Android 앱 다운받기</a></h3> 업데이트 일자 {{ android_update_date }} <br>
      <h3><a :href="ios_url">iOS 앱 다운받기</a></h3> 업데이트 일자 {{ ios_update_date }}
    </div>
  </div>
</template>

<script>
export default {
  name: "download",

  mounted() {
    this.axios.get(this.$store.state.host + "/url")
    .then((res) => {
      let anroid = res.data.result[0];
      let ios = res.data.result[1];
      this.android_url = anroid.url;
      this.android_update_date = anroid.update_date;
      this.ios_url = ios.url;
      this.ios_update_date = ios.update_date;
    })
    .catch(error =>{ alert(`network error = ${error}`)});
  },

  data: () => ({
    android_url: '',
    android_update_date: '',
    ios_url: '',
    ios_update_date: ''
  }),

}
</script>

<style scoped>

#index {
  text-align: center;
  margin-top: 50px;
}

</style>
